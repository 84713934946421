import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from 'web/components/loading_overlay';
import SignUpForm, {Errors} from 'web/components/sign_up_form';

import Disclaimer from '../disclaimer';

export default class FlowSignUpScreen extends Component {
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmitSignUpForm();
  }

  render() {
    return (
      <div className="flow-sign-up">
        <h1 className="sign-in-flow__title">Sign Up</h1>

        <div className="auth-flow__form-sign">
          <Errors
            className="flow-sign-up__errors"
            error={this.props.error}
            signInAction={this.props.onClickErrorSignIn}
          />

          <SignUpForm
            excludePhone
            excludePassword={false}
            onSubmit={this.handleSubmit.bind(this)}
          />
        </div>

        <Disclaimer />

        {this.props.isWorking ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

FlowSignUpScreen.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customerMessage: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  onSubmitSignUpForm: PropTypes.func,
  onClickErrorSignIn: PropTypes.func,
};
