import React, {FC} from 'react';

import segmentAnalytics from '@analytics/client';
import useRecentSearches from 'web/hooks/useRecentSearches';
import useClientSettings from 'web/hooks/useClientSettings';

interface RecentSearchesProps {
  onItemClick?: (searchTerm: string) => void;
}

const RecentSearches: FC<RecentSearchesProps> = ({onItemClick}) => {
  const {recentSearches} = useRecentSearches();
  const clientSettings = useClientSettings();

  const handleClickItem = (item: string): void => {
    segmentAnalytics.track('siteSearched', {
      query: item,
      queryType: 'recent',
      index: clientSettings.search.algolia.index,
    });
    onItemClick?.(item);
  };

  return recentSearches.length > 0 ? (
    <div className="search-suggestions__recent-searches">
      <h3>Recent Searches</h3>
      <div className="recent-searches">
        {recentSearches.map((item: string) => (
          <div
            key={item}
            onClick={(): void => handleClickItem(item)}
            className="recent-search-item"
            data-testid="recent-search-item"
          >
            <i className="icon icon-recent-clock recent-search-icon" />
            <span className="recent-search-label">{item}</span>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default RecentSearches;
