import React, {ComponentType, PropsWithChildren} from 'react';
// TODO: (guilherme-vp) use react-dom/client
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {Action, AnyAction, applyMiddleware, createStore, Reducer, Store} from 'redux';
import thunk, {ThunkDispatch} from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import PageEvents from '@analytics/client/page_events';
import segmentAnalytics from '@analytics/client';
import cookieHelper from 'web/helpers/cookies';
import assetPath from 'web/helpers/asset_path';
import {ClientSettingsProvider} from 'web/hooks/useClientSettings';

import {initWindowServices, initSession} from './client';

// TODO(serhalp) This doesn't appear to make a ton of sense and seems pretty brittle and dangerous.
// There is no actual guaranteed type AFAICT for this `storeData` at this point, that this file can
// assume. We could _maybe_ - and this isn't great either - argue for assuming that every server
// render ultimately uses the `basicStoreBuilder` one way or another, and import that type here.  As
// is, this is an arbitrary grab-bag of general properties from the `basicStoreBuilder` (`session`,
// `user`, `features`) and properties that are specific to specific layouts / store builders (`app`,
// seemingly specific to the MarketLayout).
//
// P.S. there's also an implicitly used by not defined `disableSegment` property below, which is
// also from `basicStoreBuilder`.
export interface StoreData extends Record<string, unknown> {
  session?: {
    id?: string | null;
  };
  user?: {
    id: string;
    email?: string | null;
    masquerading?: boolean;
  };
  app?: {
    name: string;
    instance: string;
    commitSHA: string;
  };
  features?: string[];
}

export type AppDispatch = ThunkDispatch<any, any, AnyAction>;

declare global {
  interface Window {
    __data?: StoreData;
    store?: Store<StoreData>;
  }
}

export type PageType<
  TStoreData = StoreData,
  TActions extends Action = AnyAction,
  TProps = Record<string, unknown>,
> = ComponentType<PropsWithChildren<TProps>> & {
  reducer: Reducer<TStoreData, TActions>;
  pageName: string | false;
};

// Page - Ex: web/inspire2/content_piece_page
// storeData - ex: {foo: 'bar'}
export function startReactRedux(Page: PageType, storeData: StoreData): void {
  initWindowServices({
    disableSegment: Boolean(storeData.disableSegment),
    features: storeData.features ?? [],
  });

  // For the use case of a user arriving from a MWA page, a Segment anonymous ID cookie would
  // already exist when the user clicked to a Garbanzo page. To accurately report Analytics
  // sign up conversions, if there is an existing Segment anonymousId cookie use it before
  // falling back to Redux session ID.
  // TODO(serhalp) Review fallback behavior here. Is it really expected that both could be missing?
  // If so, what should the behavior be? It seems like it would throw an error downstream anyway.
  const anonymousId = (
    cookieHelper.getCookie('ajs_anonymous_id') ??
    storeData.session?.id ??
    ''
  ).replace(/[\\"]/g, ''); // the segment cookie gets stored with "" wrapping the string

  initSession({
    sessionId: anonymousId,
    userId: storeData.user?.id,
    email: storeData.user?.email,
    masquerading: storeData.user?.masquerading,
  });

  if (storeData.app) {
    const {app} = storeData;
    window.metrics.setPageProperty('app', {
      name: app.name,
      instance: app.instance,
      commitSHA: app.commitSHA,
    });
    segmentAnalytics.setContextField('app', {
      name: 'garbanzo',
      instance: app.instance,
      commitSHA: app.commitSHA,
    });
  }

  // We shouldn't need this manifest loaded here once our public assets are loaded properly in webpack
  assetPath.load(storeData.assetsManifest);

  if (!(Page.pageName === false) && !Page.pageName && window.console && window.console.error) {
    window.console.error('Page missing pageName required for metrics tracking');
  }

  const middlewares = [thunk];
  const store = createStore<StoreData, AnyAction, unknown, unknown>(
    Page.reducer,
    storeData,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
  window.store = store; // used from nettle
  window.__data = storeData;

  /**
   * React Axe
   * Needs to run in the dev environment only
   * and needs to be excluded from the production build
   */
  if (window.settings.env === 'development' && process.env.NODE_ENV !== 'production') {
    const axe = require('@axe-core/react'); // eslint-disable-line global-require
    const config = require('./react_axe.config').default; // eslint-disable-line global-require
    axe(React, ReactDOM, 1000, config);
  }

  // FIXME: (guilherme-vp) ReactDOM >= 18 uses a new approach to hydrate the DOM in a concurrent mode.
  // Replace `hydrate` with `hydrateRoot` and figure out how we can track `PageEvents` at the end of
  // the components hydration in this concurrent method... We should pass a callback to the inner component
  // and use it to trigger the `PageEvents` function, but this is very complicated since we use teacup to
  // render our content and not a JSX component in `redux_layout.js`.
  // That being said, when ReactDOM encounters this, it will throw a warning in console saying that React will
  // behave as React 17 since this method is not supported in v18, this is the last piece keeping us from
  // upgrading to v18.
  ReactDOM.hydrate(
    <HelmetProvider>
      <ClientSettingsProvider value={window.settings}>
        <Provider store={store}>
          <Page />
        </Provider>
      </ClientSettingsProvider>
    </HelmetProvider>,
    document.getElementById('redux-root'),
    function () {
      document.body.className += ' react-ready';
      // Track page view - we do this after `render` so the page
      // can set extra pageProperties via componentDidMount.
      if (Page.pageName !== false) {
        PageEvents(Page.pageName, storeData, location, document);
      }
    },
  );
}
