import {postJson} from 'web/helpers/api_helpers';

export async function redeemGiftCard(code: string): Promise<{
  amountRedeemed: number;
  currentBalance: number;
}> {
  return postJson('/api/gift-cards/redeem', {body: {code}});
}

export async function applyPromoCode(code: string): Promise<{success: boolean}> {
  return postJson('/api/basket/update-promo-code', {body: {code}});
}

export async function removePromoCode(): Promise<{success: boolean}> {
  return postJson('/api/basket/update-promo-code', {body: {}});
}
