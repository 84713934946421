export interface StringType {
  tagline: string;
  error: Record<string, string>;
  enterCode: string;
}

const strings: StringType = {
  tagline: 'Absurdly fresh groceries, delivered.',
  error: {
    /* eslint-disable @typescript-eslint/naming-convention */
    GIFT_CARD_REDEEM_ALREADY_REDEEMED: 'Gift card <%= code %> has already been redeemed.',
    GIFT_CARD_REDEEM_INVALID: 'Invalid gift card code <%= code %>.',
    GIFT_CARD_REDEEM_INVALID_CODE: 'Invalid gift card code <%= code %>.',
    GIFT_CARD_REDEEM_FAILURE: 'Unexpected failure redeeming a gift card.',
    /* eslint-enable @typescript-eslint/naming-convention */
  },
  enterCode: 'Add a Promo/Gift/School Code',
};

export default strings;
