import React, {FC, MouseEvent, PropsWithChildren, useEffect} from 'react';
import classNames from 'classnames';

export interface ModalProps {
  className?: string;
  showCloseButton?: boolean;
  fullScreenMobile?: boolean;
  onClose: () => void;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  className,
  showCloseButton,
  fullScreenMobile,
}) => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const onKeyDown = (e: globalThis.KeyboardEvent): void => {
    if (/^Esc$|^Escape$/.test(e.key)) {
      onClose();
    }
  };

  const handleClose = (e: MouseEvent): void => {
    e.stopPropagation();
    onClose();
  };

  const onModalClick = (e: MouseEvent): void => {
    e.stopPropagation();
  };

  const baseClass = fullScreenMobile ? 'ui-modal-full-screen-mobile' : 'ui-modal';
  return (
    <div
      className={classNames(
        'ui-modal__layer',
        'ui-modal__layer-is-shown',
        `ui-modal__${className != null ? className : ''}`,
      )}
      data-testid="ui-modal__container"
      onClick={handleClose}
    >
      <div className={`${baseClass}__vertical-align-container`} onClick={handleClose}>
        <div className={`${baseClass}__vertical-align-cell`} onClick={handleClose}>
          <div onClick={onModalClick} className={classNames(`${baseClass}__modal`, className)}>
            {showCloseButton ? (
              <div onClick={handleClose} className="ui-modal__close-button">
                <a className="ui-modal__x">
                  <i className="icon icon-thin-x" />
                </a>
              </div>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
