import Cents from 'goodeggs-money';

import {Foodhub} from 'web/basket/basket_page/index';
import {SerializedUIBasket} from 'domain/baskets/serializer';
import {ProductAvailabilityStatus} from 'domain/catalog/products/types';
import segmentAnalytics from '@analytics/client';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';

import {ProductAdded} from './generated';

interface Category {
  id: string;
  name: string;
  subcategories: Category[];
  url: string;
}

interface Product {
  id: string;
  currentProducer: {name: string};
  name: string;
  photoUrl: string;
  retailPrice: number;
  availabilitiesByDay?: string[];
  status: ProductAvailabilityStatus;
}

interface RootState {
  category: Category;
  subcategory?: string;
  queryID?: string;
  user: {masquerading: boolean};
  products: Record<string, Product>;
  basket: SerializedUIBasket;
  productDetails: {
    breadCrumbs?: {
      category: Category;
      subcategory: Category;
    };
  };
  features?: FeatureFlags;
  foodhub: Foodhub;
}

export interface ProductAddedParams {
  productId: string;
  quantity: number;
  position?: number;
  state: RootState;
  context?: Record<string, any>;
}

function getCategoryIdByUrl(categories: Category[], url: string): string | undefined {
  const category = categories.find((c) => c.url === url);
  return category?.id;
}

export function trackProductAdded({
  productId,
  quantity,
  position,
  state,
  context,
}: ProductAddedParams): void {
  const {category, subcategory, queryID, user, products, basket, productDetails, foodhub} = state;
  const product = products[productId];
  const price = product == null ? 0 : product.retailPrice;
  const availabilitiesByDay =
    product?.availabilitiesByDay != null ? Object.keys(product?.availabilitiesByDay).join() : '';
  // Keys are defined and set in the Segment protocol
  const data: ProductAdded = {
    basketId: basket?._id,
    category: category?.id || productDetails?.breadCrumbs?.category.name.toLowerCase() || undefined,
    coupon: basket.promoCodes?.map((promo) => promo.code).join(),
    daysAvailable: availabilitiesByDay,
    feature: context?.feature ?? context?.context?.__wrapped__?.feature,
    imageUrl: product?.photoUrl,
    index: window.settings?.search?.algolia?.index,
    loggedIn: user != null,
    masquerading: user == null ? false : user.masquerading,
    name: product?.name,
    objectID: productId,
    position: position != null && position > -1 ? position : undefined,
    price: new Cents(price).toDollars(),
    producer: product?.currentProducer.name,
    productId,
    quantity,
    queryID,
    sku: productId,
    subCategory:
      subcategory ||
      context?.subcategoryName ||
      getCategoryIdByUrl(
        category?.subcategories ?? [],
        window.location.href.split(window.location.host)[1],
      ) ||
      productDetails?.breadCrumbs?.subcategory.name.toLowerCase() ||
      undefined,
    underMinimum: (basket?.totals?.amountToMinimum ?? -1) > 0,
    value: new Cents(quantity * price).toDollars(),
    /* Add the new region properties to the product added protocol */
    regionName: foodhub?.slug || '',
    regionFulfillmentCenter: foodhub?.slug || '',
    engine: context?.engine,
    moduleName: context?.moduleName,
    moduleType: context?.moduleType,
  };

  segmentAnalytics.track('productAdded', data);
}
