import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client';

export function createClient(): ApolloClient<unknown> {
  return new ApolloClient({
    name: 'web',
    link: new HttpLink({
      uri: '/graphql',
    }),
    cache: new InMemoryCache(),
  });
}

/**
 * Remove the "GraphQL error: " content from the error.message
 *
 * @param {Error} error the reference is changed
 * @returns {Error} Error object with message field sanitized
 */
export const sanitizeGraphQLError = (error: Error): Error => {
  try {
    error.message = error.message.replace('GraphQL error: ', '');
  } catch {
    // do nothing
  }

  return error;
};

export default createClient();
