import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  FormEvent,
  MouseEvent,
  FC,
} from 'react';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {debounce} from 'lodash';
import qs from 'query-string';

import useRecentSearches from 'web/hooks/useRecentSearches';
import segmentAnalytics from '@analytics/client';
import useClientSettings from 'web/hooks/useClientSettings';
import {SerializedUiMarketCategory} from 'web/helpers/serializers/category';

import SearchSuggestions from '../search_suggestions';
import CategoryDropdown from './category';

const CATEGORY_MORE_CUTOFF = 1100;

export interface DesktopCategoriesProps {
  categories: SerializedUiMarketCategory[];
  selectedNavId?: string;
}

const DesktopCategories: FC<DesktopCategoriesProps> = ({categories, selectedNavId}) => {
  const [isMoreSelected, setIsMoreSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const {addRecentSearch} = useRecentSearches();
  const [selectedSearchTerm, setSelectedSearchTerm] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const {search} = useClientSettings();
  const displayedSearchTerm = selectedSearchTerm ?? searchTerm;

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (/^Esc$|^Escape$/.test(e.key)) handleCancelSearch();
  }, []);

  const lastTwoCategories = useMemo(
    () => categories.filter((category) => !category.hidden).slice(-2),
    [categories],
  );

  const updateMoreSelected = useCallback(() => {
    const width = window.innerWidth;
    if (width < CATEGORY_MORE_CUTOFF) {
      setIsMoreSelected(lastTwoCategories.some(({id}) => id === selectedNavId));
    }
  }, [lastTwoCategories, selectedNavId]);

  const handleClickSearch = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    setIsSearching(true);
    document.body.style.overflow = 'hidden';
    window.scrollTo(0, 0);
  };

  const handleCancelSearch = (): void => {
    setIsSearching(false);
    document.body.style.overflow = 'unset';
  };

  const redirectToSearchPage = (term: string): void => {
    const searchUrl = qs.stringifyUrl({url: '/search', query: {q: term}});

    window.location.href = searchUrl;
  };

  const handleSubmitSearch = (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    if (searchTerm) {
      addRecentSearch(displayedSearchTerm);
      segmentAnalytics.track('siteSearched', {
        query: searchTerm,
        queryType: 'userSubmitted',
        index: search.algolia.index,
      });

      redirectToSearchPage(displayedSearchTerm);
    }
  };

  const listener = debounce(updateMoreSelected, 200);

  useEffect(() => {
    document.addEventListener('keydown', escapeListener);
    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, [escapeListener]);

  useEffect(() => {
    window.addEventListener('resize', listener);
    updateMoreSelected();
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [listener, updateMoreSelected]);

  const lastTwoCategoryIndices = [categories.length - 1, categories.length - 2];

  return isSearching ? (
    <div>
      <div
        className="marketplace-hider marketplace-hider-lg"
        data-testid="marketplace-hider"
        onClick={handleCancelSearch}
      />
      <CSSTransition
        appear
        classNames="desktop-header__inline-transition"
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
      >
        <form className="desktop-header__inline-search" onSubmit={handleSubmitSearch}>
          <input
            className="desktop-header__inline-search-input form-control"
            type="text"
            placeholder="Search Groceries"
            autoFocus
            onBlur={(e) => {
              e.target.focus();
            }}
            ref={inputRef}
            value={displayedSearchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value.substring(0, 256));
            }}
          />
          <div className="desktop-header__inline-search-right-pill" onClick={handleSubmitSearch}>
            <i className="icon icon-search desktop-header__inline-search-icon" />
          </div>
          <div className="desktop-header__inline-search-cancel" onClick={handleCancelSearch}>
            Cancel
          </div>
          <SearchSuggestions
            rootRef={inputRef}
            searchTerm={searchTerm}
            onSearchItemClick={(item) => {
              addRecentSearch(item);
              redirectToSearchPage(item);
            }}
            onChangeSelectedItem={(selected) => {
              setSelectedSearchTerm(selected);
            }}
            onItemClick={(item) => {
              addRecentSearch(item);
              redirectToSearchPage(item);
            }}
          />
        </form>
      </CSSTransition>
    </div>
  ) : (
    <div className="desktop-header-row__categories">
      {categories.map((category, index) => {
        return (
          !category.hidden && (
            <CategoryDropdown
              className={classNames({
                'desktop-header__include-in-more': lastTwoCategoryIndices.includes(index),
              })}
              category={category}
              key={category.id}
              flyout
              selected={selectedNavId === category.id}
            />
          )
        );
      })}
      <CategoryDropdown
        category={{
          id: 'more',
          name: '',
          shortName: 'More ▾',
          url: '',
          subcategories: lastTwoCategories,
        }}
        flyout
        selected={isMoreSelected}
      />
      <div className="right-border desktop-header__category" />
      <div
        className={classNames('desktop-header__standalone-text-container', {
          current: selectedNavId === 'reorder',
        })}
      >
        <a className="remote" href="/reorder">
          {' '}
          Reorder{' '}
        </a>
      </div>
      <div className="desktop-header__search-container">
        <a className="remote" onClick={handleClickSearch} href="/search">
          <i className="icon icon-search" data-testid="icon-search" />
        </a>
      </div>
    </div>
  );
};

export default DesktopCategories;
