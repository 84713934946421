import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {groupBy} from 'lodash';

import moment from 'infra/moment';
import {defaultError} from 'infra/formatters/error';
import {humanTimeRange} from 'infra/formatters/time';
import Alert from 'web/components/alert';
import ToggleSwitch from 'web/components/toggle_switch';
import Modal from 'web/components/modal';
import SubmitButton from 'web/components/submit_button';

import {actions} from './duck';

class ScheduledDelivery extends Component {
  getStatusText() {
    switch (this.props.delivery.status) {
      case 'delivered': {
        return 'Delivered';
      }
      case 'paid': {
        return 'In the works';
      }
      case 'pending': {
        return 'Upcoming';
      }
      case 'placed': {
        return 'Upcoming';
      }
      case 'cancelled': {
        return 'Cancelled';
      }
      case 'scheduled': {
        return 'Scheduled';
      }
      case 'skipped': {
        return 'Skipped';
      }
      case 'unavailable': {
        return 'Unavailable';
      }
      default: {
        return this.props.delivery.status;
      }
    }
  }

  onChangeSkip() {
    if (this.isScheduled()) {
      this.props.skip(this.props.delivery.subscriptionId, this.props.delivery.day);
    } else {
      this.props.unskip(this.props.delivery.subscriptionId, this.props.delivery.day);
    }
  }

  isOrderEditable() {
    return (
      this.props.delivery.orderId && ['pending', 'placed'].includes(this.props.delivery.status)
    );
  }

  orderPath() {
    return `/account/orders/${this.props.delivery.orderId}`;
  }

  isScheduled() {
    return this.props.delivery.status === 'scheduled';
  }

  render() {
    const {delivery} = this.props;

    return (
      <div
        className={`account-page__subscription-schedule__delivery ${
          this.props.delivery.orderId ? 'account-page__subscription-schedule__delivery-order' : ''
        } ${this.props.className}`}
        id={`js-subscription-schedule-${delivery.subscriptionId}-${delivery.day}`}
        data-testid={`js-subscription-schedule-${delivery.subscriptionId}-${delivery.day}`}
      >
        <div className="account-page__subscription-schedule__details">
          <span className="account-page__subscription-schedule__title">
            {moment.tz(delivery.startAt, delivery.tzid).format('dddd, MMMM Do')}
          </span>
          <span className="account-page__subscription-schedule__description">
            {delivery.address},{' '}
            <span className="account-page__subscription-schedule__window">
              {humanTimeRange(delivery.startAt, delivery.endAt, delivery.tzid)}
            </span>
          </span>
        </div>

        <div className="account-page__subscription-schedule__status">
          <span className="account-page__subscription-schedule__status-text">
            {delivery.orderId && delivery.status !== 'cancelled' ? (
              <a
                className="account-page__subscription-schedule__order-link"
                href={this.orderPath()}
              >
                {this.getStatusText()}
              </a>
            ) : (
              this.getStatusText()
            )}
          </span>
          {delivery.unavailableCustomerReason ? (
            <span className="account-page__subscription-schedule__unavailable-customer-reason">
              {delivery.unavailableCustomerReason}
            </span>
          ) : null}
        </div>
        <div
          className={`account-page__subscription-schedule__controls ${
            this.props.editable && this.isOrderEditable() ? 'edit-button' : ''
          }`}
        >
          {this.props.editable ? (
            this.isOrderEditable() ? (
              <a className="button" href={this.orderPath()}>
                Edit {moment.tz(delivery.startAt, delivery.tzid).format('M/D')} Order
              </a>
            ) : delivery.isSkippable ? (
              <ToggleSwitch checked={this.isScheduled()} onChange={this.onChangeSkip.bind(this)} />
            ) : null
          ) : null}
        </div>
      </div>
    );
  }
}

ScheduledDelivery.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  skip: PropTypes.func.isRequired,
  unskip: PropTypes.func.isRequired,
  delivery: PropTypes.shape({
    subscriptionId: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    isSkippable: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    tzid: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    unavailableCustomerReason: PropTypes.string,
  }).isRequired,
};

class SubscriptionCancelOptions extends Component {
  getCancelOptionString(subscription) {
    const startAt = moment.tz(subscription[0].startAt, subscription[0].tzid);
    const endAt = moment.tz(subscription[0].endAt, subscription[0].tzid);
    const day = startAt.format('dddd');
    return `Cancel ${day}, ${startAt.format('ha')}-${endAt.format('ha')} Subscriptions`;
  }

  render() {
    return (
      <div className="account-page__subscription-schedule__cancel-options">
        {this.props.isConfirming ? (
          <Modal onClose={this.props.unconfirm}>
            <div className="account-page__subscription-cancel-controls">
              <div className="account-page__modal-title">
                Are you sure you want to cancel your subscriptions?
              </div>
              <button
                className="account-page__cancel__cancel-button"
                onClick={this.props.unconfirm}
                type="button"
              >
                don{"'"}t cancel
              </button>
              <SubmitButton
                className="account-page__cancel__confirm-button"
                workingLabel="cancelling"
                onClick={this.props.cancel.bind(this, this.props.cancellingSubscription)}
              >
                cancel subscriptions
              </SubmitButton>
            </div>
          </Modal>
        ) : null}
        <hr />
        {Object.keys(this.props.subscriptions).map((id, i) => {
          const subscription = this.props.subscriptions[id];
          return (
            <div key={i} className="account-page__subscription-schedule__cancel-option">
              <span
                id={`cancel-${id}`}
                data-testid={`cancel-${id}`}
                onClick={this.props.confirm.bind(this, id)}
                className="account-page__subscription-schedule__cancel-option-link"
              >
                {this.getCancelOptionString(subscription)}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}

SubscriptionCancelOptions.propTypes = {
  subscriptions: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
  cancellingSubscription: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  unconfirm: PropTypes.func.isRequired,
};

class SubscriptionSchedule extends Component {
  getWeek(delivery) {
    return moment.tz(delivery.startAt, delivery.tzid).startOf('week').format('YYYY-MM-DD');
  }

  render() {
    return (
      <div className="account-page__subscription-schedule">
        {this.props.subscriptionSchedule.error ? (
          <div className="account-page__subscription-schedule__error">
            <Alert type="error" heading={this.props.subscriptionSchedule.error.customerMessage}>
              {this.props.subscriptionSchedule.error.advice}
            </Alert>
          </div>
        ) : null}

        <div className="account-page__subscription-schedule__deliveries">
          {this.props.subscriptionSchedule.deliveries.map((delivery, i) => {
            const nextDelivery = this.props.subscriptionSchedule.deliveries[i + 1];
            const lastInWeek =
              !nextDelivery || this.getWeek(nextDelivery) !== this.getWeek(delivery);

            return (
              <ScheduledDelivery
                key={`${delivery.subscriptionId}-${delivery.day}`}
                className={`${lastInWeek ? 'last-in-week' : ''}`}
                delivery={delivery}
                skip={this.props.skip}
                unskip={this.props.unskip}
                editable={this.props.editable}
              />
            );
          })}
        </div>
        {this.props.editable ? (
          <SubscriptionCancelOptions
            subscriptions={this.props.subscriptions}
            cancel={this.props.cancel}
            confirm={this.props.confirmCancel}
            unconfirm={this.props.unconfirmCancel}
            isConfirming={this.props.isConfirming}
            cancellingSubscription={this.props.cancellingSubscription}
          />
        ) : null}
      </div>
    );
  }
}

SubscriptionSchedule.propTypes = {
  editable: PropTypes.bool,
  skip: PropTypes.func.isRequired,
  unskip: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  confirmCancel: PropTypes.func.isRequired,
  unconfirmCancel: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
  cancellingSubscription: PropTypes.string,
  subscriptionSchedule: PropTypes.shape({
    error: PropTypes.object,
    deliveries: PropTypes.array.isRequired,
  }),
  subscriptions: PropTypes.object,
};

function mapStateToProps(state) {
  const subscriptionSchedule = {...state.subscriptionSchedule} || {weeks: []};
  if (subscriptionSchedule.error) {
    subscriptionSchedule.error = defaultError();
  }
  const subscriptions = groupBy(subscriptionSchedule.deliveries, (del) => del.subscriptionId);
  return {
    subscriptionSchedule,
    subscriptions,
    isConfirming: subscriptionSchedule.isConfirming,
    cancellingSubscription: subscriptionSchedule.cancellingSubscription,
  };
}

const mapDispatchToProps = {
  skip: actions.skip,
  unskip: actions.unskip,
  cancel: actions.cancel,
  confirmCancel: actions.confirmCancel,
  unconfirmCancel: actions.unconfirmCancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSchedule);
