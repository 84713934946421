import React, {FC, MouseEvent} from 'react';
import {useSelector} from 'react-redux';

import assetPath from 'web/helpers/asset_path';
import {formatCents} from 'web/helpers/money';
import {MarketLayoutStore} from 'web/components/market_layout/store_builder';
import useClientSettings from 'web/hooks/useClientSettings';

interface AccountControlsProps {
  upcomingOrdersCount: number;
  onLogout: (e: MouseEvent) => void;
  onLogin: (e: MouseEvent) => void;
}

const AccountControls: FC<AccountControlsProps> = ({upcomingOrdersCount, onLogout, onLogin}) => {
  const user = useSelector((state: MarketLayoutStore) => state.user);
  const clientSettings = useClientSettings();
  const iconMember = `/img/svg/icons/ge-member-black${
    clientSettings.enableNewLogos.enableWeb ? '-new' : ''
  }.svg`;
  if (user) {
    const {availableBalance} = user;
    return (
      <li className="dropdown-menu__container desktop-header__account-menu">
        <a className="desktop-header__link dropdown-menu__button js-user-account-menu-button">
          {user.membership?.isMember && (
            <img
              alt="Membership badge"
              src={`${assetPath(iconMember)}?auto=format`}
              className="dropdown-menu__membership-badge"
            />
          )}
          {user.masquerading
            ? `MASQUERADING ${user.firstName}${
                availableBalance ? ` ($${formatCents(availableBalance)}) ` : ' '
              }`
            : `Hi ${user.firstName}${
                availableBalance ? ` ($${formatCents(availableBalance)}) ` : ' '
              }`}
          ▾
        </a>
        <div className="dropdown-menu__content js-user-account-menu-content">
          {upcomingOrdersCount > 0 ? (
            <a className="dropdown-menu__item remote" href="/account/orders">
              Your Orders
              <div className="upcoming-orders-badge"> {upcomingOrdersCount} </div>
            </a>
          ) : (
            <a className="dropdown-menu__item remote" href="/account/orders">
              Your Orders
            </a>
          )}
          <a className="dropdown-menu__item remote" href="/account">
            Your Account
          </a>
          <a
            className="dropdown-menu__item logout js-logout remote"
            href="/logout"
            onClick={onLogout}
          >
            Sign Out
          </a>
        </div>
      </li>
    );
  }
  return (
    <a className="desktop-header__link login js-header-login" href="/signin" onClick={onLogin}>
      Sign In
    </a>
  );
};

export default AccountControls;
