import JsCookie from 'js-cookie';
import {defaults} from 'lodash';

import {Context} from '@analytics/client';
import cookieHelper from 'web/helpers/cookies';

interface Cookie {
  name: string;
  value: string;
}

const helpers = {
  parseCookiesFrom(cookieText: string): Cookie[] {
    if (!cookieText) {
      return [];
    }
    return cookieText
      .split(';')
      .map(function (c) {
        const parts = c.split('=');
        return {
          name: parts[0].trim(),
          value: unescape(parts[1]),
        };
      })
      .filter((c) => c.name && c.value);
  },

  getAll(): Cookie[] {
    return this.parseCookiesFrom(document.cookie);
  },

  clearCookie(key: string): void {
    JsCookie.remove(key, {path: '/', domain: window.settings.cookieDomain});
  },

  setCookie(key: string, value: string, options = {}): string | undefined {
    return JsCookie.set(
      key,
      value,
      defaults(options, {expires: 366, path: '/', domain: window.settings.cookieDomain}),
    );
  },

  getCookie(key: string): string | undefined {
    return JsCookie.get(key);
  },

  getShoppingSessionCookie(): Context['shoppingSession'] | Record<string, unknown> {
    const data = this.getCookie('BS');
    let jsonData: Context['shoppingSession'] | null = null;
    if (data) {
      try {
        jsonData = JSON.parse(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
    return jsonData != null ? jsonData : {};
  },

  setDismissedInterests(): string | undefined {
    return JsCookie.set('homepage-interests', '1', {expires: 366, path: '/'});
  },

  getCookieInferredZip(cookies: Record<string, string>): string {
    return cookies['z-default'];
  },

  getExperiments(): Context['experiments'] {
    const cookies: Cookie[] = cookieHelper.getAll();

    return cookies.reduce((acc: Context['experiments'], cookie) => {
      if (/^laboratory-/.test(cookie.name)) {
        acc.push({name: cookie.name.replace(/^laboratory-/, ''), variant: cookie.value});
      }

      return acc;
    }, []);
  },

  getExperimentsFlattened(): Record<string, string> {
    const experiments = cookieHelper.getExperiments();
    return experiments.reduce<Record<string, string>>((acc, {name, variant}) => {
      acc[name] = variant;
      return acc;
    }, {});
  },
};

export default helpers;
