import React, {FC} from 'react';

interface ToggleSwitchProps {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
  return (
    <div className="toggle-switch-container">
      <input
        className="toggle-switch"
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <label onClick={props.onChange} />
    </div>
  );
};

export default ToggleSwitch;
