import React, {FC} from 'react';
import classNames from 'classnames';

export interface TermsNavListItemProps {
  currentSlug: string;
  slug: string;
  label: string;
  href?: string;
}

const TermsNavListItem: FC<TermsNavListItemProps> = ({currentSlug, slug, label, href}) => {
  const isCurrent = currentSlug === slug;
  return (
    <li
      className={classNames('terms-page__nav-element', {
        'terms-page__nav-element-current': isCurrent,
      })}
    >
      {isCurrent ? (
        label
      ) : (
        <a href={href != null && href === '' ? href : `/terms/${slug}`}>{label}</a>
      )}
    </li>
  );
};

export default TermsNavListItem;
