import {union, without} from 'lodash';

import {add, remove} from './api_client';

const ADD_FAVORITE = 'FAVORITES_ADD';
const ADDED_FAVORITE = 'FAVORITES_ADDED';
const REMOVE_FAVORITE = 'FAVORITES_REMOVE';
const REMOVED_FAVORITE = 'FAVORITES_REMOVED';

/* Wrap api calls in sinon stubbable object for testing */
export const _stubbable = {add, remove};

export const actions = {
  addFavorite:
    (productId, {context} = {}) =>
    (dispatch) => {
      const promise = _stubbable.add(productId, {context}).then(() => {
        dispatch({type: ADDED_FAVORITE, productId});
      });

      dispatch({type: ADD_FAVORITE, productId});
      return promise;
    },
  removeFavorite:
    (productId, {context} = {}) =>
    (dispatch) => {
      const promise = _stubbable.remove(productId, {context}).then(() => {
        dispatch({type: REMOVED_FAVORITE, productId});
      });

      dispatch({type: REMOVE_FAVORITE, productId});
      return promise;
    },
};

export function reducer(state, action = {}) {
  const {productId} = action;
  switch (action.type) {
    case ADD_FAVORITE:
      return state;
    case REMOVE_FAVORITE:
      return state;
    case ADDED_FAVORITE:
      return union(state, [productId]);
    case REMOVED_FAVORITE:
      return without(state, productId);
    default:
      return state;
  }
}

export default {actions, reducer};
