import moment from 'infra/moment';

export interface DeliveryWindow {
  dateDelta: number;
  window: string;
  cutoffDateDelta: number;
  cutoffTime: string;
  status: string;
  dayOfWeek: string;
  hasUnavailableProducts: boolean;
  orderIds: string[];
  hasPreorderWindows: boolean;
}

export interface FulfillmentOffer {
  startAt: string;
  endAt: string;
  cutoffDate: string;
  status: string;
}

export interface SerializedFulfillmentOffer {
  offer: FulfillmentOffer;
  hasUnavailableProducts: boolean;
  orderIds?: string[];
  hasPreorderWindows: boolean;
}

const diffInDays = (date: moment.Moment, baseDate: moment.Moment): number => {
  return date.clone().startOf('day').diff(baseDate.clone().startOf('day'), 'days');
};

export const transformOfferForTracking = ({
  tzid,
  offer: {offer, hasUnavailableProducts, orderIds = [], hasPreorderWindows},
}: {
  tzid: string;
  offer: SerializedFulfillmentOffer;
}): DeliveryWindow => {
  const today = moment.tz(tzid).startOf('day');
  const startAt = moment.tz(offer.startAt, tzid);
  const endAt = moment.tz(offer.endAt, tzid);
  const cutoffDate = moment.tz(offer.cutoffDate, tzid);

  return {
    dateDelta: diffInDays(startAt, today),
    window: `${startAt.format('HHmm')}-${endAt.format('HHmm')}`,
    cutoffDateDelta: diffInDays(cutoffDate, today),
    cutoffTime: cutoffDate.format('HHmm'),
    status: offer.status,
    dayOfWeek: startAt.format('dddd'),
    hasUnavailableProducts,
    orderIds,
    hasPreorderWindows,
  };
};
