import update from 'immutability-helper';
import {reducer as formReducer} from 'redux-form';

import MarketLayout from 'web/components/market_layout';
import {
  actions as signUpFormActions,
  reducer as signUpFormReducer,
} from 'web/components/sign_up_form/duck';
import {reducer as socialSignInReducer} from 'web/components/social_sign_in/duck';
import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';
import sanitizeReturnPath from 'web/helpers/routing/sanitize_return_path';
import {
  reducer as signUpModalReducer,
  actions as signUpModalDuck,
} from 'web/helpers/sign_up_modal_duck';

export const actions = {
  updateBasket: () => async (dispatch) => {
    const newStore = await fetch('/signup/reset').then((r) => r.json());
    const {basket, products} = newStore;
    if (basket && products) {
      return dispatch(signUpModalDuck.appendToBasketItems({basket, products}));
    }
  },
  onLogin: () => async (dispatch, getState) => {
    const {signUpPage, referralId} = getState();
    if (referralId) {
      // passing this redirect flag so that we don't count this as a user inititate metric
      return dispatch(navigationActions.navigate(`/r/${referralId}?didRedirect=true`));
    }
    return dispatch(
      navigationActions.navigate(
        sanitizeReturnPath({
          path: signUpPage?.destinationUrl,
          defaultPath: '/',
        }),
      ),
    );
  },
  submitSignUpForm: () => (dispatch) =>
    dispatch(signUpFormActions.submitForm({rejectUponError: true}))
      .then(() => dispatch(actions.onLogin()))
      .catch(console.error),
};

export function reducer(state, action) {
  let newState = state;
  if (!state.signUpPage.useMinimalLayout) {
    newState = MarketLayout.reducer(state, action);
  }
  return update(newState, {
    navigation: {$set: navigationReducer(state.navigation, action)},
    signUpForm: {$set: signUpFormReducer(state.signUpForm, action)},
    form: {$set: formReducer(state.form, action)},
    socialSignIn: {$set: socialSignInReducer(state.socialSignIn, action)},
    signUpModal: {$set: signUpModalReducer(state.signUpModal, action)},
  });
}
