import React from 'react';
import {connect} from 'react-redux';

import {actions as zipCodeActions} from 'web/helpers/zip_code_duck';
import SigninHelpers from 'web/components/market_layout/components/header/components/signin_helpers';

import {actions} from '../../duck';
import STEPS from '../../steps';

const AuthFlowFooter = ({step, goToSignUp}) => {
  return (
    <div>
      {step !== STEPS.signIn ? (
        <div className="auth-flow__auth">
          <p className="sign-in-flow__sign-in">
            Already have an account?{' '}
            <a
              href="/signin"
              className="text-link"
              onClick={(e) => {
                e.preventDefault();
                SigninHelpers.signinAndReturn();
              }}
            >
              Sign in
            </a>
          </p>
        </div>
      ) : (
        <div className="flow-auth__sign-up">
          New to Good Eggs?{' '}
          <a id="go-to-signup" onClick={goToSignUp}>
            Sign up
          </a>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({signInFlow: {step}}) => ({
  step,
});

const mapDispatchToProps = (dispatch) => ({
  goToSignUp: () => dispatch(actions.navigate(STEPS.signUp)),
  goToSignIn: () => dispatch(actions.navigate(STEPS.signIn)),
  onTryAnotherZip: () => {
    dispatch(actions.navigate(STEPS.zip));
    dispatch(zipCodeActions.startCheckingZipCode());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthFlowFooter);
