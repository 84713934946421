import React, {Component, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {email as validateEmail} from 'goodeggs-validators';
import classNames from 'classnames';

import SubmitButton from 'web/components/submit_button';

export {default as Errors} from './errors';

export const SignInFormCta = 'Sign In';

const RenderField = ({input, type = 'text', meta, className, placeholder, focus = false}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const inputClasses = classNames(className, {
    invalid: meta.touched && meta.error && !meta.active && !meta.pristine,
    valid: meta.touched && !meta.error,
    '': !meta.touched && !meta.error,
  });
  // The useEffect and focus is used to make validation trigger once the code has been run
  // in older browsers (windows XP and firefox 52) it was noticed that the view rendered but validation
  // did not run until JS loaded.
  // The issue shows itself when a person enters their email before the JS runs later, this causes the inputs
  // to seem valid to the user but the submit button is still disabled.
  const inputEl = useRef(null);
  useEffect(() => {
    if (focus) {
      inputEl.current.focus();
      inputEl.current.value = '';
    }
  }, []);

  return (
    <div className="sign-in-form__field">
      <div className="sign-in-form__input-container">
        <input
          className={inputClasses}
          {...input}
          placeholder={placeholder}
          ref={inputEl}
          type={passwordShown ? 'text' : type}
        />

        {typeof type === 'string' && type.toLowerCase() === 'password' ? (
          <i
            className={`icon ${passwordShown ? 'icon-eye-open-slash' : 'icon-eye-open'}`}
            onClick={() => {
              setPasswordShown(!passwordShown);
            }}
          />
        ) : null}
      </div>

      {inputClasses.indexOf('invalid') !== -1 ? (
        <div className="sign-in-form__error error">{meta.error}</div>
      ) : null}
    </div>
  );
};

RenderField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

class SignInForm extends Component {
  constructor() {
    super();
    this.state = {passwordShown: false};
  }

  render() {
    const {passwordShown} = this.state;
    const {handleSubmit, valid, submitting, pristine} = this.props;
    const {options = {}} = this.props;

    return (
      <form onSubmit={handleSubmit} className="sign-in-form" data-testid="sign-in-form">
        <div className="sign-in-form__email-row">
          <Field
            name="email"
            type="email"
            className="form-control"
            placeholder="Email"
            focus
            component={RenderField}
          />
        </div>
        <div className="sign-in-form__password-row">
          <Field
            name="password"
            className="form-control"
            placeholder="Password"
            type={passwordShown ? 'text' : 'password'}
            component={RenderField}
          />
        </div>
        <SubmitButton
          className={options.submit?.className || 'sign-in-form__submit'}
          isSaving={submitting}
          disabled={!valid || submitting || pristine}
          workingLabel={options.submit?.buttonText || SignInFormCta}
        >
          {options.submit?.buttonText || SignInFormCta}
        </SubmitButton>
      </form>
    );
  }
}

function validate(values = {}) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter your email address';
  } else if (!validateEmail(values.email)) {
    errors.email = "That email address doesn't look quite right";
  }
  if (!values.password) {
    errors.password = 'Please enter your password';
  }
  return errors;
}

export default reduxForm({
  form: 'signIn',
  validate,
  touchOnBlur: true,
})(SignInForm);
