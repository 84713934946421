import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Alert from 'web/components/alert';

export default class Errors extends Component {
  onClickSignIn(e) {
    e.preventDefault();

    const {signInUrl = '/signin', signInAction} = this.props;
    if (signInAction) {
      signInAction();
    } else {
      window.location = signInUrl;
    }
  }

  render() {
    const {error} = this.props;
    if (!error) {
      return null;
    }
    let advice;
    if (error.type === 'EMAIL_USED') {
      advice = (
        <span>
          Please{' '}
          <a className="link" onClick={this.onClickSignIn.bind(this)}>
            Sign in
          </a>
        </span>
      );
    }
    return (
      <Alert
        className={this.props.className}
        type="error"
        heading={error.customerMessage || error.message}
      >
        {advice}
      </Alert>
    );
  }
}

Errors.propTypes = {
  signInUrl: PropTypes.string,
  signInAction: PropTypes.func,
  error: PropTypes.object,
  className: PropTypes.string,
};
