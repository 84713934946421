import React, {Component} from 'react';
import {email as validateEmail} from 'goodeggs-validators';

import Input from 'web/components/input';
import SubmitButton from 'web/components/submit_button';
import Alert from 'web/components/alert';
import EmailProvided from 'web/components/sign_in_flow/components/email_provided';
// import {GOODEGGS_SELECT_URL} from 'web/constants';

export default class EmailEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      collectedEmail: false,
      input: '',
    };
  }

  onSubmit(e) {
    e.preventDefault();
    const {input: email} = this.state;

    this.setState({error: null});

    if (!validateEmail(email)) {
      this.setState({error: "Oops - that doesn't quite look right"});
      return;
    }
    const emailCapturedProps = {
      email,
      formName: 'PDP',
      loggedIn: this.props.user !== null,
      masquerading: this.props.user?.masquerading ?? false,
      zipCode: this.props.zip,
    };
    window.metrics.track('Email Captured', emailCapturedProps, false);
    this.setState({collectedEmail: true});
  }

  render() {
    const className = !validateEmail(this.state.input)
      ? 'disabled-buttons__modal'
      : 'action__button';
    return (
      <div className="sign-in-flow__zip-code-collection ">
        {this.state.collectedEmail ? (
          <div>
            <EmailProvided zip={this.props.zip} />
          </div>
        ) : (
          <div>
            <h1 className="pdp-sign-in-flow-refresh__title" data-testid="headline">
              We currently do not deliver to your area locally.
              {/* <a
                href={`${GOODEGGS_SELECT_URL}?utm_source=goodeggs_local&utm_campaign=delivery_area_check`}
              >
                Good Eggs Select
              </a> */}
              {''}.
            </h1>
            <div className="pdp-sign-in-flow-zip-entry-refresh__subtitle">
              We hope to be there soon! Be the first to know when Good Eggs does local deliveries to{' '}
              {this.props.zip}.
            </div>
            {this.state.error ? (
              <Alert className="flow-zip-entry__errors" type="error" heading={this.state.error} />
            ) : null}
            <div className="sign-in-flow__form-content">
              <form
                className="flow-zip-entry-form flow-zip-entry-form__email"
                onSubmit={this.onSubmit.bind(this)}
              >
                <div className="sign-in-flow__zip-code-collection ">
                  <Input
                    type="email"
                    className="flow-zip-entry_refresh_modal__input js-zip-input"
                    placeholder="Email"
                    onChange={(ev) => {
                      this.setState({input: ev.target.value});
                    }}
                    value={this.state.input}
                  />
                  <SubmitButton
                    disabled={!validateEmail(this.state.input)}
                    className={`js-submit-zip ${className}`}
                  >
                    Notify me
                  </SubmitButton>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
