import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from 'web/components/loading_overlay';
import SignInForm, {Errors} from 'web/components/sign_in_form';
import SocialSignIn from 'web/components/social_sign_in';

export default class FlowSignInScreen extends Component {
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmitSignInForm();
  }

  render() {
    return (
      <div className="flow-sign-in">
        <h1 className="sign-in-flow__title">Sign In</h1>

        {this.props.error ? (
          <Errors className="flow-sign-in__errors" error={this.props.error} />
        ) : null}
        <SignInForm onSubmit={this.handleSubmit.bind(this)} />
        <div className="flow-sign-in__forgot">
          <a href="/account/resetpassword">Forgot password?</a>
        </div>

        <SocialSignIn onLogin={this.props.onLogin} />
        <div className="flow-sign-in__sign-up">
          New to Good Eggs?{' '}
          <a id="go-to-signup" onClick={this.props.onGoToSignUp}>
            Sign up
          </a>
        </div>
        {this.props.isWorking ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

FlowSignInScreen.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onGoToSignUp: PropTypes.func.isRequired,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customerMessage: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  onSubmitSignInForm: PropTypes.func.isRequired,
};
