import React, {useEffect, useState} from 'react';

import Banner from 'web/components/banner';

export const BANNER_PATH = '/fresh-picks ';

const minWidth = 720;

const HomeBanner: React.FC = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : minWidth);
  const handleClickCard = (): void => {
    window.location.assign(BANNER_PATH);
  };

  useEffect(() => {
    const handleResize = (): void => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className="basket-view__header home-banner">
      <Banner
        onClickInfo={() => handleClickCard()}
        title={
          <p>
            Welcome to Good Eggs! Get 20% off your first four orders as a new user. Use code
            HelloGoodEggs at checkout.&nbsp;
            {width < minWidth && <br />}
            <a href={BANNER_PATH}>Start shopping.</a>
          </p>
        }
        showInfoButton={false}
      />
    </div>
  );
};

export default HomeBanner;
