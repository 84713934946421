// Serialize updates with a queue to guarantee delivery order
let queue: Array<() => Promise<unknown>> = [];

const onCallFinished = (): void => {
  queue.shift();
  if (queue.length > 0) {
    startNextCall();
  }
};

const startNextCall = (): void => {
  // all enqueued thunks are expected to return a promise
  const result = queue[0]();
  // Start the next call when this one completes
  result.then(onCallFinished, onCallFinished);
};

export const resetQueue = (): void => {
  queue = [];
};

/**
 *  Given a thunk that returns a promise, returns a promise that resolves when the thunk is called
 *  and its returned promise resolves. Enqueues the thunk to be called when other API requests have
 *  finished.
 */
const enqueue = async <T>(thunk: () => Promise<T>): Promise<T> =>
  new Promise((resolve, reject) => {
    queue.push(async () => thunk().then(resolve, reject));
    // Call immediately if the queue was empty
    if (queue.length === 1) {
      startNextCall();
    }
  });

export default enqueue;
