import React from 'react';
import {object} from 'prop-types';

import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

const MinimalHeader = (props) => {
  const settings = useClientSettings();
  return (
    <nav className="minimal-nav">
      <a className="logo" href="/">
        {settings.enableNewLogos.enableWeb ? (
          <img alt="Good Eggs" src={`${assetPath('/img/svg/good-eggs-logo-white.svg')}`} />
        ) : (
          <i className="icon icon-logo-black" alt="Good Eggs" />
        )}
      </a>
      {props.user && props.user.masquerading ? (
        <span className="user-controls-view">
          <span className="user-control-link masquerading">Masquerading {props.user.name}</span>
        </span>
      ) : null}
    </nav>
  );
};

MinimalHeader.propTypes = {
  user: object,
};

export default MinimalHeader;
