import React, {FC, PropsWithChildren} from 'react';

export interface ModalTextProps {
  text: string;
}

const ModalText: FC<PropsWithChildren<ModalTextProps>> = ({text}) => (
  <p className="sign-up-modal__text">{text}</p>
);

export default ModalText;
