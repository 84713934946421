import React, {Component} from 'react';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';

import {ISerializedUser} from 'domain/users';
import Modal from 'web/components/modal';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {actions as navigationActions} from 'web/helpers/navigation_duck';
import {actions as zipCodeActions} from 'web/helpers/zip_code_duck';

interface StateProps {
  currentFulfillmentDay: string;
  zipCode?: string;
  user?: ISerializedUser;
}

interface DispatchProps {
  closeModal: (cb?: () => void) => void;
  confirmChange: (
    fulfillmentDay: string,
    zip: string,
    prevZip: string | null,
    callback: () => void,
  ) => void;
  navigateToBasket: () => void;
}

export interface SuccessModalProps {
  onDismiss?: () => void;
  newZip: string;
  requireConfirmation?: boolean;
}

interface Props extends SuccessModalProps, DispatchProps, StateProps {}

class ZipCodeSuccessModal extends Component<Props> {
  public closeSelf(): void {
    this.props.closeModal(this.props.onDismiss);
  }
  public handleConfirmedChange = (): void => {
    this.props.navigateToBasket();
    this.closeSelf();
  };

  public render(): React.ReactElement {
    return (
      <Modal className="refresh" fullScreenMobile onClose={(): void => this.closeSelf()}>
        <div className="auth-flow__modal" id="zip__modal">
          <div className="auth-flow__content">
            <div className="auth-flow__header">
              <div className="close-modal__icon">
                <i
                  className="icon icon-thin-x"
                  data-testid="modal-close-icon"
                  onClick={(): void => this.closeSelf()}
                />
              </div>
              <div className="auth-flow__logo">
                <i className={`icon icon-logo-black`} />
              </div>
            </div>
            <div>
              <div className="flow-zip-entry desktopMargin__modal">
                <div>
                  <h3 className="modal__title" data-testid="headline">
                    Hooray! We deliver to {this.props.newZip}
                  </h3>
                </div>
                <div className="modal-content">
                  <div className="modal-content__message" data-testid="message-container">
                    {this.props.requireConfirmation ? (
                      <span>
                        <strong>Items in the basket may be removed</strong> once you change the zip
                        code. You will be able to shop different items based on your region.
                      </span>
                    ) : (
                      <span>
                        You are one step closer to absurdly fresh groceries delivered straight to
                        your door.
                      </span>
                    )}
                  </div>
                  <div className="modal-content__cta">
                    {this.props.requireConfirmation ? (
                      <>
                        <button
                          type="button"
                          className="button refresh"
                          onClick={(): void =>
                            this.props.confirmChange(
                              this.props.currentFulfillmentDay,
                              this.props.newZip,
                              this.props.zipCode ?? null,
                              this.handleConfirmedChange,
                            )
                          }
                        >
                          Got It
                        </button>

                        <p>
                          <a
                            href="#"
                            className="text-link"
                            onClick={(e): void => {
                              e.preventDefault();
                              this.closeSelf();
                            }}
                          >
                            No thanks, continue shopping in {this.props.zipCode}
                          </a>
                        </p>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="button refresh"
                        onClick={(): void => this.closeSelf()}
                      >
                        Start Shopping
                      </button>
                    )}
                    {this.props.user == null ? (
                      <p className="sign-in-flow__text">
                        Already have an account?{' '}
                        <a className="text-link" href="/signin">
                          Sign in
                        </a>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: StateProps): StateProps => ({
  currentFulfillmentDay: state.currentFulfillmentDay,
  zipCode: state.zipCode,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, Props, AnyAction>,
): DispatchProps => ({
  closeModal: (cb): void => {
    dispatch(modalActions.closeModal());
    if (cb != null) {
      cb();
    }
  },
  confirmChange: async (fulfillmentDay, zipCode, prevZipCode, callback): Promise<void> => {
    await dispatch(zipCodeActions.updateZipCode(fulfillmentDay, zipCode, prevZipCode, true));
    callback();
  },
  navigateToBasket: (): void => {
    dispatch(navigationActions.navigate(`/basket`));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeSuccessModal);
