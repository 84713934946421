// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//
// This is a thin wrapper around Segment's analytics.js library:
// https://segment.com/docs/libraries/analytics.js/
//
// For use in browser.  Async methods return promises.
// Guards against errors in the segment script.
// The snippet is inject via `segment_snippet` helper in
// ./segment_snippet.js
//

class SegmentClient {
  constructor({logger} = {}) {
    this.logger = logger;
    if (this.logger == null) {
      this.logger = console;
    }
    const syncMethods = ['trackLink'];
    const asyncMethods = ['identify', 'track', 'page'];
    const methods = [...syncMethods, ...asyncMethods];

    methods.forEach(
      (method) =>
        (this[method] = function (...args) {
          return window.analytics[method](...args);
        }),
    );
  }

  // Alternative to segment identify that sets ids in the client library without
  // incurring an extra HTTP request.  We call identify on the server to record user traits.
  setUserIds({userId, anonymousId}) {
    const setIds = function () {
      // Segment replays the queue before assigning window.analytics, so
      // instead we grab a reference to the hydrated library via event emitter scope.
      const analytics = this;

      // Recommended by Segment support: https://help.segment.com/hc/en-us/requests/39220
      analytics.user().id(userId ?? null); // important to clear userid after logout
      // Must come after user().id(), which resets the anonymousId
      analytics.user().anonymousId(anonymousId);

      // We identify upon page load whenever we know the userId to configure some
      // integrations (like Qualaroo. see note in https://segment.com/docs/spec/identify/)
      if (userId != null) {
        return analytics.identify(userId);
      }
    };

    // wait for the library to load
    // TODO(adam): this is a total hack, waiting for a better suggestion from segment.
    // Piggyback on the fact that segment's an event emitter to get it to call a function
    // in queue order once the library loads.
    window.analytics.once('goodeggsProcessingQueue', setIds);
    // This line only works 'cause we added 'emit' to the list of queued methods
    // in the segment_snippet.
    return window.analytics.emit('goodeggsProcessingQueue');
  }

  ready(cb) {
    window.analytics.ready(cb);
  }
}

export default SegmentClient;
