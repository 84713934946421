import update from 'immutability-helper';
import {keyBy} from 'lodash';

const helpers = {
  itemMatches({item, productId}) {
    return item.product.id === productId;
  },

  findItem({basketItems, productId}) {
    return basketItems.find((item) => helpers.itemMatches({item, productId}));
  },

  editProduct({basketItems, productId, quantity, shouldSubscribe}) {
    if (quantity === 0) {
      return helpers.removeProduct({basketItems, productId});
    }

    const changes = {};
    if (quantity != null) changes.quantity = quantity;
    if (shouldSubscribe != null) changes.shouldSubscribe = shouldSubscribe;

    const index = basketItems.findIndex((item) => helpers.itemMatches({item, productId}));
    if (index < 0) {
      return basketItems;
    }

    return update(basketItems, {[index]: {$merge: changes}});
  },

  getQuantity({basketItems, productId}) {
    const basketItem = helpers.findItem({basketItems, productId});
    return !basketItem ? 0 : basketItem.quantity;
  },

  increaseQuantity({basketItems, products, productId, proposedQuantity}) {
    const maxQuantity = products[productId].maxQuantity || Number.POSITIVE_INFINITY;
    const updatedQuantity = Math.min(maxQuantity, proposedQuantity);
    const isInBasket = basketItems.find((item) => helpers.itemMatches({item, productId}));
    if (!isInBasket) {
      return update(basketItems, {
        $push: [
          {
            id: `basket-item:${productId}`,
            product: {
              id: productId,
            },
            quantity: updatedQuantity,
          },
        ],
      });
    }
    return helpers.editProduct({
      basketItems,
      productId,
      quantity: updatedQuantity,
    });
  },

  removeProduct({basketItems, productId}) {
    const index = basketItems.findIndex((item) => helpers.itemMatches({item, productId}));
    if (index < 0) {
      return basketItems;
    }

    return update(basketItems, {$splice: [[index, 1]]});
  },

  decreaseQuantity({basketItems, productId, proposedQuantity}) {
    const updatedQuantity = Math.max(0, proposedQuantity);
    if (updatedQuantity === 0) {
      return helpers.removeProduct({basketItems, productId});
    }
    return helpers.editProduct({
      basketItems,
      productId,
      quantity: updatedQuantity,
    });
  },

  itemsById(basket) {
    const basketItems = basket && basket.items ? basket.items : [];
    return keyBy(basketItems, (item) => item.product.id);
  },
};

export default helpers;
