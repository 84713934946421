//
// Swaps between cards, which occupy the whole screen on
// mobile devices.  Encapsulates the details of switching
// between screens with different heights while preserving
// smooth scrolling on iOS and maintaining scroll position of
// cards.
//
import React, {Children, FC, PropsWithChildren} from 'react';
import classNames from 'classnames';
import {AnyAction} from 'redux';

export const actions = {
  ACTIVATE: 'DECK_ACTIVATE',
  BACK: 'DECK_BACK',

  activate: (name: string) => ({
    type: actions.ACTIVATE,
    cardName: name,
  }),
  back: () => ({
    type: actions.BACK,
  }),
};

interface StateProps {
  activeCard?: string;
}

export function reducer<TState extends StateProps>(state: TState, action: AnyAction): TState {
  let activeCard = state.activeCard ?? 'main';
  switch (action?.type) {
    // Activating a card twice cancels the action and
    // returns to the main card so that wired components
    // default to the expected action.
    case actions.ACTIVATE:
      if (activeCard === action.cardName) {
        activeCard = 'main';
      } else {
        activeCard = action.cardName;
      }
      break;
    case actions.BACK:
      activeCard = 'main';
      break;
    default:
    // noop
  }
  return {...state, activeCard};
}

interface CardProps {
  name: string;
  active?: boolean;
}

interface DeckProps {
  activeCard?: string;
}

export const Card: FC<PropsWithChildren<CardProps>> = ({name, active, children}) => (
  <div className={classNames('card', `card-${name}`, {active})} data-card={name}>
    {children}
  </div>
);

const Deck: FC<PropsWithChildren<DeckProps>> = ({children, activeCard}) => (
  <div className="deck-view">
    {Children.map(
      children as React.ReactElement<CardProps>,
      (child: React.ReactElement<CardProps>) =>
        React.cloneElement<CardProps>(child, {
          key: child.props.name,
          active: child.props.name === activeCard,
        }),
    )}
  </div>
);

export default Deck;
