import React, {FC} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {ModalViewed} from '@analytics/client/generated';
import SignIn from 'web/account/sign_in_page/sign_in';
import Modal from 'web/components/modal';
import {actions} from 'web/account/sign_in_page/duck';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {AppDispatch} from 'web/helpers/redux_client';

interface State {
  signInForm: {
    error: string | null;
    alert: string | null;
    useMinimalLayout: boolean;
  };
  modalViewed: ModalViewed;
  signInPage?: {
    resetPasswordUrl: string;
  };
}

interface Props {
  signUpUrl?: string;
}

const SignInModal: FC<Props> = (props) => {
  const error = useSelector((state: State) => state.signInForm.error);
  const alert = useSelector((state: State) => state.signInForm.alert);
  const useMinimalLayout = useSelector((state: State) => state.signInForm.useMinimalLayout);
  const modalViewed = useSelector((state: State) => state.modalViewed);
  const signInPage = useSelector((state: State) => state.signInPage);
  const dispatch: AppDispatch = useDispatch();

  const handleCloseModal = (): void => {
    dispatch(modalActions.closeModal({modal: 'SignInModal'}));
  };

  const handleSubmit = (): void => {
    dispatch(actions.submitSignInForm());
  };

  return (
    <Modal onClose={handleCloseModal}>
      <SignIn
        segmentFeature={modalViewed?.feature}
        resetPasswordUrl={signInPage?.resetPasswordUrl}
        alert={alert}
        error={error}
        submitSignInForm={handleSubmit}
        signUpUrl={props.signUpUrl}
        useMinimalLayout={useMinimalLayout}
        onClose={handleCloseModal}
        isModal
      />
    </Modal>
  );
};

export default SignInModal;
