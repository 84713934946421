import React, {FC, PropsWithChildren} from 'react';

const Separator: FC<PropsWithChildren> = (props) => {
  return (
    <div className="ge-separator">
      <span className="ge-separator__line" />
      <span className="ge-separator__content">{props.children}</span>
      <span className="ge-separator__line" />
    </div>
  );
};

export const SeparatorLine: FC = () => {
  return (
    <div className="ge-separator">
      <span className="ge-separator__line" />
    </div>
  );
};

export default Separator;
