import React, {ReactElement} from 'react';

import {AvailabilitiesByDay} from 'web/basket/basket_page';

interface AvailabilityMessageProps {
  availabilitiesByDay: AvailabilitiesByDay;
}

const AvailabilityMessage = ({availabilitiesByDay}: AvailabilityMessageProps): ReactElement => (
  <div className="product-tile__message" data-testid="product-tile-availability-message">
    <div className="product-tile__message__text" data-testid="product-tile-message">
      {Object.values(availabilitiesByDay).find((availability) => availability.status === 'soldout')
        ? 'Sold Out'
        : 'Unavailable'}
    </div>
  </div>
);

export default AvailabilityMessage;
