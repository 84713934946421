/* eslint-disable unicorn/filename-case */
import {useCallback} from 'react';

import useLocalStorage from './useLocalStorage';

interface UseRecentSearchesReturnedValues {
  recentSearches: string[];
  addRecentSearch: (termToBeAdded: string) => void;
  removeRecentSearch: (termToBeRemoved: string) => void;
  clearRecentSearches: () => void;
}

const INITIAL_VALUE: string[] = [];

const useRecentSearches = (): UseRecentSearchesReturnedValues => {
  const [recentSearches, setRecentSearches] = useLocalStorage('recent-searches', INITIAL_VALUE);
  const addRecentSearch = useCallback(
    (termToBeAdded: string) => {
      setRecentSearches((current: string[]) =>
        Array.from(new Set([termToBeAdded, ...current])).slice(0, 5),
      );
    },
    [setRecentSearches],
  );
  const removeRecentSearch = useCallback(
    (termToBeRemoved: string) => {
      setRecentSearches((current: string[]) =>
        Array.from(new Set(current.filter((term) => term !== termToBeRemoved))).slice(0, 5),
      );
    },
    [setRecentSearches],
  );
  const clearRecentSearches = useCallback(() => {
    setRecentSearches([]);
  }, [setRecentSearches]);

  return {
    recentSearches,
    addRecentSearch,
    removeRecentSearch,
    clearRecentSearches,
  };
};

export default useRecentSearches;
