export interface BasketHelper {
  totals: {
    orderMinimum: number;
  };
  items: BasketItemHelper[];
}

export interface BasketItemHelper {
  shouldSubscribe: boolean;
  product: {
    id: string;
  };
  quantity: number;
}

export interface ProductHelper {
  id: string;
  retailPrice: number;
}

export default function getSubscriptionAmountToMinimum(
  basket: BasketHelper,
  products: Record<string, ProductHelper>,
): number {
  const subscribedBasketItems = basket.items.filter((item) => item.shouldSubscribe);

  const subscribedTotals = subscribedBasketItems.map((item) => {
    const product = products[item.product.id];
    return item.quantity * product.retailPrice;
  });

  const subscribedItemSubtotal = subscribedTotals.reduce((a: number, b: number) => a + b, 0);
  const amountToMinimum = basket.totals.orderMinimum - subscribedItemSubtotal;
  return Math.max(0, amountToMinimum);
}
