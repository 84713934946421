const CHANGE = 'FULFILLMENT_DAYS_CHANGE';

export const actions = {
  changeFulfillmentDays: (fulfillmentDaySummaries) => ({
    type: CHANGE,
    value: fulfillmentDaySummaries,
  }),
};

export function reducer(state, action = {}) {
  switch (action.type) {
    case CHANGE: {
      const fulfillmentDaySummaries = action.value;
      let {currentFulfillmentDay} = state;

      // see if the currentFulillmentDay is still available
      let currentFulfillmentDaySummary = fulfillmentDaySummaries.find(
        (fulfillmentDaySummary) =>
          fulfillmentDaySummary.day === currentFulfillmentDay &&
          fulfillmentDaySummary.status === 'available',
      );

      // otherwise, find the next available day
      if (!currentFulfillmentDaySummary) {
        currentFulfillmentDaySummary = fulfillmentDaySummaries.find(
          (fulfillmentDaySummary) => fulfillmentDaySummary.status === 'available',
        );
      }

      if (currentFulfillmentDaySummary) {
        currentFulfillmentDay = currentFulfillmentDaySummary.day;
      } else {
        currentFulfillmentDay = null;
      }

      return {
        fulfillmentDaySummaries,
        currentFulfillmentDay,
      };
    }
    default: {
      return state;
    }
  }
}
