import React, {FC} from 'react';
import {useDispatch} from 'react-redux';

import Modal from 'web/components/modal';
import SubmitButton from 'web/components/submit_button';
import useClientSettings from 'web/hooks/useClientSettings';
import ImageComponent from 'web/components/image';
import {actions as modalActions} from 'web/helpers/modal_duck';

export const SignUpSuccessModalCta = 'Start Shopping';

const SignUpSuccessModal: FC = () => {
  const dispatch = useDispatch();
  const clientSettings = useClientSettings();

  const handleClose = (): void => {
    dispatch(
      modalActions.closeModal({
        modal: 'SignUpSuccessModal',
      }),
    );
  };

  let header = 'Welcome to the good food revolution';
  let message = `New to Good Eggs? We're so glad you're here.`;

  if (clientSettings.welcomeFeesPromo.enabled) {
    header = 'No delivery fees for your first month';
    message = `New to Good Eggs? We're so glad you're here. For your first month you can shop with no delivery fees, as many times as you want. And when a month is up, become a member to skip delivery fees forevermore.`;
  }

  return (
    <Modal onClose={handleClose}>
      <div className="sign-up-success-modal__container">
        <div className="sign-up-success-modal__content">
          <ImageComponent
            className="sign-up-success-modal__close"
            src="/img/svg/icons/thin_x.svg"
            width={25}
            alt="Good Eggs logo"
            onClick={handleClose}
            loading="eager"
            data-testid="close-modal__icon"
          />
          <ImageComponent
            className="sign-up-success-modal__logo"
            src="/img/svg/icons/icon_black.svg"
            width={66}
            alt="Good Eggs logo"
            loading="eager"
            data-testid="close-modal__logo"
          />
          <h1 className="sign-up-success-modal__header">{header}</h1>
          <p className="sign-up-success-modal__message">{message}</p>
          <SubmitButton className="sign-up-success-modal__start_shopping" onClick={handleClose}>
            {SignUpSuccessModalCta}
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpSuccessModal;
