import React from 'react';
import PropTypes from 'prop-types';

import Button from 'web/components/button';
import Alert from 'web/components/alert';
import LoadingOverlay from 'web/components/loading_overlay';

export default function FlowSocialSignInScreen(props) {
  const title = props.successMessage ?? `Hooray! We deliver to ${props.zipCode}.`;
  return (
    <div className="flow-social-sign-in">
      <h1 className="sign-in-flow_refresh_modal__title">{title}</h1>
      <div className="auth-flow__form-sign">
        {props.error ? (
          <Alert
            className="flow-social-sign-in__errors"
            type="error"
            heading={props.error.customerMessage || props.error.message}
          />
        ) : null}
        <Button
          type="button"
          onClick={props.onCloseModal}
          className="flow-social-sign-in-screen__signup-button js-sign-up-with-email"
        >
          Start Shopping
        </Button>
        {props.isWorking ? <LoadingOverlay /> : null}
      </div>
    </div>
  );
}

FlowSocialSignInScreen.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customerMessage: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  zipCode: PropTypes.string,
  onLogin: PropTypes.func,
  onContinueWithEmail: PropTypes.func,
  onTryAnotherZip: PropTypes.func,
  successMessage: PropTypes.string,
};
