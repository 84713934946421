// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from 'web/components/loading_overlay';
import SignUpForm, {Errors} from 'web/components/sign_up_form';

export default class FlowSignUpScreen extends Component {
  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmitSignUpForm();
  }

  render() {
    return (
      <div className="flow-sign-up">
        <h1 className="sign-in-flow__title">Sign Up</h1>

        <Errors
          className="flow-sign-up__errors"
          error={this.props.error}
          signInAction={this.props.onClickErrorSignIn}
        />
        <SignUpForm excludePhone excludePassword={false} onSubmit={this.handleSubmit.bind(this)} />
        <div className="sign-in-flow__disclaimer">
          By signing up, you agree to Good Eggs{"'"} <a href="/terms">Terms of Service</a> and{' '}
          <a href={settings.privacyLink}>Privacy Policy</a>.
        </div>
        {this.props.isWorking ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

FlowSignUpScreen.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customerMessage: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  onSubmitSignUpForm: PropTypes.func,
  onClickErrorSignIn: PropTypes.func,
};
