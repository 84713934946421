import React from 'react';

import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';
import useIsComponentLoading from 'web/hooks/useIsComponentLoading';

interface MobileBannerProps {
  handleClose: (uri: string) => void;
  handleOpenStore: (uri: string) => void;
}

export const androidLink =
  'https://play.google.com/store/apps/details?id=com.goodeggs.mobile.production&hl=en_US&gl=US';
export const iosLink = 'https://apps.apple.com/us/app/good-eggs-grocery-delivery/id1020495070';

function platformDeepLink(): string | null {
  if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    return iosLink;
  }

  if (/Android/i.test(window.navigator.userAgent)) {
    return androidLink;
  }

  return null;
}

export default function MobileBanner({
  handleClose,
  handleOpenStore,
}: MobileBannerProps): JSX.Element | null {
  const isComponentLoading = useIsComponentLoading();
  const clientSettings = useClientSettings();

  // (guivpw) necessary check to use window object when rendered by the client
  if (isComponentLoading) return null;

  const link = platformDeepLink();

  if (!link) return null;

  const enableNewLogos = clientSettings.enableNewLogos.enableMobile;

  return (
    <div className="mobile-banner" data-testid="banner-container">
      <div className="mobile-banner__container">
        <a href="#" onClick={(): void => handleClose(link)} data-testid="close-icon">
          <span className="icon icon-thin-x" />
        </a>
        <a href="/home">
          {enableNewLogos ? (
            <img
              src={`${assetPath('/img/svg/icons/ge-g-mobile-banner.svg')}?auto=format`}
              className="banner-logo"
            />
          ) : (
            <i className="icon icon-icon-black" />
          )}
        </a>
        <div className="mobile-banner__content">
          <strong className="mobile-banner__content__title">Get the app</strong>
          <p className="mobile-banner__content__description">
            The easiest way to shop on Good Eggs
          </p>
        </div>
      </div>
      <button
        type="button"
        className="button-outline"
        data-testid="get-button"
        onClick={(): void => handleOpenStore(link)}
      >
        Get
      </button>
    </div>
  );
}
