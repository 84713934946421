import React, {FC, PropsWithChildren, ReactNode} from 'react';

export interface PanelProps {
  header: ReactNode;
}

const Panel: FC<PropsWithChildren<PanelProps>> = ({header, children}) => (
  <div className="panel">
    <div className="panel__header">{header}</div>
    <div className="panel__body">{children}</div>
  </div>
);

export default Panel;
