import React, {FC, PropsWithChildren} from 'react';
import {useDispatch} from 'react-redux';

import SignInForm, {Errors} from 'web/components/sign_in_form';
import SocialSignIn from 'web/components/social_sign_in';
import Alert from 'web/components/alert';
import {actions as modalActions} from 'web/helpers/modal_duck';
import ModalHeader from 'web/components/modal_header';
import {SignUpFormCta} from 'web/components/sign_up_form';

import {actions} from '../duck';

interface SignInMessageProps {
  alert: string | null;
}

function SignInMessage(props: SignInMessageProps): React.ReactElement | null {
  if (props.alert == null) return null;

  let message;
  switch (props.alert) {
    case 'auth':
      message = 'You are not authorized to view that page.';
      break;
    case 'signin':
      message = 'You must be logged in to view that page.';
      break;
    case 'forgot':
      message = 'Looks like that link has already been used. Try clicking “Forgot password” again.';
      break;
    default:
      // TODO(serhalp) Consider logging an error or throwing on unexpected values?
      message = '';
  }
  return <Alert className="sign-in-page__errors" type="error" heading={message} />;
}

export interface SignInProps {
  alert: string | null;
  error: string | null;
  resetPasswordUrl?: string;
  segmentFeature?: string;
  signUpUrl?: string;
  submitSignInForm: () => void;
  useMinimalLayout: boolean;
  onClose: () => void;
  isModal: boolean;
}

const SignIn: FC<PropsWithChildren<SignInProps>> = (props) => {
  const handleSubmit = props.submitSignInForm;
  const dispatch = useDispatch();

  const handleClickSignUpLink = (): void => {
    if (props.signUpUrl == null) {
      dispatch(
        modalActions.replaceModal({
          modal: 'SignUpModal',
          modalViewed: {
            feature: props.segmentFeature,
            pageUrl: window.location.pathname,
            loggedIn: false,
            ctaText: SignUpFormCta,
          },
        }),
      );
    }
  };

  return (
    <div className={props.isModal ? 'sign-in-page sign-in-modal' : 'sign-in-page narrow-page'}>
      {props.isModal && (
        <div className="close-modal__icon">
          <i className="icon icon-thin-x" onClick={props.onClose} />
        </div>
      )}
      <SignInMessage alert={props.alert} />
      {props.useMinimalLayout ? (
        <div className="sign-in-page__intro">Sign in or sign up to continue</div>
      ) : null}
      <ModalHeader isModal={props.isModal} headingText="Welcome Back" onClose={props.onClose} />
      <Errors className="sign-in-page__errors" error={props.error} />
      <SignInForm onSubmit={handleSubmit} />
      <div className="sign-in-page__text">
        <a href={props.resetPasswordUrl}>Forgot password?</a>
      </div>
      <SocialSignIn onLogin={() => actions.onLogin()} />
      <p className="sign-in-page__text">
        Don&apos;t have an account?&nbsp;
        <a
          data-testid="sign-up-link"
          href={props.signUpUrl ? props.signUpUrl : '#'}
          onClick={handleClickSignUpLink}
        >
          Sign Up
        </a>
      </p>
    </div>
  );
};

export default SignIn;
