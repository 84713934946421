import React, {FC} from 'react';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';

import {ISerializedUser} from 'domain/users';
import Modal from 'web/components/modal';
import {ZipCodeModalProps} from 'web/components/zip_code_modal';
import {actions as modalActions} from 'web/helpers/modal_duck';
import EmailEntry from 'web/components/auth_flow/components/zip_entry_screen/components/email_entry';
// import {GOODEGGS_SELECT_URL} from 'web/constants';

interface StateProps {
  zipCode?: string;
  user?: ISerializedUser;
}

interface DispatchProps {
  closeModal: (cb?: () => void) => void;
  openZipModal: (props: ZipCodeModalProps) => void;
}

export interface ConsolationModalProps {
  onDismiss?: () => void;
  onClickTryAnotherZip?: () => void;
  newZip: string;
  comingSoon: boolean;
}

interface Props extends ConsolationModalProps, StateProps, DispatchProps {}

const ZipCodeModal: FC<Props> = ({
  closeModal,
  comingSoon,
  newZip,
  onClickTryAnotherZip,
  onDismiss,
  openZipModal,
  user,
}) => {
  const onTryAnotherZip = (): void => {
    if (onClickTryAnotherZip != null) {
      onClickTryAnotherZip();
    } else {
      openZipModal({onDismiss});
    }
  };
  const [displayThanks, setDisplayThanks] = React.useState<boolean>(false);

  const renderCTA = (): React.ReactElement => (
    <div className="modal-content__cta">
      {!displayThanks ? (
        <EmailEntry
          onClickTryAnotherZipCode={onTryAnotherZip}
          zip={newZip}
          onSuccess={(): void => setDisplayThanks(true)}
          onCloseModal={(): void => closeModal(onDismiss)}
        />
      ) : (
        <button
          type="button"
          className="button refresh"
          onClick={(): void => closeModal(onDismiss)}
        >
          Close
        </button>
      )}
      <p>
        <a href="#" className="text-link js-try-another-zip" onClick={onTryAnotherZip}>
          Try Another Zip Code
        </a>
      </p>
      {/* <p>
        <a href={GOODEGGS_SELECT_URL} className="text-link">
          Ship Nationwide
        </a>
      </p> */}
      {user == null ? (
        <p className="sign-in-flow__sign-in">
          Already have an account?{' '}
          <a href="/signin" className="text-link">
            Sign In
          </a>
        </p>
      ) : null}
    </div>
  );

  return (
    <Modal className="refresh" fullScreenMobile onClose={(): void => closeModal(onDismiss)}>
      <div className="auth-flow__modal" data-testid="zip-consolation__modal">
        <div className="auth-flow__content">
          <div className="auth-flow__header">
            <div className="close-modal__icon">
              <i
                className="icon icon-thin-x"
                data-testid="modal-close-icon"
                onClick={(): void => closeModal(onDismiss)}
              />
            </div>
            <div className="auth-flow__logo">
              <i className={`icon icon-logo-black`} />
            </div>
          </div>
          <div>
            <div className="flow-zip-entry desktopMargin__modal">
              {!displayThanks ? (
                <div>
                  <div>
                    {comingSoon ? (
                      <h3 className="modal__title" data-testid="headline">
                        Good news, we are expanding to your neighborhood soon!
                      </h3>
                    ) : (
                      <h3 className="modal__title" data-testid="headline">
                        We currently do not deliver to your area locally.
                        {/* <a
                          href={`${GOODEGGS_SELECT_URL}?utm_source=goodeggs_local&utm_campaign=delivery_area_check`}
                        >
                          Good Eggs Select
                        </a>
                        {''}. */}
                      </h3>
                    )}
                  </div>
                  <div className="modal-content">
                    <div className="modal-content__message">
                      {comingSoon ? (
                        <p>Be the first to know when Good Eggs does local deliveries to {newZip}</p>
                      ) : (
                        <p>
                          We hope to be there soon! Be the first to know when Good Eggs does local
                          deliveries to {newZip}.
                        </p>
                      )}
                    </div>
                    {renderCTA()}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <h3 className="modal__title">Thank you!</h3>
                  </div>
                  <div className="modal-content">
                    <div className="modal-content__message" data-testid="message-container">
                      We will notify you when Good Eggs delivers to {newZip}.
                    </div>
                    {renderCTA()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StateProps): StateProps => ({
  zipCode: state.zipCode,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, Props, AnyAction>,
): DispatchProps => ({
  closeModal: (cb): void => {
    dispatch(modalActions.closeModal());
    if (cb != null) {
      cb();
    }
  },
  openZipModal: (props): void => {
    dispatch(modalActions.replaceModal({modal: 'ZipCodeModal', props}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeModal);
