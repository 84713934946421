import React, {FC, MouseEventHandler} from 'react';
import classNames from 'classnames';

const NAV_LIST_ITEMS = [
  {
    slug: '',
    label: 'Your Account',
  },
  {
    slug: 'details',
    label: 'Account Details',
  },
  {
    slug: 'password',
    label: 'Password',
  },
  {
    slug: 'creditcard',
    label: 'Payment',
  },
  {
    slug: 'schedule',
    label: 'Subscriptions',
  },
  {
    slug: 'membership',
    label: 'Membership',
  },
];

interface AccountNavListProps {
  currentSlug: string;
  handleClick: MouseEventHandler<HTMLAnchorElement>;
}

const AccountNavList: FC<AccountNavListProps> = ({currentSlug, handleClick}) => {
  return (
    <ul className="account-nav-list__list">
      {NAV_LIST_ITEMS.map(({slug, label}) => (
        <li
          className={classNames('account-nav-list__list-item', {
            'account-nav-list__list-item-current': currentSlug === slug,
          })}
          key={slug}
        >
          {currentSlug !== slug ? (
            <a href={`/account/${slug}`} onClick={handleClick}>
              {label}
            </a>
          ) : (
            label
          )}
        </li>
      ))}
    </ul>
  );
};

export default AccountNavList;
