// Avoid use of goodeggs-money and therefore bignumber in client side code to avoid a crash in Safari 10.1, see https://github.com/MikeMcl/bignumber.js/issues/120

const formatCents = (cents: number): string => {
  return (cents / 100).toFixed(2);
};

const centsRoundedToDollars = (cents: number): number => {
  return Math.floor(cents / 100);
};

export {formatCents, centsRoundedToDollars};
