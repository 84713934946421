// This is the entrypoint for client side code
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from 'jquery';

import {startReactRedux, PageType, StoreData} from 'web/helpers/redux_client';

declare global {
  interface Window {
    _goodeggs?: {
      init?: () => void;
      initReactRedux?: (pageModule: PageType | null, opt: {storeData: StoreData}) => void;
    };
    $?: unknown;
  }
}

export default function init(): void {
  // deprecated - this is a current integration test requirement for `executeScript` style
  // assertions and actions - whle we have it in tests, we need to leave it for prod as well
  // to avoid accidental production global jquery dependencies passing in test and failing in prod
  window.$ = $;

  // Touch feature detection
  if ('ontouchstart' in document.documentElement) {
    document.documentElement.className += ' touch';
  } else {
    document.documentElement.className += ' no-touch';
  }

  // spec document loaded mechanism
  document.addEventListener('DOMContentLoaded', function () {
    document.documentElement.className += ' all-js';
  });
}

export function initReactRedux(
  pageModule: PageType | null,
  {storeData}: {storeData: StoreData},
): void {
  if (pageModule) {
    startReactRedux(pageModule, storeData);
  }
}

// currently accommodate for both the pageModule and init functions to be exported from the bundles
if (!window._goodeggs) {
  window._goodeggs = {};
}
window._goodeggs.init = init;
window._goodeggs.initReactRedux = initReactRedux;
