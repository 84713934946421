import update from 'immutability-helper';
import {reducer as formReducer} from 'redux-form';

import MarketLayout from 'web/components/market_layout';
import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';
import {
  actions as signInFormActions,
  reducer as signInFormReducer,
} from 'web/components/sign_in_form/duck';
import {actions as signInPageActions} from 'web/account/sign_up_page/duck';
import {reducer as socialSignInReducer} from 'web/components/social_sign_in/duck';
import sanitizeReturnPath from 'web/helpers/routing/sanitize_return_path';

export const actions = {
  onLogin: () => (dispatch, getState) => {
    const {signInPage, showSignUpModalFlow} = getState();
    if (showSignUpModalFlow) {
      return dispatch(signInPageActions.updateBasket());
    } else if (signInPage) {
      return dispatch(
        navigationActions.navigate(
          sanitizeReturnPath({
            path: signInPage.destinationUrl,
            defaultPath: '/',
          }),
        ),
      );
    }
  },
  submitSignInForm: () => (dispatch) =>
    dispatch(signInFormActions.submitForm({rejectUponError: true}))
      .then(() => dispatch(actions.onLogin()))
      .catch(() => null),
};

export function reducer(state, action) {
  let newState = state;
  if (!state.signInPage.useMinimalLayout) {
    newState = MarketLayout.reducer(state, action);
  }
  return update(newState, {
    navigation: {$set: navigationReducer(state.navigation, action)},
    signInForm: {$set: signInFormReducer(state.signInForm, action)},
    socialSignIn: {$set: socialSignInReducer(state.socialSignIn, action)},
    form: {$set: formReducer(state.form, action)},
  });
}
