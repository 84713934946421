import React, {FC} from 'react';

import Modal from 'web/components/modal';

export interface SubscriptionInfoModalProps {
  onDismiss: () => void;
  showDiscountContent?: boolean;
}

const SubscriptionInfoModal: FC<SubscriptionInfoModalProps> = ({
  onDismiss,
  showDiscountContent = false,
}) => {
  return (
    <Modal className="subscription-info-modal" onClose={onDismiss}>
      <div
        data-testid="subscription-info-modal__close-button"
        className="subscription-info-modal__close-button"
        onClick={onDismiss}
      >
        <i className="icon icon-thin-x" />
      </div>
      <h1 className="subscription-info-modal__title">
        {showDiscountContent ? 'Save Time (and Money) Every Week' : 'Save Time, Every Week'}
      </h1>
      <div className="subscription-info-modal__content">
        <div className="subscription-info-modal__item">
          <div className="subscription-info-modal__item__icon">1</div>
          <div className="subscription-info-modal__item__text">
            <h3>Never Run Out</h3>
            <p>
              Subscribe to your staples at checkout and we’ll set up an automatic weekly order for
              the same day and time as this order.
            </p>
          </div>
        </div>
        <div className="subscription-info-modal__item">
          <div className="subscription-info-modal__item__icon">2</div>
          <div className="subscription-info-modal__item__text">
            <h3>{showDiscountContent ? 'Save Money' : 'Your Shopping Shortcut'}</h3>
            <p>
              {showDiscountContent
                ? 'Save 5-20% on many of your favorite items. Saving money while saving time? Now that’s a win-win.'
                : 'We’ll remind you to add to your order before it arrives. Get everything else you need for the week delivered along with your subscription items.'}
            </p>
          </div>
        </div>
        <div className="subscription-info-modal__item">
          <div className="subscription-info-modal__item__icon">3</div>
          <div className="subscription-info-modal__item__text">
            <h3>No Commitments</h3>
            <p>
              Change your mind? Don’t need it this week? Edit or cancel upcoming orders up until the
              order cutoff.
            </p>
          </div>
        </div>
        <p className="subscription-info-modal__faq-blurb">
          Go to our
          <a
            href="https://help.goodeggs.com/hc/en-us/sections/360001684112-Subscriptions"
            data-testid="subscriptions__help-center"
          >
            {' '}
            Help Center{' '}
          </a>
          to learn more.
        </p>
      </div>
      <div className="subscription-info-modal__footer">
        <button type="button" className="subscription-info-modal__ok-button" onClick={onDismiss}>
          OK
        </button>
      </div>
    </Modal>
  );
};

export default SubscriptionInfoModal;
