import typewriter, {Campaign} from '@analytics/client/generated';
import {Callback, Context as SegmentContext} from '@analytics/client/generated/segment';
import cookieHelper from 'web/helpers/cookies';

type Typewriter = typeof typewriter;
type TypewriterTrackEventMethods = Omit<Typewriter, 'setTypewriterOptions'>;
type TypewriterTrackEventParams = {
  [Property in keyof TypewriterTrackEventMethods]: {
    event: Property;
    properties: Parameters<TypewriterTrackEventMethods[Property]>[0];
  };
};
type TrackKey = TypewriterTrackEventParams[keyof TypewriterTrackEventParams]['event'];

export interface Context extends SegmentContext {
  app?: {
    commitSHA: string;
    instance: string;
    // TODO Only `app.name` is part of the segment spec, unsure if we can extend their fields
    name: 'garbanzo';
  };
  platform: 'web';
  screen: {
    height: string; // TODO This seems to be wrong in typewriter and should be a number
    width: string; // TODO This seems to be wrong in typewriter and should be a number
  };
  loggedIn: boolean;
  masquerading?: boolean;
  shoppingSession:
    | {
        campaign?: Campaign;
        id: string;
        initialReferrer: string;
        isNew?: boolean;
      }
    | Record<string, unknown>;
  experiments: Array<{
    name: string;
    variant: string;
  }>;
  foodshed: 'sfbay';
  pageName?: string; // TODO Somewhat duplicate of segment `page.name` auto provided field
  isMember?: boolean;
}

function getScreen(): Context['screen'] {
  const documentElement = document?.documentElement;
  const bodyElement =
    typeof document?.getElementsByTagName === 'function'
      ? document?.getElementsByTagName('body')[0]
      : null;

  const width =
    window?.innerWidth ?? documentElement?.clientWidth ?? bodyElement?.clientWidth ?? null;
  const height = window?.innerHeight ?? documentElement?.clientHeight ?? bodyElement?.clientHeight;

  return {
    width: width?.toString(),
    height: height?.toString(),
  };
}

export type ContextAccumulator = Partial<
  Pick<Context, 'app' | 'masquerading' | 'pageName' | 'isMember'>
>;

export class Analytics {
  private readonly contextAccumulator: ContextAccumulator;
  private userId: string | null;
  private anonymousId: string | null;

  public constructor() {
    this.contextAccumulator = {};
    this.userId = null;
    this.anonymousId = null;
  }

  public setContextField<T extends keyof ContextAccumulator>(
    key: T,
    value: ContextAccumulator[T],
  ): void {
    this.contextAccumulator[key] = value;
  }

  public updateUserId({userId}: {userId: string}): void {
    this.userId = userId;

    window.analytics.ready(() => {
      window.analytics.user().id(userId);
      window.analytics.identify(userId);
    });
  }

  public setUserIds({userId, anonymousId}: {userId: string | null; anonymousId: string}): void {
    this.userId = userId;
    this.anonymousId = anonymousId;

    window.analytics.ready(() => {
      window.analytics.user().id(userId);
      window.analytics.user().anonymousId(anonymousId);

      // TODO Should we still call identify here like legacy implementation?
      if (userId != null) {
        window.analytics.identify(userId);
      }
    });
  }

  public async track<T extends TrackKey>(
    event: T,
    properties: TypewriterTrackEventParams[T]['properties'],
    callback?: Callback,
  ): Promise<void> {
    if (!this.userId && !this.anonymousId) {
      console.error('Expected either userId or anonymousId to be defined for segment track calls');
      return;
    }

    const context: Context = {
      platform: 'web',
      foodshed: 'sfbay',
      screen: getScreen(),
      experiments: cookieHelper.getExperiments(),
      shoppingSession: cookieHelper.getShoppingSessionCookie(),
      loggedIn: Boolean(this.userId),
      app: this.contextAccumulator.app,
      masquerading: this.contextAccumulator.masquerading,
      pageName: this.contextAccumulator.pageName,
      isMember: this.contextAccumulator.isMember,
    };

    // TODO https://github.com/Microsoft/TypeScript/issues/30581
    // TypeScript can't narrow this type because we are using union params, although
    // we can guarantee as used the event will match the associated properties
    typewriter[event](
      {
        // TODO: (@shermam) We are duplicating a couple of context
        // properties here on the event properties. But
        // this is a requirement that Mark asked, because some
        // destinations are not able to read data from the context
        // For reference see this comment
        // https://www.pivotaltracker.com/story/show/179796545/comments/227421406
        loggedIn: Boolean(this.userId),
        masquerading: this.contextAccumulator.masquerading,
        // Although we should be able to read this from the context, Amplitude is only able to read it from the event,
        // we're including it here to simplify filtering on this data in the dashboard
        experiments: cookieHelper.getExperimentsFlattened(),
        ...properties,
      } as any,
      {
        context,
      },
      callback,
    );
  }
}

export default new Analytics();
