import React, {FC} from 'react';

import Modal from 'web/components/modal';
import AuthFlow from 'web/components/auth_flow';

export interface AuthFlowModalProps {
  onCloseModal: () => void;
  onAuthenticate: () => void;
  successMessage?: string;
}

const AuthFlowModal: FC<AuthFlowModalProps> = ({onAuthenticate, onCloseModal, successMessage}) => {
  return (
    <div className="auth-flow__modal-wrapper">
      <Modal onClose={onCloseModal}>
        <div className="auth-flow__modal" id="auth-flow__modal" data-testid="auth-flow__modal">
          <AuthFlow
            onCloseModal={onCloseModal}
            onAuthenticate={onAuthenticate}
            successMessage={successMessage}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AuthFlowModal;
