const socialSignInImport = (id, src, cb) => {
  const s = 'script';
  const d = document;
  const scriptInDocument = d.getElementsByTagName(s)[0];
  if (!d.getElementById(id) && scriptInDocument) {
    const js = d.createElement(s);
    js.id = id;
    js.src = src;
    scriptInDocument.parentNode.insertBefore(js, scriptInDocument);

    js.addEventListener('load', cb);
  } else {
    return true;
  }
};

export default socialSignInImport;
