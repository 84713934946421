import React, {FC, useEffect, useState} from 'react';
import {throttle} from 'lodash';
import classNames from 'classnames';
import $ from 'jquery';

import {LayoutRow, LayoutCell} from 'web/components/layout_row';

interface Subcategory {
  id: string;
  name: string;
  url: string;
}

interface Category {
  id: string;
  name: string;
  url: string;
  subcategories: Subcategory[];
}

export interface MobileLocalNavigationProps {
  categories: Category[];
  selectedCategoryName?: string;
  selectedSubcategoryName?: string;
  subcategories?: Subcategory[];
}

const MobileLocalNavigation: FC<MobileLocalNavigationProps> = ({
  categories,
  subcategories,
  selectedCategoryName,
  selectedSubcategoryName,
}) => {
  const [flyoutShown, setFlyoutShown] = useState<'subcategory' | 'category'>();
  const [activeSubcategoryName, setActiveSubcategoryName] = useState<string>();

  const isAnchorContentsVisible = (anchorId: string): boolean => {
    const anchorEl = $(`#${anchorId}`)[0];

    if (anchorEl.parentElement != null) {
      const listingsEl = anchorEl.parentElement;
      return listingsEl.getBoundingClientRect().bottom > 100;
    }

    return false;
  };

  const updateActiveSubcategory = (): void => {
    if (activeSubcategoryName) {
      const activeSubCategory = subcategories?.find((subcategory) =>
        isAnchorContentsVisible(subcategory.id),
      );
      setActiveSubcategoryName(activeSubCategory?.name);
    } else {
      const firstSubcategoryName = subcategories?.[0].name;
      setActiveSubcategoryName(firstSubcategoryName);
    }
  };

  const handleScroll = (): void => {
    updateActiveSubcategory();
  };

  const hideFlyout = (): void => {
    setFlyoutShown(undefined);
    $('body').css('overflow-y', '');
  };

  const hideFlyoutOnClickOutside = (e: JQuery.ClickEvent): void => {
    const titleParentsCount = $(e.target).parents('.mobile-local-navigation__title').length;

    if (titleParentsCount > 0) return;

    const flyoutParentsCount = $(e.target).parents(
      '.mobile-local-navigation__flyout-content',
    ).length;

    if (flyoutParentsCount === 0) {
      hideFlyout();
    }
  };

  useEffect(() => {
    $('body').on('click', (e) => hideFlyoutOnClickOutside(e));
    $(window).on(
      'scroll',
      throttle(() => handleScroll(), 100),
    );

    return () => {
      $('body').off('click');
    };
  }, []);

  const handleCategoryClick = (): void => {
    if (flyoutShown === 'category') {
      hideFlyout();
    } else {
      setFlyoutShown('category');
      $('body').css('overflow-y', 'hidden');
    }
  };

  const handleSubcategoryClick = (): void => {
    if (!subcategories) return;
    if (flyoutShown === 'subcategory') {
      hideFlyout();
    } else {
      $('body').css('overflow-y', 'hidden');
      setFlyoutShown('subcategory');
    }
  };

  const orderedCategories = Array.from(categories);

  return (
    <div className="mobile-local-navigation">
      <LayoutRow className="mobile-local-navigation__title">
        <LayoutCell
          className={classNames('mobile-local-navigation__category-title', {
            'is-open': flyoutShown === 'category',
          })}
        >
          <div onClick={handleCategoryClick}>
            {selectedCategoryName}
            <i className="icon icon-chevron" />
          </div>
        </LayoutCell>
        <LayoutCell
          className={classNames('mobile-local-navigation__subcategory-title', {
            'is-open': flyoutShown === 'subcategory',
          })}
          expanding
        >
          <div onClick={handleSubcategoryClick}>
            {activeSubcategoryName || selectedSubcategoryName}
            {subcategories && <i className="icon icon-chevron" />}
          </div>
        </LayoutCell>
      </LayoutRow>
      {flyoutShown != null && <div className="mobile-local-navigation__flyout-overlay" />}
      {flyoutShown === 'category' && (
        <div className="mobile-local-navigation__category-flyout">
          <div className="mobile-local-navigation__flyout-content">
            {orderedCategories.map((category) => (
              <a
                className="mobile-local-navigation__flyout-row"
                key={category.id}
                href={category.url}
              >
                {category.name}
              </a>
            ))}
          </div>
        </div>
      )}
      {subcategories && flyoutShown === 'subcategory' && (
        <div className="mobile-local-navigation__subcategory-flyout">
          <div className="mobile-local-navigation__flyout-content">
            {subcategories.map((subcategory) => (
              <a
                className="mobile-local-navigation__flyout-row"
                key={subcategory.id}
                href={subcategory.url}
                onClick={handleSubcategoryClick}
              >
                {subcategory.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileLocalNavigation;
