import React, {FC} from 'react';

import {humanPriceFromCents} from 'infra/formatters/money';

interface StrikethroughLineItemProps {
  basetotal: number;
}
const StrikethroughLineItem: FC<StrikethroughLineItemProps> = ({
  basetotal,
}: StrikethroughLineItemProps) => {
  return (
    <span className="strikethrough-lineitem" data-testid="strikethrough-lineitem">
      {humanPriceFromCents(basetotal)}
    </span>
  );
};

export default StrikethroughLineItem;
