import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'infra/moment';
import {humanPriceFromCents, humanDollarsFromCents} from 'infra/formatters/money';
import * as timeFormatters from 'infra/formatters/time';

function renderBannerCopy({order, tzid}) {
  const now = moment().tz(tzid);
  const cutoff = moment(order.orderCutoff).tz(tzid);
  const {startAt, endAt} = order.deliveryWindow;
  const formattedDeliveryTime = timeFormatters.deliveryTimeLabel(startAt, endAt, tzid);
  const formattedOrderCutoffTime = timeFormatters.shortCutoffTimeAndDay(cutoff, tzid);

  if (now.isAfter(cutoff)) {
    return (
      <div className="upcoming-orders-banner__copy">
        You have an order for <a href={`/account/orders/${order.id}`}>{formattedDeliveryTime}</a>.
      </div>
    );
  }

  if (order.additionalPricingDetails && order.additionalPricingDetails.amountToMinimum > 0) {
    const formattedAmountToMinimum = humanPriceFromCents(
      order.additionalPricingDetails.amountToMinimum,
    );
    const formattedOrderMinimum = humanDollarsFromCents(
      order.additionalPricingDetails.orderMinimum,
    );

    return (
      <div className="upcoming-orders-banner__copy">
        Get your order for <a href={`/account/orders/${order.id}`}>{formattedDeliveryTime}</a>! Add{' '}
        {formattedAmountToMinimum} to meet the {formattedOrderMinimum} minimum.
      </div>
    );
  }

  return (
    <div className="upcoming-orders-banner__copy">
      You can add to your order for{' '}
      <a href={`/account/orders/${order.id}`}>{formattedDeliveryTime}</a> until{' '}
      {formattedOrderCutoffTime}.
    </div>
  );
}

export default function UpcomingOrderBanner({order, tzid}) {
  return (
    <div
      className={classNames('upcoming-orders-banner', {
        preorder: Boolean(order.isPreorder),
      })}
    >
      <div className="upcoming-orders-banner__subscription-icon">
        {order.hasSubscribedItems ? (
          <i className="icon icon-subscriptions" data-testid="icon-subscriptions" />
        ) : null}
        {order.additionalPricingDetails &&
        order.additionalPricingDetails.amountToMinimum > 0 &&
        order.hasSubscribedItems ? (
          <i className="icon icon-alert-warning" data-testid="icon-alert-warning" />
        ) : null}
      </div>
      {renderBannerCopy({order, tzid})}
    </div>
  );
}

renderBannerCopy.propTypes = UpcomingOrderBanner.propTypes = {
  order: PropTypes.shape({
    isPreorder: PropTypes.bool,
    id: PropTypes.string.isRequired,
    hasSubscribedItems: PropTypes.bool.isRequired,
    orderCutoff: PropTypes.string.isRequired,
    deliveryWindow: PropTypes.shape({
      startAt: PropTypes.string.isRequired,
      endAt: PropTypes.string.isRequired,
    }).isRequired,
    additionalPricingDetails: PropTypes.shape({
      amountToMinimum: PropTypes.number.isRequired,
      orderMinimum: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  tzid: PropTypes.string.isRequired,
};
