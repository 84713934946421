// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {reduxForm, Field} from 'redux-form';

import Alert from 'web/components/alert';
import SubmitButton from 'web/components/submit_button';

const inputWithError = ({input, meta, type, label, className, inputClassName, placeholder}) => (
  <div className={className}>
    <label>{label}</label>
    <input
      type={type || 'text'}
      placeholder={placeholder}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      className={classNames(
        'form-control',
        {error: meta.error && !meta.active && meta.touched},
        'form-row-input',
        inputClassName,
      )}
    />
    {meta.error && !meta.active && meta.touched ? <div className="error">{meta.error}</div> : null}
  </div>
);

inputWithError.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

class PasswordForm extends Component {
  render() {
    const {handleSubmit, label, placeholder, hint} = this.props;
    return (
      <form className="form" onSubmit={handleSubmit}>
        {this.props.globalError ? (
          <Alert
            className="password-form__alert"
            type="error"
            heading="Sorry, there was a problem saving your password."
          >
            {(() => {
              switch (this.props.globalError.type) {
                case 'FAILED_TO_SAVE_USER':
                  return 'Your new password must be 8 characters or more. Please try again.';
                default:
                  return 'Error saving password, please contact community care';
              }
            })()}
          </Alert>
        ) : null}
        <Field
          label={label}
          placeholder={placeholder}
          type="password"
          component={inputWithError}
          name="newPassword"
          className="form-row"
          inputClassName="form-row-input-new-password"
        />
        {hint ? (
          <div className="row-fluid">
            <div className="span8">
              <div className="message">{hint}</div>
            </div>
            <div className="span4">{this.renderSubmitButton()}</div>
          </div>
        ) : (
          this.renderSubmitButton()
        )}
      </form>
    );
  }

  renderSubmitButton() {
    return (
      <SubmitButton
        className="button form-submit"
        disabled={this.props.pristine}
        isSaving={this.props.submitting}
        workingLabel="Saving..."
      >
        Save
      </SubmitButton>
    );
  }
}

PasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  hint: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  globalError: PropTypes.object,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

const validatePasswordForm = (values) => {
  const {newPassword} = values;
  const errors = {};

  if (newPassword == null || newPassword === '') {
    errors.newPassword = 'Required';
  } else if (newPassword.length < settings.minPasswordLength) {
    errors.newPassword = `Password must be ${settings.minPasswordLength} characters or more`;
  } else if (newPassword.length > settings.maxPasswordLength) {
    errors.newPassword = `Your password cannot exceed ${settings.maxPasswordLength} characters.`;
  }

  return errors;
};

const PasswordReduxForm = reduxForm({
  form: 'password',
  validate: validatePasswordForm,
})(PasswordForm);

function mapStateToProps(state) {
  return {
    globalError: state.form.globalError,
  };
}

export default connect(mapStateToProps)(PasswordReduxForm);
