import React, {Component} from 'react';
import classNames from 'classnames';

import moment from 'infra/moment';
import {orderStatus, deliveryTimeHeader} from 'infra/formatters/time';
import Panel from 'web/components/panel';

import AddToOrder from './add_to_order';

export default class OrderPanel extends Component {
  header() {
    const {
      startAt,
      endAt,
      orderCutoff,
      tzid,
      hasSubscribedItems,
      additionalPricingDetails,
      deliveryStatus,
    } = this.props;

    const headerTitleClassnames = classNames('single-order-panel__header-title', {
      subscription: hasSubscribedItems,
    });

    return (
      <div className="single-order-panel__header">
        {this.props.isEditable ? (
          <AddToOrder
            orderCutoff={orderCutoff}
            tzid={tzid}
            orderId={this.props.orderId}
            onOpenModal={this.props.onOpenModal}
            onCloseModal={this.props.onCloseModal}
          />
        ) : null}
        {hasSubscribedItems ? (
          <div className="single-order-panel__subscription-icon">
            <i className="icon icon-subscriptions" />
            {additionalPricingDetails.amountToMinimum > 0 ? (
              <i className="icon icon-alert-warning" />
            ) : null}
          </div>
        ) : null}
        {this.props.isLinkable ? (
          <a href={`/account/orders/${this.props.orderId}`}>
            <div className={headerTitleClassnames}>{deliveryTimeHeader(startAt, endAt, tzid)}</div>
          </a>
        ) : (
          <div className={headerTitleClassnames}>{deliveryTimeHeader(startAt, endAt, tzid)}</div>
        )}

        <div
          className={classNames('single-order-panel__header-subtitle', {
            'is-upcoming': moment().startOf('day').isBefore(startAt),
          })}
        >
          {orderStatus({
            startAt,
            orderCutoff,
            pricingDetails: additionalPricingDetails,
            tzid,
            deliveryStatus,
          })}
        </div>
        {this.props.isEditable ? (
          <AddToOrder
            className="single-order-page__add-to-order-button-mobile button"
            orderCutoff={orderCutoff}
            tzid={tzid}
            orderId={this.props.orderId}
            onOpenModal={this.props.onOpenModal}
            onCloseModal={this.props.onCloseModal}
          />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div className="single-order-panel" data-orderid={this.props.orderId}>
        {this.props.showMasqueradeWarning && this.props.user.masquerading ? (
          <div className="single-order-panel__masquerading-warning">Masquerading</div>
        ) : null}
        <Panel header={this.header()}>{this.props.children}</Panel>
      </div>
    );
  }
}
