import React, {FC} from 'react';

const LoadingOverlay: FC = () => {
  return (
    <div className="loading-overlay" data-testid="loading-overlay">
      <div className="loading-overlay__background" />
      <div className="loading-overlay__spinner">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingOverlay;
