import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class StepsHeader extends Component {
  render() {
    let visitedStep = 1;
    if (this.props.hasValidFulfillmentOffer) {
      visitedStep = 2;
    }
    if (this.props.hasActiveCard) {
      visitedStep = 3;
    }
    const steps = ['Delivery', 'Payment', 'Review'];
    return (
      <div className="step-header">
        <div className="step-header__content">
          {steps.map((stepName, index) => [
            <div
              key={`step-${index}`}
              className={stepClass(index + 1, this.props.currentStep, visitedStep)}
            >
              <div className="step-header__step-number">{index + 1}</div>
              {stepName}
            </div>,
            steps[index + 1] ? (
              <div
                key={`spacer-${index}`}
                className={spacerClass(index + 2, this.props.currentStep, visitedStep)}
              />
            ) : null,
          ])}
        </div>
      </div>
    );
  }
}

StepsHeader.propTypes = {
  currentStep: PropTypes.number,
  hasActiveCard: PropTypes.bool,
  hasValidFulfillmentOffer: PropTypes.bool,
};

function stepClass(step, currentStep, visitedStep) {
  if (step === currentStep) {
    return 'step-header__step is-selected-step';
  } else if (step <= visitedStep) {
    return 'step-header__step is-visited-step';
  } else if (step > currentStep) {
    return 'step-header__step is-unvisited-step';
  }
  return 'step-header__step is-completed-step';
}

function spacerClass(step, currentStep, visitedStep) {
  if (step > currentStep && step > visitedStep) {
    return 'step-header__spacer';
  }
  return 'step-header__spacer is-visited';
}
