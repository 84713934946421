import React, {FC} from 'react';

import {ClientSettings} from 'config/settings';

import TermsNavListItem from './terms_nav_list_item';

export interface Props {
  currentSlug: string;
}

declare const settings: ClientSettings;

const TermsNavList: FC<Props> = ({currentSlug}) => {
  return (
    <div className="terms-page__column-nav">
      <ul className="terms-page__nav-list">
        <TermsNavListItem href="/terms" slug="" currentSlug={currentSlug} label="Terms of Use" />
        <TermsNavListItem
          slug="privacy"
          currentSlug={currentSlug}
          href={settings.privacyLink}
          label="Privacy Policy"
        />
        <TermsNavListItem slug="copyright" currentSlug={currentSlug} label="Copyright Policy" />
        <TermsNavListItem slug="gift-cards" currentSlug={currentSlug} label="Gift Cards" />
        <TermsNavListItem
          slug="referral"
          currentSlug={currentSlug}
          label="Promotions &amp; Referral Program"
        />
        <TermsNavListItem slug="alcohol" currentSlug={currentSlug} label="Alcohol" />
        <TermsNavListItem slug="returns" currentSlug={currentSlug} label="Returns and Refunds" />
        <TermsNavListItem slug="membership" currentSlug={currentSlug} label="Membership" />
      </ul>
    </div>
  );
};

export default TermsNavList;
