import React, {ReactElement, ReactNode, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useSelector} from 'react-redux';

const StripeContext = ({children}: {children?: ReactNode}): ReactElement => {
  const stripeKey = useSelector((state: {stripeKey: string}) => state.stripeKey);
  const [stripe] = useState(loadStripe(stripeKey));
  return <Elements stripe={stripe}>{children}</Elements>;
};

export default StripeContext;
