import React from 'react';
import classNames from 'classnames';

import useClientSettings from 'web/hooks/useClientSettings';

interface ShareLinksProps {
  noCircle?: boolean;
}

interface SocialHandles {
  facebook: string;
  instagram: string;
}

const ShareLinks = ({noCircle}: ShareLinksProps): JSX.Element => {
  const clientSettings = useClientSettings();
  const links: SocialHandles = {
    facebook: `https://facebook.com/${clientSettings.defaultSocialHandles.facebook}`,
    instagram: `https://instagram.com/${clientSettings.defaultSocialHandles.instagram}`,
  };

  return (
    <div className="icons social-icons" data-testid="shared-social-icons">
      {Object.keys(links).map((company: string) => (
        <a href={links[company as keyof SocialHandles]} key={company}>
          <i className={classNames('icon', `icon-${company}${noCircle ? '' : '-circle'}`)} />
        </a>
      ))}
    </div>
  );
};

export default ShareLinks;
