import rolodex from 'goodeggs-rolodex';

export const DEFAULT_CUSTOMER_MESSAGE = 'Oops... something went wrong';
export const defaultError = (): {
  customerMessage: typeof DEFAULT_CUSTOMER_MESSAGE;
  advice: string;
} => {
  return {
    customerMessage: DEFAULT_CUSTOMER_MESSAGE,
    advice: `Contact us at ${rolodex.eaterHelpEmailAddress()} and we'll sort it out.`,
  };
};
