import React, {FC, SyntheticEvent, useEffect, useRef, useState} from 'react';
import $ from 'jquery';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {throttle} from 'lodash';

import {DropdownOpener} from 'web/helpers/dropdown_opener';
import assetPath from 'web/helpers/asset_path';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {
  MarketLayoutStore,
  UpcomingPreorderPeriod,
} from 'web/components/market_layout/store_builder';
import useClientSettings from 'web/hooks/useClientSettings';
import {ShoppingNotification} from 'web/components/market_layout';
import useLocalStorage from 'web/hooks/useLocalStorage';

import DayChooser from '../day_chooser';
import SigninHelpers from '../signin_helpers';
import BasketDropdown from '../basket_dropdown';
import {DesktopCategories, StickyDesktopCategories} from '../desktop_categories';
import AccountControls from './components/account_controls';
import ForYouControls from './components/for_you_controls';

// Determines height at which sticky header is shown
// May interact with styles in web/components/header/components/desktop_header/index.styl:
const MINI_BREAKPOINT = 140;

let basketHoverMetricsInitialized = false;
const initBasketHoverMetrics = (): boolean | null => {
  if (basketHoverMetricsInitialized) {
    return null;
  }
  basketHoverMetricsInitialized = true;
  return basketHoverMetricsInitialized;
};

interface DesktopHeaderProps {
  disableBasketDropdown?: boolean;
  handleChangeDay: (day: string, shoppingNotification?: ShoppingNotification) => void;
}

const DesktopHeader: FC<DesktopHeaderProps> = ({disableBasketDropdown, handleChangeDay}) => {
  const dispatch = useDispatch();
  const {
    user,
    currentFulfillmentDay,
    foodhub,
    fulfillmentDaySummaries,
    upcomingPreorderPeriods,
    fulfillmentDaysByDay,
    upcomingOrders,
    categories,
    basket,
    features,
    zipCode,
    inferredZipCode,
    selectedNavId,
  } = useSelector((state: MarketLayoutStore) => ({
    user: state.user,
    currentFulfillmentDay: state.currentFulfillmentDay,
    foodhub: state.foodhub,
    fulfillmentDaySummaries: state.fulfillmentDaySummaries,
    upcomingPreorderPeriods: state.upcomingPreorderPeriods,
    fulfillmentDaysByDay: state.fulfillmentDaysByDay,
    upcomingOrders: state.upcomingOrders,
    categories: state.categories,
    basket: state.basket,
    features: state.features,
    zipCode: state.zipCode,
    inferredZipCode: state.inferredZipCode,
    selectedNavId: state.selectedNavId,
  }));
  const [, , removeDismissedSurveyPopin] = useLocalStorage<boolean>(
    'dismissed-survey-popin',
    false,
  );
  const [currentTabSlug, setCurrentTabSlug] = useState<string | null>(null);
  const mini = useRef<boolean>(false);
  const clientSettings = useClientSettings();

  const openDayChooserModal = (preorderPeriod: UpcomingPreorderPeriod): void => {
    dispatch(modalActions.openDayChooserModal({preorderPeriod}));
  };

  const setTab = (slug: string, pointing: boolean): void => {
    if (pointing) {
      setCurrentTabSlug(slug);
    }
    if (currentTabSlug === slug && !pointing) {
      setCurrentTabSlug(null);
    }
    $(`.${slug}-expansion`).toggleClass('open', pointing);
  };

  useEffect(() => {
    if ($('.basket-expansion.expansion').length > 0) {
      // eslint-disable-next-line no-new
      new DropdownOpener(
        '.desktop-header__basket-container',
        '.basket-expansion .expansion-content',
        (pointing) => {
          setTab('basket', pointing);
          if (pointing) {
            window.metrics.track('Viewed Hover Basket');
          }
          return initBasketHoverMetrics();
        },
      );
    }

    const opener = new DropdownOpener(
      '.account-menu-link',
      '.account-menu .flyout-background',
      (pointing) => {
        return $('.account-menu.flyout-container').toggleClass('active', pointing);
      },
    );

    $('.account-menu-link').on('click', (e) => {
      opener.onClick(e);
      return false;
    });

    // eslint-disable-next-line no-new
    new DropdownOpener('.js-user-account-menu-button', '.js-user-account-menu-content');

    // eslint-disable-next-line no-new
    new DropdownOpener('.js-shop-for-day-menu-button', '.js-shop-for-day-menu-content', function (
      pointing,
    ) {
      return $('.js-shop-for-day-menu-content').toggle(pointing);
    });

    $('.expansion').on('mouseenter', () => {
      return null;
    });

    $(window).on(
      'scroll',
      throttle(() => {
        setMiniNav();
      }, 100),
    );
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-new
    new DropdownOpener('.js-user-account-menu-button', '.js-user-account-menu-content');
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line no-new
    new DropdownOpener('.js-for-you-menu-button', '.js-for-you-menu-content');
  }, []);

  const setMiniNav = (): void => {
    const isMini = window.pageYOffset >= MINI_BREAKPOINT;

    if (mini.current === isMini) return;

    mini.current = isMini;
    $('body').toggleClass('mini-nav', isMini);
  };

  const handleLogin = (e: SyntheticEvent): void => {
    e.preventDefault();
    SigninHelpers.signinAndReturn();
  };

  const handleLogout = (e: SyntheticEvent): void => {
    removeDismissedSurveyPopin();

    if (!user?.masquerading) {
      return;
    }

    e.preventDefault();

    SigninHelpers.signoutMasquerade();
  };

  const shouldRenderBasketDropdown = !disableBasketDropdown;
  const enableNewLogos = clientSettings.enableNewLogos.enableWeb;

  const desktopHeader = (
    <div className="desktop-header desktop-nav top-nav-large js-desktop-header-container">
      <div id="fb-root" />
      <div
        className={classNames('desktop-header__top-row', {
          masquerading: user?.masquerading,
        })}
      >
        <div className="desktop-header__row-content page-boundary">
          <a className="brand remote" href="/home">
            {enableNewLogos ? (
              <img src={`${assetPath('/img/svg/icons/ge-header-logo-white.svg')}?auto=format`} />
            ) : (
              <div className="desktop-header__logo js-header-logo">Good Eggs</div>
            )}
          </a>
          <ul className="js-how-it-works-links">
            <li className="desktop-header__help-center">
              {/*
                  NOTE: This is an external website and there only exists one instance of it, which is
                  why "goodeggs.com" is hardcoded here. Do not copy this for other links.
                */}
              <a className="desktop-header__link" href="https://help.goodeggs.com">
                Help Center
              </a>
            </li>
            <li>
              <a
                className="desktop-header__refer-a-friend desktop-header__link"
                href="/refer-a-friend"
              >
                Invite Friends
              </a>
            </li>
            <li>
              <a className="desktop-header__gift-card desktop-header__link" href="/giftcards">
                Buy a Gift Card
              </a>
            </li>
            <li>
              <a
                className="desktop-header__membership desktop-header__link"
                href="/join-membership"
              >
                Join Membership
              </a>
            </li>
          </ul>
          <ul className="js-user-controls">
            <DayChooser
              fulfillmentDaySummaries={fulfillmentDaySummaries}
              fulfillmentDaysByDay={fulfillmentDaysByDay}
              upcomingPreorderPeriods={upcomingPreorderPeriods}
              currentFulfillmentDay={currentFulfillmentDay}
              tzid={foodhub?.tzid}
              handleChangeDay={handleChangeDay}
              onClickPreorderPeriod={openDayChooserModal}
              features={features}
              zipCode={zipCode ?? inferredZipCode}
            />
            {clientSettings.discoverability.enableCuratedForYouPage ? (
              <ForYouControls />
            ) : (
              <li className="desktop-header__row-content-column">
                <a className="desktop-header__favorite" href="/favorites">
                  <svg
                    className="desktop-header__favorite-heart icon icon-heart"
                    viewBox="8.9 7.8 18 16.633654"
                    data-testid="icon-favorite"
                  >
                    <path d="m 17.89989,23.433653 c 0.631042,-0.630881 4.233291,-4.23521 6.485296,-6.486896 2.017605,-2.017765 1.974565,-4.958732 0.140161,-6.783217 -1.834405,-1.8249642 -4.800652,-1.8169641 -6.625457,0.0176 -1.824645,-1.8345641 -4.790892,-1.8425642 -6.625297,-0.0176 -1.8344041,1.824485 -1.8772842,4.765452 0.140161,6.783217 2.251845,2.251686 5.854574,5.856015 6.485136,6.486896 l 0,0 z" />
                  </svg>
                  Favorites
                </a>
              </li>
            )}
            <AccountControls
              upcomingOrdersCount={upcomingOrders.length}
              onLogin={handleLogin}
              onLogout={handleLogout}
            />
          </ul>
        </div>
      </div>
      <div className="desktop-header__bottom-row">
        <div className="page-boundary">
          <div className="desktop-header__row-content">
            <div className="desktop-header__categories-container">
              <DesktopCategories categories={categories} selectedNavId={selectedNavId} />
            </div>
            <div className="desktop-header__basket-container">
              <a
                className="js-header__basket desktop-header__basket basket-button basket remote"
                href="/basket"
              >
                <i className="basket-button__icon icon icon-basket-2">
                  <div
                    className={classNames(
                      'basket-button__item-count',
                      'icon__inner-content',
                      'js-header-basket-item-count',
                      {
                        'triple-digits': basket.items.length > 99,
                      },
                    )}
                  >
                    {basket.items.length}
                  </div>
                </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const stickyDesktopHeader = (
    <div className="sticky-desktop-header top-nav-large">
      <div className="sticky-desktop-header__content page-boundary">
        <a className="remote" href="/home">
          {enableNewLogos ? (
            <div className="sticky-desktop-header__logo">
              <img src={`${assetPath('/img/svg/icons/ge-mobile-log-white.svg')}?auto=format`} />
            </div>
          ) : (
            <div className="sticky-desktop-header__logo">
              <img src={`${assetPath('/img/svg/icons/logo_black.svg')}?auto=format`} />
            </div>
          )}
        </a>
        <div className="sticky-desktop-header__nav">
          <div className="sticky-desktop-header__categories-container">
            <StickyDesktopCategories categories={categories} selectedNavId={selectedNavId} />
          </div>
          <div className="desktop-header__basket-container sticky-desktop-header__basket_container">
            <a
              className="js-header__basket sticky-desktop-header__basket basket-button basket remote"
              href="/basket"
            >
              <i className="basket-button__icon icon icon-basket-2">
                <div
                  className={classNames(
                    'basket-button__item-count',
                    'icon__inner-content',
                    'js-header-basket-item-count',
                    {
                      'triple-digits': basket.items.length > 99,
                    },
                  )}
                >
                  {basket.items.length}
                </div>
              </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classNames('desktop-nav')}>
      <div id="nav">
        {desktopHeader}
        {stickyDesktopHeader}
        {shouldRenderBasketDropdown && <BasketDropdown />}
      </div>
    </div>
  );
};

export default DesktopHeader;
