import React, {FC} from 'react';

interface EmailProvidedProps {
  zip: string;
}

const EmailProvided: FC<EmailProvidedProps> = ({zip}) => {
  return (
    <div className="sign-in-flow__zip-code-collection ">
      <h1 className="pdp-sign-in-flow-refresh__title pdp-center-flow__title">Thank you!</h1>
      <div className="pdp-sign-in-flow-zip-entry-refresh__subtitle">
        We will notify you when Good Eggs delivers to {zip}.
      </div>
    </div>
  );
};

export default EmailProvided;
