import {template} from 'lodash';

import strings from './strings';

export function getText(key, context = {}) {
  const parts = key.split('.');
  let match = strings;
  parts.forEach(function (part) {
    if (match[part]) {
      match = match[part];
    } else {
      throw new Error(`unable to find template string path ${part} of ${key}`);
    }
  });
  try {
    return template(match)(context);
  } catch {
    throw new Error(`Error templatizing string ${match} of ${key}`);
  }
}

export function getCustomerErrorMessage(domainError) {
  let customerMessage = getText(`error.${domainError.type}`, domainError.details);
  if (customerMessage === '') {
    customerMessage = 'Oops - something went wrong.';
  }
  return customerMessage;
}
