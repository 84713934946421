import React, {FC, PropsWithChildren, useMemo} from 'react';

export interface ProductTagProps {
  promoPrice?: number;
  basePrice?: number;
  size?: 'md' | 'lg';
}

const ProductTag: FC<PropsWithChildren<ProductTagProps>> = ({
  promoPrice,
  basePrice,
  size = 'md',
}) => {
  const discountAmount = useMemo(() => {
    if (promoPrice != null && basePrice != null && promoPrice > 0 && basePrice > 0) {
      return Math.round(100 - (promoPrice / basePrice) * 100);
    }

    return 0;
  }, [promoPrice, basePrice]);

  return (
    <div data-testid="product-tag" className={`tag-container`}>
      <span
        data-testid="discount-amount"
        className={`tag-container__discount-amount tag-container__discount-amount--${size}`}
      >
        {discountAmount}% OFF
      </span>
    </div>
  );
};

export default ProductTag;
