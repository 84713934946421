import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';

import assetPath from 'web/helpers/asset_path';

import {actions} from './duck';

export class GoogleLoginButton extends Component {
  constructor(props) {
    super(props);

    this._handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    if (!this.props.isConnected && !this.props.isConnecting) {
      this.props.initialize();
    }
  }

  handleLogin() {
    if (this.props.isConnected && !this.props.isAuthenticating) {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    }
  }

  render() {
    return (
      <button
        type="button"
        className={classNames('button google-login-button', this.props.className, {
          'is-working': this.props.isConnecting || this.props.isAuthenticating,
          'is-not-ready': !this.props.isConnected,
          disabled: !this.props.enabled,
        })}
        onClick={this.props.enabled ? this._handleLogin : () => null}
      >
        {this.props.icon || (
          <img
            alt="Google Logo"
            src={`${assetPath('/img/jujube/icons/sso-google.svg')}?auto=format`}
          />
        )}
        <span>Continue with Google</span>
      </button>
    );
  }
}

GoogleLoginButton.defaultProps = {
  className: '',
};

GoogleLoginButton.propTypes = {
  onSubmit: PropTypes.func,
  onConnect: PropTypes.func,
  onFailure: PropTypes.func,
  initialize: PropTypes.func,
  login: PropTypes.func,
  isConnected: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
  enabled: PropTypes.bool,
  className: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    initialize: () => dispatch(actions.initialize()),
    login: () => dispatch(actions.login()),
  };
}

function mapStateToProps(state) {
  return {
    isConnected: state.socialSignIn.googleLogin.isConnected,
    isConnecting: state.socialSignIn.googleLogin.isConnecting,
    isAuthenticating: state.socialSignIn.googleLogin.isAuthenticating,
    enabled: state.socialSignIn.googleLogin.enabled,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton);
