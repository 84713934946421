import classNames from 'classnames';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'web/components/checkbox';

// eslint-disable-next-line react/no-unsafe
export default class ToggleInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {checked: props.checked, justChanged: false};
  }

  onChange() {
    this.setState({checked: !this.props.checked});
    this.props.onToggle();
  }

  // FIXME: (guilherme-vp) Refactor this component to use getDerivedStateFromProps and componentDidUpdate
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.checked !== this.props.checked) {
      this.setState({justChanged: true, checked: newProps.checked});
      setTimeout(this.setState.bind(this, {justChanged: false}), 500);
    } else {
      this.setState({checked: newProps.checked});
    }
  }

  render() {
    return (
      <div className={classNames('account-toggle-input', this.props.className)}>
        <Checkbox
          className="account-toggle-input__checkbox"
          active={this.state.checked}
          onChange={this.onChange}
        />

        <label className="account-toggle-input__title" onClick={this.onChange}>
          {this.props.title}
        </label>
        <i
          className={classNames('icon', 'account-toggle-input__changed-icon', {
            'account-toggle-input__post-changed': !this.state.justChanged,
            'account-toggle-input__just-changed': this.state.justChanged,
            'icon-circle-checkmark': !this.props.dismissIcon,
          })}
        />
        <div className="account-toggle-input__body">{this.props.children}</div>
      </div>
    );
  }
}

ToggleInput.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  dismissIcon: PropTypes.bool,
};
