// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import React from 'react';
import PropTypes from 'prop-types';

import Alert from 'web/components/alert';
import LoadingOverlay from 'web/components/loading_overlay';
import SocialSignIn from 'web/components/social_sign_in';

export default function FlowSocialSignInScreen(props) {
  return (
    <div className="flow-social-sign-in">
      <h1 className="sign-in-flow__title">Hooray! We deliver to {props.zipCode}.</h1>

      {props.error ? (
        <Alert
          className="flow-social-sign-in__errors"
          type="error"
          heading={props.error.customerMessage || props.error.message}
        />
      ) : null}
      <button
        onClick={props.onContinueWithEmail}
        className="sign-in-flow__button flow-social-sign-in-screen__signup-button js-sign-up-with-email"
      >
        Continue with <strong>email</strong>
      </button>
      <SocialSignIn onLogin={props.onLogin} />
      <div className="sign-in-flow__disclaimer">
        By signing up, you agree to Good Eggs{"'"} <a href="/terms">Terms of Service</a> and{' '}
        <a href={settings.privacyLink}>Privacy Policy</a>.
      </div>
      {props.isWorking ? <LoadingOverlay /> : null}

      <span
        className="flow-social-sign-in__link js-try-another-zip"
        onClick={props.onTryAnotherZip}
      >
        Try Another Zip Code
      </span>
    </div>
  );
}

FlowSocialSignInScreen.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    customerMessage: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  zipCode: PropTypes.string,
  onLogin: PropTypes.func,
  onContinueWithEmail: PropTypes.func,
  onTryAnotherZip: PropTypes.func,
};
