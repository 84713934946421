/* eslint-disable @typescript-eslint/naming-convention -- we don't control the Google Maps API */

// Google maps sometimes comes back with Zero-width-space characters (\u200B) in
// its zip codes. ¯\_(ツ)_/¯
// Also occasionally a center dot
// This strips out all non digit characters
export function cleanZip(zip: string): string {
  if (zip != null && zip !== '') {
    return zip.toString().replace(/[^\d]/g, '');
  }
  return '';
}

type PlaceType =
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'colloquial_area'
  | 'country'
  | 'establishment'
  | 'finance'
  | 'floor'
  | 'food'
  | 'general_contractor'
  | 'geocode'
  | 'health'
  | 'intersection'
  | 'locality'
  | 'natural_feature'
  | 'neighborhood'
  | 'place_of_worship'
  | 'political'
  | 'point_of_interest'
  | 'post_box'
  | 'postal_code'
  | 'postal_code_prefix'
  | 'postal_code_suffix'
  | 'postal_town'
  | 'premise'
  | 'room'
  | 'route'
  | 'street_address'
  | 'street_number'
  | 'sublocality'
  | 'sublocality_level_4'
  | 'sublocality_level_5'
  | 'sublocality_level_3'
  | 'sublocality_level_2'
  | 'sublocality_level_1'
  | 'subpremise';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: PlaceType[];
}
interface Point {
  lat: number;
  lng: number;
}
interface Geometry {
  location: Point;
  viewport: {
    northeast: Point;
    southwest: Point;
  };
}
export interface GooglePlaceDetail {
  address_components: AddressComponent[];
  adr_address: string;
  formatted_address: string;
  geometry: Geometry;
  icon: string;
  id: string;
  name: string;
  place_id: string;
  reference: string;
  scope: 'GOOGLE';
  types: PlaceType;
  url: string;
  utc_offset: number;
  vicinity: string;
}

export interface ParsedGooglePlace {
  streetNumber: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  address: string;
  lon?: number;
  lat?: number;
}
export function parseGooglePlace(place: Partial<GooglePlaceDetail>): ParsedGooglePlace {
  const componentsByType = (place.address_components ?? []).reduce((memo, component) => {
    component.types.forEach((type) => {
      if (memo[type] == null) {
        memo[type] = [];
      }
      return memo[type].push({
        shortName: component.short_name,
        longName: component.long_name,
      });
    });
    return memo;
  }, {} as Record<string, Array<Record<string, string>>>);

  const streetNumber = componentsByType?.street_number?.[0]?.longName;
  const street = componentsByType?.route?.[0]?.longName;
  const cityComponent =
    componentsByType?.locality?.[0] ??
    componentsByType?.neighborhood?.[0] ??
    componentsByType?.sublocality?.[0];
  const city = cityComponent?.longName;
  const state = componentsByType?.administrative_area_level_1?.[0]?.shortName;
  const zip = cleanZip(componentsByType?.postal_code?.[0]?.longName);
  const lon = place?.geometry?.location?.lng;
  const lat = place?.geometry?.location?.lat;

  let address = '';
  if (streetNumber != null && streetNumber !== '' && street != null && street !== '') {
    address = `${streetNumber} ${street}`;
  }

  return {streetNumber, street, city, state, zip, address, lon, lat};
}
