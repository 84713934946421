import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import GoogleLoginButton from 'web/components/google_login_button';
import {actions as googleActions} from 'web/components/google_login_button/duck';
import {actions as appleActions} from 'web/components/apple_login_button/duck';
import AppleLoginButton from 'web/components/apple_login_button';
import Separator from 'web/components/separator';

const SocialSignIn = (props) => {
  return (
    <div className="social-sign-content">
      <Separator>{props.content || 'or'}</Separator>
      <div className="social-sign-in__buttons">
        <GoogleLoginButton
          {...props.googleProps}
          onSubmit={() => props.loginWithGoogle(props.onLogin, props.loginOpts)}
        />
        <AppleLoginButton
          {...props.appleProps}
          onSubmit={(opts) => props.loginWithApple(props.onLogin, {...props.loginOpts, ...opts})}
        />
      </div>
    </div>
  );
};

SocialSignIn.defaultProps = {
  content: null,
  googleProps: {},
  appleProps: {},
};

SocialSignIn.propTypes = {
  content: PropTypes.string,
  loginWithGoogle: PropTypes.func.isRequired,
  loginWithApple: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  loginOpts: PropTypes.shape({
    zip: PropTypes.string,
  }),
  googleProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  appleProps: PropTypes.shape({
    className: PropTypes.string,
  }),
};

function mapDispatchToProps(dispatch) {
  return {
    loginWithGoogle: (onLogin, loginOpts) => dispatch(googleActions.login(onLogin, loginOpts)),
    loginWithApple: (onLogin, loginOpts) => dispatch(appleActions.login(onLogin, loginOpts)),
  };
}

function mapStateToProps(state) {
  return {
    loginOpts: {zip: state.zipCode},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialSignIn);
