/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface CommitSha {
	default?: string
}
export interface App {
	commitSHA?: CommitSha
	instance?: string
	name?: string
}
export interface Experiments {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen {
	height?: number
	width?: number
}
export interface Campaign {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession {
	campaign?: Campaign
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface AbandonedRecipeVideo {
	addToWeeklyMode?: any | null
	app?: App
	at?: any | null
	experiments?: Experiments
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	platform?: any | null
	recipe?: any | null
	screen?: Screen
	shoppingSession?: ShoppingSession
}
export interface AccountCreated {
	/**
	 * input value for email
	 */
	email?: string
	/**
	 * individual page/screen (home, basket, PDP) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * input value for first name
	 */
	firstName?: string
	/**
	 * input value for last name
	 */
	lastName?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * url path the where the interaction occurred
	 */
	pageUrl?: string
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFuilfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * what sign-in method did the user use (email, Facebook, Google, or Apple)
	 */
	signInMethod?: string
}
export interface AccountUpdated {
	/**
	 * input value for email
	 */
	email?: string
	/**
	 * input value for first name
	 */
	firstName?: string
	/**
	 * input value for last name
	 */
	lastName?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * input value for their full name
	 */
	name?: string
	/**
	 * boolean value for if they have news and inspirations on
	 */
	newsAndInspirations?: boolean
	/**
	 * boolean value for if they have a payment method saved
	 */
	paymentMethod?: boolean
	/**
	 * input value for their phone number
	 */
	phoneNumber?: string
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * boolean value for if they have shopping reminders on
	 */
	shoppingReminders?: boolean
	/**
	 * boolean value for if they have sms reminders on
	 */
	smsReminders?: boolean
}
export interface CommitSha1 {
	default?: string
}
export interface App1 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha1
	instance?: string
	name?: string
	version?: string
}
export interface Experiments1 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign1 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession1 {
	campaign?: Campaign1
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ActivatedSubscription {
	addToOrderMode?: any | null
	app?: App1
	experiments?: Experiments1
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession1
	subscriptionId?: any | null
}
export interface CommitSha2 {
	default?: string
}
export interface App2 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha2
	instance?: string
	name?: any | null
	version?: string
}
export interface Context {
	feature?: string
	resultCount?: number
	searchQuery?: string
	searchRequestId?: string
	subcategory?: string
}
export interface Experiments2 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign2 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession2 {
	campaign?: Campaign2
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface AddedProduct {
	addToOrderMode?: any | null
	app?: App2
	category?: any | null
	context?: Context
	employee?: any | null
	experiments?: Experiments2
	feature?: any | null
	foodshed?: any | null
	id?: any | null
	issue?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	name?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	price?: any | null
	product?: any | null
	productId?: any | null
	productScheduleId?: any | null
	quantity?: any | null
	quantityAfter?: any | null
	quantityBefore?: any | null
	resultCount?: any | null
	searchIndex?: any | null
	searchQuery?: any | null
	searchRequestId?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession2
	shouldSubscribe?: any | null
	sku?: any | null
	subcategory?: any | null
	tags?: any | null
	vendor?: any | null
}
export interface CommitSha3 {
	default?: string
}
export interface App3 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha3
	instance?: string
	name?: string
	version?: string
}
export interface Experiments3 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign3 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession3 {
	campaign?: Campaign3
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface AddedProductToSubscription {
	addToOrderMode?: any | null
	app?: App3
	experiments?: Experiments3
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	quantityAfter?: any | null
	quantityBefore?: any | null
	shoppingSession?: ShoppingSession3
	subscriptionId?: any | null
	subscriptionItemId?: any | null
}
export interface App4 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign4 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession4 {
	campaign?: Campaign4
	crossDeviceId?: string
	id?: string
}
export interface AnsweredNotificationPrompt {
	addToOrderMode?: any | null
	app?: App4
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	selection?: any | null
	shoppingSession?: ShoppingSession4
}
export interface App5 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign5 {
	medium?: string
	name?: string
	source?: string
}
export interface ShoppingSession5 {
	campaign?: Campaign5
	id?: string
}
export interface ApplicationDidFinishLaunching {
	addToOrderMode?: any | null
	app?: App5
	duration?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession5
}
export interface App6 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface ShoppingSession6 {
	id?: string
}
export interface ApplicationInstalled {
	app?: App6
	build?: string
	foodshed?: string
	loggedIn?: boolean
	platform?: string
	shoppingSession?: ShoppingSession6
	version?: string
}
export interface App7 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign6 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession7 {
	campaign?: Campaign6
	crossDeviceId?: string
	id?: string
}
export interface ApplicationWillBecomeInteractive {
	addToOrderMode?: any | null
	app?: App7
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession7
}
export interface App8 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign7 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession8 {
	campaign?: Campaign7
	crossDeviceId?: string
	id?: string
}
export interface CancelledApplePayCheckout {
	addToOrderMode?: any | null
	app?: App8
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession8
}
export interface App9 {
	commitSHA?: string
	instance?: string
	name?: string
}
export interface Experiments4 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen1 {
	height?: number
	width?: number
}
export interface Campaign8 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession9 {
	campaign?: Campaign8
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface CancelledOrder {
	app?: App9
	experiments?: Experiments4
	foodshed?: string
	loggedIn?: boolean
	masquerading?: boolean
	orderId?: string
	pageName?: string
	platform?: string
	screen?: Screen1
	shoppingSession?: ShoppingSession9
	userId?: string
}
export interface CommitSha4 {
	default?: string
}
export interface App10 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha4
	instance?: string
	name?: string
	version?: string
}
export interface Experiments5 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign9 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession10 {
	campaign?: Campaign9
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface CancelledSubscription {
	addToOrderMode?: any | null
	app?: App10
	experiments?: Experiments5
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	processingSubscription?: any | null
	shoppingSession?: ShoppingSession10
	subscriptionId?: any | null
}
export interface CommitSha5 {
	default?: string
}
export interface App11 {
	commitSHA?: CommitSha5
	instance?: string
	name?: string
}
export interface Experiments6 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen2 {
	height?: number
	width?: number
}
export interface Campaign10 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession11 {
	campaign?: Campaign10
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface CapturedServiceAreaEmail {
	addToWeeklyMode?: any | null
	app?: App11
	category?: any | null
	context?: any | null
	email?: any | null
	experiments?: Experiments6
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	screen?: Screen2
	shoppingSession?: ShoppingSession11
	tags?: any | null
	vendor?: any | null
	zip?: any | null
}
export interface ProductsItem {
	/**
	 * product category of the product being viewed
	 */
	category?: string | null
	/**
	 * coupon applied to the product being viewed
	 */
	coupon?: string
	/**
	 * unique databaseId of the product being viewed
	 */
	id?: string
	/**
	 * imageURL of the product being viwed
	 */
	imageUrl?: string
	/**
	 * name of the product being viwed
	 */
	name?: string
	/**
	 * price of the product being viewed
	 */
	price?: number
	/**
	 * producer of the product being viewed
	 */
	producer?: string
	/**
	 * quantity of the product being viewed
	 */
	quantity?: number
	/**
	 * product subcategory of the product being viewed
	 */
	subCategory?: string | null
	/**
	 * url of the product being viewed
	 */
	url?: string
	/**
	 * total value of the product after accounting for quantity
	 */
	value?: number
}
export interface CartViewed {
	/**
	 * productIds for the products available
	 */
	availableProducts?: string
	/**
	 * basketId of the cart being viewed
	 */
	basketId?: string
	/**
	 * Cart cutoff date
	 */
	cartCutOff?: string
	/**
	 * value of credits applied to cart total
	 */
	credits?: number
	/**
	 * value of delivery fee in cart
	 */
	deliveryFee?: number
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * value of applied discount at cart
	 */
	discount?: number
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * value of good jobs fee applied to cart
	 */
	goodJobsFees?: number
	/**
	 * Boolean value for if the user is loggedIn or not
	 */
	loggedIn?: boolean
	/**
	 * Boolean value for if the use is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * product details array
	 */
	products?: ProductsItem[]
	/**
	 * Total of all products without taxes, fees, or discounts applied
	 */
	subtotal?: number
	/**
	 * Total of fees in cart
	 */
	totalFees?: number
	/**
	 * productIds for the unavailable products in cart
	 */
	unavailableProducts?: string
	/**
	 * boolean for if cart value is under the $30 threshold
	 */
	underMinimum?: boolean
	userId?: string
}
export interface App12 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign11 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession12 {
	campaign?: Campaign11
	crossDeviceId?: string
	id?: string
}
export interface ChangedDay {
	addToOrderMode?: any | null
	app?: App12
	foodshed?: any | null
	index?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession12
	source?: any | null
}
export interface App13 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign12 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession13 {
	campaign?: Campaign12
	crossDeviceId?: string
	id?: string
}
export interface ChangedNotificationSetting {
	addToOrderMode?: any | null
	app?: App13
	enabled?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession13
	source?: any | null
}
export interface App14 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign13 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession14 {
	campaign?: Campaign13
	crossDeviceId?: string
	id?: string
}
export interface ChangedPaymentMethod {
	addToOrderMode?: any | null
	app?: App14
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	selectedPaymentMethod?: any | null
	shoppingSession?: ShoppingSession14
}
export interface CommitSha6 {
	default?: string
}
export interface App15 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha6
	instance?: string
	name?: string
	version?: string
}
export interface Experiments7 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign14 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession15 {
	campaign?: Campaign14
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ChangedProduct {
	addToOrderMode?: any | null
	app?: App15
	category?: any | null
	context?: any | null
	experiments?: Experiments7
	feature?: any | null
	foodshed?: any | null
	id?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	name?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	price?: any | null
	productId?: any | null
	productScheduleId?: any | null
	quantity?: any | null
	quantityAfter?: any | null
	quantityBefore?: any | null
	resultCount?: any | null
	searchIndex?: any | null
	searchQuery?: any | null
	searchRequestId?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession15
	shouldSubscribe?: any | null
	sku?: any | null
	subcategory?: any | null
	vendor?: any | null
}
export interface CommitSha7 {
	default?: string
}
export interface App16 {
	commitSHA?: CommitSha7
	instance?: string
	name?: string
}
export interface Products {
	available?: any | null
	totalPrice?: any | null
	unavailable?: any | null
}
export interface Basket {
	products?: Products
}
export interface Experiments8 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Product {
	id?: any | null
	price?: any | null
}
export interface Screen3 {
	height?: number
	width?: number
}
export interface Campaign15 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession16 {
	campaign?: Campaign15
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface ChangedShoppingDay {
	addToOrderMode?: any | null
	addToWeeklyMode?: any | null
	addonValue?: any | null
	app?: App16
	basket?: Basket
	category?: any | null
	checkoutType?: any | null
	context?: any | null
	contextAttribution?: any | null
	createNewWeeklie?: any | null
	day?: any | null
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments8
	foodshed?: any | null
	fulfillment_days_in_basket?: any | null
	has_add_to_order_button?: any | null
	'inspire-slug'?: any | null
	isEmpty?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	pageName?: any | null
	pageTags?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	product?: Product
	productId?: any | null
	screen?: Screen3
	shoppingSession?: ShoppingSession16
	site?: any | null
	status?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface CommitSha8 {
	default?: string
}
export interface App17 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha8
	instance?: string
	name?: string
	version?: string
}
export interface Experiments9 {
	'no-op'?: string
}
export interface Screen4 {
	height?: number
	width?: number
}
export interface Campaign16 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession17 {
	campaign?: Campaign16
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface CheckedServiceArea {
	addToOrderMode?: any | null
	addToWeeklyMode?: any | null
	app?: App17
	category?: any | null
	context?: any | null
	experiments?: Experiments9
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	screen?: Screen4
	shoppingSession?: ShoppingSession17
	success?: any | null
	tags?: any | null
	vendor?: any | null
	zip?: any | null
	zipCode?: any | null
	zip_code?: any | null
}
export interface App18 {
	commitSHA?: string
	instance?: string
	name?: string
}
export interface DeliveryDetails {
	address?: string
	address2?: string
	canLeaveAtDoor?: boolean
	city?: string
	deliveryInstructions?: string
	giftMessage?: string
	giftRecipientName?: string
	giftRecipientPhone?: string
	isGift?: boolean
	lat?: number
	lon?: number
	state?: string
	zip?: string
	zipCode?: string
}
export interface DevlieryDetailsItem {
	/**
	 * street address of the order
	 */
	address?: string
	/**
	 * street number of the order
	 */
	address2?: string
	/**
	 * boolean for if delivery can be left at the door
	 */
	canLeaveAtDoor?: boolean
	/**
	 * city of the delivery address
	 */
	city?: string
	/**
	 * any instructions left for the delivery
	 */
	deliveryInstructions?: string
	/**
	 * boolean for if the delivery is a gift
	 */
	isGift?: boolean
	/**
	 * lattitude of the delivery address
	 */
	lat?: string
	/**
	 * longitude of the delivery
	 */
	lon?: string
	/**
	 * state of the delivery
	 */
	state?: string
	/**
	 * zip code of the delivery address
	 */
	zip?: string
}
export interface Experiments10 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface ProductsItem1 {
	/**
	 * product category of the product in the order
	 */
	category?: string | null
	/**
	 * product level coupon of the item being purchased
	 */
	coupon?: string
	/**
	 * hat engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string | null
	/**
	 * database id of the product in the order
	 */
	id?: string
	/**
	 * url of the image of the product in the order
	 */
	imageUrl?: string
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string | null
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string | null
	/**
	 * name of the product in the order
	 */
	name?: string
	/**
	 * price ($) of the product in the order
	 */
	price?: number
	/**
	 * producer of the product in the order
	 */
	producer?: string
	/**
	 * quantity of the product being purchased
	 */
	quantity?: number
	/**
	 * sku of the product in the order
	 */
	sku?: string
	/**
	 * subcategory of the product in the order
	 */
	subCategory?: string | null
	/**
	 * url of the product in the order
	 */
	url?: string
	/**
	 * total value of the product after accounting for quantity
	 */
	value?: number
}
export interface PromoCodesItem {
	code?: string
	label?: string
	type?: string
	value?: number
}
export interface Screen5 {
	height?: number
	width?: number
}
export interface CheckoutCompleted {
	addingToOrder?: boolean
	addonValue?: number
	app?: App18
	/**
	 * boolean for if the order contains a subscription product
	 */
	containsSubscription?: boolean
	/**
	 * order coupon redeemed with the transaction
	 */
	coupon?: string
	/**
	 * amount of discount from coupon
	 */
	couponDiscount?: number
	/**
	 * total credits applied to the order
	 */
	credits?: number
	delivery?: number
	/**
	 * selected date of the delivery
	 */
	deliveryDate?: string
	deliveryDay?: string
	deliveryDetails?: DeliveryDetails
	/**
	 * date and time for the end of the delivery window
	 */
	deliveryEndWindow?: string
	/**
	 * value of the delivery fee applied to the order
	 */
	deliveryFee?: number
	/**
	 * selected method of the delivery
	 */
	deliveryMethod?: string
	deliveryPrice?: number
	/**
	 * date and time for the start of the delivery window
	 */
	deliveryStartWindow?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * delivery details array
	 */
	devlieryDetails?: DevlieryDetailsItem[]
	discount?: number
	experiments?: Experiments10
	fees?: number
	foodShed?: string
	foodshed?: string
	/**
	 * total value of the good jobs fee
	 */
	goodJobsFees?: number
	hashedEmail?: string
	/**
	 * true if the order was placed by an active member
	 */
	isMembershipOrder?: boolean
	/**
	 * boolean value for if the order contains a thanksgiving preorder
	 */
	isPreorder?: boolean
	/**
	 * Count of individual items in the order
	 */
	itemCount?: number
	/**
	 * boolean for if the user was loggedin
	 */
	loggedIn?: boolean
	/**
	 * boolean for if the user is an employee
	 */
	masquerading?: boolean
	orderCount?: number
	/**
	 * Date and time of cut off for editing order
	 */
	orderCutOffDate?: string
	orderCutoff?: string
	/**
	 * unique order ID
	 */
	orderId?: string
	/**
	 * revenue ($) associated with the order
	 */
	orderTotal?: number
	pageName?: string
	platform?: string
	processingSubscription?: boolean
	/**
	 * number of individual SKUs in order
	 */
	productCount?: number
	/**
	 * product details array
	 */
	products?: ProductsItem1[]
	promoCodes?: PromoCodesItem[]
	/**
	 * amount of discount from the referral
	 */
	referralDiscount?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	screen?: Screen5
	/**
	 * amount of discount from the subscription
	 */
	subscriptionDiscount?: number
	/**
	 * total of all products value without taxes, fees, and discounts applied
	 */
	subtotal?: number
	subtotalAfter?: number
	subtotalBefore?: number
	/**
	 * total taxes applied to the order
	 */
	tax?: number
	/**
	 * type of payment chosen for order
	 */
	tenderType?: string
	total?: number
	totalCheckoutValue?: number
	/**
	 * total discount applied to the order
	 */
	totalDiscount?: number
	/**
	 * total fees applied to the order: tax, delivery, and good jobs fees
	 */
	totalFees?: number
	userId?: string
}
export interface ProductsItem2 {
	/**
	 * product category of the product in the order
	 */
	category?: string | null
	/**
	 * Product level coupon being applied to the order
	 */
	coupon?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string | null
	/**
	 * unique database Id of the product in the order
	 */
	id?: string
	/**
	 * image url of the product in the order
	 */
	imageUrl?: string
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string | null
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string | null
	/**
	 * name of the product in the order
	 */
	name?: string
	/**
	 * price ($) of the product in the order
	 */
	price?: number
	/**
	 * producer of the product in the order
	 */
	producer?: string
	/**
	 * quantity of the product in the order
	 */
	quantity?: number
	/**
	 * populate with null (market section being viewed)
	 */
	section?: string | null
	/**
	 * sku of the product in the order
	 */
	sku?: string
	/**
	 * sub productCategory of the product in the order
	 */
	subCategory?: string | null
	/**
	 * url of the product in the order
	 */
	url?: string
	/**
	 * total value of the product after accounting for quantity
	 */
	value?: number
}
export interface CheckoutStarted {
	/**
	 * basketId for the checkout process
	 */
	basketId?: string
	/**
	 * boolean for if a checkout contains a subscription product
	 */
	containsSubscription?: boolean
	/**
	 * name of the transaction level coupon applied to the order
	 */
	coupon?: string
	/**
	 * amount of discount from coupon
	 */
	couponDiscount?: number
	/**
	 * total credits applied to this basket
	 */
	credits?: number
	/**
	 * delivery date selected for this checkout
	 */
	deliveryDate?: string
	/**
	 * delivery fee applied to checkout
	 */
	deliveryFee?: number
	/**
	 * selected delivery method for checkout
	 */
	deliveryMethod?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * he individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * true if the order was placed by an active member
	 */
	isMembershipOrder?: boolean
	/**
	 * amount of individual items in the order
	 */
	itemCount?: number
	/**
	 * Boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * orderID tied to the checkout
	 */
	orderId?: string
	/**
	 * amount of individual products in the order
	 */
	productCount?: number
	/**
	 * product details array
	 */
	products?: ProductsItem2[]
	/**
	 * amount of discount from referral
	 */
	referralDiscount?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * amount of discount from the subscription
	 */
	subscriptionDiscount?: number
	/**
	 * total value of all products without taxes, fees, or discounts applied
	 */
	subtotal?: number
	/**
	 * total tax applied to the order
	 */
	tax?: number
	/**
	 * total discount applied to the order
	 */
	totalDiscount?: number
	/**
	 * total value for good job fee, delivery, and tax added together
	 */
	totalFees?: number
}
export interface CheckoutStepCompleted {
	/**
	 * id of cart that has the product being added to it
	 */
	basketId?: string
	/**
	 * delivery window selected in the checkout process
	 */
	deliveryWindow?: string
	/**
	 * boolean value for if the checkout involves a gift
	 */
	isGift?: boolean
	/**
	 * true if the order was placed by an active member
	 */
	isMembershipOrder?: boolean
	/**
	 * boolean value for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * payment method selected upon completed the payment stage
	 */
	paymentMethod?: string
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * Step number tied to the step completed
	 */
	step?: string
	/**
	 * name of the step completed
	 */
	stepName?: string
}
export interface App19 {
	commitSHA?: string
	instance?: string
	name?: string
}
export interface DeliveryDetailsItem {
	/**
	 * street address of the delivery
	 */
	address?: string
	/**
	 * apartment number of the delivery address
	 */
	address2?: string
	/**
	 * boolean value for if the delivery can be left at the door
	 */
	canLeaveAtDoor?: boolean
	/**
	 * city of the delivery
	 */
	city?: string
	/**
	 * any specific instructions left for the delivery
	 */
	deliveryInstructions?: string
	/**
	 * boolean value for if the delivery is a gift
	 */
	isGift?: boolean
	/**
	 * lattitude of the delivery
	 */
	lat?: string
	/**
	 * longitude of the delivery
	 */
	lon?: string
	/**
	 * state of the delivery
	 */
	state?: string
	/**
	 * zip code of the delivery
	 */
	zip?: string
}
export interface Experiments11 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface ProductsItem3 {
	/**
	 * category of the product in the order
	 */
	category?: string | null
	/**
	 * product level coupon associated with the order
	 */
	coupon?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string | null
	/**
	 * database id of the product
	 */
	id?: string
	/**
	 * url of the image of the product
	 */
	imageUrl?: string
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string | null
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string | null
	/**
	 * name of the product in the order
	 */
	name?: string
	/**
	 * price($) of the product in the order
	 */
	price?: number
	/**
	 * producer of the product in the order
	 */
	producer?: string
	/**
	 * Quantity of the product in the order
	 */
	quantity?: number
	/**
	 * sku of the product in the order
	 */
	sku?: string
	/**
	 * subcategory of the product in the order
	 */
	subCategory?: string | null
	/**
	 * url of the product
	 */
	url?: string
	/**
	 * total value associated to the product after accounting for quantity
	 */
	value?: number
}
export interface PromoCodesItem1 {
	code?: string
	label?: string
	type?: string
	value?: number
}
export interface Screen6 {
	height?: number
	width?: number
}
export interface CheckoutUpdated {
	addingToOrder?: boolean
	addonValue?: number
	app?: App19
	/**
	 * boolean value for if the order contains a subscription product
	 */
	containsSubscription?: boolean
	/**
	 * order level promo code
	 */
	coupon?: string
	/**
	 * amount of discount from the order level coupon
	 */
	couponDiscount?: number
	/**
	 * total value of credits tied to the order
	 */
	credits?: number
	daysBeforeFulfillment?: number
	delivery?: number
	/**
	 * scheduled date of the delivery
	 */
	deliveryDate?: string
	deliveryDay?: string
	/**
	 * delivery details array
	 */
	deliveryDetails?: DeliveryDetailsItem[]
	/**
	 * Date and time for end of the delivery window
	 */
	deliveryEndWindow?: string
	/**
	 * delivery fee applied to the order
	 */
	deliveryFee?: number
	/**
	 * chosen delivery method for the delivery
	 */
	deliveryMethod?: string
	deliveryPrice?: number
	/**
	 * Date and time for the start of the delivery window
	 */
	deliveryStartWindow?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	discount?: number
	experiments?: Experiments11
	fees?: number
	foodShed?: string
	foodshed?: string
	/**
	 * total value of the good jobs fee
	 */
	goodJobsFee?: number
	hashedEmail?: string
	/**
	 * true if the order was placed by an active member
	 */
	isMembershipOrder?: boolean
	/**
	 * count of individual items in the order
	 */
	itemCount?: number
	/**
	 * boolean value for if the user is logged in
	 */
	loggedIn?: boolean
	/**
	 * boolean for if the user is an employee
	 */
	masquerading?: boolean
	orderCount?: number
	/**
	 * Date and time of cut off for editing the order
	 */
	orderCutOffDate?: string
	orderCutoff?: string
	/**
	 * unique identifier of the order
	 */
	orderId?: string
	pageName?: string
	platform?: string
	processingSubscription?: boolean
	/**
	 * count of individual products in the order
	 */
	productCount?: number
	/**
	 * product details array
	 */
	products?: ProductsItem3[]
	promoCodes?: PromoCodesItem1[]
	/**
	 * quantity of products in the order after updating the order
	 */
	quantityAfter?: number
	/**
	 * quantity of products purchased before updating order
	 */
	quantityBefore?: number
	/**
	 * amount of discount from referral
	 */
	referralDiscount?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	screen?: Screen6
	/**
	 * amount of discount from subscription
	 */
	subscriptionDiscount?: number
	/**
	 * total of all products without taxes, fees, and discounts applied
	 */
	subtotal?: number
	/**
	 * subtotal after updating the cart
	 */
	subtotalAfter?: number
	/**
	 * subtotal before updating the cart
	 */
	subtotalBefore?: number
	/**
	 * total tax applied to the order
	 */
	tax?: number
	total?: number
	totalCheckoutValue?: number
	/**
	 * total discount applied to the order
	 */
	totalDiscount?: number
	/**
	 * tax,delivery,and good jobs fees applied to the order
	 */
	totalFees?: number
	userId?: string
}
export interface App20 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign17 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession18 {
	campaign?: Campaign17
	crossDeviceId?: string
	id?: string
}
export interface ChoseDeliveryTime {
	addToOrderMode?: any | null
	app?: App20
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession18
}
export interface CommitSha9 {
	default?: string
}
export interface App21 {
	commitSHA?: CommitSha9
	instance?: string
	name?: string
}
export interface Experiments12 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Feature {
	id?: string
	name?: string
	type?: string
}
export interface Screen7 {
	height?: number
	width?: number
}
export interface Campaign18 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession19 {
	campaign?: Campaign18
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface User {
	orderCount?: number
}
export interface ClickedHomepageFeature {
	addToWeeklyMode?: any | null
	app?: App21
	experiments?: Experiments12
	feature?: Feature
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	platform?: any | null
	screen?: Screen7
	shoppingSession?: ShoppingSession19
	user?: User
}
export interface App22 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign19 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession20 {
	campaign?: Campaign19
	crossDeviceId?: string
	id?: string
}
export interface CompletedApplePayCheckout {
	addToOrderMode?: any | null
	app?: App22
	error?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession20
	status?: any | null
}
export interface CommitSha10 {
	default?: string
}
export interface App23 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha10
	instance?: string
	name?: string
	version?: string
}
export interface Experiments13 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign20 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession21 {
	campaign?: Campaign20
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface CreatedAccount {
	addToOrderMode?: any | null
	app?: App23
	category?: any | null
	experiments?: Experiments13
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	provider?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession21
}
export interface CommitSha11 {
	default?: string
}
export interface App24 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha11
	instance?: string
	name?: string
	version?: string
}
export interface Experiments14 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign21 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession22 {
	campaign?: Campaign21
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface CreatedSubscription {
	addToOrderMode?: any | null
	app?: App24
	experiments?: Experiments14
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession22
	subscriptionId?: any | null
}
export interface CtaClicked {
	/**
	 * Url destination of clicking the CTA
	 */
	destination?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean | null
	/**
	 * the consumer facing name of the module that the click action lived in (e.g. welcome to good eggs, Cream Co. Meats, Breakfast Made Easy)
	 */
	moduleName?: string
	/**
	 * the non-consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc...)
	 */
	moduleType?: string
	/**
	 * what url is the CTA button on
	 */
	pageUrl?: string
	/**
	 * the text/name of the CTA
	 */
	text?: string
	/**
	 * the type of CTA clicked (button, interstitial, banner)
	 */
	type?: string
}
export interface CtaDismissed {
	/**
	 * url destination of clicking the CTA
	 */
	destination?: string
	/**
	 * the individual page/screen (home, basket) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * the consumer facing name or title of the module that the click action lives in (e.g welcome to good eggs, new & peak season, etc)
	 */
	moduleName?: string
	/**
	 * the non consumer facing of the module or content type that the click action lives in (e.g. banner Cta, carousel item, featured content tile)
	 */
	moduleType?: string
	/**
	 * url path of where the action occurred
	 */
	pageUrl?: string
	/**
	 * the text/name of the CTA (i.e. cta text on button)
	 */
	text?: string
	/**
	 * the type of CTA element (button, banner, image, tile)
	 */
	type?: string
}
export interface DeliveryAreaChecked {
	/**
	 * boolean for if the zip code is eligible for delivery or not
	 */
	deliveryEligible?: boolean
	/**
	 * boolean for if user is performing initial check or not
	 */
	initialCheck?: boolean
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * Url of page where check is performed
	 */
	pageUrl?: string
	/**
	 * zip code entered by user
	 */
	zipCode?: string
}
export interface DeliveryAreaUpdated {
	/**
	 * boolean for if the zip code is eligible for delivery or not
	 */
	deliveryEligible?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * current region user is shopping in after update
	 */
	regionNameAfter?: string | null
	/**
	 * previous region user was shopping in before update
	 */
	regionNameBefore?: string | null
	/**
	 * new zip code user was shopping in
	 */
	zipCodeAfter?: string
	/**
	 * previous zip code user was shopping in
	 */
	zipCodeBefore?: string
}
export interface App25 {
	commitSHA?: string
	instance?: string
	name?: string
}
export interface Experiments15 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen8 {
	height?: number
	width?: number
}
export interface Campaign22 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession23 {
	campaign?: Campaign22
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface Cutoff {
	dateDelta?: number
	time?: string
}
export interface WindowPreviouslyChosen {
	cutoff?: Cutoff
	cutoffDateDelta?: number
	cutoffTime?: string
	dateDelta?: number
	dayOfWeek?: string
	hasPreorderWindows?: boolean
	hasUnavailableProducts?: boolean
	orderIds?: string[]
	status?: string
	window?: string
}
export interface Cutoff1 {
	dateDelta?: number
	time?: string
}
export interface WindowsViewedItem {
	cutoff?: Cutoff1
	cutoffDateDelta?: number
	cutoffTime?: string
	dateDelta?: number
	dayOfWeek?: string
	hasPreorderWindows?: boolean
	hasUnavailableProducts?: boolean
	orderIds?: string[]
	status?: string
	window?: string
}
export interface DeliveryWindowOptionsViewed {
	app?: App25
	experiments?: Experiments15
	foodshed?: string
	loggedIn?: boolean
	masquerading?: boolean
	pageName?: string
	platform?: string
	screen?: Screen8
	shoppingSession?: ShoppingSession23
	windowPreviouslyChosen?: WindowPreviouslyChosen
	windowsViewed?: WindowsViewedItem[]
}
export interface App26 {
	commitSHA?: string
	instance?: string
	name?: string
}
export interface Experiments16 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen9 {
	height?: number
	width?: number
}
export interface Campaign23 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession24 {
	campaign?: Campaign23
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface Cutoff2 {
	dateDelta?: number
	time?: string
}
export interface WindowChosen {
	cutoff?: Cutoff2
	cutoffDateDelta?: number
	cutoffTime?: string
	dateDelta?: number
	dayOfWeek?: string
	hasPreorderWindows?: boolean
	hasUnavailableProducts?: boolean
	orderIds?: string[]
	status?: string
	window?: string
}
export interface DeliveryWindowSelected {
	app?: App26
	experiments?: Experiments16
	foodshed?: string
	loggedIn?: boolean
	masquerading?: boolean
	pageName?: string
	platform?: string
	screen?: Screen9
	shoppingSession?: ShoppingSession24
	windowChosen?: WindowChosen
}
export interface CommitSha12 {
	default?: string
}
export interface App27 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha12
	instance?: string
	name?: string
	version?: string
}
export interface Experiments17 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign24 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession25 {
	campaign?: Campaign24
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface DisallowedFulfillmentOffer {
	action?: any | null
	addToOrderMode?: any | null
	app?: App27
	category?: any | null
	endAt?: any | null
	experiments?: Experiments17
	feature?: any | null
	foodshed?: any | null
	issue?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: string
	reason?: any | null
	searchIndex?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession25
	startAt?: any | null
	subcategory?: any | null
}
export interface EmailCaptured {
	/**
	 * email address entered
	 */
	email?: string
	/**
	 * name of the page, screen or form where the email was entered (service area, waitlist, etc)
	 */
	formName?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * zip code entered by user
	 */
	zipCode?: string
}
export interface App28 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign25 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession26 {
	campaign?: Campaign25
	crossDeviceId?: string
	id?: string
}
export interface EnteredForeground {
	addToOrderMode?: any | null
	app?: App28
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession26
}
export interface CommitSha13 {
	default?: string
}
export interface App29 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha13
	instance?: string
	name?: string
	version?: string
}
export interface Experiments18 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign26 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession27 {
	campaign?: Campaign26
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface EnteredPaymentDetails {
	addToOrderMode?: any | null
	app?: App29
	experiments?: Experiments18
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession27
	updating?: any | null
}
export interface CommitSha14 {
	default?: string
}
export interface App30 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha14
	instance?: string
	name?: string
	version?: string
}
export interface Experiments19 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign27 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession28 {
	campaign?: Campaign27
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface FailedCheckout {
	addToOrderMode?: any | null
	app?: App30
	experiments?: Experiments19
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	message?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession28
	type?: any | null
}
export interface CommitSha15 {
	default?: string
}
export interface App31 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha15
	instance?: string
	name?: string
	version?: string
}
export interface Context1 {
	feature?: string
}
export interface Experiments20 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign28 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession29 {
	campaign?: Campaign28
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface FavoritedProduct {
	addToOrderMode?: any | null
	app?: App31
	category?: any | null
	context?: Context1
	experiments?: Experiments20
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession29
	subcategory?: any | null
	tags?: any | null
	vendor?: any | null
}
export interface CommitSha16 {
	default?: string
}
export interface App32 {
	commitSHA?: CommitSha16
	instance?: string
	name?: string
}
export interface Experiments21 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Timing {
	connectEnd?: number
	connectStart?: number
	domComplete?: number
	domContentLoadedEventEnd?: number
	domContentLoadedEventStart?: number
	domInteractive?: number
	domLoading?: number
	domainLookupEnd?: number
	domainLookupStart?: number
	fetchStart?: number
	firstContentfulPaintTime?: number
	firstImagePaintTime?: number
	firstMeaningfulPaintTime?: number
	firstPaintTime?: number
	loadEventEnd?: number
	loadEventStart?: number
	msFirstPaint?: number
	navigationStart?: number
	redirectEnd?: number
	redirectStart?: number
	requestStart?: number
	responseEnd?: number
	responseStart?: number
	secureConnectionStart?: number
	timeOrigin?: number
	timeToContentfulPaint?: number
	timeToDOMContentFlushed?: number
	timeToFirstInteractive?: number
	timeToNonBlankPaint?: number
	unloadEventEnd?: number
	unloadEventStart?: number
	workerStart?: number
	x5BatteryLoadStart?: number
	x5CpuUsageFirstFrame?: number
	x5MemoryLoadStart?: number
	x5TrafficLoadStart?: number
}
export interface Performance {
	endTime?: number
	startTime?: number
	timing?: Timing
	wait?: number
}
export interface Screen10 {
	height?: number
	width?: number
}
export interface Campaign29 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession30 {
	campaign?: Campaign29
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface FinishedNavigation {
	addToWeeklyMode?: any | null
	addingToOrder?: any | null
	addonValue?: any | null
	app?: App32
	category?: any | null
	checkoutType?: any | null
	context?: any | null
	createNewWeeklie?: any | null
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments21
	foodshed?: any | null
	isEmpty?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	pageName?: any | null
	performance?: Performance
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	screen?: Screen10
	shoppingSession?: ShoppingSession30
	status?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface App33 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign30 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession31 {
	campaign?: Campaign30
	crossDeviceId?: string
	id?: string
}
export interface FinishedSpinning {
	addToOrderMode?: any | null
	app?: App33
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	screen?: any | null
	shoppingSession?: ShoppingSession31
	totalDuration?: any | null
	visibleDuration?: any | null
}
export interface FulfillmentOptionSoldOut {
	foodhubSlug?: any | null
	orderCutoff?: string
	soldOutAt?: any | null
	windowEndAt?: any | null
	windowStartAt?: any | null
}
export interface CommitSha17 {
	default?: string
}
export interface App34 {
	commitSHA?: CommitSha17
	instance?: string
	name?: string
}
export interface Experiments22 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen11 {
	height?: number
	width?: number
}
export interface Campaign31 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession32 {
	campaign?: Campaign31
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface HitSearch {
	addToOrderMode?: any | null
	addToWeeklyMode?: any | null
	app?: App34
	experiments?: Experiments22
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	nextAction?: any | null
	pageName?: any | null
	platform?: any | null
	positionOnPage?: any | null
	resultCount?: any | null
	screen?: Screen11
	searchQuery?: any | null
	searchRequestId?: any | null
	selectedProductId?: any | null
	selectedProductName?: any | null
	selectedProductVendor?: any | null
	shoppingSession?: ShoppingSession32
}
export interface ItemBilled {
	/**
	 * product category of the item being purchased
	 */
	category?: string | null
	/**
	 * coupon code associated with the item being purchased
	 */
	coupon?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * image url of the item being purchased
	 */
	imageUrl?: string
	/**
	 * boolean value for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * name of the item being purchased
	 */
	name?: string
	/**
	 * Order ID tied to order which this item was purchased
	 */
	orderId?: string
	/**
	 * price ($) of the item bring purchased
	 */
	price?: number
	/**
	 * producer of the item being purchased
	 */
	producer?: string
	/**
	 * databaseID of the product being viewed
	 */
	productId?: string
	/**
	 * Quantity of the item being purchased
	 */
	quanitity?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * sku of the product being purchased
	 */
	sku?: string
	/**
	 * subcategory of the item being purchased
	 */
	subCategory?: string | null
	/**
	 * url of the product being purchased
	 */
	url?: string
	/**
	 * Total value of the product after quantity without taxes, fees, and discounts applied
	 */
	value?: number
}
export interface ItemCompleted {
	/**
	 * unique basket Id
	 */
	basketID?: string
	/**
	 * populate with null (product category being viewed)
	 */
	category?: string | null
	/**
	 * coupon code associated with a product (e.g MAY DEALS 3)
	 */
	coupon?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * image url of the product
	 */
	imageUrl?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
feature = feature used to add product to cart (e.g Re-order or market)
	 */
	moduleType?: string
	/**
	 * name of the product being viewed
	 */
	name?: string
	/**
	 * price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Name of product producer (E.g From Our Farmers)
	 */
	producer?: string
	/**
	 * database id of the product being viewed
	 */
	productId?: string
	/**
	 * quantity of a product
	 */
	quantity?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * sku of the product being viewed
	 */
	sku?: string
	/**
	 * populate with null (product subcategory being viewed)
	 */
	subCategory?: string | null
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * Total value of the product after quantity without taxes, fees, and discounts applied
	 */
	value?: number
}
export interface ItemUpdated {
	/**
	 * populate with null (product category being viewed)
	 */
	category?: string | null
	/**
	 * coupon code associated with a product (e.g MAY DEALS 3)
	 */
	coupon?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: number
	/**
	 * image url of the product
	 */
	imageUrl?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
feature = feature used to add product to cart (e.g Re-order or market)
	 */
	moduleType?: string
	/**
	 * name of the product being viewed
	 */
	name?: string
	/**
	 * unique order id
	 */
	orderId?: string
	/**
	 * price ($) of the product being viewed
	 */
	price?: number
	/**
	 * Name of product producer (E.g From Our Farmers)
	 */
	producer?: string
	/**
	 * database id of the product being viewed
	 */
	productId?: string
	/**
	 * quantity of a product
	 */
	quantity?: number
	/**
	 * quantity of the product after updating
	 */
	quantityAfter?: number
	/**
	 * quantity of the product before updating
	 */
	quantityBefore?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * sku of the product being viewed
	 */
	sku?: string
	/**
	 * populate with null (product subcategory being viewed)
	 */
	subCategory?: string | null
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * Total value of the product after quantity without taxes, fees, and discounts applied
	 */
	value?: number
	/**
	 * Total value of the product after quantity without taxes, fees, and discounts applied after updating
	 */
	valueAfter?: number
	/**
	 * Total value of the product after quantity without taxes, fees, and discounts applied before updating
	 */
	valueBefore?: number
}
export interface App35 {
	apiVersion?: string
	build?: string
	name?: any | null
	version?: string
}
export interface ShoppingSession33 {
	id?: string
}
export interface LaunchedCleanInstall {
	addToOrderMode?: any | null
	app?: App35
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession33
}
export interface CommitSha18 {
	default?: string
}
export interface App36 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha18
	instance?: string
	name?: string
	version?: string
}
export interface Experiments23 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign32 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession34 {
	campaign?: Campaign32
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface LeftProductInBasket {
	addToOrderMode?: any | null
	app?: App36
	availabilityOnFulfillmentDay?: any | null
	experiments?: Experiments23
	feature?: any | null
	foodhubSlug?: string
	foodshed?: any | null
	fulfillmentId?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	price?: any | null
	productId?: any | null
	quantity?: any | null
	shoppingSession?: ShoppingSession34
	totalValue?: any | null
}
export interface CommitSha19 {
	default?: string
}
export interface App37 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha19
	instance?: string
	name?: string
	version?: string
}
export interface Experiments24 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign33 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession35 {
	campaign?: Campaign33
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface LoggedIn {
	addToOrderMode?: any | null
	app?: App37
	category?: any | null
	experiments?: Experiments24
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	productId?: string
	provider?: any | null
	shoppingSession?: ShoppingSession35
}
export interface CommitSha20 {
	default?: string
}
export interface App38 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha20
	instance?: string
	name?: string
	version?: string
}
export interface Experiments25 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign34 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession36 {
	campaign?: Campaign34
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface LoggedOut {
	addToOrderMode?: any | null
	app?: App38
	experiments?: Experiments25
	foodshed?: any | null
	loggedIn?: any | null
	page?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession36
}
export interface MembershipModalClicked {
	/**
	 * id of the cart
	 */
	basketId?: string
	/**
	 * boolean value for if the user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * orderID tied to the basket if it exists
	 */
	orderId?: string
	/**
	 * Name of the region tied to where the order is going to be placed
	 */
	regionName?: string
	/**
	 * Id of the user
	 */
	userId?: string
}
export interface CommitSha21 {
	default?: string
}
export interface App39 {
	commitSHA?: CommitSha21
	instance?: string
	name?: string
}
export interface Experiments26 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen12 {
	height?: number
	width?: number
}
export interface Campaign35 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession37 {
	campaign?: Campaign35
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface MissedSearch {
	addToOrderMode?: any | null
	addToWeeklyMode?: any | null
	app?: App39
	experiments?: Experiments26
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	platform?: any | null
	resultCount?: any | null
	screen?: Screen12
	searchQuery?: any | null
	searchRequestId?: any | null
	shoppingSession?: ShoppingSession37
}
export interface ModalViewed {
	/**
	 * CTA text on primary button shown to users
	 */
	ctaText?: string
	/**
	 * laboratory experiment group
	 */
	experiments?: Record<string, any>
	/**
	 * the individual page/screen (home, basket, PDP) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * boolean value for if a user is logged in
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * name of the modal (sign-up, sign-in)
	 */
	modalName?: string
	/**
	 * url path the modal surfaced on
	 */
	pageUrl?: string
}
export interface App40 {
	name?: string
}
export interface Campaign36 {
	medium?: string
	name?: string
	source?: string
}
export interface NotificationSent {
	app?: App40
	campaign?: Campaign36
	foodshed?: any | null
	medium?: any | null
	platform?: any | null
	source?: any | null
}
export interface App41 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign37 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession38 {
	campaign?: Campaign37
	crossDeviceId?: string
	id?: string
}
export interface OpenedUrl {
	addToOrderMode?: any | null
	app?: App41
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession38
	url?: any | null
}
export interface DeliveryDetailsItem1 {
	/**
	 * address of the delivery
	 */
	address?: string
	/**
	 * street number of the delivert
	 */
	address2?: string
	/**
	 * boolean for if the delivery can be left at the door
	 */
	canLeaveAtDoor?: boolean
	/**
	 * city of the delivery
	 */
	city?: string
	/**
	 * any instructions left for the delivery
	 */
	deliveryInstructions?: string
	/**
	 * boolean for if the delivery is a gift
	 */
	isGift?: boolean
	/**
	 * lattitude of the delivery
	 */
	lat?: string
	/**
	 * longitude for the delivery
	 */
	lon?: string
	/**
	 * state of the delivery
	 */
	state?: string
	/**
	 * zip of order delivery
	 */
	zip?: string
}
export interface ProductsItem4 {
	id?: string
	name?: string
	price?: number
	quantity?: number
}
export interface PromoCodesItem2 {
	autoReapply?: boolean
	code?: string
	label?: string
	type?: string
	value?: number
}
export interface OrderBilled {
	/**
	 * boolean value for if order contains a subscription
	 */
	containsSubscription?: boolean
	/**
	 * order level promo code
	 */
	coupon?: string
	/**
	 * amount of discount from coupon
	 */
	couponDiscount?: number
	/**
	 * total credits applied to the order
	 */
	credits?: number
	delivery?: number
	/**
	 * date scheduled for the delivery
	 */
	deliveryDate?: string
	/**
	 * delivery details array
	 */
	deliveryDetails?: DeliveryDetailsItem1[]
	/**
	 * date and time of end of the delivery window
	 */
	deliveryEndWindow?: string
	/**
	 * delivery fee associated the order
	 */
	deliveryFee?: number
	/**
	 * delivery method chosen for the order
	 */
	deliveryMethod?: string
	/**
	 * Date and time of start of delivery window
	 */
	deliveryStartWindow?: string
	/**
	 * window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
	 */
	deliveryWindow?: string
	discount?: number
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * total good job fee applied to the order
	 */
	goodJobFees?: number
	/**
	 * amount of skus in the order
	 */
	itemCount?: number
	/**
	 * boolean for if a user was loggedin
	 */
	loggedIn?: boolean
	/**
	 * boolean for if the user is an employee
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	orderCount?: number
	/**
	 * date and time for when the order is closed for updating
	 */
	orderCutOffDate?: string
	/**
	 * unique identifier for the order
	 */
	orderId?: string
	products?: ProductsItem4[]
	promoCodes?: PromoCodesItem2[]
	/**
	 * total amount of discount from referral
	 */
	referralDiscount?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * revenue ($) associated with the order
	 */
	revenue?: number
	/**
	 * amount of discount from subscription
	 */
	subscriptionDiscount?: number
	/**
	 * total of all products without taxes, delivery, discounts, fees applied
	 */
	subtotal?: number
	/**
	 * total tax applied to the order
	 */
	tax?: number
	/**
	 * type of payment method used in transaction
	 */
	tenderType?: string
	total?: number
	/**
	 * total discount applied to the order
	 */
	totalDiscount?: number
	/**
	 * tax, delivery, and good jobs fees attributed to the order
	 */
	totalFees?: number
}
export interface App42 {
	apiVersion?: string
	build?: string
	commitSHA?: string
	instance?: string
	name?: string
	version?: string
}
export interface DeliveryDetails1 {
	address?: string
	address2?: string
	canLeaveAtDoor?: boolean
	city?: string
	deliveryInstructions?: string
	isGift?: boolean
	lat?: number
	lon?: number
	state?: string
	zip?: string
	zipCode?: string
}
export interface Experiments27 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface ProductsItem5 {
	id?: string
	name?: string
	price?: number
	quantity?: number
}
export interface Screen13 {
	height?: number
	width?: number
}
export interface Campaign38 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession39 {
	campaign?: Campaign38
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface OrderCompleted {
	addingToOrder?: boolean
	affiliation?: string
	app?: App42
	coupon?: string
	credits?: number
	delivery?: number
	deliveryDay?: string
	deliveryDetails?: DeliveryDetails1
	deliveryEndWindow?: string
	deliveryPrice?: number
	deliveryStartWindow?: string
	discount?: number
	experiments?: Experiments27
	feature?: string
	fees?: number
	foodshed?: string
	itemCount?: number
	loggedIn?: boolean
	masquerading?: boolean
	orderCount?: number
	orderCutoff?: string
	orderId?: string
	pageName?: string
	platform?: string
	processingSubscription?: boolean
	productCount?: number
	products?: ProductsItem5[]
	promoCodes?: string[]
	referralDiscount?: number
	revenue?: number
	screen?: Screen13
	shoppingSession?: ShoppingSession39
	subtotal?: number
	tax?: number
	tier?: string
	total?: number
	totalCheckoutValue?: number
}
export interface App43 {
	apiVersion?: string
	build?: string
	commitSHA?: string
	instance?: string
	name?: string
	version?: string
}
export interface DeliveryDetails2 {
	address?: string
	address2?: string
	canLeaveAtDoor?: boolean
	city?: string
	deliveryInstructions?: string
	isGift?: boolean
	lat?: number
	lon?: number
	state?: string
	zip?: string
	zipCode?: string
}
export interface Experiments28 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface ProductsItem6 {
	id?: string
	price?: number
	quantity?: number
}
export interface Screen14 {
	height?: number
	width?: number
}
export interface Campaign39 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession40 {
	campaign?: Campaign39
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface OrderUpdated {
	addingToOrder?: boolean
	app?: App43
	coupon?: string
	credits?: number
	delivery?: number
	deliveryDay?: string
	deliveryDetails?: DeliveryDetails2
	deliveryEndWindow?: string
	deliveryStartWindow?: string
	discount?: number
	experiments?: Experiments28
	feature?: string
	foodshed?: string
	itemCount?: number
	loggedIn?: boolean
	masquerading?: boolean
	orderCount?: number
	orderCutoff?: string
	orderId?: string
	pageName?: string
	platform?: string
	processingSubscription?: boolean
	productCount?: number
	products?: ProductsItem6[]
	promoCodes?: string[]
	referralDiscount?: number
	revenue?: number
	screen?: Screen14
	shoppingSession?: ShoppingSession40
	subtotal?: number
	subtotalAfter?: number
	subtotalBefore?: number
	tax?: number
	tier?: string
	total?: number
}
export interface PageViewed {
	/**
	 * the category of the page
	 */
	category?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the name of the page
	 */
	name?: string
	/**
	 * path portion of the URL of the page (equivalent to canonical path which defaults to location.pathname from the DOM API)

	 */
	path?: string
	/**
	 * full URL of the previous page (equivalent to document.referrer from the DOM API)
	 */
	referrer?: string
	/**
	 * query string portion of the URL of the page (equivalent to location.search from the DOM API)
	 */
	search?: string
	/**
	 * title of the page (equivalent to document.title from the DOM API)
	 */
	title?: string
	/**
	 * ull URL of the page (first Segment looks for the canonical url. if the canonical url is not provided, Segment uses location.href from the DOM API)
	 */
	url?: string
}
export interface CommitSha22 {
	default?: string
}
export interface App44 {
	commitSHA?: CommitSha22
	instance?: string
	name?: string
}
export interface Experiments29 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen15 {
	height?: number
	width?: number
}
export interface Campaign40 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession41 {
	campaign?: Campaign40
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface PartialAddress {
	address?: any | null
	app?: App44
	city?: any | null
	experiments?: Experiments29
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	platform?: any | null
	screen?: Screen15
	shoppingSession?: ShoppingSession41
	state?: any | null
	zip?: any | null
}
export interface ProductAdded {
	/**
	 * basketId that the product is being added to
	 */
	basketId?: string
	/**
	 * category of the product being added
	 */
	category?: string
	/**
	 * coupon applied to the product being added
	 */
	coupon?: string
	/**
	 * dates a product is available for delivery
	 */
	daysAvailable?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * image url of the product being added
	 */
	imageUrl?: string
	/**
	 * Name of the targeted Algolia index

	 */
	index?: string
	/**
	 * boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * Name of the product being added
	 */
	name?: string
	/**
	 * Object ID of the record (same as productId)
	 */
	objectID?: string
	/**
	 * position in the product list being added to cart
	 */
	position?: number
	/**
	 * price ($) of the product being added
	 */
	price?: number
	/**
	 * producer of the product being added
	 */
	producer?: string
	/**
	 * database id of the product being added
	 */
	productId?: string
	/**
	 * quantity of the product being added
	 */
	quantity?: number
	/**
	 * Query ID returned in search results from Algolia
	 */
	queryID?: string
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * sku of the product being added
	 */
	sku?: string
	/**
	 * category of the product being added
	 */
	subCategory?: string
	/**
	 * boolean for if the product added results in a cart that is under the cost threshold or not
	 */
	underMinimum?: boolean
	/**
	 * value of the product being added after accounting for quantity
	 */
	value?: number
}
export interface ProductCategoryClicked {
	/**
	 * name of the client-facing category which is clicked, in the case that this is a subCategory this value will fill with the corresponding primary category
	 */
	category?: string
	/**
	 * url of the page where the click propels you into
	 */
	destination?: string
	/**
	 * url of the page where the click occurs
	 */
	pageUrl?: string
	/**
	 * name of the client-facing subcategory which is clicked, in the case that the click is a primary category this value will be empty
	 */
	subCategory?: string
}
export interface ProductCategoryFiltered {
	/**
	 * url of the page where the click propels you into
	 */
	destination?: string
	/**
	 * client-facing name of the primary filter selected
	 */
	filter?: string
	/**
	 * url of the page where the click occurs
	 */
	pageUrl?: string
	/**
	 * client-facing name of the secondary filter selected
	 */
	secondaryFilter?: string
}
export interface ProductClicked {
	/**
	 * the category of the product being clicked
	 */
	category?: string
	/**
	 * coupon code associated with a product (e.g. MAY DEALS 3)
	 */
	coupon?: string
	/**
	 * what engine is feeding the specific product tile (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * the feature of the product being clicked is the name of the set of pages that propel the click into its destination, on a web source examples would be home, reorder, pdp, market, category etc.. (ex a user adds a product to cart from a pdp, the feature value would be pdp)
	 */
	feature?: string
	/**
	 * image url of the product
	 */
	imageUrl?: string
	/**
	 * name of the targeted index (algolia)
	 */
	index?: string
	/**
	 * boolean value for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if a user is logged in or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * name of the product being clicked
	 */
	name?: string
	/**
	 * productId (for Algolia)
	 */
	objectID?: string
	/**
	 * position in the product list
	 */
	position?: number
	/**
	 * price ($) of the product being clicked
	 */
	price?: number
	/**
	 * producer of the product being clicked
	 */
	producer?: string
	/**
	 * database id of the product being clicked
	 */
	productId?: string
	/**
	 * quantity of the product being clicked
	 */
	quantity?: number
	/**
	 * algolia Id found in the search response
	 */
	queryID?: string
	/**
	 * sku of the product being clicked (productId)
	 */
	sku?: string
	/**
	 * sub category of the product being clicked
	 */
	subCategory?: string
	/**
	 * url of the product
	 */
	url?: string
	/**
	 * total value of the product after quantity
	 */
	value?: number
}
export interface ProductFavorited {
	/**
	 * category of the product being clicked
	 */
	category?: string
	/**
	 * coupon code associated with a product (e.g. MAY DEALS 3)
	 */
	coupon?: string
	/**
	 * dates a product is available for delivery
	 */
	daysAvailable?: string
	/**
	 * what engine was feeding the product being added (e.g best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * the feature of the product being clicked is the name of the set of pages that propel the click into its destination, on a web source examples would be home, reorder, pdp, market, category etc.. (ex a user adds a product to cart from a pdp, the feature value would be pdp)
	 */
	feature?: string
	/**
	 * image url of the product being clicked
	 */
	imageUrl?: string
	/**
	 * name of the targeted index
	 */
	index?: string
	/**
	 * boolean for if the user clicking the product is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, Cream Co. Meats, category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * name of the product being clicked
	 */
	name?: string
	/**
	 * productId value
	 */
	objectId?: string
	/**
	 * position in the product list (e.g. 3)
	 */
	position?: number
	/**
	 * price of the product being clicked
	 */
	price?: string
	/**
	 * producer of the product being clicked
	 */
	producer?: string
	/**
	 * database id of the product being clicked
	 */
	productId?: string
	/**
	 * quantity of the product being clicked
	 */
	quantity?: number
	/**
	 * Algolia ID found in the search responde
	 */
	queryId?: string
	/**
	 * sku of the product being clicked
	 */
	sku?: string
	/**
	 * subCategory of the product being clicked
	 */
	subCategory?: string
	/**
	 * total value of the product after quantity
	 */
	value?: number
}
export interface ProductRemoved {
	/**
	 * basketID of the cart that the product is being removed from
	 */
	basketId?: string
	/**
	 * category of the product being removed
	 */
	category?: string
	/**
	 * Coupon applied to the product being removed
	 */
	coupon?: string
	/**
	 * dates a product is available for delivery
	 */
	daysAvailable?: string
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * image url of the product being removed
	 */
	imageUrl?: string
	/**
	 * boolean for if a user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee
	 */
	masquerading?: boolean
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string
	/**
	 * name of the product being removed
	 */
	name?: string
	/**
	 * position in the product listing of the product being removed
	 */
	position?: number
	/**
	 * price ($) of the product being removed
	 */
	price?: number
	/**
	 * producer of the product being removed
	 */
	producer?: string
	/**
	 * databaseId of the product being removed
	 */
	productId?: string
	/**
	 * quantity of the products being removed
	 */
	quantity?: number
	/**
	 * sku of the product being removed
	 */
	sku?: string
	/**
	 * subCategory of the product being removed
	 */
	subCategory?: string
	/**
	 * boolean for if the product added leads to a cart under the $30 threshold
	 */
	underMinimum?: boolean
	/**
	 * total value of the product after accounting for quantity
	 */
	value?: number
}
export interface ProductViewed {
	/**
	 * product category being viewed
	 */
	category?: string
	/**
	 * coupon code associated with product
	 */
	coupon?: string
	/**
	 * image Url of the product
	 */
	imageUrl?: string
	/**
	 * Name of the targeted Algolia index

	 */
	index?: string
	/**
	 * boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is logged in or not
	 */
	masquerading?: boolean
	/**
	 * name of the product being viewed
	 */
	name?: string
	/**
	 * Object ID of the record (same as productId)
	 */
	objectId?: string
	/**
	 * position of product within the product list
	 */
	position?: number
	/**
	 * price ($) of the product being viewed
	 */
	price?: number
	/**
	 * name of the product producer
	 */
	producer?: string
	/**
	 * database ID of the product being viewed
	 */
	productId?: string
	/**
	 * quantity of the product
	 */
	quantity?: number
	/**
	 * market section being viewed (market, re-order, favorites)
	 */
	section?: string
	/**
	 * sku of the product being viewed
	 */
	sku?: string
	/**
	 * product subcategory being viewed
	 */
	subCategory?: string
	/**
	 * URL of the product page
	 */
	url?: string
	/**
	 * total value of the product after quantity
	 */
	value?: number
}
export interface App45 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign41 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession42 {
	campaign?: Campaign41
	crossDeviceId?: string
	id?: string
}
export interface PromoCodeApplied {
	app?: App45
	code?: string
	foodshed?: string
	loggedIn?: boolean
	platform?: string
	shoppingSession?: ShoppingSession42
}
export interface App46 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign42 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession43 {
	campaign?: Campaign42
	crossDeviceId?: string
	id?: string
}
export interface ReferralCta {
	app?: App46
	foodshed?: string
	loggedIn?: boolean
	platform?: string
	shoppingSession?: ShoppingSession43
}
export interface CommitSha23 {
	default?: string
}
export interface App47 {
	commitSHA?: CommitSha23
	instance?: string
	name?: string
}
export interface Experiments30 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign43 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession44 {
	campaign?: Campaign43
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ReferralClicked {
	app?: App47
	experiments?: Experiments30
	loggedIn?: boolean
	platform?: string
	referralId?: string
	shoppingSession?: ShoppingSession44
}
export interface CommitSha24 {
	default?: string
}
export interface App48 {
	commitSHA?: CommitSha24
	instance?: string
	name?: string
}
export interface ReferralDiscountConsumed {
	app?: App48
	giverEmail?: string
	giverId?: string
	loggedIn?: boolean
	platform?: string
	referralGiftId?: string
}
export interface CommitSha25 {
	default?: string
}
export interface App49 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha25
	instance?: string
	name?: string
	version?: string
}
export interface Experiments31 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Screen16 {
	height?: number
	width?: number
}
export interface Campaign44 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession45 {
	campaign?: Campaign44
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ReferralShared {
	amount?: number
	anonymousRecipientId?: string
	app?: App49
	displayAmount?: string
	experiments?: Experiments31
	foodshed?: string
	giverEmail?: string
	giverFirstName?: string
	giverId?: string
	loggedIn?: boolean
	masquerading?: boolean
	medium?: string
	pageName?: string
	platform?: string
	recipientEmail?: string
	screen?: Screen16
	shoppingSession?: ShoppingSession45
}
export interface CommitSha26 {
	default?: string
}
export interface App50 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha26
	instance?: string
	name?: any | null
	version?: string
}
export interface Context2 {
	feature?: string
	resultCount?: number
	searchQuery?: string
	searchRequestId?: string
	subcategory?: string
}
export interface Experiments32 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign45 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession46 {
	campaign?: Campaign45
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface RemovedProduct {
	addToOrderMode?: any | null
	addonValue?: any | null
	app?: App50
	category?: any | null
	checkoutType?: any | null
	context?: Context2
	createNewWeeklie?: any | null
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	employee?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments32
	feature?: any | null
	foodshed?: any | null
	id?: any | null
	isEmpty?: any | null
	issue?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	name?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	price?: any | null
	product?: any | null
	productId?: any | null
	productScheduleId?: any | null
	quantity?: any | null
	quantityAfter?: any | null
	quantityBefore?: any | null
	resultCount?: any | null
	searchIndex?: any | null
	searchQuery?: any | null
	searchRequestId?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession46
	shouldSubscribe?: any | null
	sku?: any | null
	status?: any | null
	subcategory?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface App51 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign46 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession47 {
	campaign?: Campaign46
	crossDeviceId?: string
	id?: string
}
export interface RenderedCheckoutButton {
	addToOrderMode?: any | null
	addingToOrder?: any | null
	app?: App51
	enabled?: any | null
	foodshed?: any | null
	fulfillmentOptionCanCheckout?: any | null
	hasAddress?: any | null
	hasPayment?: any | null
	hasTime?: any | null
	loggedIn?: any | null
	platform?: any | null
	screen?: any | null
	shoppingSession?: ShoppingSession47
}
export interface CommitSha27 {
	default?: string
}
export interface App52 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha27
	instance?: string
	name?: string
	version?: string
}
export interface Experiments33 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign47 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession48 {
	campaign?: Campaign47
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface SanitizedBasketItem {
	addToOrderMode?: any | null
	afterQuantity?: any | null
	app?: App52
	basketLastUpdatedAt?: any | null
	beforeQuantity?: any | null
	category?: any | null
	context?: any | null
	experiments?: Experiments33
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	productId?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession48
	subcategory?: any | null
	type?: any | null
	vendor?: any | null
}
export interface SearchResultSelected {
	/**
	 * the category of the item selected in search
	 */
	categorySelected?: string
	/**
	 * Name of the targeted Algolia index
	 */
	index?: string
	/**
	 * the name of the item selected in search
	 */
	itemSelected?: string
	/**
	 * boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee
	 */
	masquerading?: boolean
	/**
	 * Object ID of the record (same as productId)
	 */
	objectID?: string
	/**
	 * position of the search result selected
	 */
	position?: number
	/**
	 * the producer of the product selected
	 */
	producerSelected?: string
	/**
	 * The search query that yielded the result
	 */
	query?: string
	/**
	 * Query ID returned in search results from Algolia
	 */
	queryID?: string
}
export interface CommitSha28 {
	default?: string
}
export interface App53 {
	commitSHA?: CommitSha28
	instance?: string
	name?: string
}
export interface Context3 {
	feature?: string
	resultCount?: number
	searchQuery?: string
	searchRequestId?: string
	subcategory?: string
}
export interface Experiments34 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign48 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession49 {
	campaign?: Campaign48
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ServedResponse {
	addonValue?: any | null
	app?: App53
	category?: any | null
	checkoutType?: any | null
	context?: Context3
	createNewWeeklie?: any | null
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments34
	feature?: any | null
	foodshed?: any | null
	httpMethod?: any | null
	isEmpty?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	page?: any | null
	pageName?: any | null
	path?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	processingSubscription?: any | null
	productId?: any | null
	responseTime?: any | null
	resultCount?: any | null
	searchQuery?: any | null
	searchRequestId?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession49
	status?: any | null
	statusCode?: any | null
	subcategory?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface CommitSha29 {
	default?: string
}
export interface App54 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha29
	instance?: string
	name?: string
	version?: string
}
export interface Experiments35 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface NewDeliveryDetails {
	address?: string
	address2?: string
	canLeaveAtDoor?: boolean
	city?: string
	customerBags?: boolean
	deliveryInstructions?: string
	giftMessage?: string
	giftRecipientName?: string
	giftRecipientPhone?: string
	isGift?: boolean
	key?: string
	lat?: number
	lon?: number
	state?: string
	zip?: string
}
export interface Campaign49 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession50 {
	campaign?: Campaign49
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface SetDeliveryAddress {
	addToOrderMode?: any | null
	addressIsConfirmed?: boolean
	app?: App54
	experiments?: Experiments35
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	newDeliveryDetails?: NewDeliveryDetails
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession50
	updating?: any | null
}
export interface CommitSha30 {
	default?: string
}
export interface App55 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha30
	instance?: string
	name?: string
	version?: string
}
export interface Context4 {
	feature?: string
	resultCount?: number
	searchQuery?: string
	searchRequestId?: string
	subcategory?: string
}
export interface Experiments36 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign50 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession51 {
	campaign?: Campaign50
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface SetDeliveryTime {
	addToOrderMode?: any | null
	app?: App55
	autoAssigned?: any | null
	category?: any | null
	context?: Context4
	endAt?: any | null
	experiments?: Experiments36
	feature?: any | null
	foodshed?: any | null
	issue?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	resultCount?: any | null
	searchIndex?: any | null
	searchQuery?: any | null
	searchRequestId?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession51
	startAt?: any | null
	subcategory?: any | null
	updating?: any | null
	vendor?: any | null
}
export interface SignedIn {
	/**
	 * user's email
	 */
	email?: string
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * what sign-in method did the user use (email, Facebook, Google, or Apple)
	 */
	signInMethod?: string
	/**
	 * user's user name
	 */
	userName?: string
}
export interface SiteSearched {
	/**
	 * Name of the targeted Algolia index
	 */
	index?: string
	/**
	 * boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * the search query entered into the search bar
	 */
	query?: string
	/**
	 * Query ID returned in search results from Algolia
	 */
	queryID?: string
	/**
	 * type of search query submitted or selected (recent, trending, userSubmitted, suggestion)
	 */
	queryType?: string
}
export interface CommitSha31 {
	default?: string
}
export interface App56 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha31
	instance?: string
	name?: string
	version?: string
}
export interface Experiments37 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign51 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession52 {
	campaign?: Campaign51
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface SkippedSubscriptionWeek {
	addToOrderMode?: any | null
	app?: App56
	day?: any | null
	experiments?: Experiments37
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession52
	subscriptionId?: any | null
}
export interface CommitSha32 {
	default?: string
}
export interface App57 {
	commitSHA?: CommitSha32
	instance?: string
	name?: string
}
export interface Experiments38 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign52 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession53 {
	campaign?: Campaign52
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface StartedAddToOrder {
	app?: App57
	experiments?: Experiments38
	loggedIn?: any | null
	orderId?: any | null
	page?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession53
}
export interface App58 {
	apiVersion?: string
	build?: string
	name?: string
	version?: string
}
export interface Campaign53 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession54 {
	campaign?: Campaign53
	crossDeviceId?: string
	id?: string
}
export interface StartedApplePayCheckout {
	addToOrderMode?: any | null
	app?: App58
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession54
}
export interface CommitSha33 {
	default?: string
}
export interface App59 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha33
	instance?: string
	name?: string
	version?: string
}
export interface Experiments39 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign54 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession55 {
	campaign?: Campaign54
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface StartedCheckout {
	addToOrderMode?: any | null
	addingToOrder?: any | null
	app?: App59
	experiments?: Experiments39
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession55
}
export interface CommitSha34 {
	default?: string
}
export interface App60 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha34
	instance?: string
	name?: string
	version?: string
}
export interface Context5 {
	feature?: string
	resultCount?: number
	searchQuery?: string
	searchRequestId?: string
	subcategory?: string
}
export interface Experiments40 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign55 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession56 {
	campaign?: Campaign55
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface ShoppingSession57 {
	id?: string
}
export interface StartedShoppingSession {
	addToOrderMode?: any | null
	addonValue?: any | null
	app?: App60
	applePayEnabled?: any | null
	applePaySupported?: any | null
	category?: any | null
	checkoutType?: any | null
	commit_sha?: string
	context?: Context5
	context_ip?: string
	context_network_bluetooth?: boolean
	context_network_carrier?: string
	context_network_cellular?: boolean
	context_network_wifi?: boolean
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments40
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	resultCount?: any | null
	screen_height?: number
	screen_width?: number
	searchQuery?: any | null
	searchRequestId?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession56
	shopping_session?: ShoppingSession57
	status?: any | null
	subcategory?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface SubscriptionCancelled {
	/**
	 * boolean value for if a user allows substitutions
	 */
	allowSubstitutions?: boolean
	/**
	 * boolean value for it a user is logged in
	 */
	loggedIn?: boolean
	/**
	 * boolean value for it a user is an employee
	 */
	masquerading?: boolean
	/**
	 * unique subcription order id (different than order id)
	 */
	subscriptionId?: string
	/**
	 * total value of the subscription cart accounting for quantity
	 */
	value?: number
}
export interface ProductsItem7 {
	/**
	 * product category of the product being viewed
	 */
	category?: string | null
	/**
	 * product category of the product being viewed
	 */
	coupon?: string | null
	/**
	 * what engine is feeding the specific product (e.g. best sellers, recommendation engine)
	 */
	engine?: string | null
	/**
	 * unique database Id of the product in the order
	 */
	id?: string
	/**
	 * the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
	 */
	moduleName?: string | null
	/**
	 * the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
	 */
	moduleType?: string | null
	/**
	 * name of the product in the order
	 */
	name?: string
	/**
	 * price ($) of the product in the order
	 */
	price?: number
	/**
	 * producer of the product in the order
	 */
	producer?: string | null
	/**
	 * quantity of the product in the order
	 */
	quantity?: number
	/**
	 * sku of the product in the order
	 */
	sku?: string | null
	/**
	 * sub productCategory of the product in the order
	 */
	subCategory?: string | null
	/**
	 * url of the product in the order
	 */
	url?: string | null
	/**
	 * total value of the product after accounting for quantity
	 */
	value?: number
}
export interface SubscriptionCreated {
	/**
	 * amount of discount from coupon
	 */
	couponDiscount?: number
	/**
	 * total credits applied to this basket
	 */
	credits?: number
	/**
	 * end of delivery window
	 */
	deliveryEndWindow?: string
	/**
	 * delivery fee applied to checkout
	 */
	deliveryFee?: number
	/**
	 * start of delivery window
	 */
	deliveryStartWindow?: string
	/**
	 * good jobs fee associated with the order
	 */
	goodJobsFee?: number
	/**
	 * amount of individual items in the order
	 */
	itemCount?: number
	/**
	 * Boolean value for if the user is logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean value for if the user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * type of payment method used (e.g. credit card, apple pay...)
	 */
	paymentMethod?: string
	/**
	 * web or MMA
	 */
	platform?: string
	/**
	 * amount of individual products in the order
	 */
	productCount?: number
	/**
	 * product details array
	 */
	products?: ProductsItem7[]
	/**
	 * amount of discount from referral
	 */
	referralDiscount?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * amount of discount from the subscription
	 */
	subscriptionDiscount?: number
	/**
	 * unique id tied to the subscription order (seperate from orderId)
	 */
	subscriptionID?: string
	/**
	 * total value of all products without taxes, fees, or discounts applied
	 */
	subtotal?: number
	/**
	 * total tax applied to the order
	 */
	tax?: number
	/**
	 * total discount applied to the order
	 */
	totalDiscount?: number
	/**
	 * total value for good job fee, delivery, and tax added together
	 */
	totalFees?: number
}
export interface SubscriptionProductAdded {
	/**
	 * id of the cart that has the product being added to it
	 */
	basketId?: string
	/**
	 * dates a product is available for delivery
	 */
	daysAvailable?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * image url of the product being added
	 */
	imageUrl?: string
	/**
	 * boolean value for if a user is an employee or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * name of the product being added
	 */
	name?: string
	/**
	 * price ($) of of the product being added
	 */
	price?: number
	/**
	 * producer to the product being added
	 */
	producer?: string
	/**
	 * database id of the product being added
	 */
	productId?: string
	/**
	 * amount of products being added
	 */
	quantity?: number
	/**
	 * sku of the product being added
	 */
	sku?: string
	/**
	 * id of the subscription cart that has the product being added to it
	 */
	subscriptionId?: string
	/**
	 * boolean for if the product added leads to a cart under the $30 threshold
	 */
	underSubscriptionMinimum?: boolean
	/**
	 * total value after the product after quantity
	 */
	value?: number
	/**
	 * the value remaining until you hit the $30 minimum (visibile in the UI)
	 */
	valueUnderSubscriptionMinimum?: number
}
export interface SubscriptionProductRemoved {
	/**
	 * id of cart that has the product being removed from it
	 */
	basketId?: string
	/**
	 * dates a product is available
	 */
	daysAvailable?: string
	/**
	 * The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
	 */
	feature?: string
	/**
	 * image url of the product
	 */
	imageUrl?: string
	/**
	 * boolean value for if a user was logged in or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * name of the product being removed
	 */
	name?: string
	/**
	 * price ($) of the product being removed
	 */
	price?: number
	/**
	 * producer of the product being removed
	 */
	producer?: string
	/**
	 * database id of the product being removed
	 */
	productId?: string
	/**
	 * amount of products being removed
	 */
	quantity?: number
	/**
	 * sku of the product being removed
	 */
	sku?: string
	/**
	 * id of the subscription cart that has the product being removed from it
	 */
	subscriptionId?: string
	/**
	 * boolean for if the product removed leads to a cart under the $30 threshold
	 */
	underSubscriptionMinimum?: boolean
	/**
	 * total value of the product after quantity
	 */
	value?: number
	/**
	 * the value remaining until you hit the $30 minimum (visibile in the UI)
	 */
	valueUnderSubscriptionMinimum?: number
}
export interface SubscriptionUpdated {
	/**
	 * order level promo code
	 */
	coupon?: string
	/**
	 * Total credits applied to order
	 */
	credits?: number
	/**
	 * selected for delivery date of product
	 */
	deliveryDate?: string
	/**
	 * delivery fee applied to the order
	 */
	deliveryFee?: number
	/**
	 * total discount applied to order
	 */
	discount?: number
	/**
	 * Count of individual items in order
	 */
	itemCount?: number
	/**
	 * boolean value for if a user is loggedin or not
	 */
	loggedIn?: boolean
	/**
	 * boolean for if a user is an employee or not
	 */
	masquerading?: boolean
	/**
	 * Number of individual SKUs in order
	 */
	productCount?: number
	/**
	 * quantity of products in cart after updating
	 */
	quantityAfter?: number
	/**
	 * quantity of products in cart before updating
	 */
	quantityBefore?: number
	/**
	 * Name of the region tied to where the order was fulfilled
	 */
	regionFulfillmentCenter?: string
	/**
	 * Name of the region tied to where the order was placed
	 */
	regionName?: string
	/**
	 * what type of change did the user make to their subscription (e.g. changing day, changing time, changing address, adding items, removing items)
	 */
	statusChange?: string
	/**
	 * amount of discount from subscription
	 */
	subscriptionDiscount?: number
	/**
	 * unique id tied to the subscription order (seperate from orderId)
	 */
	subscriptionId?: string
	/**
	 * Total of all products without taxes, fees, or discounts applied
	 */
	subtotal?: number
	/**
	 * subtotal before order updated
	 */
	subtotalAfter?: number
	/**
	 * Subtotal before order update
	 */
	subtotalBefore?: number
	/**
	 * total taxes applied to the order
	 */
	tax?: number
	/**
	 * tax, shipping, delivery, and good jobs fees totaled
	 */
	totalFees?: number
	/**
	 * total value of the product after quantity
	 */
	value?: number
}
export interface CommitSha35 {
	default?: string
}
export interface App61 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha35
	instance?: string
	name?: string
	version?: string
}
export interface Context6 {
	feature?: string
}
export interface Experiments41 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign56 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession58 {
	campaign?: Campaign56
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface UnfavoritedProduct {
	addToOrderMode?: any | null
	app?: App61
	category?: any | null
	context?: Context6
	experiments?: Experiments41
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	productId?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession58
	subcategory?: any | null
	tags?: any | null
	vendor?: any | null
}
export interface CommitSha36 {
	default?: string
}
export interface App62 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha36
	instance?: string
	name?: string
	version?: string
}
export interface Experiments42 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign57 {
	content?: string
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string
}
export interface ShoppingSession59 {
	campaign?: Campaign57
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface UnskippedSubscriptionWeek {
	addToOrderMode?: any | null
	app?: App62
	day?: any | null
	experiments?: Experiments42
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession59
	subscriptionId?: any | null
}
export interface CommitSha37 {
	default?: string
}
export interface App63 {
	apiVersion?: string
	build?: string
	commitSHA?: CommitSha37
	instance?: string
	name?: string
	version?: string
}
export interface Experiments43 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign58 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession60 {
	campaign?: Campaign58
	crossDeviceId?: string
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface UsedPromoCode {
	addToOrderMode?: any | null
	app?: App63
	attributionType?: string
	code?: any | null
	experiments?: Experiments43
	feature?: any | null
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession60
}
export interface CommitSha38 {
	default?: string
}
export interface App64 {
	commitSHA?: CommitSha38
	instance?: string
	name?: string
}
export interface Experiments44 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Campaign59 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession61 {
	campaign?: Campaign59
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
	isNew?: boolean
}
export interface Viewed500ErrorPage {
	app?: App64
	experiments?: Experiments44
	feature?: any | null
	loggedIn?: any | null
	page?: any | null
	pageName?: any | null
	platform?: any | null
	processingSubscription?: any | null
	shoppingSession?: ShoppingSession61
}
export interface App65 {
	apiVersion?: string
	build?: string
	name?: any | null
	version?: string
}
export interface Campaign60 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession62 {
	campaign?: Campaign60
	crossDeviceId?: string
	id?: string
}
export interface ViewedBasket {
	addToOrderMode?: any | null
	app?: App65
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession62
}
export interface App66 {
	apiVersion?: string
	build?: string
	name?: any | null
	version?: string
}
export interface Details {
	unavailableCount?: any | null
}
export interface Basket1 {
	details?: Details
}
export interface Campaign61 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession63 {
	campaign?: Campaign61
	crossDeviceId?: string
	id?: string
}
export interface ViewedBasketDetails {
	addToOrderMode?: any | null
	app?: App66
	basket?: Basket1
	foodshed?: any | null
	loggedIn?: any | null
	platform?: any | null
	shoppingSession?: ShoppingSession63
	unavailableCount?: any | null
}
export interface CommitSha39 {
	default?: string
}
export interface App67 {
	commitSHA?: CommitSha39
	instance?: string
	name?: string
}
export interface Experiments45 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Feature1 {
	id?: string
	name?: string
	type?: string
}
export interface Screen17 {
	height?: number
	width?: number
}
export interface Campaign62 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession64 {
	campaign?: Campaign62
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface User1 {
	orderCount?: number
}
export interface ViewedHomepageFeature {
	addToWeeklyMode?: any | null
	app?: App67
	experiments?: Experiments45
	feature?: Feature1
	foodshed?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	pageName?: any | null
	platform?: any | null
	screen?: Screen17
	shoppingSession?: ShoppingSession64
	user?: User1
}
export interface CommitSha40 {
	default?: string
}
export interface App68 {
	commitSHA?: CommitSha40
	instance?: string
	name?: string
}
export interface Experiments46 {
	'2021-q2-delayed-signup'?: string
	'no-op'?: string
}
export interface Product1 {
	id?: any | null
	price?: any | null
}
export interface Screen18 {
	height?: number
	width?: number
}
export interface Campaign63 {
	content?: string[]
	medium?: string[]
	name?: string[]
	source?: string[]
	term?: string[]
}
export interface ShoppingSession65 {
	campaign?: Campaign63
	dismissedAppPromoModal?: boolean
	id?: string
	initialReferrer?: string
}
export interface ViewedHoverBasket {
	addToOrderMode?: any | null
	addToWeeklyMode?: any | null
	addonValue?: any | null
	app?: App68
	category?: any | null
	checkoutType?: any | null
	context?: any | null
	createNewWeeklie?: any | null
	daysBeforeFulfillment?: any | null
	deliveryPrice?: any | null
	existingWeeklies?: any | null
	experiments?: Experiments46
	foodshed?: any | null
	fulfillment_days_in_basket?: any | null
	has_add_to_order_button?: any | null
	'inspire-slug'?: any | null
	isEmpty?: any | null
	loggedIn?: any | null
	masquerading?: any | null
	missingPickup?: any | null
	pageName?: any | null
	pageTags?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	product?: Product1
	productId?: any | null
	screen?: Screen18
	shoppingSession?: ShoppingSession65
	site?: any | null
	status?: any | null
	tags?: any | null
	totalCheckoutValue?: any | null
	unactivated?: any | null
	underMinimum?: any | null
	vendor?: any | null
}
export interface App69 {
	apiVersion?: string
	build?: string
	name?: any | null
	version?: string
}
export interface Campaign64 {
	content?: string
	medium?: string
	name?: string
	source?: string
	term?: string
}
export interface ShoppingSession66 {
	campaign?: Campaign64
	crossDeviceId?: string
	id?: string
}
export interface ViewedProduct {
	addToOrderMode?: any | null
	app?: App69
	category?: any | null
	content?: any | null
	feature?: any | null
	foodshed?: any | null
	id?: any | null
	issue?: any | null
	loggedIn?: any | null
	name?: any | null
	piece?: any | null
	pieceType?: any | null
	platform?: any | null
	price?: any | null
	searchIndex?: any | null
	searchSection?: any | null
	section?: any | null
	shoppingSession?: ShoppingSession66
	sku?: any | null
	subcategory?: any | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef CommitSha
 * @property {string} [default] -
 */
/**
 * @typedef App
 * @property {CommitSha} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession
 * @property {Campaign} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef AbandonedRecipeVideo
 * @property {any | null} [addToWeeklyMode] -
 * @property {App} [app] -
 * @property {any | null} [at] -
 * @property {Experiments} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [recipe] -
 * @property {Screen} [screen] -
 * @property {ShoppingSession} [shoppingSession] -
 */
/**
 * @typedef AccountCreated
 * @property {string} [email] - input value for email
 * @property {string} [feature] - individual page/screen (home, basket, PDP) of where a user interaction occurred
 * @property {string} [firstName] - input value for first name
 * @property {string} [lastName] - input value for last name
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [pageUrl] - url path the where the interaction occurred
 * @property {string} [regionFuilfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [signInMethod] - what sign-in method did the user use (email, Facebook, Google, or Apple)
 */
/**
 * @typedef AccountUpdated
 * @property {string} [email] - input value for email
 * @property {string} [firstName] - input value for first name
 * @property {string} [lastName] - input value for last name
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [name] - input value for their full name
 * @property {boolean} [newsAndInspirations] - boolean value for if they have news and inspirations on
 * @property {boolean} [paymentMethod] - boolean value for if they have a payment method saved
 * @property {string} [phoneNumber] - input value for their phone number
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {boolean} [shoppingReminders] - boolean value for if they have shopping reminders on
 * @property {boolean} [smsReminders] - boolean value for if they have sms reminders on
 */
/**
 * @typedef CommitSha1
 * @property {string} [default] -
 */
/**
 * @typedef App1
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha1} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments1
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign1
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession1
 * @property {Campaign1} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ActivatedSubscription
 * @property {any | null} [addToOrderMode] -
 * @property {App1} [app] -
 * @property {Experiments1} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession1} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 */
/**
 * @typedef CommitSha2
 * @property {string} [default] -
 */
/**
 * @typedef App2
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha2} [commitSHA] -
 * @property {string} [instance] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context
 * @property {string} [feature] -
 * @property {number} [resultCount] -
 * @property {string} [searchQuery] -
 * @property {string} [searchRequestId] -
 * @property {string} [subcategory] -
 */
/**
 * @typedef Experiments2
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign2
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession2
 * @property {Campaign2} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef AddedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {App2} [app] -
 * @property {any | null} [category] -
 * @property {Context} [context] -
 * @property {any | null} [employee] -
 * @property {Experiments2} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [id] -
 * @property {any | null} [issue] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [name] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [price] -
 * @property {any | null} [product] -
 * @property {any | null} [productId] -
 * @property {any | null} [productScheduleId] -
 * @property {any | null} [quantity] -
 * @property {any | null} [quantityAfter] -
 * @property {any | null} [quantityBefore] -
 * @property {any | null} [resultCount] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession2} [shoppingSession] -
 * @property {any | null} [shouldSubscribe] -
 * @property {any | null} [sku] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha3
 * @property {string} [default] -
 */
/**
 * @typedef App3
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha3} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments3
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign3
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession3
 * @property {Campaign3} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef AddedProductToSubscription
 * @property {any | null} [addToOrderMode] -
 * @property {App3} [app] -
 * @property {Experiments3} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [quantityAfter] -
 * @property {any | null} [quantityBefore] -
 * @property {ShoppingSession3} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 * @property {any | null} [subscriptionItemId] -
 */
/**
 * @typedef App4
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign4
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession4
 * @property {Campaign4} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef AnsweredNotificationPrompt
 * @property {any | null} [addToOrderMode] -
 * @property {App4} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {any | null} [selection] -
 * @property {ShoppingSession4} [shoppingSession] -
 */
/**
 * @typedef App5
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign5
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 */
/**
 * @typedef ShoppingSession5
 * @property {Campaign5} [campaign] -
 * @property {string} [id] -
 */
/**
 * @typedef ApplicationDidFinishLaunching
 * @property {any | null} [addToOrderMode] -
 * @property {App5} [app] -
 * @property {any | null} [duration] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession5} [shoppingSession] -
 */
/**
 * @typedef App6
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef ShoppingSession6
 * @property {string} [id] -
 */
/**
 * @typedef ApplicationInstalled
 * @property {App6} [app] -
 * @property {string} [build] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {string} [platform] -
 * @property {ShoppingSession6} [shoppingSession] -
 * @property {string} [version] -
 */
/**
 * @typedef App7
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign6
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession7
 * @property {Campaign6} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ApplicationWillBecomeInteractive
 * @property {any | null} [addToOrderMode] -
 * @property {App7} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession7} [shoppingSession] -
 */
/**
 * @typedef App8
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign7
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession8
 * @property {Campaign7} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef CancelledApplePayCheckout
 * @property {any | null} [addToOrderMode] -
 * @property {App8} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession8} [shoppingSession] -
 */
/**
 * @typedef App9
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments4
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen1
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign8
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession9
 * @property {Campaign8} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef CancelledOrder
 * @property {App9} [app] -
 * @property {Experiments4} [experiments] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {string} [orderId] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {Screen1} [screen] -
 * @property {ShoppingSession9} [shoppingSession] -
 * @property {string} [userId] -
 */
/**
 * @typedef CommitSha4
 * @property {string} [default] -
 */
/**
 * @typedef App10
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha4} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments5
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign9
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession10
 * @property {Campaign9} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef CancelledSubscription
 * @property {any | null} [addToOrderMode] -
 * @property {App10} [app] -
 * @property {Experiments5} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [processingSubscription] -
 * @property {ShoppingSession10} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 */
/**
 * @typedef CommitSha5
 * @property {string} [default] -
 */
/**
 * @typedef App11
 * @property {CommitSha5} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments6
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen2
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign10
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession11
 * @property {Campaign10} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef CapturedServiceAreaEmail
 * @property {any | null} [addToWeeklyMode] -
 * @property {App11} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [context] -
 * @property {any | null} [email] -
 * @property {Experiments6} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {Screen2} [screen] -
 * @property {ShoppingSession11} [shoppingSession] -
 * @property {any | null} [tags] -
 * @property {any | null} [vendor] -
 * @property {any | null} [zip] -
 */
/**
 * @typedef ProductsItem
 * @property {string | null} [category] - product category of the product being viewed
 * @property {string} [coupon] - coupon applied to the product being viewed
 * @property {string} [id] - unique databaseId of the product being viewed
 * @property {string} [imageUrl] - imageURL of the product being viwed
 * @property {string} [name] - name of the product being viwed
 * @property {number} [price] - price of the product being viewed
 * @property {string} [producer] - producer of the product being viewed
 * @property {number} [quantity] - quantity of the product being viewed
 * @property {string | null} [subCategory] - product subcategory of the product being viewed
 * @property {string} [url] - url of the product being viewed
 * @property {number} [value] - total value of the product after accounting for quantity
 */
/**
 * @typedef CartViewed
 * @property {string} [availableProducts] - productIds for the products available
 * @property {string} [basketId] - basketId of the cart being viewed
 * @property {string} [cartCutOff] - Cart cutoff date
 * @property {number} [credits] - value of credits applied to cart total
 * @property {number} [deliveryFee] - value of delivery fee in cart
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {number} [discount] - value of applied discount at cart
 * @property {string} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {number} [goodJobsFees] - value of good jobs fee applied to cart
 * @property {boolean} [loggedIn] - Boolean value for if the user is loggedIn or not
 * @property {boolean} [masquerading] - Boolean value for if the use is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {ProductsItem[]} [products] - product details array
 * @property {number} [subtotal] - Total of all products without taxes, fees, or discounts applied
 * @property {number} [totalFees] - Total of fees in cart
 * @property {string} [unavailableProducts] - productIds for the unavailable products in cart
 * @property {boolean} [underMinimum] - boolean for if cart value is under the $30 threshold
 * @property {string} [userId] -
 */
/**
 * @typedef App12
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign11
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession12
 * @property {Campaign11} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ChangedDay
 * @property {any | null} [addToOrderMode] -
 * @property {App12} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [index] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession12} [shoppingSession] -
 * @property {any | null} [source] -
 */
/**
 * @typedef App13
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign12
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession13
 * @property {Campaign12} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ChangedNotificationSetting
 * @property {any | null} [addToOrderMode] -
 * @property {App13} [app] -
 * @property {any | null} [enabled] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession13} [shoppingSession] -
 * @property {any | null} [source] -
 */
/**
 * @typedef App14
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign13
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession14
 * @property {Campaign13} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ChangedPaymentMethod
 * @property {any | null} [addToOrderMode] -
 * @property {App14} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {any | null} [selectedPaymentMethod] -
 * @property {ShoppingSession14} [shoppingSession] -
 */
/**
 * @typedef CommitSha6
 * @property {string} [default] -
 */
/**
 * @typedef App15
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha6} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments7
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign14
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession15
 * @property {Campaign14} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ChangedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {App15} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [context] -
 * @property {Experiments7} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [id] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [name] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [price] -
 * @property {any | null} [productId] -
 * @property {any | null} [productScheduleId] -
 * @property {any | null} [quantity] -
 * @property {any | null} [quantityAfter] -
 * @property {any | null} [quantityBefore] -
 * @property {any | null} [resultCount] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession15} [shoppingSession] -
 * @property {any | null} [shouldSubscribe] -
 * @property {any | null} [sku] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha7
 * @property {string} [default] -
 */
/**
 * @typedef App16
 * @property {CommitSha7} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Products
 * @property {any | null} [available] -
 * @property {any | null} [totalPrice] -
 * @property {any | null} [unavailable] -
 */
/**
 * @typedef Basket
 * @property {Products} [products] -
 */
/**
 * @typedef Experiments8
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Product
 * @property {any | null} [id] -
 * @property {any | null} [price] -
 */
/**
 * @typedef Screen3
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign15
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession16
 * @property {Campaign15} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef ChangedShoppingDay
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addToWeeklyMode] -
 * @property {any | null} [addonValue] -
 * @property {App16} [app] -
 * @property {Basket} [basket] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {any | null} [context] -
 * @property {any | null} [contextAttribution] -
 * @property {any | null} [createNewWeeklie] -
 * @property {any | null} [day] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments8} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [fulfillment_days_in_basket] -
 * @property {any | null} [has_add_to_order_button] -
 * @property {any | null} [inspire-slug] -
 * @property {any | null} [isEmpty] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [pageName] -
 * @property {any | null} [pageTags] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {Product} [product] -
 * @property {any | null} [productId] -
 * @property {Screen3} [screen] -
 * @property {ShoppingSession16} [shoppingSession] -
 * @property {any | null} [site] -
 * @property {any | null} [status] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha8
 * @property {string} [default] -
 */
/**
 * @typedef App17
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha8} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments9
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen4
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign16
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession17
 * @property {Campaign16} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef CheckedServiceArea
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addToWeeklyMode] -
 * @property {App17} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [context] -
 * @property {Experiments9} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {Screen4} [screen] -
 * @property {ShoppingSession17} [shoppingSession] -
 * @property {any | null} [success] -
 * @property {any | null} [tags] -
 * @property {any | null} [vendor] -
 * @property {any | null} [zip] -
 * @property {any | null} [zipCode] -
 * @property {any | null} [zip_code] -
 */
/**
 * @typedef App18
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef DeliveryDetails
 * @property {string} [address] -
 * @property {string} [address2] -
 * @property {boolean} [canLeaveAtDoor] -
 * @property {string} [city] -
 * @property {string} [deliveryInstructions] -
 * @property {string} [giftMessage] -
 * @property {string} [giftRecipientName] -
 * @property {string} [giftRecipientPhone] -
 * @property {boolean} [isGift] -
 * @property {number} [lat] -
 * @property {number} [lon] -
 * @property {string} [state] -
 * @property {string} [zip] -
 * @property {string} [zipCode] -
 */
/**
 * @typedef DevlieryDetailsItem
 * @property {string} [address] - street address of the order
 * @property {string} [address2] - street number of the order
 * @property {boolean} [canLeaveAtDoor] - boolean for if delivery can be left at the door
 * @property {string} [city] - city of the delivery address
 * @property {string} [deliveryInstructions] - any instructions left for the delivery
 * @property {boolean} [isGift] - boolean for if the delivery is a gift
 * @property {string} [lat] - lattitude of the delivery address
 * @property {string} [lon] - longitude of the delivery
 * @property {string} [state] - state of the delivery
 * @property {string} [zip] - zip code of the delivery address
 */
/**
 * @typedef Experiments10
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef ProductsItem1
 * @property {string | null} [category] - product category of the product in the order
 * @property {string} [coupon] - product level coupon of the item being purchased
 * @property {string | null} [engine] - hat engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [id] - database id of the product in the order
 * @property {string} [imageUrl] - url of the image of the product in the order
 * @property {string | null} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string | null} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product in the order
 * @property {number} [price] - price ($) of the product in the order
 * @property {string} [producer] - producer of the product in the order
 * @property {number} [quantity] - quantity of the product being purchased
 * @property {string} [sku] - sku of the product in the order
 * @property {string | null} [subCategory] - subcategory of the product in the order
 * @property {string} [url] - url of the product in the order
 * @property {number} [value] - total value of the product after accounting for quantity
 */
/**
 * @typedef PromoCodesItem
 * @property {string} [code] -
 * @property {string} [label] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Screen5
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef CheckoutCompleted
 * @property {boolean} [addingToOrder] -
 * @property {number} [addonValue] -
 * @property {App18} [app] -
 * @property {boolean} [containsSubscription] - boolean for if the order contains a subscription product
 * @property {string} [coupon] - order coupon redeemed with the transaction
 * @property {number} [couponDiscount] - amount of discount from coupon
 * @property {number} [credits] - total credits applied to the order
 * @property {number} [delivery] -
 * @property {string} [deliveryDate] - selected date of the delivery
 * @property {string} [deliveryDay] -
 * @property {DeliveryDetails} [deliveryDetails] -
 * @property {string} [deliveryEndWindow] - date and time for the end of the delivery window
 * @property {number} [deliveryFee] - value of the delivery fee applied to the order
 * @property {string} [deliveryMethod] - selected method of the delivery
 * @property {number} [deliveryPrice] -
 * @property {string} [deliveryStartWindow] - date and time for the start of the delivery window
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {DevlieryDetailsItem[]} [devlieryDetails] - delivery details array
 * @property {number} [discount] -
 * @property {Experiments10} [experiments] -
 * @property {number} [fees] -
 * @property {string} [foodShed] -
 * @property {string} [foodshed] -
 * @property {number} [goodJobsFees] - total value of the good jobs fee
 * @property {string} [hashedEmail] -
 * @property {boolean} [isMembershipOrder] - true if the order was placed by an active member
 * @property {boolean} [isPreorder] - boolean value for if the order contains a thanksgiving preorder
 * @property {number} [itemCount] - Count of individual items in the order
 * @property {boolean} [loggedIn] - boolean for if the user was loggedin
 * @property {boolean} [masquerading] - boolean for if the user is an employee
 * @property {number} [orderCount] -
 * @property {string} [orderCutOffDate] - Date and time of cut off for editing order
 * @property {string} [orderCutoff] -
 * @property {string} [orderId] - unique order ID
 * @property {number} [orderTotal] - revenue ($) associated with the order
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {boolean} [processingSubscription] -
 * @property {number} [productCount] - number of individual SKUs in order
 * @property {ProductsItem1[]} [products] - product details array
 * @property {PromoCodesItem[]} [promoCodes] -
 * @property {number} [referralDiscount] - amount of discount from the referral
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {Screen5} [screen] -
 * @property {number} [subscriptionDiscount] - amount of discount from the subscription
 * @property {number} [subtotal] - total of all products value without taxes, fees, and discounts applied
 * @property {number} [subtotalAfter] -
 * @property {number} [subtotalBefore] -
 * @property {number} [tax] - total taxes applied to the order
 * @property {string} [tenderType] - type of payment chosen for order
 * @property {number} [total] -
 * @property {number} [totalCheckoutValue] -
 * @property {number} [totalDiscount] - total discount applied to the order
 * @property {number} [totalFees] - total fees applied to the order: tax, delivery, and good jobs fees
 * @property {string} [userId] -
 */
/**
 * @typedef ProductsItem2
 * @property {string | null} [category] - product category of the product in the order
 * @property {string} [coupon] - Product level coupon being applied to the order
 * @property {string | null} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [id] - unique database Id of the product in the order
 * @property {string} [imageUrl] - image url of the product in the order
 * @property {string | null} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string | null} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product in the order
 * @property {number} [price] - price ($) of the product in the order
 * @property {string} [producer] - producer of the product in the order
 * @property {number} [quantity] - quantity of the product in the order
 * @property {string | null} [section] - populate with null (market section being viewed)
 * @property {string} [sku] - sku of the product in the order
 * @property {string | null} [subCategory] - sub productCategory of the product in the order
 * @property {string} [url] - url of the product in the order
 * @property {number} [value] - total value of the product after accounting for quantity
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [basketId] - basketId for the checkout process
 * @property {boolean} [containsSubscription] - boolean for if a checkout contains a subscription product
 * @property {string} [coupon] - name of the transaction level coupon applied to the order
 * @property {number} [couponDiscount] - amount of discount from coupon
 * @property {number} [credits] - total credits applied to this basket
 * @property {string} [deliveryDate] - delivery date selected for this checkout
 * @property {number} [deliveryFee] - delivery fee applied to checkout
 * @property {string} [deliveryMethod] - selected delivery method for checkout
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {string} [feature] - he individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {boolean} [isMembershipOrder] - true if the order was placed by an active member
 * @property {number} [itemCount] - amount of individual items in the order
 * @property {boolean} [loggedIn] - Boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee or not
 * @property {string} [orderId] - orderID tied to the checkout
 * @property {number} [productCount] - amount of individual products in the order
 * @property {ProductsItem2[]} [products] - product details array
 * @property {number} [referralDiscount] - amount of discount from referral
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {number} [subscriptionDiscount] - amount of discount from the subscription
 * @property {number} [subtotal] - total value of all products without taxes, fees, or discounts applied
 * @property {number} [tax] - total tax applied to the order
 * @property {number} [totalDiscount] - total discount applied to the order
 * @property {number} [totalFees] - total value for good job fee, delivery, and tax added together
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [basketId] - id of cart that has the product being added to it
 * @property {string} [deliveryWindow] - delivery window selected in the checkout process
 * @property {boolean} [isGift] - boolean value for if the checkout involves a gift
 * @property {boolean} [isMembershipOrder] - true if the order was placed by an active member
 * @property {boolean} [loggedIn] - boolean value for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if a user is an employee or not
 * @property {string} [paymentMethod] - payment method selected upon completed the payment stage
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [step] - Step number tied to the step completed
 * @property {string} [stepName] - name of the step completed
 */
/**
 * @typedef App19
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef DeliveryDetailsItem
 * @property {string} [address] - street address of the delivery
 * @property {string} [address2] - apartment number of the delivery address
 * @property {boolean} [canLeaveAtDoor] - boolean value for if the delivery can be left at the door
 * @property {string} [city] - city of the delivery
 * @property {string} [deliveryInstructions] - any specific instructions left for the delivery
 * @property {boolean} [isGift] - boolean value for if the delivery is a gift
 * @property {string} [lat] - lattitude of the delivery
 * @property {string} [lon] - longitude of the delivery
 * @property {string} [state] - state of the delivery
 * @property {string} [zip] - zip code of the delivery
 */
/**
 * @typedef Experiments11
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef ProductsItem3
 * @property {string | null} [category] - category of the product in the order
 * @property {string} [coupon] - product level coupon associated with the order
 * @property {string | null} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [id] - database id of the product
 * @property {string} [imageUrl] - url of the image of the product
 * @property {string | null} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string | null} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product in the order
 * @property {number} [price] - price($) of the product in the order
 * @property {string} [producer] - producer of the product in the order
 * @property {number} [quantity] - Quantity of the product in the order
 * @property {string} [sku] - sku of the product in the order
 * @property {string | null} [subCategory] - subcategory of the product in the order
 * @property {string} [url] - url of the product
 * @property {number} [value] - total value associated to the product after accounting for quantity
 */
/**
 * @typedef PromoCodesItem1
 * @property {string} [code] -
 * @property {string} [label] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef Screen6
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef CheckoutUpdated
 * @property {boolean} [addingToOrder] -
 * @property {number} [addonValue] -
 * @property {App19} [app] -
 * @property {boolean} [containsSubscription] - boolean value for if the order contains a subscription product
 * @property {string} [coupon] - order level promo code
 * @property {number} [couponDiscount] - amount of discount from the order level coupon
 * @property {number} [credits] - total value of credits tied to the order
 * @property {number} [daysBeforeFulfillment] -
 * @property {number} [delivery] -
 * @property {string} [deliveryDate] - scheduled date of the delivery
 * @property {string} [deliveryDay] -
 * @property {DeliveryDetailsItem[]} [deliveryDetails] - delivery details array
 * @property {string} [deliveryEndWindow] - Date and time for end of the delivery window
 * @property {number} [deliveryFee] - delivery fee applied to the order
 * @property {string} [deliveryMethod] - chosen delivery method for the delivery
 * @property {number} [deliveryPrice] -
 * @property {string} [deliveryStartWindow] - Date and time for the start of the delivery window
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {number} [discount] -
 * @property {Experiments11} [experiments] -
 * @property {number} [fees] -
 * @property {string} [foodShed] -
 * @property {string} [foodshed] -
 * @property {number} [goodJobsFee] - total value of the good jobs fee
 * @property {string} [hashedEmail] -
 * @property {boolean} [isMembershipOrder] - true if the order was placed by an active member
 * @property {number} [itemCount] - count of individual items in the order
 * @property {boolean} [loggedIn] - boolean value for if the user is logged in
 * @property {boolean} [masquerading] - boolean for if the user is an employee
 * @property {number} [orderCount] -
 * @property {string} [orderCutOffDate] - Date and time of cut off for editing the order
 * @property {string} [orderCutoff] -
 * @property {string} [orderId] - unique identifier of the order
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {boolean} [processingSubscription] -
 * @property {number} [productCount] - count of individual products in the order
 * @property {ProductsItem3[]} [products] - product details array
 * @property {PromoCodesItem1[]} [promoCodes] -
 * @property {number} [quantityAfter] - quantity of products in the order after updating the order
 * @property {number} [quantityBefore] - quantity of products purchased before updating order
 * @property {number} [referralDiscount] - amount of discount from referral
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {Screen6} [screen] -
 * @property {number} [subscriptionDiscount] - amount of discount from subscription
 * @property {number} [subtotal] - total of all products without taxes, fees, and discounts applied
 * @property {number} [subtotalAfter] - subtotal after updating the cart
 * @property {number} [subtotalBefore] - subtotal before updating the cart
 * @property {number} [tax] - total tax applied to the order
 * @property {number} [total] -
 * @property {number} [totalCheckoutValue] -
 * @property {number} [totalDiscount] - total discount applied to the order
 * @property {number} [totalFees] - tax,delivery,and good jobs fees applied to the order
 * @property {string} [userId] -
 */
/**
 * @typedef App20
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign17
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession18
 * @property {Campaign17} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ChoseDeliveryTime
 * @property {any | null} [addToOrderMode] -
 * @property {App20} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession18} [shoppingSession] -
 */
/**
 * @typedef CommitSha9
 * @property {string} [default] -
 */
/**
 * @typedef App21
 * @property {CommitSha9} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments12
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Feature
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Screen7
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign18
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession19
 * @property {Campaign18} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef User
 * @property {number} [orderCount] -
 */
/**
 * @typedef ClickedHomepageFeature
 * @property {any | null} [addToWeeklyMode] -
 * @property {App21} [app] -
 * @property {Experiments12} [experiments] -
 * @property {Feature} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {Screen7} [screen] -
 * @property {ShoppingSession19} [shoppingSession] -
 * @property {User} [user] -
 */
/**
 * @typedef App22
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign19
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession20
 * @property {Campaign19} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef CompletedApplePayCheckout
 * @property {any | null} [addToOrderMode] -
 * @property {App22} [app] -
 * @property {any | null} [error] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession20} [shoppingSession] -
 * @property {any | null} [status] -
 */
/**
 * @typedef CommitSha10
 * @property {string} [default] -
 */
/**
 * @typedef App23
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha10} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments13
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign20
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession21
 * @property {Campaign20} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef CreatedAccount
 * @property {any | null} [addToOrderMode] -
 * @property {App23} [app] -
 * @property {any | null} [category] -
 * @property {Experiments13} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [provider] -
 * @property {any | null} [section] -
 * @property {ShoppingSession21} [shoppingSession] -
 */
/**
 * @typedef CommitSha11
 * @property {string} [default] -
 */
/**
 * @typedef App24
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha11} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments14
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign21
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession22
 * @property {Campaign21} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef CreatedSubscription
 * @property {any | null} [addToOrderMode] -
 * @property {App24} [app] -
 * @property {Experiments14} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession22} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 */
/**
 * @typedef CtaClicked
 * @property {string} [destination] - Url destination of clicking the CTA
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean | null} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lived in (e.g. welcome to good eggs, Cream Co. Meats, Breakfast Made Easy)
 * @property {string} [moduleType] - the non-consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc...)
 * @property {string} [pageUrl] - what url is the CTA button on
 * @property {string} [text] - the text/name of the CTA
 * @property {string} [type] - the type of CTA clicked (button, interstitial, banner)
 */
/**
 * @typedef CtaDismissed
 * @property {string} [destination] - url destination of clicking the CTA
 * @property {string} [feature] - the individual page/screen (home, basket) of where a user interaction occurred
 * @property {string} [moduleName] - the consumer facing name or title of the module that the click action lives in (e.g welcome to good eggs, new & peak season, etc)
 * @property {string} [moduleType] - the non consumer facing of the module or content type that the click action lives in (e.g. banner Cta, carousel item, featured content tile)
 * @property {string} [pageUrl] - url path of where the action occurred
 * @property {string} [text] - the text/name of the CTA (i.e. cta text on button)
 * @property {string} [type] - the type of CTA element (button, banner, image, tile)
 */
/**
 * @typedef DeliveryAreaChecked
 * @property {boolean} [deliveryEligible] - boolean for if the zip code is eligible for delivery or not
 * @property {boolean} [initialCheck] - boolean for if user is performing initial check or not
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [pageUrl] - Url of page where check is performed
 * @property {string} [zipCode] - zip code entered by user
 */
/**
 * @typedef DeliveryAreaUpdated
 * @property {string} [deliveryEligible] - boolean for if the zip code is eligible for delivery or not
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string | null} [regionNameAfter] - current region user is shopping in after update
 * @property {string | null} [regionNameBefore] - previous region user was shopping in before update
 * @property {string} [zipCodeAfter] - new zip code user was shopping in
 * @property {string} [zipCodeBefore] - previous zip code user was shopping in
 */
/**
 * @typedef App25
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments15
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen8
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign22
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession23
 * @property {Campaign22} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef Cutoff
 * @property {number} [dateDelta] -
 * @property {string} [time] -
 */
/**
 * @typedef WindowPreviouslyChosen
 * @property {Cutoff} [cutoff] -
 * @property {number} [cutoffDateDelta] -
 * @property {string} [cutoffTime] -
 * @property {number} [dateDelta] -
 * @property {string} [dayOfWeek] -
 * @property {boolean} [hasPreorderWindows] -
 * @property {boolean} [hasUnavailableProducts] -
 * @property {string[]} [orderIds] -
 * @property {string} [status] -
 * @property {string} [window] -
 */
/**
 * @typedef Cutoff1
 * @property {number} [dateDelta] -
 * @property {string} [time] -
 */
/**
 * @typedef WindowsViewedItem
 * @property {Cutoff1} [cutoff] -
 * @property {number} [cutoffDateDelta] -
 * @property {string} [cutoffTime] -
 * @property {number} [dateDelta] -
 * @property {string} [dayOfWeek] -
 * @property {boolean} [hasPreorderWindows] -
 * @property {boolean} [hasUnavailableProducts] -
 * @property {string[]} [orderIds] -
 * @property {string} [status] -
 * @property {string} [window] -
 */
/**
 * @typedef DeliveryWindowOptionsViewed
 * @property {App25} [app] -
 * @property {Experiments15} [experiments] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {Screen8} [screen] -
 * @property {ShoppingSession23} [shoppingSession] -
 * @property {WindowPreviouslyChosen} [windowPreviouslyChosen] -
 * @property {WindowsViewedItem[]} [windowsViewed] -
 */
/**
 * @typedef App26
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments16
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen9
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign23
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession24
 * @property {Campaign23} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef Cutoff2
 * @property {number} [dateDelta] -
 * @property {string} [time] -
 */
/**
 * @typedef WindowChosen
 * @property {Cutoff2} [cutoff] -
 * @property {number} [cutoffDateDelta] -
 * @property {string} [cutoffTime] -
 * @property {number} [dateDelta] -
 * @property {string} [dayOfWeek] -
 * @property {boolean} [hasPreorderWindows] -
 * @property {boolean} [hasUnavailableProducts] -
 * @property {string[]} [orderIds] -
 * @property {string} [status] -
 * @property {string} [window] -
 */
/**
 * @typedef DeliveryWindowSelected
 * @property {App26} [app] -
 * @property {Experiments16} [experiments] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {Screen9} [screen] -
 * @property {ShoppingSession24} [shoppingSession] -
 * @property {WindowChosen} [windowChosen] -
 */
/**
 * @typedef CommitSha12
 * @property {string} [default] -
 */
/**
 * @typedef App27
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha12} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments17
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign24
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession25
 * @property {Campaign24} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef DisallowedFulfillmentOffer
 * @property {any | null} [action] -
 * @property {any | null} [addToOrderMode] -
 * @property {App27} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [endAt] -
 * @property {Experiments17} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [issue] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {string} [productId] -
 * @property {any | null} [reason] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession25} [shoppingSession] -
 * @property {any | null} [startAt] -
 * @property {any | null} [subcategory] -
 */
/**
 * @typedef EmailCaptured
 * @property {string} [email] - email address entered
 * @property {string} [formName] - name of the page, screen or form where the email was entered (service area, waitlist, etc)
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [zipCode] - zip code entered by user
 */
/**
 * @typedef App28
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign25
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession26
 * @property {Campaign25} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef EnteredForeground
 * @property {any | null} [addToOrderMode] -
 * @property {App28} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession26} [shoppingSession] -
 */
/**
 * @typedef CommitSha13
 * @property {string} [default] -
 */
/**
 * @typedef App29
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha13} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments18
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign26
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession27
 * @property {Campaign26} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef EnteredPaymentDetails
 * @property {any | null} [addToOrderMode] -
 * @property {App29} [app] -
 * @property {Experiments18} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession27} [shoppingSession] -
 * @property {any | null} [updating] -
 */
/**
 * @typedef CommitSha14
 * @property {string} [default] -
 */
/**
 * @typedef App30
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha14} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments19
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign27
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession28
 * @property {Campaign27} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef FailedCheckout
 * @property {any | null} [addToOrderMode] -
 * @property {App30} [app] -
 * @property {Experiments19} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [message] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession28} [shoppingSession] -
 * @property {any | null} [type] -
 */
/**
 * @typedef CommitSha15
 * @property {string} [default] -
 */
/**
 * @typedef App31
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha15} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context1
 * @property {string} [feature] -
 */
/**
 * @typedef Experiments20
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign28
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession29
 * @property {Campaign28} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef FavoritedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {App31} [app] -
 * @property {any | null} [category] -
 * @property {Context1} [context] -
 * @property {Experiments20} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {any | null} [section] -
 * @property {ShoppingSession29} [shoppingSession] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha16
 * @property {string} [default] -
 */
/**
 * @typedef App32
 * @property {CommitSha16} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments21
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Timing
 * @property {number} [connectEnd] -
 * @property {number} [connectStart] -
 * @property {number} [domComplete] -
 * @property {number} [domContentLoadedEventEnd] -
 * @property {number} [domContentLoadedEventStart] -
 * @property {number} [domInteractive] -
 * @property {number} [domLoading] -
 * @property {number} [domainLookupEnd] -
 * @property {number} [domainLookupStart] -
 * @property {number} [fetchStart] -
 * @property {number} [firstContentfulPaintTime] -
 * @property {number} [firstImagePaintTime] -
 * @property {number} [firstMeaningfulPaintTime] -
 * @property {number} [firstPaintTime] -
 * @property {number} [loadEventEnd] -
 * @property {number} [loadEventStart] -
 * @property {number} [msFirstPaint] -
 * @property {number} [navigationStart] -
 * @property {number} [redirectEnd] -
 * @property {number} [redirectStart] -
 * @property {number} [requestStart] -
 * @property {number} [responseEnd] -
 * @property {number} [responseStart] -
 * @property {number} [secureConnectionStart] -
 * @property {number} [timeOrigin] -
 * @property {number} [timeToContentfulPaint] -
 * @property {number} [timeToDOMContentFlushed] -
 * @property {number} [timeToFirstInteractive] -
 * @property {number} [timeToNonBlankPaint] -
 * @property {number} [unloadEventEnd] -
 * @property {number} [unloadEventStart] -
 * @property {number} [workerStart] -
 * @property {number} [x5BatteryLoadStart] -
 * @property {number} [x5CpuUsageFirstFrame] -
 * @property {number} [x5MemoryLoadStart] -
 * @property {number} [x5TrafficLoadStart] -
 */
/**
 * @typedef Performance
 * @property {number} [endTime] -
 * @property {number} [startTime] -
 * @property {Timing} [timing] -
 * @property {number} [wait] -
 */
/**
 * @typedef Screen10
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign29
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession30
 * @property {Campaign29} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef FinishedNavigation
 * @property {any | null} [addToWeeklyMode] -
 * @property {any | null} [addingToOrder] -
 * @property {any | null} [addonValue] -
 * @property {App32} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {any | null} [context] -
 * @property {any | null} [createNewWeeklie] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments21} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [isEmpty] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [pageName] -
 * @property {Performance} [performance] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {Screen10} [screen] -
 * @property {ShoppingSession30} [shoppingSession] -
 * @property {any | null} [status] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef App33
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign30
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession31
 * @property {Campaign30} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef FinishedSpinning
 * @property {any | null} [addToOrderMode] -
 * @property {App33} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {any | null} [screen] -
 * @property {ShoppingSession31} [shoppingSession] -
 * @property {any | null} [totalDuration] -
 * @property {any | null} [visibleDuration] -
 */
/**
 * @typedef FulfillmentOptionSoldOut
 * @property {any | null} [foodhubSlug] -
 * @property {string} [orderCutoff] -
 * @property {any | null} [soldOutAt] -
 * @property {any | null} [windowEndAt] -
 * @property {any | null} [windowStartAt] -
 */
/**
 * @typedef CommitSha17
 * @property {string} [default] -
 */
/**
 * @typedef App34
 * @property {CommitSha17} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments22
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen11
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign31
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession32
 * @property {Campaign31} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef HitSearch
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addToWeeklyMode] -
 * @property {App34} [app] -
 * @property {Experiments22} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [nextAction] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [positionOnPage] -
 * @property {any | null} [resultCount] -
 * @property {Screen11} [screen] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [selectedProductId] -
 * @property {any | null} [selectedProductName] -
 * @property {any | null} [selectedProductVendor] -
 * @property {ShoppingSession32} [shoppingSession] -
 */
/**
 * @typedef ItemBilled
 * @property {string | null} [category] - product category of the item being purchased
 * @property {string} [coupon] - coupon code associated with the item being purchased
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {string} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {string} [imageUrl] - image url of the item being purchased
 * @property {boolean} [loggedIn] - boolean value for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if a user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the item being purchased
 * @property {string} [orderId] - Order ID tied to order which this item was purchased
 * @property {number} [price] - price ($) of the item bring purchased
 * @property {string} [producer] - producer of the item being purchased
 * @property {string} [productId] - databaseID of the product being viewed
 * @property {number} [quanitity] - Quantity of the item being purchased
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [sku] - sku of the product being purchased
 * @property {string | null} [subCategory] - subcategory of the item being purchased
 * @property {string} [url] - url of the product being purchased
 * @property {number} [value] - Total value of the product after quantity without taxes, fees, and discounts applied
 */
/**
 * @typedef ItemCompleted
 * @property {string} [basketID] - unique basket Id
 * @property {string | null} [category] - populate with null (product category being viewed)
 * @property {string} [coupon] - coupon code associated with a product (e.g MAY DEALS 3)
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {string} [imageUrl] - image url of the product
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
feature = feature used to add product to cart (e.g Re-order or market)
 * @property {string} [name] - name of the product being viewed
 * @property {number} [price] - price ($) of the product being viewed
 * @property {string} [producer] - Name of product producer (E.g From Our Farmers)
 * @property {string} [productId] - database id of the product being viewed
 * @property {number} [quantity] - quantity of a product
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [sku] - sku of the product being viewed
 * @property {string | null} [subCategory] - populate with null (product subcategory being viewed)
 * @property {string} [url] - URL of the product page
 * @property {number} [value] - Total value of the product after quantity without taxes, fees, and discounts applied
 */
/**
 * @typedef ItemUpdated
 * @property {string | null} [category] - populate with null (product category being viewed)
 * @property {string} [coupon] - coupon code associated with a product (e.g MAY DEALS 3)
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {number} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [imageUrl] - image url of the product
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
feature = feature used to add product to cart (e.g Re-order or market)
 * @property {string} [name] - name of the product being viewed
 * @property {string} [orderId] - unique order id
 * @property {number} [price] - price ($) of the product being viewed
 * @property {string} [producer] - Name of product producer (E.g From Our Farmers)
 * @property {string} [productId] - database id of the product being viewed
 * @property {number} [quantity] - quantity of a product
 * @property {number} [quantityAfter] - quantity of the product after updating
 * @property {number} [quantityBefore] - quantity of the product before updating
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [sku] - sku of the product being viewed
 * @property {string | null} [subCategory] - populate with null (product subcategory being viewed)
 * @property {string} [url] - URL of the product page
 * @property {number} [value] - Total value of the product after quantity without taxes, fees, and discounts applied
 * @property {number} [valueAfter] - Total value of the product after quantity without taxes, fees, and discounts applied after updating
 * @property {number} [valueBefore] - Total value of the product after quantity without taxes, fees, and discounts applied before updating
 */
/**
 * @typedef App35
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef ShoppingSession33
 * @property {string} [id] -
 */
/**
 * @typedef LaunchedCleanInstall
 * @property {any | null} [addToOrderMode] -
 * @property {App35} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession33} [shoppingSession] -
 */
/**
 * @typedef CommitSha18
 * @property {string} [default] -
 */
/**
 * @typedef App36
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha18} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments23
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign32
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession34
 * @property {Campaign32} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef LeftProductInBasket
 * @property {any | null} [addToOrderMode] -
 * @property {App36} [app] -
 * @property {any | null} [availabilityOnFulfillmentDay] -
 * @property {Experiments23} [experiments] -
 * @property {any | null} [feature] -
 * @property {string} [foodhubSlug] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [fulfillmentId] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [price] -
 * @property {any | null} [productId] -
 * @property {any | null} [quantity] -
 * @property {ShoppingSession34} [shoppingSession] -
 * @property {any | null} [totalValue] -
 */
/**
 * @typedef CommitSha19
 * @property {string} [default] -
 */
/**
 * @typedef App37
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha19} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments24
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign33
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession35
 * @property {Campaign33} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef LoggedIn
 * @property {any | null} [addToOrderMode] -
 * @property {App37} [app] -
 * @property {any | null} [category] -
 * @property {Experiments24} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {string} [productId] -
 * @property {any | null} [provider] -
 * @property {ShoppingSession35} [shoppingSession] -
 */
/**
 * @typedef CommitSha20
 * @property {string} [default] -
 */
/**
 * @typedef App38
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha20} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments25
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign34
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession36
 * @property {Campaign34} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef LoggedOut
 * @property {any | null} [addToOrderMode] -
 * @property {App38} [app] -
 * @property {Experiments25} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [page] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession36} [shoppingSession] -
 */
/**
 * @typedef MembershipModalClicked
 * @property {string} [basketId] - id of the cart
 * @property {boolean} [masquerading] - boolean value for if the user is an employee or not
 * @property {string} [orderId] - orderID tied to the basket if it exists
 * @property {string} [regionName] - Name of the region tied to where the order is going to be placed
 * @property {string} [userId] - Id of the user
 */
/**
 * @typedef CommitSha21
 * @property {string} [default] -
 */
/**
 * @typedef App39
 * @property {CommitSha21} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments26
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen12
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign35
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession37
 * @property {Campaign35} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef MissedSearch
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addToWeeklyMode] -
 * @property {App39} [app] -
 * @property {Experiments26} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [resultCount] -
 * @property {Screen12} [screen] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {ShoppingSession37} [shoppingSession] -
 */
/**
 * @typedef ModalViewed
 * @property {string} [ctaText] - CTA text on primary button shown to users
 * @property {Record<string, any>} [experiments] - laboratory experiment group
 * @property {string} [feature] - the individual page/screen (home, basket, PDP) of where a user interaction occurred
 * @property {boolean} [loggedIn] - boolean value for if a user is logged in
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [modalName] - name of the modal (sign-up, sign-in)
 * @property {string} [pageUrl] - url path the modal surfaced on
 */
/**
 * @typedef App40
 * @property {string} [name] -
 */
/**
 * @typedef Campaign36
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 */
/**
 * @typedef NotificationSent
 * @property {App40} [app] -
 * @property {Campaign36} [campaign] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [medium] -
 * @property {any | null} [platform] -
 * @property {any | null} [source] -
 */
/**
 * @typedef App41
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign37
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession38
 * @property {Campaign37} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef OpenedUrl
 * @property {any | null} [addToOrderMode] -
 * @property {App41} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession38} [shoppingSession] -
 * @property {any | null} [url] -
 */
/**
 * @typedef DeliveryDetailsItem1
 * @property {string} [address] - address of the delivery
 * @property {string} [address2] - street number of the delivert
 * @property {boolean} [canLeaveAtDoor] - boolean for if the delivery can be left at the door
 * @property {string} [city] - city of the delivery
 * @property {string} [deliveryInstructions] - any instructions left for the delivery
 * @property {boolean} [isGift] - boolean for if the delivery is a gift
 * @property {string} [lat] - lattitude of the delivery
 * @property {string} [lon] - longitude for the delivery
 * @property {string} [state] - state of the delivery
 * @property {string} [zip] - zip of order delivery
 */
/**
 * @typedef ProductsItem4
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {number} [price] -
 * @property {number} [quantity] -
 */
/**
 * @typedef PromoCodesItem2
 * @property {boolean} [autoReapply] -
 * @property {string} [code] -
 * @property {string} [label] -
 * @property {string} [type] -
 * @property {number} [value] -
 */
/**
 * @typedef OrderBilled
 * @property {boolean} [containsSubscription] - boolean value for if order contains a subscription
 * @property {string} [coupon] - order level promo code
 * @property {number} [couponDiscount] - amount of discount from coupon
 * @property {number} [credits] - total credits applied to the order
 * @property {number} [delivery] -
 * @property {string} [deliveryDate] - date scheduled for the delivery
 * @property {DeliveryDetailsItem1[]} [deliveryDetails] - delivery details array
 * @property {string} [deliveryEndWindow] - date and time of end of the delivery window
 * @property {number} [deliveryFee] - delivery fee associated the order
 * @property {string} [deliveryMethod] - delivery method chosen for the order
 * @property {string} [deliveryStartWindow] - Date and time of start of delivery window
 * @property {string} [deliveryWindow] - window selected for delivery using the 24 hour time scale, a selection of 5pm-9pm would populate as "1700 - 2100" (examples: 1700-2100, 1300-1700)
 * @property {number} [discount] -
 * @property {string} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {number} [goodJobFees] - total good job fee applied to the order
 * @property {number} [itemCount] - amount of skus in the order
 * @property {boolean} [loggedIn] - boolean for if a user was loggedin
 * @property {boolean} [masquerading] - boolean for if the user is an employee
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {number} [orderCount] -
 * @property {string} [orderCutOffDate] - date and time for when the order is closed for updating
 * @property {string} [orderId] - unique identifier for the order
 * @property {ProductsItem4[]} [products] -
 * @property {PromoCodesItem2[]} [promoCodes] -
 * @property {number} [referralDiscount] - total amount of discount from referral
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {number} [revenue] - revenue ($) associated with the order
 * @property {number} [subscriptionDiscount] - amount of discount from subscription
 * @property {number} [subtotal] - total of all products without taxes, delivery, discounts, fees applied
 * @property {number} [tax] - total tax applied to the order
 * @property {string} [tenderType] - type of payment method used in transaction
 * @property {number} [total] -
 * @property {number} [totalDiscount] - total discount applied to the order
 * @property {number} [totalFees] - tax, delivery, and good jobs fees attributed to the order
 */
/**
 * @typedef App42
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef DeliveryDetails1
 * @property {string} [address] -
 * @property {string} [address2] -
 * @property {boolean} [canLeaveAtDoor] -
 * @property {string} [city] -
 * @property {string} [deliveryInstructions] -
 * @property {boolean} [isGift] -
 * @property {number} [lat] -
 * @property {number} [lon] -
 * @property {string} [state] -
 * @property {string} [zip] -
 * @property {string} [zipCode] -
 */
/**
 * @typedef Experiments27
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef ProductsItem5
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {number} [price] -
 * @property {number} [quantity] -
 */
/**
 * @typedef Screen13
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign38
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession39
 * @property {Campaign38} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef OrderCompleted
 * @property {boolean} [addingToOrder] -
 * @property {string} [affiliation] -
 * @property {App42} [app] -
 * @property {string} [coupon] -
 * @property {number} [credits] -
 * @property {number} [delivery] -
 * @property {string} [deliveryDay] -
 * @property {DeliveryDetails1} [deliveryDetails] -
 * @property {string} [deliveryEndWindow] -
 * @property {number} [deliveryPrice] -
 * @property {string} [deliveryStartWindow] -
 * @property {number} [discount] -
 * @property {Experiments27} [experiments] -
 * @property {string} [feature] -
 * @property {number} [fees] -
 * @property {string} [foodshed] -
 * @property {number} [itemCount] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {number} [orderCount] -
 * @property {string} [orderCutoff] -
 * @property {string} [orderId] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {boolean} [processingSubscription] -
 * @property {number} [productCount] -
 * @property {ProductsItem5[]} [products] -
 * @property {string[]} [promoCodes] -
 * @property {number} [referralDiscount] -
 * @property {number} [revenue] -
 * @property {Screen13} [screen] -
 * @property {ShoppingSession39} [shoppingSession] -
 * @property {number} [subtotal] -
 * @property {number} [tax] -
 * @property {string} [tier] -
 * @property {number} [total] -
 * @property {number} [totalCheckoutValue] -
 */
/**
 * @typedef App43
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef DeliveryDetails2
 * @property {string} [address] -
 * @property {string} [address2] -
 * @property {boolean} [canLeaveAtDoor] -
 * @property {string} [city] -
 * @property {string} [deliveryInstructions] -
 * @property {boolean} [isGift] -
 * @property {number} [lat] -
 * @property {number} [lon] -
 * @property {string} [state] -
 * @property {string} [zip] -
 * @property {string} [zipCode] -
 */
/**
 * @typedef Experiments28
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef ProductsItem6
 * @property {string} [id] -
 * @property {number} [price] -
 * @property {number} [quantity] -
 */
/**
 * @typedef Screen14
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign39
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession40
 * @property {Campaign39} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef OrderUpdated
 * @property {boolean} [addingToOrder] -
 * @property {App43} [app] -
 * @property {string} [coupon] -
 * @property {number} [credits] -
 * @property {number} [delivery] -
 * @property {string} [deliveryDay] -
 * @property {DeliveryDetails2} [deliveryDetails] -
 * @property {string} [deliveryEndWindow] -
 * @property {string} [deliveryStartWindow] -
 * @property {number} [discount] -
 * @property {Experiments28} [experiments] -
 * @property {string} [feature] -
 * @property {string} [foodshed] -
 * @property {number} [itemCount] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {number} [orderCount] -
 * @property {string} [orderCutoff] -
 * @property {string} [orderId] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {boolean} [processingSubscription] -
 * @property {number} [productCount] -
 * @property {ProductsItem6[]} [products] -
 * @property {string[]} [promoCodes] -
 * @property {number} [referralDiscount] -
 * @property {number} [revenue] -
 * @property {Screen14} [screen] -
 * @property {ShoppingSession40} [shoppingSession] -
 * @property {number} [subtotal] -
 * @property {number} [subtotalAfter] -
 * @property {number} [subtotalBefore] -
 * @property {number} [tax] -
 * @property {string} [tier] -
 * @property {number} [total] -
 */
/**
 * @typedef PageViewed
 * @property {string} [category] - the category of the page
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [name] - the name of the page
 * @property {string} [path] - path portion of the URL of the page (equivalent to canonical path which defaults to location.pathname from the DOM API)

 * @property {string} [referrer] - full URL of the previous page (equivalent to document.referrer from the DOM API)
 * @property {string} [search] - query string portion of the URL of the page (equivalent to location.search from the DOM API)
 * @property {string} [title] - title of the page (equivalent to document.title from the DOM API)
 * @property {string} [url] - ull URL of the page (first Segment looks for the canonical url. if the canonical url is not provided, Segment uses location.href from the DOM API)
 */
/**
 * @typedef CommitSha22
 * @property {string} [default] -
 */
/**
 * @typedef App44
 * @property {CommitSha22} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments29
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen15
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign40
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession41
 * @property {Campaign40} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef PartialAddress
 * @property {any | null} [address] -
 * @property {App44} [app] -
 * @property {any | null} [city] -
 * @property {Experiments29} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {Screen15} [screen] -
 * @property {ShoppingSession41} [shoppingSession] -
 * @property {any | null} [state] -
 * @property {any | null} [zip] -
 */
/**
 * @typedef ProductAdded
 * @property {string} [basketId] - basketId that the product is being added to
 * @property {string} [category] - category of the product being added
 * @property {string} [coupon] - coupon applied to the product being added
 * @property {string} [daysAvailable] - dates a product is available for delivery
 * @property {string} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {string} [imageUrl] - image url of the product being added
 * @property {string} [index] - Name of the targeted Algolia index

 * @property {boolean} [loggedIn] - boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - Name of the product being added
 * @property {string} [objectID] - Object ID of the record (same as productId)
 * @property {number} [position] - position in the product list being added to cart
 * @property {number} [price] - price ($) of the product being added
 * @property {string} [producer] - producer of the product being added
 * @property {string} [productId] - database id of the product being added
 * @property {number} [quantity] - quantity of the product being added
 * @property {string} [queryID] - Query ID returned in search results from Algolia
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [sku] - sku of the product being added
 * @property {string} [subCategory] - category of the product being added
 * @property {boolean} [underMinimum] - boolean for if the product added results in a cart that is under the cost threshold or not
 * @property {number} [value] - value of the product being added after accounting for quantity
 */
/**
 * @typedef ProductCategoryClicked
 * @property {string} [category] - name of the client-facing category which is clicked, in the case that this is a subCategory this value will fill with the corresponding primary category
 * @property {string} [destination] - url of the page where the click propels you into
 * @property {string} [pageUrl] - url of the page where the click occurs
 * @property {string} [subCategory] - name of the client-facing subcategory which is clicked, in the case that the click is a primary category this value will be empty
 */
/**
 * @typedef ProductCategoryFiltered
 * @property {string} [destination] - url of the page where the click propels you into
 * @property {string} [filter] - client-facing name of the primary filter selected
 * @property {string} [pageUrl] - url of the page where the click occurs
 * @property {string} [secondaryFilter] - client-facing name of the secondary filter selected
 */
/**
 * @typedef ProductClicked
 * @property {string} [category] - the category of the product being clicked
 * @property {string} [coupon] - coupon code associated with a product (e.g. MAY DEALS 3)
 * @property {string} [engine] - what engine is feeding the specific product tile (e.g. best sellers, recommendation engine)
 * @property {string} [feature] - the feature of the product being clicked is the name of the set of pages that propel the click into its destination, on a web source examples would be home, reorder, pdp, market, category etc.. (ex a user adds a product to cart from a pdp, the feature value would be pdp)
 * @property {string} [imageUrl] - image url of the product
 * @property {string} [index] - name of the targeted index (algolia)
 * @property {boolean} [loggedIn] - boolean value for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if a user is logged in or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product being clicked
 * @property {string} [objectID] - productId (for Algolia)
 * @property {number} [position] - position in the product list
 * @property {number} [price] - price ($) of the product being clicked
 * @property {string} [producer] - producer of the product being clicked
 * @property {string} [productId] - database id of the product being clicked
 * @property {number} [quantity] - quantity of the product being clicked
 * @property {string} [queryID] - algolia Id found in the search response
 * @property {string} [sku] - sku of the product being clicked (productId)
 * @property {string} [subCategory] - sub category of the product being clicked
 * @property {string} [url] - url of the product
 * @property {number} [value] - total value of the product after quantity
 */
/**
 * @typedef ProductFavorited
 * @property {string} [category] - category of the product being clicked
 * @property {string} [coupon] - coupon code associated with a product (e.g. MAY DEALS 3)
 * @property {string} [daysAvailable] - dates a product is available for delivery
 * @property {string} [engine] - what engine was feeding the product being added (e.g best sellers, recommendation engine)
 * @property {string} [feature] - the feature of the product being clicked is the name of the set of pages that propel the click into its destination, on a web source examples would be home, reorder, pdp, market, category etc.. (ex a user adds a product to cart from a pdp, the feature value would be pdp)
 * @property {string} [imageUrl] - image url of the product being clicked
 * @property {string} [index] - name of the targeted index
 * @property {boolean} [loggedIn] - boolean for if the user clicking the product is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, Cream Co. Meats, category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product being clicked
 * @property {string} [objectId] - productId value
 * @property {number} [position] - position in the product list (e.g. 3)
 * @property {string} [price] - price of the product being clicked
 * @property {string} [producer] - producer of the product being clicked
 * @property {string} [productId] - database id of the product being clicked
 * @property {number} [quantity] - quantity of the product being clicked
 * @property {string} [queryId] - Algolia ID found in the search responde
 * @property {string} [sku] - sku of the product being clicked
 * @property {string} [subCategory] - subCategory of the product being clicked
 * @property {number} [value] - total value of the product after quantity
 */
/**
 * @typedef ProductRemoved
 * @property {string} [basketId] - basketID of the cart that the product is being removed from
 * @property {string} [category] - category of the product being removed
 * @property {string} [coupon] - Coupon applied to the product being removed
 * @property {string} [daysAvailable] - dates a product is available for delivery
 * @property {string} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {string} [imageUrl] - image url of the product being removed
 * @property {boolean} [loggedIn] - boolean for if a user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee
 * @property {string} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product being removed
 * @property {number} [position] - position in the product listing of the product being removed
 * @property {number} [price] - price ($) of the product being removed
 * @property {string} [producer] - producer of the product being removed
 * @property {string} [productId] - databaseId of the product being removed
 * @property {number} [quantity] - quantity of the products being removed
 * @property {string} [sku] - sku of the product being removed
 * @property {string} [subCategory] - subCategory of the product being removed
 * @property {boolean} [underMinimum] - boolean for if the product added leads to a cart under the $30 threshold
 * @property {number} [value] - total value of the product after accounting for quantity
 */
/**
 * @typedef ProductViewed
 * @property {string} [category] - product category being viewed
 * @property {string} [coupon] - coupon code associated with product
 * @property {string} [imageUrl] - image Url of the product
 * @property {string} [index] - Name of the targeted Algolia index

 * @property {boolean} [loggedIn] - boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is logged in or not
 * @property {string} [name] - name of the product being viewed
 * @property {string} [objectId] - Object ID of the record (same as productId)
 * @property {number} [position] - position of product within the product list
 * @property {number} [price] - price ($) of the product being viewed
 * @property {string} [producer] - name of the product producer
 * @property {string} [productId] - database ID of the product being viewed
 * @property {number} [quantity] - quantity of the product
 * @property {string} [section] - market section being viewed (market, re-order, favorites)
 * @property {string} [sku] - sku of the product being viewed
 * @property {string} [subCategory] - product subcategory being viewed
 * @property {string} [url] - URL of the product page
 * @property {number} [value] - total value of the product after quantity
 */
/**
 * @typedef App45
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign41
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession42
 * @property {Campaign41} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef PromoCodeApplied
 * @property {App45} [app] -
 * @property {string} [code] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {string} [platform] -
 * @property {ShoppingSession42} [shoppingSession] -
 */
/**
 * @typedef App46
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign42
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession43
 * @property {Campaign42} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ReferralCta
 * @property {App46} [app] -
 * @property {string} [foodshed] -
 * @property {boolean} [loggedIn] -
 * @property {string} [platform] -
 * @property {ShoppingSession43} [shoppingSession] -
 */
/**
 * @typedef CommitSha23
 * @property {string} [default] -
 */
/**
 * @typedef App47
 * @property {CommitSha23} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments30
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign43
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession44
 * @property {Campaign43} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ReferralClicked
 * @property {App47} [app] -
 * @property {Experiments30} [experiments] -
 * @property {boolean} [loggedIn] -
 * @property {string} [platform] -
 * @property {string} [referralId] -
 * @property {ShoppingSession44} [shoppingSession] -
 */
/**
 * @typedef CommitSha24
 * @property {string} [default] -
 */
/**
 * @typedef App48
 * @property {CommitSha24} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef ReferralDiscountConsumed
 * @property {App48} [app] -
 * @property {string} [giverEmail] -
 * @property {string} [giverId] -
 * @property {boolean} [loggedIn] -
 * @property {string} [platform] -
 * @property {string} [referralGiftId] -
 */
/**
 * @typedef CommitSha25
 * @property {string} [default] -
 */
/**
 * @typedef App49
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha25} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments31
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Screen16
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign44
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession45
 * @property {Campaign44} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ReferralShared
 * @property {number} [amount] -
 * @property {string} [anonymousRecipientId] -
 * @property {App49} [app] -
 * @property {string} [displayAmount] -
 * @property {Experiments31} [experiments] -
 * @property {string} [foodshed] -
 * @property {string} [giverEmail] -
 * @property {string} [giverFirstName] -
 * @property {string} [giverId] -
 * @property {boolean} [loggedIn] -
 * @property {boolean} [masquerading] -
 * @property {string} [medium] -
 * @property {string} [pageName] -
 * @property {string} [platform] -
 * @property {string} [recipientEmail] -
 * @property {Screen16} [screen] -
 * @property {ShoppingSession45} [shoppingSession] -
 */
/**
 * @typedef CommitSha26
 * @property {string} [default] -
 */
/**
 * @typedef App50
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha26} [commitSHA] -
 * @property {string} [instance] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context2
 * @property {string} [feature] -
 * @property {number} [resultCount] -
 * @property {string} [searchQuery] -
 * @property {string} [searchRequestId] -
 * @property {string} [subcategory] -
 */
/**
 * @typedef Experiments32
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign45
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession46
 * @property {Campaign45} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef RemovedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addonValue] -
 * @property {App50} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {Context2} [context] -
 * @property {any | null} [createNewWeeklie] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [employee] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments32} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [id] -
 * @property {any | null} [isEmpty] -
 * @property {any | null} [issue] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [name] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [price] -
 * @property {any | null} [product] -
 * @property {any | null} [productId] -
 * @property {any | null} [productScheduleId] -
 * @property {any | null} [quantity] -
 * @property {any | null} [quantityAfter] -
 * @property {any | null} [quantityBefore] -
 * @property {any | null} [resultCount] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession46} [shoppingSession] -
 * @property {any | null} [shouldSubscribe] -
 * @property {any | null} [sku] -
 * @property {any | null} [status] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef App51
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign46
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession47
 * @property {Campaign46} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef RenderedCheckoutButton
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addingToOrder] -
 * @property {App51} [app] -
 * @property {any | null} [enabled] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [fulfillmentOptionCanCheckout] -
 * @property {any | null} [hasAddress] -
 * @property {any | null} [hasPayment] -
 * @property {any | null} [hasTime] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {any | null} [screen] -
 * @property {ShoppingSession47} [shoppingSession] -
 */
/**
 * @typedef CommitSha27
 * @property {string} [default] -
 */
/**
 * @typedef App52
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha27} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments33
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign47
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession48
 * @property {Campaign47} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef SanitizedBasketItem
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [afterQuantity] -
 * @property {App52} [app] -
 * @property {any | null} [basketLastUpdatedAt] -
 * @property {any | null} [beforeQuantity] -
 * @property {any | null} [category] -
 * @property {any | null} [context] -
 * @property {Experiments33} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {any | null} [section] -
 * @property {ShoppingSession48} [shoppingSession] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [type] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef SearchResultSelected
 * @property {string} [categorySelected] - the category of the item selected in search
 * @property {string} [index] - Name of the targeted Algolia index
 * @property {string} [itemSelected] - the name of the item selected in search
 * @property {boolean} [loggedIn] - boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee
 * @property {string} [objectID] - Object ID of the record (same as productId)
 * @property {number} [position] - position of the search result selected
 * @property {string} [producerSelected] - the producer of the product selected
 * @property {string} [query] - The search query that yielded the result
 * @property {string} [queryID] - Query ID returned in search results from Algolia
 */
/**
 * @typedef CommitSha28
 * @property {string} [default] -
 */
/**
 * @typedef App53
 * @property {CommitSha28} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Context3
 * @property {string} [feature] -
 * @property {number} [resultCount] -
 * @property {string} [searchQuery] -
 * @property {string} [searchRequestId] -
 * @property {string} [subcategory] -
 */
/**
 * @typedef Experiments34
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign48
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession49
 * @property {Campaign48} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ServedResponse
 * @property {any | null} [addonValue] -
 * @property {App53} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {Context3} [context] -
 * @property {any | null} [createNewWeeklie] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments34} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [httpMethod] -
 * @property {any | null} [isEmpty] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [path] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [processingSubscription] -
 * @property {any | null} [productId] -
 * @property {any | null} [responseTime] -
 * @property {any | null} [resultCount] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [section] -
 * @property {ShoppingSession49} [shoppingSession] -
 * @property {any | null} [status] -
 * @property {any | null} [statusCode] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha29
 * @property {string} [default] -
 */
/**
 * @typedef App54
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha29} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments35
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef NewDeliveryDetails
 * @property {string} [address] -
 * @property {string} [address2] -
 * @property {boolean} [canLeaveAtDoor] -
 * @property {string} [city] -
 * @property {boolean} [customerBags] -
 * @property {string} [deliveryInstructions] -
 * @property {string} [giftMessage] -
 * @property {string} [giftRecipientName] -
 * @property {string} [giftRecipientPhone] -
 * @property {boolean} [isGift] -
 * @property {string} [key] -
 * @property {number} [lat] -
 * @property {number} [lon] -
 * @property {string} [state] -
 * @property {string} [zip] -
 */
/**
 * @typedef Campaign49
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession50
 * @property {Campaign49} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef SetDeliveryAddress
 * @property {any | null} [addToOrderMode] -
 * @property {boolean} [addressIsConfirmed] -
 * @property {App54} [app] -
 * @property {Experiments35} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {NewDeliveryDetails} [newDeliveryDetails] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession50} [shoppingSession] -
 * @property {any | null} [updating] -
 */
/**
 * @typedef CommitSha30
 * @property {string} [default] -
 */
/**
 * @typedef App55
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha30} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context4
 * @property {string} [feature] -
 * @property {number} [resultCount] -
 * @property {string} [searchQuery] -
 * @property {string} [searchRequestId] -
 * @property {string} [subcategory] -
 */
/**
 * @typedef Experiments36
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign50
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession51
 * @property {Campaign50} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef SetDeliveryTime
 * @property {any | null} [addToOrderMode] -
 * @property {App55} [app] -
 * @property {any | null} [autoAssigned] -
 * @property {any | null} [category] -
 * @property {Context4} [context] -
 * @property {any | null} [endAt] -
 * @property {Experiments36} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [issue] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {any | null} [resultCount] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession51} [shoppingSession] -
 * @property {any | null} [startAt] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [updating] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef SignedIn
 * @property {string} [email] - user's email
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [signInMethod] - what sign-in method did the user use (email, Facebook, Google, or Apple)
 * @property {string} [userName] - user's user name
 */
/**
 * @typedef SiteSearched
 * @property {string} [index] - Name of the targeted Algolia index
 * @property {boolean} [loggedIn] - boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee or not
 * @property {string} [query] - the search query entered into the search bar
 * @property {string} [queryID] - Query ID returned in search results from Algolia
 * @property {string} [queryType] - type of search query submitted or selected (recent, trending, userSubmitted, suggestion)
 */
/**
 * @typedef CommitSha31
 * @property {string} [default] -
 */
/**
 * @typedef App56
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha31} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments37
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign51
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession52
 * @property {Campaign51} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef SkippedSubscriptionWeek
 * @property {any | null} [addToOrderMode] -
 * @property {App56} [app] -
 * @property {any | null} [day] -
 * @property {Experiments37} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession52} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 */
/**
 * @typedef CommitSha32
 * @property {string} [default] -
 */
/**
 * @typedef App57
 * @property {CommitSha32} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments38
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign52
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession53
 * @property {Campaign52} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef StartedAddToOrder
 * @property {App57} [app] -
 * @property {Experiments38} [experiments] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [orderId] -
 * @property {any | null} [page] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession53} [shoppingSession] -
 */
/**
 * @typedef App58
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign53
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession54
 * @property {Campaign53} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef StartedApplePayCheckout
 * @property {any | null} [addToOrderMode] -
 * @property {App58} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession54} [shoppingSession] -
 */
/**
 * @typedef CommitSha33
 * @property {string} [default] -
 */
/**
 * @typedef App59
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha33} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments39
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign54
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession55
 * @property {Campaign54} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef StartedCheckout
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addingToOrder] -
 * @property {App59} [app] -
 * @property {Experiments39} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession55} [shoppingSession] -
 */
/**
 * @typedef CommitSha34
 * @property {string} [default] -
 */
/**
 * @typedef App60
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha34} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context5
 * @property {string} [feature] -
 * @property {number} [resultCount] -
 * @property {string} [searchQuery] -
 * @property {string} [searchRequestId] -
 * @property {string} [subcategory] -
 */
/**
 * @typedef Experiments40
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign55
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession56
 * @property {Campaign55} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef ShoppingSession57
 * @property {string} [id] -
 */
/**
 * @typedef StartedShoppingSession
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addonValue] -
 * @property {App60} [app] -
 * @property {any | null} [applePayEnabled] -
 * @property {any | null} [applePaySupported] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {string} [commit_sha] -
 * @property {Context5} [context] -
 * @property {string} [context_ip] -
 * @property {boolean} [context_network_bluetooth] -
 * @property {string} [context_network_carrier] -
 * @property {boolean} [context_network_cellular] -
 * @property {boolean} [context_network_wifi] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments40} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {any | null} [resultCount] -
 * @property {number} [screen_height] -
 * @property {number} [screen_width] -
 * @property {any | null} [searchQuery] -
 * @property {any | null} [searchRequestId] -
 * @property {any | null} [section] -
 * @property {ShoppingSession56} [shoppingSession] -
 * @property {ShoppingSession57} [shopping_session] -
 * @property {any | null} [status] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef SubscriptionCancelled
 * @property {boolean} [allowSubstitutions] - boolean value for if a user allows substitutions
 * @property {boolean} [loggedIn] - boolean value for it a user is logged in
 * @property {boolean} [masquerading] - boolean value for it a user is an employee
 * @property {string} [subscriptionId] - unique subcription order id (different than order id)
 * @property {number} [value] - total value of the subscription cart accounting for quantity
 */
/**
 * @typedef ProductsItem7
 * @property {string | null} [category] - product category of the product being viewed
 * @property {string | null} [coupon] - product category of the product being viewed
 * @property {string | null} [engine] - what engine is feeding the specific product (e.g. best sellers, recommendation engine)
 * @property {string} [id] - unique database Id of the product in the order
 * @property {string | null} [moduleName] - the consumer facing name of the module that the click action lives in (e.g welcome to good eggs, cream co. meats , category selections)
 * @property {string | null} [moduleType] - the non consumer facing title of the type of module that the click action lives in (e.g. product tiles_small, content + Product tiles_small, categories, etc..)
 * @property {string} [name] - name of the product in the order
 * @property {number} [price] - price ($) of the product in the order
 * @property {string | null} [producer] - producer of the product in the order
 * @property {number} [quantity] - quantity of the product in the order
 * @property {string | null} [sku] - sku of the product in the order
 * @property {string | null} [subCategory] - sub productCategory of the product in the order
 * @property {string | null} [url] - url of the product in the order
 * @property {number} [value] - total value of the product after accounting for quantity
 */
/**
 * @typedef SubscriptionCreated
 * @property {number} [couponDiscount] - amount of discount from coupon
 * @property {number} [credits] - total credits applied to this basket
 * @property {string} [deliveryEndWindow] - end of delivery window
 * @property {number} [deliveryFee] - delivery fee applied to checkout
 * @property {string} [deliveryStartWindow] - start of delivery window
 * @property {number} [goodJobsFee] - good jobs fee associated with the order
 * @property {number} [itemCount] - amount of individual items in the order
 * @property {boolean} [loggedIn] - Boolean value for if the user is logged in or not
 * @property {boolean} [masquerading] - boolean value for if the user is an employee or not
 * @property {string} [paymentMethod] - type of payment method used (e.g. credit card, apple pay...)
 * @property {string} [platform] - web or MMA
 * @property {number} [productCount] - amount of individual products in the order
 * @property {ProductsItem7[]} [products] - product details array
 * @property {number} [referralDiscount] - amount of discount from referral
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {number} [subscriptionDiscount] - amount of discount from the subscription
 * @property {string} [subscriptionID] - unique id tied to the subscription order (seperate from orderId)
 * @property {number} [subtotal] - total value of all products without taxes, fees, or discounts applied
 * @property {number} [tax] - total tax applied to the order
 * @property {number} [totalDiscount] - total discount applied to the order
 * @property {number} [totalFees] - total value for good job fee, delivery, and tax added together
 */
/**
 * @typedef SubscriptionProductAdded
 * @property {string} [basketId] - id of the cart that has the product being added to it
 * @property {string} [daysAvailable] - dates a product is available for delivery
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {string} [imageUrl] - image url of the product being added
 * @property {boolean} [loggedIn] - boolean value for if a user is an employee or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [name] - name of the product being added
 * @property {number} [price] - price ($) of of the product being added
 * @property {string} [producer] - producer to the product being added
 * @property {string} [productId] - database id of the product being added
 * @property {number} [quantity] - amount of products being added
 * @property {string} [sku] - sku of the product being added
 * @property {string} [subscriptionId] - id of the subscription cart that has the product being added to it
 * @property {boolean} [underSubscriptionMinimum] - boolean for if the product added leads to a cart under the $30 threshold
 * @property {number} [value] - total value after the product after quantity
 * @property {number} [valueUnderSubscriptionMinimum] - the value remaining until you hit the $30 minimum (visibile in the UI)
 */
/**
 * @typedef SubscriptionProductRemoved
 * @property {string} [basketId] - id of cart that has the product being removed from it
 * @property {string} [daysAvailable] - dates a product is available
 * @property {string} [feature] - The individual page/screen (home, basket) or grouping of similar pages/screens (PDP, category) of where a user interaction occurred
 * @property {string} [imageUrl] - image url of the product
 * @property {boolean} [loggedIn] - boolean value for if a user was logged in or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {string} [name] - name of the product being removed
 * @property {number} [price] - price ($) of the product being removed
 * @property {string} [producer] - producer of the product being removed
 * @property {string} [productId] - database id of the product being removed
 * @property {number} [quantity] - amount of products being removed
 * @property {string} [sku] - sku of the product being removed
 * @property {string} [subscriptionId] - id of the subscription cart that has the product being removed from it
 * @property {boolean} [underSubscriptionMinimum] - boolean for if the product removed leads to a cart under the $30 threshold
 * @property {number} [value] - total value of the product after quantity
 * @property {number} [valueUnderSubscriptionMinimum] - the value remaining until you hit the $30 minimum (visibile in the UI)
 */
/**
 * @typedef SubscriptionUpdated
 * @property {string} [coupon] - order level promo code
 * @property {number} [credits] - Total credits applied to order
 * @property {string} [deliveryDate] - selected for delivery date of product
 * @property {number} [deliveryFee] - delivery fee applied to the order
 * @property {number} [discount] - total discount applied to order
 * @property {number} [itemCount] - Count of individual items in order
 * @property {boolean} [loggedIn] - boolean value for if a user is loggedin or not
 * @property {boolean} [masquerading] - boolean for if a user is an employee or not
 * @property {number} [productCount] - Number of individual SKUs in order
 * @property {number} [quantityAfter] - quantity of products in cart after updating
 * @property {number} [quantityBefore] - quantity of products in cart before updating
 * @property {string} [regionFulfillmentCenter] - Name of the region tied to where the order was fulfilled
 * @property {string} [regionName] - Name of the region tied to where the order was placed
 * @property {string} [statusChange] - what type of change did the user make to their subscription (e.g. changing day, changing time, changing address, adding items, removing items)
 * @property {number} [subscriptionDiscount] - amount of discount from subscription
 * @property {string} [subscriptionId] - unique id tied to the subscription order (seperate from orderId)
 * @property {number} [subtotal] - Total of all products without taxes, fees, or discounts applied
 * @property {number} [subtotalAfter] - subtotal before order updated
 * @property {number} [subtotalBefore] - Subtotal before order update
 * @property {number} [tax] - total taxes applied to the order
 * @property {number} [totalFees] - tax, shipping, delivery, and good jobs fees totaled
 * @property {number} [value] - total value of the product after quantity
 */
/**
 * @typedef CommitSha35
 * @property {string} [default] -
 */
/**
 * @typedef App61
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha35} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Context6
 * @property {string} [feature] -
 */
/**
 * @typedef Experiments41
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign56
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession58
 * @property {Campaign56} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef UnfavoritedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {App61} [app] -
 * @property {any | null} [category] -
 * @property {Context6} [context] -
 * @property {Experiments41} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [productId] -
 * @property {any | null} [section] -
 * @property {ShoppingSession58} [shoppingSession] -
 * @property {any | null} [subcategory] -
 * @property {any | null} [tags] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef CommitSha36
 * @property {string} [default] -
 */
/**
 * @typedef App62
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha36} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments42
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign57
 * @property {string} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession59
 * @property {Campaign57} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef UnskippedSubscriptionWeek
 * @property {any | null} [addToOrderMode] -
 * @property {App62} [app] -
 * @property {any | null} [day] -
 * @property {Experiments42} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession59} [shoppingSession] -
 * @property {any | null} [subscriptionId] -
 */
/**
 * @typedef CommitSha37
 * @property {string} [default] -
 */
/**
 * @typedef App63
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {CommitSha37} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Experiments43
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign58
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession60
 * @property {Campaign58} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef UsedPromoCode
 * @property {any | null} [addToOrderMode] -
 * @property {App63} [app] -
 * @property {string} [attributionType] -
 * @property {any | null} [code] -
 * @property {Experiments43} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession60} [shoppingSession] -
 */
/**
 * @typedef CommitSha38
 * @property {string} [default] -
 */
/**
 * @typedef App64
 * @property {CommitSha38} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments44
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Campaign59
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession61
 * @property {Campaign59} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 * @property {boolean} [isNew] -
 */
/**
 * @typedef Viewed500ErrorPage
 * @property {App64} [app] -
 * @property {Experiments44} [experiments] -
 * @property {any | null} [feature] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [page] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {any | null} [processingSubscription] -
 * @property {ShoppingSession61} [shoppingSession] -
 */
/**
 * @typedef App65
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign60
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession62
 * @property {Campaign60} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ViewedBasket
 * @property {any | null} [addToOrderMode] -
 * @property {App65} [app] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession62} [shoppingSession] -
 */
/**
 * @typedef App66
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Details
 * @property {any | null} [unavailableCount] -
 */
/**
 * @typedef Basket1
 * @property {Details} [details] -
 */
/**
 * @typedef Campaign61
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession63
 * @property {Campaign61} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ViewedBasketDetails
 * @property {any | null} [addToOrderMode] -
 * @property {App66} [app] -
 * @property {Basket1} [basket] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [platform] -
 * @property {ShoppingSession63} [shoppingSession] -
 * @property {any | null} [unavailableCount] -
 */
/**
 * @typedef CommitSha39
 * @property {string} [default] -
 */
/**
 * @typedef App67
 * @property {CommitSha39} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments45
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Feature1
 * @property {string} [id] -
 * @property {string} [name] -
 * @property {string} [type] -
 */
/**
 * @typedef Screen17
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign62
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession64
 * @property {Campaign62} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef User1
 * @property {number} [orderCount] -
 */
/**
 * @typedef ViewedHomepageFeature
 * @property {any | null} [addToWeeklyMode] -
 * @property {App67} [app] -
 * @property {Experiments45} [experiments] -
 * @property {Feature1} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [pageName] -
 * @property {any | null} [platform] -
 * @property {Screen17} [screen] -
 * @property {ShoppingSession64} [shoppingSession] -
 * @property {User1} [user] -
 */
/**
 * @typedef CommitSha40
 * @property {string} [default] -
 */
/**
 * @typedef App68
 * @property {CommitSha40} [commitSHA] -
 * @property {string} [instance] -
 * @property {string} [name] -
 */
/**
 * @typedef Experiments46
 * @property {string} [2021-q2-delayed-signup] -
 * @property {string} [no-op] -
 */
/**
 * @typedef Product1
 * @property {any | null} [id] -
 * @property {any | null} [price] -
 */
/**
 * @typedef Screen18
 * @property {number} [height] -
 * @property {number} [width] -
 */
/**
 * @typedef Campaign63
 * @property {string[]} [content] -
 * @property {string[]} [medium] -
 * @property {string[]} [name] -
 * @property {string[]} [source] -
 * @property {string[]} [term] -
 */
/**
 * @typedef ShoppingSession65
 * @property {Campaign63} [campaign] -
 * @property {boolean} [dismissedAppPromoModal] -
 * @property {string} [id] -
 * @property {string} [initialReferrer] -
 */
/**
 * @typedef ViewedHoverBasket
 * @property {any | null} [addToOrderMode] -
 * @property {any | null} [addToWeeklyMode] -
 * @property {any | null} [addonValue] -
 * @property {App68} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [checkoutType] -
 * @property {any | null} [context] -
 * @property {any | null} [createNewWeeklie] -
 * @property {any | null} [daysBeforeFulfillment] -
 * @property {any | null} [deliveryPrice] -
 * @property {any | null} [existingWeeklies] -
 * @property {Experiments46} [experiments] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [fulfillment_days_in_basket] -
 * @property {any | null} [has_add_to_order_button] -
 * @property {any | null} [inspire-slug] -
 * @property {any | null} [isEmpty] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [masquerading] -
 * @property {any | null} [missingPickup] -
 * @property {any | null} [pageName] -
 * @property {any | null} [pageTags] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {Product1} [product] -
 * @property {any | null} [productId] -
 * @property {Screen18} [screen] -
 * @property {ShoppingSession65} [shoppingSession] -
 * @property {any | null} [site] -
 * @property {any | null} [status] -
 * @property {any | null} [tags] -
 * @property {any | null} [totalCheckoutValue] -
 * @property {any | null} [unactivated] -
 * @property {any | null} [underMinimum] -
 * @property {any | null} [vendor] -
 */
/**
 * @typedef App69
 * @property {string} [apiVersion] -
 * @property {string} [build] -
 * @property {any | null} [name] -
 * @property {string} [version] -
 */
/**
 * @typedef Campaign64
 * @property {string} [content] -
 * @property {string} [medium] -
 * @property {string} [name] -
 * @property {string} [source] -
 * @property {string} [term] -
 */
/**
 * @typedef ShoppingSession66
 * @property {Campaign64} [campaign] -
 * @property {string} [crossDeviceId] -
 * @property {string} [id] -
 */
/**
 * @typedef ViewedProduct
 * @property {any | null} [addToOrderMode] -
 * @property {App69} [app] -
 * @property {any | null} [category] -
 * @property {any | null} [content] -
 * @property {any | null} [feature] -
 * @property {any | null} [foodshed] -
 * @property {any | null} [id] -
 * @property {any | null} [issue] -
 * @property {any | null} [loggedIn] -
 * @property {any | null} [name] -
 * @property {any | null} [piece] -
 * @property {any | null} [pieceType] -
 * @property {any | null} [platform] -
 * @property {any | null} [price] -
 * @property {any | null} [searchIndex] -
 * @property {any | null} [searchSection] -
 * @property {any | null} [section] -
 * @property {ShoppingSession66} [shoppingSession] -
 * @property {any | null} [sku] -
 * @property {any | null} [subcategory] -
 */

/**
 * Fires a 'Abandoned Recipe Video' track call.
 *
 * @param {AbandonedRecipeVideo} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function abandonedRecipeVideo(
	props?: AbandonedRecipeVideo,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Abandoned Recipe Video',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User signed up to create an account
 *
 * @param {AccountCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCreated(
	props?: AccountCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates their account
 *
 * @param {AccountUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountUpdated(
	props?: AccountUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Activated Subscription' track call.
 *
 * @param {ActivatedSubscription} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activatedSubscription(
	props?: ActivatedSubscription,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Activated Subscription',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Added Product' track call.
 *
 * @param {AddedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addedProduct(
	props?: AddedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Added Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Added Product To Subscription' track call.
 *
 * @param {AddedProductToSubscription} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addedProductToSubscription(
	props?: AddedProductToSubscription,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Added Product To Subscription',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Answered Notification Prompt' track call.
 *
 * @param {AnsweredNotificationPrompt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function answeredNotificationPrompt(
	props?: AnsweredNotificationPrompt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Answered Notification Prompt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Application Did Finish Launching' track call.
 *
 * @param {ApplicationDidFinishLaunching} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationDidFinishLaunching(
	props?: ApplicationDidFinishLaunching,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Did Finish Launching',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Application Installed' track call.
 *
 * @param {ApplicationInstalled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationInstalled(
	props?: ApplicationInstalled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Installed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Application Will Become Interactive' track call.
 *
 * @param {ApplicationWillBecomeInteractive} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationWillBecomeInteractive(
	props?: ApplicationWillBecomeInteractive,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Application Will Become Interactive',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cancelled Apple Pay Checkout' track call.
 *
 * @param {CancelledApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelledApplePayCheckout(
	props?: CancelledApplePayCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancelled Apple Pay Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cancelled Order' track call.
 *
 * @param {CancelledOrder} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelledOrder(
	props?: CancelledOrder,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancelled Order',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cancelled Subscription' track call.
 *
 * @param {CancelledSubscription} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelledSubscription(
	props?: CancelledSubscription,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cancelled Subscription',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Captured Service Area Email' track call.
 *
 * @param {CapturedServiceAreaEmail} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function capturedServiceAreaEmail(
	props?: CapturedServiceAreaEmail,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Captured Service Area Email',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed their shopping cart
 *
 * @param {CartViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
	props?: CartViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Changed Day' track call.
 *
 * @param {ChangedDay} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changedDay(
	props?: ChangedDay,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changed Day',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Changed Notification Setting' track call.
 *
 * @param {ChangedNotificationSetting} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changedNotificationSetting(
	props?: ChangedNotificationSetting,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changed Notification Setting',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Changed Payment Method' track call.
 *
 * @param {ChangedPaymentMethod} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changedPaymentMethod(
	props?: ChangedPaymentMethod,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changed Payment Method',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Changed Product' track call.
 *
 * @param {ChangedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changedProduct(
	props?: ChangedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changed Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Changed Shopping Day' track call.
 *
 * @param {ChangedShoppingDay} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changedShoppingDay(
	props?: ChangedShoppingDay,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changed Shopping Day',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Checked Service Area' track call.
 *
 * @param {CheckedServiceArea} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkedServiceArea(
	props?: CheckedServiceArea,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checked Service Area',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed checkout to place an order
 *
 * @param {CheckoutCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutCompleted(
	props?: CheckoutCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated the checkout process
 *
 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props?: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes a step of the checkout
 *
 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props?: CheckoutStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updated an existing order placed before cutoff
 *
 * @param {CheckoutUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutUpdated(
	props?: CheckoutUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Chose Delivery Time' track call.
 *
 * @param {ChoseDeliveryTime} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function choseDeliveryTime(
	props?: ChoseDeliveryTime,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Chose Delivery Time',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Clicked Homepage Feature' track call.
 *
 * @param {ClickedHomepageFeature} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clickedHomepageFeature(
	props?: ClickedHomepageFeature,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Clicked Homepage Feature',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Completed Apple Pay Checkout' track call.
 *
 * @param {CompletedApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function completedApplePayCheckout(
	props?: CompletedApplePayCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Completed Apple Pay Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Created Account' track call.
 *
 * @param {CreatedAccount} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createdAccount(
	props?: CreatedAccount,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Created Account',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Created Subscription' track call.
 *
 * @param {CreatedSubscription} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createdSubscription(
	props?: CreatedSubscription,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Created Subscription',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks a CTA button on a page
 *
 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctaClicked(
	props?: CtaClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cta Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User dismissed a CTA element on a page or screen
 *
 * @param {CtaDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctaDismissed(
	props?: CtaDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cta Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User checked delivery area eligibility
 *
 * @param {DeliveryAreaChecked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryAreaChecked(
	props?: DeliveryAreaChecked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery Area Checked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates their delivery area by submitting a new zip code
 *
 * @param {DeliveryAreaUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryAreaUpdated(
	props?: DeliveryAreaUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery Area Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Delivery Window Options Viewed' track call.
 *
 * @param {DeliveryWindowOptionsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryWindowOptionsViewed(
	props?: DeliveryWindowOptionsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery Window Options Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Delivery Window Selected' track call.
 *
 * @param {DeliveryWindowSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryWindowSelected(
	props?: DeliveryWindowSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delivery Window Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Disallowed Fulfillment Offer' track call.
 *
 * @param {DisallowedFulfillmentOffer} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function disallowedFulfillmentOffer(
	props?: DisallowedFulfillmentOffer,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Disallowed Fulfillment Offer',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered an email address on a form
 *
 * @param {EmailCaptured} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailCaptured(
	props?: EmailCaptured,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Email Captured',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Entered Foreground' track call.
 *
 * @param {EnteredForeground} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enteredForeground(
	props?: EnteredForeground,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Entered Foreground',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Entered Payment Details' track call.
 *
 * @param {EnteredPaymentDetails} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enteredPaymentDetails(
	props?: EnteredPaymentDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Entered Payment Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Failed Checkout' track call.
 *
 * @param {FailedCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function failedCheckout(
	props?: FailedCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Failed Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Favorited Product' track call.
 *
 * @param {FavoritedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function favoritedProduct(
	props?: FavoritedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Favorited Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Finished Navigation' track call.
 *
 * @param {FinishedNavigation} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finishedNavigation(
	props?: FinishedNavigation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Finished Navigation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Finished Spinning' track call.
 *
 * @param {FinishedSpinning} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function finishedSpinning(
	props?: FinishedSpinning,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Finished Spinning',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Fulfillment Option Sold Out' track call.
 *
 * @param {FulfillmentOptionSoldOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fulfillmentOptionSoldOut(
	props?: FulfillmentOptionSoldOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fulfillment Option Sold Out',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Hit Search' track call.
 *
 * @param {HitSearch} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hitSearch(
	props?: HitSearch,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Hit Search', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User purchased a product within their order was billed for order after the cut-off (this event will fire for every item purchased)
 *
 * @param {ItemBilled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemBilled(
	props?: ItemBilled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Item Billed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed checkout to place an order - this event fires for each product in checkout
 *
 * @param {ItemCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemCompleted(
	props?: ItemCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Item Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user updates their order post order complete but before the billed window has closed. This event will fire for each product change, wether its removed or added. This event does not fire for any updates outside of product changes
 *
 * @param {ItemUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemUpdated(
	props?: ItemUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Item Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Launched Clean Install' track call.
 *
 * @param {LaunchedCleanInstall} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function launchedCleanInstall(
	props?: LaunchedCleanInstall,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Launched Clean Install',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Left Product In Basket' track call.
 *
 * @param {LeftProductInBasket} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leftProductInBasket(
	props?: LeftProductInBasket,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Left Product In Basket',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Logged In' track call.
 *
 * @param {LoggedIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loggedIn(
	props?: LoggedIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Logged In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Logged Out' track call.
 *
 * @param {LoggedOut} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loggedOut(
	props?: LoggedOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Logged Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Non-member user clicked on Membership CTA
 *
 * @param {MembershipModalClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function membershipModalClicked(
	props?: MembershipModalClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Membership Modal Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Missed Search' track call.
 *
 * @param {MissedSearch} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missedSearch(
	props?: MissedSearch,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Missed Search',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a modal on a page or screen
 *
 * @param {ModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function modalViewed(
	props?: ModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Notification Sent' track call.
 *
 * @param {NotificationSent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notificationSent(
	props?: NotificationSent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Notification Sent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Opened URL' track call.
 *
 * @param {OpenedUrl} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openedUrl(
	props?: OpenedUrl,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Opened URL', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User billed for order after cut-off
 *
 * @param {OrderBilled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderBilled(
	props?: OrderBilled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Billed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Order Completed' track call.
 *
 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props?: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Order Updated' track call.
 *
 * @param {OrderUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderUpdated(
	props?: OrderUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User loaded a page
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props?: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partial address' track call.
 *
 * @param {PartialAddress} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partialAddress(
	props?: PartialAddress,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partial address',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
	props?: ProductAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user clicks a category or subcategory in the header navigation dropdown
 *
 * @param {ProductCategoryClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productCategoryClicked(
	props?: ProductCategoryClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Category Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user clicks a product list filter on the left side of a product listing page
 *
 * @param {ProductCategoryFiltered} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productCategoryFiltered(
	props?: ProductCategoryFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Category Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks a product tile
 *
 * @param {ProductClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
	props?: ProductClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User favorites a product
 *
 * @param {ProductFavorited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productFavorited(
	props?: ProductFavorited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Favorited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
	props?: ProductRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product detail page
 *
 * @param {ProductViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
	props?: ProductViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Promo Code Applied' track call.
 *
 * @param {PromoCodeApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promoCodeApplied(
	props?: PromoCodeApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promo Code Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Referral CTA' track call.
 *
 * @param {ReferralCta} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralCta(
	props?: ReferralCta,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral CTA',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Referral Clicked' track call.
 *
 * @param {ReferralClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralClicked(
	props?: ReferralClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Referral Discount Consumed' track call.
 *
 * @param {ReferralDiscountConsumed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralDiscountConsumed(
	props?: ReferralDiscountConsumed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Discount Consumed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Referral Kickback Rewarded' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralKickbackRewarded(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Kickback Rewarded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Referral Shared' track call.
 *
 * @param {ReferralShared} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralShared(
	props?: ReferralShared,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Shared',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Removed Product' track call.
 *
 * @param {RemovedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removedProduct(
	props?: RemovedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Removed Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Rendered Checkout Button' track call.
 *
 * @param {RenderedCheckoutButton} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function renderedCheckoutButton(
	props?: RenderedCheckoutButton,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rendered Checkout Button',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Sanitized Basket Item' track call.
 *
 * @param {SanitizedBasketItem} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sanitizedBasketItem(
	props?: SanitizedBasketItem,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sanitized Basket Item',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on a search result
 *
 * @param {SearchResultSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchResultSelected(
	props?: SearchResultSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Result Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Served Response' track call.
 *
 * @param {ServedResponse} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servedResponse(
	props?: ServedResponse,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Served Response',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Set Delivery Address' track call.
 *
 * @param {SetDeliveryAddress} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setDeliveryAddress(
	props?: SetDeliveryAddress,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Set Delivery Address',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Set Delivery Time' track call.
 *
 * @param {SetDeliveryTime} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setDeliveryTime(
	props?: SetDeliveryTime,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Set Delivery Time',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User logged into their account
 *
 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedIn(
	props?: SignedIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Signed In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User submitted a term into a search bar
 *
 * @param {SiteSearched} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function siteSearched(
	props?: SiteSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Site Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Skipped Subscription Week' track call.
 *
 * @param {SkippedSubscriptionWeek} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function skippedSubscriptionWeek(
	props?: SkippedSubscriptionWeek,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Skipped Subscription Week',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Started Add To Order' track call.
 *
 * @param {StartedAddToOrder} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedAddToOrder(
	props?: StartedAddToOrder,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Started Add To Order',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Started Apple Pay Checkout' track call.
 *
 * @param {StartedApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedApplePayCheckout(
	props?: StartedApplePayCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Started Apple Pay Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Started Checkout' track call.
 *
 * @param {StartedCheckout} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedCheckout(
	props?: StartedCheckout,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Started Checkout',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Started Shopping Session' track call.
 *
 * @param {StartedShoppingSession} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedShoppingSession(
	props?: StartedShoppingSession,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Started Shopping Session',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancels a subscription order
 *
 * @param {SubscriptionCancelled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionCancelled(
	props?: SubscriptionCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated the checkout process
 *
 * @param {SubscriptionCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionCreated(
	props?: SubscriptionCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the subscription box in checkout
 *
 * @param {SubscriptionProductAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionProductAdded(
	props?: SubscriptionProductAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User unchecks the subscription box in checkout
 *
 * @param {SubscriptionProductRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionProductRemoved(
	props?: SubscriptionProductRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updated their current subscription order
 *
 * @param {SubscriptionUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionUpdated(
	props?: SubscriptionUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Unfavorited Product' track call.
 *
 * @param {UnfavoritedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unfavoritedProduct(
	props?: UnfavoritedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Unfavorited Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Unskipped Subscription Week' track call.
 *
 * @param {UnskippedSubscriptionWeek} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unskippedSubscriptionWeek(
	props?: UnskippedSubscriptionWeek,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Unskipped Subscription Week',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Used Promo Code' track call.
 *
 * @param {UsedPromoCode} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function usedPromoCode(
	props?: UsedPromoCode,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Used Promo Code',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed 500 Error Page' track call.
 *
 * @param {Viewed500ErrorPage} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewed500ErrorPage(
	props?: Viewed500ErrorPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed 500 Error Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed Basket' track call.
 *
 * @param {ViewedBasket} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedBasket(
	props?: ViewedBasket,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Basket',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed Basket Details' track call.
 *
 * @param {ViewedBasketDetails} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedBasketDetails(
	props?: ViewedBasketDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Basket Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed Homepage Feature' track call.
 *
 * @param {ViewedHomepageFeature} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedHomepageFeature(
	props?: ViewedHomepageFeature,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Homepage Feature',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed Hover Basket' track call.
 *
 * @param {ViewedHoverBasket} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedHoverBasket(
	props?: ViewedHoverBasket,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Hover Basket',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Viewed Product' track call.
 *
 * @param {ViewedProduct} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedProduct(
	props?: ViewedProduct,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Product',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Fires a 'Abandoned Recipe Video' track call.
	 *
	 * @param {AbandonedRecipeVideo} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	abandonedRecipeVideo,
	/**
	 * User signed up to create an account
	 *
	 * @param {AccountCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountCreated,
	/**
	 * User updates their account
	 *
	 * @param {AccountUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountUpdated,
	/**
	 * Fires a 'Activated Subscription' track call.
	 *
	 * @param {ActivatedSubscription} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activatedSubscription,
	/**
	 * Fires a 'Added Product' track call.
	 *
	 * @param {AddedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addedProduct,
	/**
	 * Fires a 'Added Product To Subscription' track call.
	 *
	 * @param {AddedProductToSubscription} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addedProductToSubscription,
	/**
	 * Fires a 'Answered Notification Prompt' track call.
	 *
	 * @param {AnsweredNotificationPrompt} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	answeredNotificationPrompt,
	/**
	 * Fires a 'Application Did Finish Launching' track call.
	 *
	 * @param {ApplicationDidFinishLaunching} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationDidFinishLaunching,
	/**
	 * Fires a 'Application Installed' track call.
	 *
	 * @param {ApplicationInstalled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationInstalled,
	/**
	 * Fires a 'Application Will Become Interactive' track call.
	 *
	 * @param {ApplicationWillBecomeInteractive} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationWillBecomeInteractive,
	/**
	 * Fires a 'Cancelled Apple Pay Checkout' track call.
	 *
	 * @param {CancelledApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelledApplePayCheckout,
	/**
	 * Fires a 'Cancelled Order' track call.
	 *
	 * @param {CancelledOrder} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelledOrder,
	/**
	 * Fires a 'Cancelled Subscription' track call.
	 *
	 * @param {CancelledSubscription} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cancelledSubscription,
	/**
	 * Fires a 'Captured Service Area Email' track call.
	 *
	 * @param {CapturedServiceAreaEmail} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	capturedServiceAreaEmail,
	/**
	 * User viewed their shopping cart
	 *
	 * @param {CartViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * Fires a 'Changed Day' track call.
	 *
	 * @param {ChangedDay} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changedDay,
	/**
	 * Fires a 'Changed Notification Setting' track call.
	 *
	 * @param {ChangedNotificationSetting} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changedNotificationSetting,
	/**
	 * Fires a 'Changed Payment Method' track call.
	 *
	 * @param {ChangedPaymentMethod} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changedPaymentMethod,
	/**
	 * Fires a 'Changed Product' track call.
	 *
	 * @param {ChangedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changedProduct,
	/**
	 * Fires a 'Changed Shopping Day' track call.
	 *
	 * @param {ChangedShoppingDay} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changedShoppingDay,
	/**
	 * Fires a 'Checked Service Area' track call.
	 *
	 * @param {CheckedServiceArea} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkedServiceArea,
	/**
	 * User completed checkout to place an order
	 *
	 * @param {CheckoutCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutCompleted,
	/**
	 * User initiated the checkout process
	 *
	 * @param {CheckoutStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completes a step of the checkout
	 *
	 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User updated an existing order placed before cutoff
	 *
	 * @param {CheckoutUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutUpdated,
	/**
	 * Fires a 'Chose Delivery Time' track call.
	 *
	 * @param {ChoseDeliveryTime} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	choseDeliveryTime,
	/**
	 * Fires a 'Clicked Homepage Feature' track call.
	 *
	 * @param {ClickedHomepageFeature} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clickedHomepageFeature,
	/**
	 * Fires a 'Completed Apple Pay Checkout' track call.
	 *
	 * @param {CompletedApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	completedApplePayCheckout,
	/**
	 * Fires a 'Created Account' track call.
	 *
	 * @param {CreatedAccount} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createdAccount,
	/**
	 * Fires a 'Created Subscription' track call.
	 *
	 * @param {CreatedSubscription} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createdSubscription,
	/**
	 * user clicks a CTA button on a page
	 *
	 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ctaClicked,
	/**
	 * User dismissed a CTA element on a page or screen
	 *
	 * @param {CtaDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ctaDismissed,
	/**
	 * User checked delivery area eligibility
	 *
	 * @param {DeliveryAreaChecked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryAreaChecked,
	/**
	 * User updates their delivery area by submitting a new zip code
	 *
	 * @param {DeliveryAreaUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryAreaUpdated,
	/**
	 * Fires a 'Delivery Window Options Viewed' track call.
	 *
	 * @param {DeliveryWindowOptionsViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryWindowOptionsViewed,
	/**
	 * Fires a 'Delivery Window Selected' track call.
	 *
	 * @param {DeliveryWindowSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deliveryWindowSelected,
	/**
	 * Fires a 'Disallowed Fulfillment Offer' track call.
	 *
	 * @param {DisallowedFulfillmentOffer} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	disallowedFulfillmentOffer,
	/**
	 * User entered an email address on a form
	 *
	 * @param {EmailCaptured} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	emailCaptured,
	/**
	 * Fires a 'Entered Foreground' track call.
	 *
	 * @param {EnteredForeground} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	enteredForeground,
	/**
	 * Fires a 'Entered Payment Details' track call.
	 *
	 * @param {EnteredPaymentDetails} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	enteredPaymentDetails,
	/**
	 * Fires a 'Failed Checkout' track call.
	 *
	 * @param {FailedCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	failedCheckout,
	/**
	 * Fires a 'Favorited Product' track call.
	 *
	 * @param {FavoritedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	favoritedProduct,
	/**
	 * Fires a 'Finished Navigation' track call.
	 *
	 * @param {FinishedNavigation} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	finishedNavigation,
	/**
	 * Fires a 'Finished Spinning' track call.
	 *
	 * @param {FinishedSpinning} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	finishedSpinning,
	/**
	 * Fires a 'Fulfillment Option Sold Out' track call.
	 *
	 * @param {FulfillmentOptionSoldOut} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fulfillmentOptionSoldOut,
	/**
	 * Fires a 'Hit Search' track call.
	 *
	 * @param {HitSearch} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hitSearch,
	/**
	 * User purchased a product within their order was billed for order after the cut-off (this event will fire for every item purchased)
	 *
	 * @param {ItemBilled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemBilled,
	/**
	 * User completed checkout to place an order - this event fires for each product in checkout
	 *
	 * @param {ItemCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemCompleted,
	/**
	 * Triggered when a user updates their order post order complete but before the billed window has closed. This event will fire for each product change, wether its removed or added. This event does not fire for any updates outside of product changes
	 *
	 * @param {ItemUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemUpdated,
	/**
	 * Fires a 'Launched Clean Install' track call.
	 *
	 * @param {LaunchedCleanInstall} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	launchedCleanInstall,
	/**
	 * Fires a 'Left Product In Basket' track call.
	 *
	 * @param {LeftProductInBasket} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	leftProductInBasket,
	/**
	 * Fires a 'Logged In' track call.
	 *
	 * @param {LoggedIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loggedIn,
	/**
	 * Fires a 'Logged Out' track call.
	 *
	 * @param {LoggedOut} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	loggedOut,
	/**
	 * Non-member user clicked on Membership CTA
	 *
	 * @param {MembershipModalClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	membershipModalClicked,
	/**
	 * Fires a 'Missed Search' track call.
	 *
	 * @param {MissedSearch} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missedSearch,
	/**
	 * User views a modal on a page or screen
	 *
	 * @param {ModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	modalViewed,
	/**
	 * Fires a 'Notification Sent' track call.
	 *
	 * @param {NotificationSent} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	notificationSent,
	/**
	 * Fires a 'Opened URL' track call.
	 *
	 * @param {OpenedUrl} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	openedUrl,
	/**
	 * User billed for order after cut-off
	 *
	 * @param {OrderBilled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderBilled,
	/**
	 * Fires a 'Order Completed' track call.
	 *
	 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * Fires a 'Order Updated' track call.
	 *
	 * @param {OrderUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderUpdated,
	/**
	 * User loaded a page
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Fires a 'Partial address' track call.
	 *
	 * @param {PartialAddress} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partialAddress,
	/**
	 * User added a product to their shopping cart
	 *
	 * @param {ProductAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * Triggered when a user clicks a category or subcategory in the header navigation dropdown
	 *
	 * @param {ProductCategoryClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productCategoryClicked,
	/**
	 * Triggered when a user clicks a product list filter on the left side of a product listing page
	 *
	 * @param {ProductCategoryFiltered} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productCategoryFiltered,
	/**
	 * user clicks a product tile
	 *
	 * @param {ProductClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User favorites a product
	 *
	 * @param {ProductFavorited} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productFavorited,
	/**
	 * User removed a product from their shopping cart
	 *
	 * @param {ProductRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * User viewed a product detail page
	 *
	 * @param {ProductViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * Fires a 'Promo Code Applied' track call.
	 *
	 * @param {PromoCodeApplied} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promoCodeApplied,
	/**
	 * Fires a 'Referral CTA' track call.
	 *
	 * @param {ReferralCta} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralCta,
	/**
	 * Fires a 'Referral Clicked' track call.
	 *
	 * @param {ReferralClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralClicked,
	/**
	 * Fires a 'Referral Discount Consumed' track call.
	 *
	 * @param {ReferralDiscountConsumed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralDiscountConsumed,
	/**
	 * Fires a 'Referral Kickback Rewarded' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralKickbackRewarded,
	/**
	 * Fires a 'Referral Shared' track call.
	 *
	 * @param {ReferralShared} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralShared,
	/**
	 * Fires a 'Removed Product' track call.
	 *
	 * @param {RemovedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removedProduct,
	/**
	 * Fires a 'Rendered Checkout Button' track call.
	 *
	 * @param {RenderedCheckoutButton} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	renderedCheckoutButton,
	/**
	 * Fires a 'Sanitized Basket Item' track call.
	 *
	 * @param {SanitizedBasketItem} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sanitizedBasketItem,
	/**
	 * User clicked on a search result
	 *
	 * @param {SearchResultSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchResultSelected,
	/**
	 * Fires a 'Served Response' track call.
	 *
	 * @param {ServedResponse} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	servedResponse,
	/**
	 * Fires a 'Set Delivery Address' track call.
	 *
	 * @param {SetDeliveryAddress} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setDeliveryAddress,
	/**
	 * Fires a 'Set Delivery Time' track call.
	 *
	 * @param {SetDeliveryTime} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setDeliveryTime,
	/**
	 * User logged into their account
	 *
	 * @param {SignedIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedIn,
	/**
	 * User submitted a term into a search bar
	 *
	 * @param {SiteSearched} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	siteSearched,
	/**
	 * Fires a 'Skipped Subscription Week' track call.
	 *
	 * @param {SkippedSubscriptionWeek} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	skippedSubscriptionWeek,
	/**
	 * Fires a 'Started Add To Order' track call.
	 *
	 * @param {StartedAddToOrder} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedAddToOrder,
	/**
	 * Fires a 'Started Apple Pay Checkout' track call.
	 *
	 * @param {StartedApplePayCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedApplePayCheckout,
	/**
	 * Fires a 'Started Checkout' track call.
	 *
	 * @param {StartedCheckout} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedCheckout,
	/**
	 * Fires a 'Started Shopping Session' track call.
	 *
	 * @param {StartedShoppingSession} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startedShoppingSession,
	/**
	 * User cancels a subscription order
	 *
	 * @param {SubscriptionCancelled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionCancelled,
	/**
	 * User initiated the checkout process
	 *
	 * @param {SubscriptionCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionCreated,
	/**
	 * User clicks the subscription box in checkout
	 *
	 * @param {SubscriptionProductAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionProductAdded,
	/**
	 * User unchecks the subscription box in checkout
	 *
	 * @param {SubscriptionProductRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionProductRemoved,
	/**
	 * User updated their current subscription order
	 *
	 * @param {SubscriptionUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionUpdated,
	/**
	 * Fires a 'Unfavorited Product' track call.
	 *
	 * @param {UnfavoritedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unfavoritedProduct,
	/**
	 * Fires a 'Unskipped Subscription Week' track call.
	 *
	 * @param {UnskippedSubscriptionWeek} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	unskippedSubscriptionWeek,
	/**
	 * Fires a 'Used Promo Code' track call.
	 *
	 * @param {UsedPromoCode} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	usedPromoCode,
	/**
	 * Fires a 'Viewed 500 Error Page' track call.
	 *
	 * @param {Viewed500ErrorPage} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewed500ErrorPage,
	/**
	 * Fires a 'Viewed Basket' track call.
	 *
	 * @param {ViewedBasket} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedBasket,
	/**
	 * Fires a 'Viewed Basket Details' track call.
	 *
	 * @param {ViewedBasketDetails} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedBasketDetails,
	/**
	 * Fires a 'Viewed Homepage Feature' track call.
	 *
	 * @param {ViewedHomepageFeature} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedHomepageFeature,
	/**
	 * Fires a 'Viewed Hover Basket' track call.
	 *
	 * @param {ViewedHoverBasket} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedHoverBasket,
	/**
	 * Fires a 'Viewed Product' track call.
	 *
	 * @param {ViewedProduct} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedProduct,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/goodeggs/protocols/tracking-plans/rs_1xZ2NbBVJT9RZ0LYe1UOzYjYlWt`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
