import React, {FC, FormEvent} from 'react';

import useRecentSearches from 'web/hooks/useRecentSearches';
import segmentAnalytics from '@analytics/client';
import useClientSettings from 'web/hooks/useClientSettings';

interface MobileSearchBox {
  searchTerm: string;
  onChange: (searchTerm: string) => void;
}

const MobileSearchBox: FC<MobileSearchBox> = ({searchTerm = '', onChange}) => {
  const clientSettings = useClientSettings();
  const {addRecentSearch} = useRecentSearches();

  const handleSearch = (e: FormEvent<HTMLFormElement>): void => {
    if (searchTerm.length <= clientSettings.minSearchQueryLength) {
      e.preventDefault();
      return;
    }

    segmentAnalytics.track('siteSearched', {
      query: searchTerm,
      queryType: 'userSubmitted',
      index: clientSettings.search.algolia.index,
    });
    addRecentSearch(searchTerm);
  };

  return (
    <form
      className="search-form-mobile"
      action={searchTerm.length >= clientSettings.minSearchQueryLength ? `/search` : ''}
      onSubmit={handleSearch}
      method="get"
    >
      <label htmlFor="search-searchTerm">Search</label>
      <input
        className="search-button"
        type="text"
        name="q"
        placeholder="Search Groceries"
        autoFocus
        autoComplete="off"
        autoCapitalize="off"
        value={searchTerm}
        onChange={(e): void => onChange(e.target.value)}
      />

      {searchTerm.length > 0 ? (
        <a className="search-action" onClick={(): void => onChange('')}>
          <i className="icon icon-thin-x" />
        </a>
      ) : (
        <div className="search-action">
          <i className="icon icon-search" />
        </div>
      )}
    </form>
  );
};

export default MobileSearchBox;
