import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Alert from 'web/components/alert';

export default class Errors extends Component {
  render() {
    const {error} = this.props;
    if (!error) {
      return null;
    }
    return (
      <Alert
        className={this.props.className}
        type="error"
        heading={error.customerMessage || error.message}
      />
    );
  }
}

Errors.propTypes = {
  error: PropTypes.object,
  className: PropTypes.string,
};
