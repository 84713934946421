import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  FormEvent,
  MouseEvent,
  FC,
} from 'react';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {debounce} from 'lodash';
import qs from 'query-string';

import useRecentSearches from 'web/hooks/useRecentSearches';
import segmentAnalytics from '@analytics/client';
import useClientSettings from 'web/hooks/useClientSettings';
import {SerializedUiMarketCategory} from 'web/helpers/serializers/category';

import SearchSuggestions from '../search_suggestions';
import StickyCategory from './sticky_category';

const CATEGORY_MORE_CUTOFF = 1100;

export interface StickyDesktopCategoriesProps {
  categories: SerializedUiMarketCategory[];
  selectedNavId?: string;
}

const StickyDesktopCategories: FC<StickyDesktopCategoriesProps> = ({categories, selectedNavId}) => {
  const [isMoreSelected, setIsMoreSelected] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSearchTerm, setSelectedSearchTerm] = useState<string>();
  const {search} = useClientSettings();
  const {addRecentSearch} = useRecentSearches();
  const lastTwoCategories = useMemo(() => categories.slice(-2), [categories]);
  const inputRef = useRef<HTMLInputElement>(null);
  const displayedSearchTerm = selectedSearchTerm ?? searchTerm;

  const escapeListener = useCallback((e: any) => {
    if (/^Esc$|^Escape$/.test(e.key)) {
      handleCancelSearch();
    }
  }, []);

  const updateMoreSelected = useCallback(() => {
    const width = window.innerWidth;
    if (width < CATEGORY_MORE_CUTOFF) {
      setIsMoreSelected(lastTwoCategories.some(({id}) => id === selectedNavId));
    }
  }, [lastTwoCategories, selectedNavId]);

  const handleClickSearch = (e: MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    setIsSearching(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCancelSearch = (): void => {
    setIsSearching(false);
    document.body.style.overflow = '';
  };

  const redirectToSearchPage = (term: string): void => {
    const searchUrl = qs.stringifyUrl({url: '/search', query: {q: term}});

    window.location.href = searchUrl;
  };

  const handleSubmitSearch = (e: FormEvent<HTMLFormElement> | MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();

    if (searchTerm) {
      addRecentSearch(displayedSearchTerm);
      segmentAnalytics.track('siteSearched', {
        query: displayedSearchTerm,
        queryType: 'userSubmitted',
        index: search.algolia.index,
      });
      redirectToSearchPage(displayedSearchTerm.substring(0, 256));
    }
  };

  const listener = debounce(updateMoreSelected, 200);
  useEffect(() => {
    window.addEventListener('resize', listener);
    updateMoreSelected();
    window.removeEventListener('resize', listener);
  }, [listener, updateMoreSelected]);

  useEffect(() => {
    document.addEventListener('keydown', escapeListener);
    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, [escapeListener]);

  const lastTwoCategoryIndices = [categories.length - 1, categories.length - 2];

  return isSearching ? (
    <div>
      <div className="marketplace-hider marketplace-hider-sm" onClick={handleCancelSearch} />
      <CSSTransition
        appear
        classNames="sticky-desktop-header__inline-transition"
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
      >
        <form className="sticky-desktop-header__inline-search" onSubmit={handleSubmitSearch}>
          <input
            className="sticky-desktop-header__inline-search-input form-control"
            type="text"
            placeholder="Search Groceries"
            value={displayedSearchTerm}
            onBlur={(e) => {
              e.target.focus();
            }}
            ref={inputRef}
            onChange={(e) => {
              setSearchTerm(e.target.value.substr(0, 256));
            }}
          />
          <div
            className="sticky-desktop-header__inline-search-right-pill"
            onClick={handleSubmitSearch}
          >
            <i className="icon icon-search sticky-desktop-header__inline-search-icon" />
          </div>
          <div className="sticky-desktop-header__inline-search-cancel" onClick={handleCancelSearch}>
            Cancel
          </div>
          <SearchSuggestions
            rootRef={inputRef}
            searchTerm={searchTerm}
            onChangeSelectedItem={(selected) => {
              setSelectedSearchTerm(selected);
            }}
            onSearchItemClick={(item) => {
              addRecentSearch(item);
              redirectToSearchPage(item);
            }}
            onItemClick={(item) => {
              addRecentSearch(item);
              redirectToSearchPage(item);
            }}
          />
        </form>
      </CSSTransition>
    </div>
  ) : (
    <div className="sticky-desktop-header__departments">
      {categories.map((category, index) => {
        return category.hidden ? null : (
          <StickyCategory
            className={classNames({
              'sticky-desktop-header__include-in-more': lastTwoCategoryIndices.includes(index),
            })}
            category={category}
            key={category.id}
            flyout
            selected={selectedNavId === category.id}
          />
        );
      })}
      <StickyCategory
        category={{
          id: 'more',
          name: '',
          shortName: 'More ▾',
          url: '',
          subcategories: lastTwoCategories,
        }}
        flyout
        selected={isMoreSelected}
      />
      <div className="right-border sticky-desktop-header__category" />
      <div
        className={classNames('sticky-desktop-header__category', {
          current: selectedNavId === 'reorder',
        })}
      >
        <a
          className="remote sticky-desktop-header__category-link"
          href={`/reorder`}
          data-slug="reorder"
        >
          <div className="sticky-desktop-header__category-link-interior">Reorder</div>
        </a>
      </div>
      <div className="sticky-desktop-header__search-container">
        <a className="remote" onClick={handleClickSearch}>
          <i className="icon icon-search" />
        </a>
      </div>
    </div>
  );
};

export default StickyDesktopCategories;
