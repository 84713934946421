import $ from 'jquery';

class DropdownOpener {
  private isOpen: boolean;
  private hoverSelector: string;
  private justOpened = false;
  private callback: (pointing: boolean) => void;
  private targetSelector: string;
  private dropdownSelector: string;
  private lineItemSelector?: string;

  public constructor(
    targetSelector: string,
    dropdownSelector: string,
    callback?: (pointing: boolean) => void,
    lineItemSelector?: string,
  ) {
    this.isOpen = false;
    this.targetSelector = targetSelector;
    this.dropdownSelector = dropdownSelector;
    this.lineItemSelector = lineItemSelector;
    if (callback == null) {
      this.callback = (pointing: boolean) => {
        $(this.targetSelector).toggleClass('is-open', pointing);
        $(this.dropdownSelector).toggle(pointing);
        this.keepInsideTheWindow();
      };
    } else {
      this.callback = callback;
    }

    // If dropdown is a child of the target, only listen for events on the target
    if ($(this.dropdownSelector).parent(this.targetSelector).length > 0) {
      this.hoverSelector = this.targetSelector;
    } else {
      this.hoverSelector = `${this.targetSelector}, ${this.dropdownSelector}`;
    }

    $(this.hoverSelector).on('mouseenter', () => this.mouseEnter());
    $(this.hoverSelector).on('mouseleave', () => this.mouseLeave());
    $(this.targetSelector).on('click', (e) => this.onClick(e));
  }

  public mouseEnter(): void {
    this.open();

    // touch enabled devices will fire a mouseEnter immediately before the click
    // use the mouseEnter to open, and ignore that first click
    this.justOpened = true;
    window.setTimeout(() => (this.justOpened = false), 1);
  }

  public mouseLeave(): void {
    this.close();
  }

  public keepInsideTheWindow(): void {
    const el = $(this.dropdownSelector);
    const targetEl = $(this.targetSelector);

    const elWidth = el.width() ?? 0;
    const targetElWidth = targetEl.width() ?? 0;
    const targetElOffset = targetEl.offset() ?? {left: 0, top: 0};

    const positionOfRightSide = targetElOffset.left + elWidth;

    const windowWidth = $(window).width() ?? 0;
    const furthestWeWantItToBe = windowWidth - 20;

    const leftIfWindowAllows = (elWidth - targetElWidth) / 2;
    const leftIfWindowIsNarrow = positionOfRightSide - furthestWeWantItToBe;

    const overhang = Math.max(leftIfWindowAllows, leftIfWindowIsNarrow);

    if (overhang > 0) {
      el.css({left: `-${overhang}px`});
      el.addClass('is-adjusted-left');
    }
  }

  public onClick(e: JQuery.ClickEvent): boolean | undefined {
    if (this.justOpened) {
      e.preventDefault();
      return;
    }

    // for non hover devices, eg mobile, first click opens the dropdown; second click peforms default function (navigate)
    if (!this.isOpen) {
      this.open();
      e.preventDefault(); // don't navigate, but allow bubbling so that other dropdowns can close on touch outside
      return;
    }

    // close the window for clicks on the entries in the dropdown, but don't preventDefault behavior
    if (this.lineItemSelector && $(e.target).closest(this.lineItemSelector).length > 0) {
      this.close();
    }

    return true;
  }

  public deactivateIfClickingOutside(event: MouseEvent): void {
    // onTouch events also bubble up to the window
    if (event.target) {
      const inside =
        $(event.target).closest(this.targetSelector).length > 0 ||
        $(event.target).closest(this.dropdownSelector).length > 0;

      if (!inside) this.close();
    }
  }

  public open(): void {
    this.isOpen = true;
    this.callback(true);
    // use capture phase to check for event target location before it might have been removed (eg. remove link in hover basket)
    document.addEventListener('click', (e) => this.deactivateIfClickingOutside(e), true);
  }

  public close(): void {
    document.removeEventListener('click', (e) => this.deactivateIfClickingOutside(e), true);
    this.callback(false);
    this.isOpen = false;
  }

  public unbind(): void {
    $(this.hoverSelector).off('mouseenter', this.mouseEnter);
    $(this.hoverSelector).off('mouseleave', this.mouseLeave);
    $(this.targetSelector).off('click', this.onClick);
    document.removeEventListener('click', this.deactivateIfClickingOutside, true);
  }
}

export {DropdownOpener};
