import React from 'react';
import {AnyAction} from 'redux';
import {connect, ConnectedProps} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';

import {ISerializedUser} from 'domain/users';
import Modal from 'web/components/modal';
import ZipCodeForm, {UpdateZipCodeResponse} from 'web/components/zip_code';
import {ConsolationModalProps} from 'web/components/zip_code_consolation_modal';
import {SuccessModalProps} from 'web/components/zip_code_success_modal';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {resultCodes} from 'web/helpers/zip_code_duck';
import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

interface StateProps {
  currentFulfillmentDay: Date;
  user?: ISerializedUser;
  zipCode?: string;
}

interface DispatchProps {
  closeModal: (cb?: () => void) => void;
  openSuccessModal: (props: SuccessModalProps) => void;
  openConsolationModal: (props: ConsolationModalProps) => void;
}

export interface ZipCodeModalProps {
  onDismiss?: () => void;
}

interface Props extends ZipCodeModalProps, ConnectedProps<typeof connector> {}

const ZipCodeModal = ({
  closeModal,
  onDismiss,
  openConsolationModal,
  openSuccessModal,
  user,
  zipCode,
}: Props): React.ReactElement => {
  const onSuccess = (response: UpdateZipCodeResponse): void => {
    if (response.result === resultCodes.CONFIRMATION_REQUIRED) {
      // tell success modal to require confirmation of zip change
      openSuccessModal({
        newZip: response.data.new.zipCode,
        onDismiss,
        requireConfirmation: true,
      });
    } else {
      // updateZipCode successfully updated the zip code
      openSuccessModal({
        newZip: response.data.new.zipCode,
        onDismiss: () => {
          if (response.data.new.foodhubSlug !== response.data.previous?.foodhubSlug) {
            // if changing region/foodhub, reload marketplace state by navigating to homepage
            window.location.href = '/home';
          }
          if (onDismiss != null) {
            onDismiss();
          }
        },
      });
    }
  };
  const onError = (response: UpdateZipCodeResponse): void => {
    openConsolationModal({
      newZip: response.data.new.zipCode,
      comingSoon: false, // placeholder until endpoint includes this data
      onDismiss,
    });
  };
  const clientSettings = useClientSettings();
  const enableNewLogos = clientSettings.enableNewLogos.enableWeb;

  return (
    <Modal className="refresh" fullScreenMobile onClose={(): void => closeModal(onDismiss)}>
      <div className="auth-flow__modal" id="zip__modal">
        <div className="auth-flow__content">
          <div className="auth-flow__header">
            <div className="close-modal__icon">
              <i
                className="icon icon-thin-x"
                data-testid="modal-close-icon"
                onClick={(): void => closeModal(onDismiss)}
              />
            </div>
            <div className="auth-flow__logo">
              {enableNewLogos ? (
                <img src={`${assetPath('/img/svg/icons/ge-header-logo-white.svg')}?auto=format`} />
              ) : (
                <i className={`icon icon-logo-black`} />
              )}
            </div>
          </div>
          <div>
            <div className="flow-zip-entry desktopMargin__modal">
              <div>
                <h1 className="modal__title">
                  {zipCode != null ? (
                    <>Enter a new zip code for your delivery</>
                  ) : (
                    <>Absurdly fresh groceries, delivered.</>
                  )}
                </h1>
              </div>
              <div className="modal-content">
                <div className="modal-content__message" data-testid="message-container">
                  {zipCode != null ? (
                    <>
                      You are currently shopping for
                      <br />
                      <strong>{zipCode}</strong>
                    </>
                  ) : (
                    <>Enter your zip code to see if we deliver to your address.</>
                  )}
                </div>
                <ZipCodeForm successHandler={onSuccess} errorHandler={onError} />
                {user == null ? (
                  <p className="sign-in-flow__sign-in">
                    Already have an account?{' '}
                    <a
                      className="text-link"
                      href="/signin"
                      onClick={(): void => closeModal(onDismiss)}
                    >
                      Sign in
                    </a>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: StateProps): StateProps => ({
  currentFulfillmentDay: state.currentFulfillmentDay,
  user: state.user,
  zipCode: state.zipCode,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, Props, AnyAction>,
): DispatchProps => {
  return {
    closeModal: (cb): void => {
      dispatch(modalActions.closeModal());
      if (cb != null) {
        cb();
      }
    },
    openSuccessModal: (props): void => {
      dispatch(modalActions.replaceModal({modal: 'ZipCodeSuccessModal', props}));
    },
    openConsolationModal: (props): void => {
      dispatch(modalActions.replaceModal({modal: 'ZipCodeConsolationModal', props}));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ZipCodeModal);
