import update from 'immutability-helper';

import basketDuck from 'web/helpers/basket_duck';

export const SET_SIGN_UP_MODAL_OPEN = 'SET_SIGN_UP_MODAL_OPEN';
export const SET_SIGN_UP_MODAL_CLOSE = 'SET_SIGN_UP_MODAL_CLOSE';
export const SET_USER = 'SET_USER';
export const APPEND_TO_BASKET_ITEMS = 'APPEND_TO_BASKET_ITEMS';

export const actions = {
  showSignUpModalFlow: () => ({
    type: SET_SIGN_UP_MODAL_OPEN,
  }),
  setUser: (user) => ({
    type: SET_USER,
    payload: user,
  }),
  appendToBasketItems: ({basket, products}) => ({
    type: APPEND_TO_BASKET_ITEMS,
    payload: {basket, products},
  }),
};

const initialState = {
  showSignUpModalFlow: false,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SIGN_UP_MODAL_OPEN:
      return update(state, {showSignUpModalFlow: {$set: true}});
    case SET_SIGN_UP_MODAL_CLOSE:
      return update(state, {showSignUpModalFlow: {$set: false}});
    case SET_USER:
      return update(state, {
        user: {$set: action.payload},
      });
    case APPEND_TO_BASKET_ITEMS: {
      const {basket: payloadBasket} = action.payload;
      const {basket: stateBasket} = state;

      const payloadBasketIds = payloadBasket.items.map(({id}) => id);
      const deDupedStateBasketItems = stateBasket.items.filter((item) => {
        return !payloadBasketIds.includes(item.id);
      });

      const newBasket = {
        ...state.basket,
        items: [...deDupedStateBasketItems, ...payloadBasket.items],
      };

      return update(state, {
        basket: {
          $set: newBasket,
        },
        products: {
          $set: {
            ...state.products,
            ...action.payload.products,
          },
        },
        basketItemsByProductId: {
          $set: basketDuck.helpers.itemsById(newBasket),
        },
      });
    }
    default:
      return state;
  }
}
