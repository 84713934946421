//
// This is a thin wrapper around Segment for use in browser.
// Adds lots of extra context to tracked events.
// Async methods return promises.
// More background here: https://docs.google.com/a/goodeggs.com/document/d/1HR3vv0MpDM5c6-AoWGOLvpIngxc5tiedYaRYqIN4TyQ/edit?usp=sharing
//

import {assignIn} from 'lodash';

import integrationRouter from 'infra/metrics/integration_router';
import cookies from 'web/helpers/cookies';
import segmentAnalytics from '@analytics/client';

import SegmentClient from './segment_client';

class Metrics {
  constructor({disableSegment} = {}) {
    this.disableSegment = disableSegment || false;
    this.segment = new SegmentClient();
    this.pageProperties = {};
    this.setShoppingSessionPageProperty();
  }

  /*
  TODO (joey) rather than reading a cookie, this could be set by the redux store page properties once all pages are reactified.
  */
  setShoppingSessionPageProperty() {
    return this.setPageProperty('shoppingSession', cookies.getShoppingSessionCookie());
  }

  // Alternative to segment identify that sets ids in the client library without
  // incurring an extra HTTP request.  We call identify on the server to record user traits.
  setUserIds({userId, anonymousId: _anonymousId}) {
    this._anonymousId = _anonymousId;
    this.setPageProperty('loggedIn', userId != null);
    return this.segment.setUserIds({userId, anonymousId: this._anonymousId});
  }

  // Page properties will be sent with every event tracked on this page.
  setPageProperty(property, value) {
    return (this.pageProperties[property] = value);
  }

  _getEnvProperties() {
    const properties = {};

    const {width, height} = this._getWindowSize();
    // Specced by Segment common context
    // https://segment.com/docs/spec/common/#context
    properties.screen = {width, height};

    // Used to discriminate web from ios
    properties.platform = 'web';

    // Probably deprecate with MIXPANEL_DIRECT? We have better ways to get this in mode.
    if (
      properties.totalOrders == null &&
      this.user &&
      this.user.statistics &&
      this.user.statistics.ordersPlacedCount
    ) {
      properties.totalOrders = this.user.statistics.ordersPlacedCount;
    }

    // TODO(adam): These properties should be set as pageProperties read from the redux store.
    properties.foodshed = 'sfbay';
    properties.experiments = {};
    for (const cookie of cookies.getAll().filter((c) => /^laboratory-/.test(c.name))) {
      properties.experiments[cookie.name.replace(/^laboratory-/, '')] = cookie.value;
    }

    if (this.pageProperties) {
      assignIn(properties, this.pageProperties);
    }
    return properties;
  }

  _getWindowSize() {
    const w = window;
    const d = document;
    const e = d && d.documentElement;
    const g =
      d && typeof d.getElementsByTagName === 'function' && d.getElementsByTagName('body')[0];

    return {
      width: (w && w.innerWidth) || (e && e.clientWidth) || (g && g.clientWidth),
      height: (w && w.innerHeight) || (e && e.clientHeight) || (g && g.clientHeight),
    };
  }

  // See: https://segment.com/docs/libraries/analytics.js/#track
  // Returns a promise resolved when Segment confirms the track.
  // If the page unloads before the promise resolves, the track may be lost.
  // See infra/metrics/README.md for details.
  // Add includeEnvProperties flag as parameter, it always be turn on, for the new events it must be set as false to
  // not add the enviroment properties.
  track(event, properties = {}, includeEnvProperties = true) {
    if (this.disableSegment) {
      return;
    }
    if (includeEnvProperties) {
      properties = assignIn(this._getEnvProperties(), properties);
    }

    return this.segment.track(event, properties, {
      integrations: integrationRouter.buildClientIntegrations(event),
    });
  }

  // See: https://segment.com/docs/libraries/analytics.js/#page
  // Also sets Heap session properties
  page(category, name, properties = {}, options = {}) {
    if (this.disableSegment) {
      return;
    }
    if (!(typeof name === 'string')) {
      name = category;
      category = null;
    }

    const segmentArgs = [properties, options];

    if (name) {
      // Include pageName with all tracked metrics
      this.setPageProperty('pageName', name);
      segmentAnalytics.setContextField('pageName', name);
      segmentArgs.unshift(name);
    }

    if (category) {
      segmentArgs.unshift(category);
    }

    // Pass A/B test experiments to Heap as session attributes
    // so they're available in Heap clickstream analysis.
    // See https://heapanalytics.com/docs/custom-api#seteventproperties
    this.segment.ready(() => {
      const heapSessionProperties = {};
      for (const experimentName in properties.experiments) {
        if (!Object.prototype.hasOwnProperty.call(properties.experiments, experimentName)) {
          continue;
        }
        const variant = properties.experiments[experimentName];
        heapSessionProperties[`experiments.${experimentName}`] = variant;
      }
      heapSessionProperties['anonymous-id'] = this._anonymousId;
      return window.heap && window.heap.setEventProperties(heapSessionProperties);
    });

    return this.segment.page(...Array.from(segmentArgs || []));
  }
}

export default Metrics;
