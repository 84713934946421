import React, {FC} from 'react';

import {humanPriceFromCents} from 'infra/formatters/money';

export interface SavingBannerProps {
  text?: string;
  totalSavings: number;
}

const SavingBanner: FC<SavingBannerProps> = ({text, totalSavings}) => {
  const defaultText = `You're saving ${humanPriceFromCents(totalSavings)} on this order!`;
  return (
    <div className="saving-banner-container">
      <div data-testid="saving-banner" className="saving-banner">
        <p>{text || defaultText} </p>
      </div>
    </div>
  );
};

export default SavingBanner;
