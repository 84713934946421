import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import Cents from 'goodeggs-money';

import {getFirstAvailableDay} from 'web/helpers/product';
import {humanPriceFromCents} from 'infra/formatters/money';
import {IMarketProductUI} from 'domain/catalog/products/models/market_product_ui';
import segmentAnalytics from '@analytics/client';
import {SerializedUIBasket} from 'domain/baskets/serializer';
import {ShoppingContextFulfillmentDaySummary} from 'orchestration/queries/get_shopping_context';
import useClientSettings from 'web/hooks/useClientSettings';

import Placeholder from './components/Placeholder';
import AvailabilityIndicator from './components/AvailabilityIndicator';
import AvailabilityMessage from './components/AvailabilityMessage';
import Favorite from './components/Favorite';
import PriceInfo from './components/PriceInfo';
import QuickAdd from './components/QuickAdd';
import ProductTag from '../product_tag';

export interface UpcomingPreorderPeriod {
  preorderDays: string[];
}

interface ProductDetailsState {
  breadCrumbs?: {
    category: {
      name: string;
      url: string;
    };
    subcategory: {
      name: string;
      url: string;
    };
  };
}

export interface MarketTileState {
  currentFulfillmentDay: string;
  fulfillmentDaysByDay: {[key: string]: ShoppingContextFulfillmentDaySummary};
  fulfillmentDaySummaries: ShoppingContextFulfillmentDaySummary[];
  upcomingPreorderPeriods: UpcomingPreorderPeriod[];
  shoppingDaysLength: number;
  quantityInBasket: number;
  activePreorderPeriod?: UpcomingPreorderPeriod;
  isFavorited?: boolean;
}

interface MarketTileProps extends Partial<MarketTileState> {
  product?: any;
  productId?: string;
  segmentFeature: string;
  position: number;
  context?: any;
  onQuickAdd?(productId: string): void;
  onQuickRemove?(productId: string): void;
  onFavorite?(productId: string): void;
  onUnFavorite?(productId: string): void;
  onProductView?(callback?: () => void): void;
  onVendorView?(): void;
}

export interface StateProps {
  category: {name: string};
  subcategory?: string;
  basketItemsByProductId: {[productId: string]: {quantity: number}};
  upcomingPreorderPeriods: UpcomingPreorderPeriod[];
  currentFulfillmentDay: string;
  fulfillmentDaysByDay: {[key: string]: ShoppingContextFulfillmentDaySummary};
  fulfillmentDaySummaries: ShoppingContextFulfillmentDaySummary[];
  shoppingDaysLength: number;
  products: {[productId: string]: IMarketProductUI};
  favorites: string[];
  basket: SerializedUIBasket;
  productDetails?: ProductDetailsState;
}

const MarketTile = ({
  product: providedProduct,
  productId,
  segmentFeature,
  position,
  context,
  onProductView,
  onVendorView,
  onFavorite,
  onUnFavorite,
  onQuickAdd,
  onQuickRemove,
}: MarketTileProps): ReactElement => {
  const clientSettings = useClientSettings();
  const pid = (productId || providedProduct?.id) ?? '';
  const product: any = useSelector((state: StateProps) => state.products[pid]);
  const basket = useSelector((state: StateProps) => state.basket);
  const category = useSelector((state: StateProps) => state.category);
  const productDetails = useSelector((state: StateProps) => state.productDetails);
  const subcategory = useSelector((state: StateProps) => state.subcategory);
  const currentFulfillmentDay = useSelector((state: StateProps) => state.currentFulfillmentDay);
  const fulfillmentDaysByDay = useSelector((state: StateProps) => state.fulfillmentDaysByDay);
  const fulfillmentDaySummaries = useSelector((state: StateProps) => state.fulfillmentDaySummaries);
  const upcomingPreorderPeriods = useSelector((state: StateProps) => state.upcomingPreorderPeriods);
  const quantityInBasket = useSelector(
    (state: StateProps) => state.basketItemsByProductId[pid]?.quantity ?? 0,
  );
  const isFavorited = useSelector((state: StateProps) => state.favorites.includes(pid));
  const activePreorderPeriod = useSelector((state: StateProps) =>
    state.upcomingPreorderPeriods?.find((preorder) =>
      preorder.preorderDays.includes(currentFulfillmentDay),
    ),
  );

  const dispatch = useDispatch();

  const handleVendorView = (): void => {
    onVendorView?.();
  };

  let firstAvailableDay: string | undefined, isAvailableForShoppingDay: boolean | undefined;
  const isPromotional =
    clientSettings.strikethroughPricing.enableWeb &&
    Boolean(product?.basePrice) &&
    Boolean(product?.promoPrice);
  if (product !== undefined) {
    firstAvailableDay = getFirstAvailableDay({
      product,
      fulfillmentDaysByDay,
      fulfillmentDaySummaries,
      currentFulfillmentDay,
      upcomingPreorderPeriods,
    });

    isAvailableForShoppingDay =
      product.availabilitiesByDay?.[currentFulfillmentDay]?.status === 'available' &&
      fulfillmentDaysByDay?.[currentFulfillmentDay]?.status === 'available';
  }

  const subscriptionDiscount = product?.quantityOptions?.[0]?.quantitySubscriptionDiscount ?? 0;
  const CLASS_NAME = 'product-tile';

  // TODO this should probably defer default navigation
  // behavior to support async handlers. Perhaps we can use React's Router?
  const handleProductView = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    segmentAnalytics.track('productClicked', {
      productId: pid,
      objectID: pid,
      quantity: quantityInBasket ?? product?.quantity,
      engine: context?.engine,
      moduleName: context?.moduleName,
      moduleType: context?.moduleType,
      sku: pid,
      feature: segmentFeature,
      name: product.name,
      position,
      coupon: basket.promoCodes.map((promo) => promo.code).join(),
      imageUrl: product.photoUrl,
      value: new Cents(quantityInBasket * product.retailPrice).toDollars(),
      producer: product.currentProducer.name,
      url: product.url,
      category: category?.name || productDetails?.breadCrumbs?.category.name.toLowerCase(),
      subCategory:
        subcategory ||
        context?.subcategoryName ||
        productDetails?.breadCrumbs?.subcategory.name.toLowerCase(),
      price: new Cents(product.retailPrice).toDollars(),
      queryID: context?.queryID,
      index: window.settings?.search?.algolia?.index,
    });
    if (e.metaKey) onProductView?.();
    else if (onProductView) {
      e.preventDefault();
      onProductView(() => {
        if (!e.metaKey) window.location.href = product.url ?? '';
      });
    }
  };

  return (
    <div
      data-testid="market-tile"
      className={classNames('grid-item', {
        empty: product === undefined,
        lazyload: product === undefined,
      })}
      data-product-id={pid}
    >
      {product !== undefined ? (
        <div
          className={classNames([
            'product-tile',
            CLASS_NAME === 'product-tile' ? 'product-tile--compact' : CLASS_NAME,
            'product-tile--market',
            {
              'product-tile-is-in-basket': quantityInBasket !== 0,
              'product-tile-disabled': !isAvailableForShoppingDay,
            },
          ])}
          data-id={pid}
          data-testid={CLASS_NAME}
        >
          <Favorite
            isFavorited={isFavorited}
            segmentFeature={segmentFeature}
            productId={pid}
            onFavorite={onFavorite}
            onUnFavorite={onUnFavorite}
            dispatch={dispatch}
          />
          {isPromotional && (
            <ProductTag promoPrice={product.promoPrice} basePrice={product.basePrice} />
          )}
          <div className={`${CLASS_NAME}__product-visuals`}>
            <a
              className="js-product-tile__product-photo-wrapper js-product-photo-link js-product-link"
              data-alt={product.name}
              data-src={product.photoLargeUrl ?? product.photoUrl}
              href={product.url}
              onClick={handleProductView}
            >
              <img
                className={`${CLASS_NAME}__product-photo product-tile-photo`}
                alt={product.name}
                src={product.photoLargeUrl ?? product.photoUrl}
              />
            </a>
            <div className="product-tile__product-photo-tinter" />
            {isAvailableForShoppingDay && (
              <QuickAdd
                segmentFeature={segmentFeature}
                quantityInBasket={quantityInBasket}
                productId={pid}
                maxQuantity={product.maxQuantity ?? 0}
                position={position}
                context={context}
                onQuickAdd={onQuickAdd}
                onQuickRemove={onQuickRemove}
                dispatch={dispatch}
              />
            )}
            {!isAvailableForShoppingDay && (
              <AvailabilityMessage availabilitiesByDay={product.availabilitiesByDay ?? {}} />
            )}
          </div>
          <div className={`${CLASS_NAME}__product-info`}>
            <div className={`${CLASS_NAME}__product-name-and-details`}>
              {!isAvailableForShoppingDay && firstAvailableDay && (
                <AvailabilityIndicator
                  firstAvailableDay={firstAvailableDay}
                  isShoppingForPreorder={Boolean(activePreorderPeriod)}
                  isPreorderDay={
                    !fulfillmentDaySummaries.find((day) => day.day === firstAvailableDay)
                  }
                />
              )}
              <a className="js-producer-link" href={product.vendor?.url} onClick={handleVendorView}>
                <div
                  className="product-tile__producer-name"
                  data-testid="product-tile-producer-name"
                >
                  {product.recipe?.totalTime || product.vendor?.name}
                </div>
              </a>
              <div className={`${CLASS_NAME}__product-name`}>
                <a
                  className="js-product-link"
                  href={product.url}
                  onClick={handleProductView}
                  data-testid="product-tile-product-name-link"
                >
                  <h5 className="product-tile__product-name">{product.name}</h5>
                </a>
              </div>
              {product.canSubscribe && (
                <div
                  className="product-tile__subscription-container"
                  data-testid={`${CLASS_NAME}-can-subscribe`}
                >
                  <i className="icon icon-subscriptions" />
                  {subscriptionDiscount !== 0
                    ? `Save ${humanPriceFromCents(subscriptionDiscount)}`
                    : ''}
                </div>
              )}
            </div>
            <PriceInfo product={product} />
          </div>
        </div>
      ) : (
        <Placeholder productInfo={providedProduct} />
      )}
    </div>
  );
};

export default MarketTile;
