import React, {Component} from 'react';

import {shortCutoffTimeAndDay} from 'infra/formatters/time';
import Modal from 'web/components/modal';

export default class AddToOrder extends Component {
  constructor(props) {
    super(props);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.state = {
      isModalOpen: false,
    };
  }

  onOpenModal(event) {
    event.preventDefault();
    this.setState({isModalOpen: true});
    if (this.props.onOpenModal) {
      this.props.onOpenModal();
    }
  }

  onCloseModal() {
    this.setState({isModalOpen: false});
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  }

  getAddToOrderLink() {
    return `/account/orders/${this.props.orderId}/add-to-order`;
  }

  render() {
    return (
      <div>
        {this.state.isModalOpen ? (
          <Modal onClose={this.onCloseModal}>
            <div className="single-order-page__add-to-order-modal">
              <div className="single-order-page__modal-title">Add to Order</div>
              <div className="single-order-page__modal-body">
                <span>Add new items to your basket</span>
                <br />
                <span>
                  and check out by {shortCutoffTimeAndDay(this.props.orderCutoff, this.props.tzid)}.
                </span>
              </div>
              <a className="single-order-page__modal-shop-button" href={this.getAddToOrderLink()}>
                Shop The Market
              </a>
            </div>
          </Modal>
        ) : null}
        <a
          className={this.props.className || 'single-order-page__add-to-order-button button'}
          href={this.getAddToOrderLink()}
          onClick={this.onOpenModal}
        >
          Add to Order
        </a>
      </div>
    );
  }
}
