import React, {FC} from 'react';

import PopularItems from './components/popular_items';
import RecentSearches from './components/recent_searches';
import SearchAsYouType from './components/search_as_you_type';
import TrendingItems from './components/trending_items';

interface SearchSuggestions {
  searchTerm: string;
  onSearchItemClick: (item: string) => void;
  rootRef?: React.RefObject<HTMLElement>;
  onChangeSelectedItem?: (selectedItem?: string) => void;
  onItemClick?: (item: string) => void;
  isMobile?: boolean;
}

const SearchSuggestions: FC<SearchSuggestions> = ({
  searchTerm,
  rootRef,
  onSearchItemClick,
  onChangeSelectedItem,
  onItemClick,
  isMobile = false,
}) =>
  searchTerm && searchTerm.length >= 2 ? (
    <SearchAsYouType
      searchTerm={searchTerm}
      rootRef={rootRef}
      onChangeSelectedItem={onChangeSelectedItem}
      onItemClick={onItemClick}
      isMobile={isMobile}
    />
  ) : (
    <div className="search-suggestions">
      <RecentSearches onItemClick={onSearchItemClick} />
      <TrendingItems onItemClick={onSearchItemClick} />
      <PopularItems />
    </div>
  );

export default SearchSuggestions;
