import {formatMoney} from 'goodeggs-formatters';

export {formatMoney};

export type Cents = number;

// ex: split(123) -> [1, 23]
export function split(priceInCents: Cents): [number, number] {
  const centsInADollar = 100;
  const cents = Math.round(priceInCents % centsInADollar);
  const dollars = Math.round((priceInCents - cents) / centsInADollar);
  return [dollars, cents];
}

// ex: padCents(1) -> "01"
// Care of http://stackoverflow.com/a/9744576/407845
export function padCents(cents: Cents): string {
  const precision = -2;
  return `00${Math.abs(cents == null ? 0 : cents).toString()}`.slice(precision);
}

// TODO - convert showZeroAsFree|showZeroAsGift to an enum instead of two different booleans
export function humanPriceFromCents(
  cents: Cents,
  {
    showZeroAsFree,
    showZeroAsGift,
    short,
  }: {showZeroAsFree?: boolean; showZeroAsGift?: boolean; short?: boolean} = {},
): string {
  if (showZeroAsGift != null && showZeroAsGift && cents === 0) {
    return 'GIFT';
  }

  if (showZeroAsFree != null && showZeroAsFree && cents === 0) {
    return 'FREE';
  }

  const negative = cents < 0;
  const sp = split(Math.abs(cents));

  if (short != null && short && sp[1] === 0) {
    return `${negative ? '–' : ''}$${sp[0]}`;
  }

  // – (negative sign), not - (hyphen)
  return `${negative ? '–' : ''}$${sp[0]}.${padCents(sp[1])}`;
}

export function humanDollarsFromCents(cents: Cents): string {
  return humanPriceFromCents(cents, {short: true});
}

export function formatPromoCode({
  code,
  label,
  value,
  type,
}: {
  code: string;
  label?: string;
  value: number;
  type: 'dollar' | 'percent';
}): string {
  const humanValue = type === 'dollar' ? `${formatMoney(value)} off` : `${value}% off`;
  let formatted = label == null || label === '' ? code : label;
  if (value > 0) {
    formatted += ` (${humanValue})`;
  }
  return formatted;
}
