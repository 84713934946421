import React from 'react';
import {oneOfType, arrayOf, node, object} from 'prop-types';
import {connect} from 'react-redux';
import rolodex from 'goodeggs-rolodex';

import MinimalHeader from './components/minimal_header';
import MinimalFooter from './components/minimal_footer';

const MinimalLayout = (props) => (
  <div className="minimal-layout" data-testid="minimal-layout">
    <MinimalHeader user={props.user} />
    <div className="minimal-layout__content">{props.children}</div>
    <MinimalFooter
      email={rolodex.eaterHelpEmailAddress()}
      phoneNumber={rolodex.eaterHelpPhoneNumber()}
    />
  </div>
);

MinimalLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  user: object,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(MinimalLayout);
