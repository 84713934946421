import React, {FC} from 'react';

const ForYouControls: FC = () => {
  return (
    <li className="dropdown-menu__container desktop-header__account-menu">
      <a className="desktop-header__link dropdown-menu__button js-for-you-menu-button">
        <svg
          className="desktop-header__favorite-heart icon icon-heart"
          viewBox="8.9 7.8 18 16.633654"
        >
          <path d="m 17.89989,23.433653 c 0.631042,-0.630881 4.233291,-4.23521 6.485296,-6.486896 2.017605,-2.017765 1.974565,-4.958732 0.140161,-6.783217 -1.834405,-1.8249642 -4.800652,-1.8169641 -6.625457,0.0176 -1.824645,-1.8345641 -4.790892,-1.8425642 -6.625297,-0.0176 -1.8344041,1.824485 -1.8772842,4.765452 0.140161,6.783217 2.251845,2.251686 5.854574,5.856015 6.485136,6.486896 l 0,0 z" />
        </svg>
        For you ▾
      </a>
      <div className="dropdown-menu__content js-for-you-menu-content">
        <a className="dropdown-menu__item remote" href="/for-you">
          Curated for You
        </a>
        <a className="dropdown-menu__item remote" href="/favorites">
          Favorites
        </a>
      </div>
    </li>
  );
};

export default ForYouControls;
