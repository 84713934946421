import $ from 'jquery';
import React, {FC, useEffect} from 'react';
import classNames from 'classnames';

import analytics from '@analytics/client';
import {DropdownOpener} from 'web/helpers/dropdown_opener';
import {SerializedUiMarketCategory} from 'web/helpers/serializers/category';

export interface CategoryProps {
  category: SerializedUiMarketCategory;
  flyout?: boolean;
  selected?: boolean;
  className?: string;
}

const CategoryDropdown: FC<CategoryProps> = ({flyout, category, selected, className}) => {
  useEffect(() => {
    // eslint-disable-next-line no-new
    new DropdownOpener(
      `.desktop-header__category-${category.id}`,
      `.desktop-header__category-${category.id} .category-expansion`,
      (pointing) =>
        $(`.desktop-header__category-${category.id} .category-expansion`).toggleClass(
          'open',
          pointing,
        ),
      `.desktop-header__category-${category.id} .category-expansion__item`,
    );
  }, []);

  const handleRedirect = ({
    url,
    categoryName,
    subcategory,
  }: {
    categoryName: string;
    url: string;
    subcategory?: string;
  }): void => {
    analytics.track('productCategoryClicked', {
      category: categoryName,
      destination: url,
      pageUrl: window.location.href.split(window.location.host)[1],
      subCategory: subcategory,
    });

    window.location.href = url;
  };

  return (
    <div
      className={classNames(
        `desktop-header__category-${category.id}`,
        'desktop-header__category',
        {
          current: selected,
          'desktop-header__category--short': true,
        },
        className,
      )}
      data-testid="desktop-header__category"
    >
      <a
        className="remote desktop-header__category-link"
        href={category.url}
        onClick={() => {
          handleRedirect({
            url: category.url,
            categoryName: category.shortName,
          });
        }}
        data-slug={category.id}
      >
        <div
          className={`desktop-header__${category.id}-category desktop-header__category-link-interior`}
        >
          <div className="desktop-header__category-link-interior__primary-heading">
            <span>{category.shortName}</span>
          </div>
        </div>
      </a>

      {flyout && (
        <div className="category-expansion expansion">
          <div className="category-expansion__content">
            {category.subcategories.map((subcategory) => (
              <a
                key={subcategory.id}
                className="category-expansion__item"
                href={subcategory.url}
                onClick={(e) => {
                  e.preventDefault();
                  handleRedirect({
                    url: subcategory.url,
                    categoryName: category.shortName,
                    subcategory: subcategory.name,
                  });
                }}
              >
                {subcategory.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
