import React, {FC, PropsWithChildren} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import SignUpForm, {Errors} from 'web/components/sign_up_form';
import SocialSignIn from 'web/components/social_sign_in';
import {actions as modalActions} from 'web/helpers/modal_duck';
import ModalHeader from 'web/components/modal_header';
import ModalText from 'web/components/modal_text';
import {SignInFormCta} from 'web/components/sign_in_form';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';

import {actions} from '../duck';

export interface SignUpProps {
  error: string | null;
  headingText: string;
  signInUrl?: string;
  submitSignUpForm: () => void;
  working: boolean;
  onClose: () => void;
  isModal: boolean;
  segmentFeature?: string;
}

const SignUp: FC<PropsWithChildren<SignUpProps>> = (props) => {
  const dispatch = useDispatch();
  const handleSubmit = props.submitSignUpForm;
  const features = useSelector((state: any) => state.features);
  const excludePhone: boolean = features.includes(FeatureFlags.RemovePhoneInput);

  const handleSignInLinkClicked = (): void => {
    if (props.signInUrl == null) {
      dispatch(
        modalActions.replaceModal({
          modal: 'SignInModal',
          modalViewed: {
            feature: props.segmentFeature,
            pageUrl: window.location.pathname,
            loggedIn: false,
            ctaText: SignInFormCta,
          },
        }),
      );
    }
  };

  return (
    <div className={props.isModal ? 'sign-up-page sign-up-modal' : 'sign-up-page'}>
      <ModalHeader
        headingText={props.headingText}
        isModal={props.isModal}
        onClose={props.onClose}
      />

      {props.isModal && (
        <ModalText text="Absurdly fresh groceries delivered same day. No subscription required." />
      )}

      <Errors className="sign-up-page__errors" error={props.error} signInUrl={props.signInUrl} />

      {/* TODO:(@shermam) There is something really weird going on with the HOC
            do 'redux-form'. And it looks like they don't provide a hook to use instead
            of the HOC. I tried spending over 30 minutes trying to port the component to TS
            with no success. We should come back and think of a better solution there
        */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SignUpForm excludePhone={excludePhone} isSaving={props.working} onSubmit={handleSubmit} />
      <SocialSignIn onLogin={() => actions.onLogin()} />

      <p className="sign-up-page__sign-in">
        Already have an account? &nbsp;
        <a
          data-testid="sign-in-link"
          href={props.signInUrl ? props.signInUrl : '#'}
          onClick={handleSignInLinkClicked}
        >
          Sign In
        </a>
      </p>
      {!excludePhone && (
        <div className="sign-up-page__phone-disclaimer">
          By entering your phone number, you agree to receive text messages from Good Eggs regarding
          your order. Standard message rates apply. Only U.S. based numbers are allowed.
        </div>
      )}
      <p className="sign-up-page__terms-and-conditions">
        By joining, you agree to our &nbsp;
        <a href="/terms">Terms & Conditions</a>
        &nbsp; and &nbsp;
        <a href="/terms/privacy">Privacy Policy</a>
      </p>
    </div>
  );
};

export default SignUp;
