/* eslint-disable unicorn/filename-case */
import {useState} from 'react';

const useLocalStorage = <T,>(
  key: string,
  initialValue: T,
): [T, (value: T | ((previousValue: T) => T)) => void, () => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') return initialValue;
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T | ((previousValue: T) => T)): void => {
    if (typeof window === 'undefined') return;
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const removeKey = (): void => {
    try {
      setStoredValue(undefined);
      window.localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  // TODO: (@guilherme-vp) Refactor to use object destructuring for better readability
  return [storedValue, setValue, removeKey];
};

export default useLocalStorage;
