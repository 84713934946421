import React, {FC, SyntheticEvent, useState} from 'react';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import Recaptcha from 'react-recaptcha';
import {useDispatch} from 'react-redux';
import {StripeCardElementChangeEvent} from '@stripe/stripe-js';

import Alert from 'web/components/alert';
import {AppDispatch} from 'web/helpers/redux_client';
import useClientSettings from 'web/hooks/useClientSettings';

import {actions} from './duck';

export interface CreditCardFormProps {
  captchaToken?: string | null;
  creditCardError?: {message: string} | null;
  setupIntentId?: string | null;
  buttonText?: string;
  checkoutSeparator?: boolean;
  onCreditCardSave?: () => void;
  onCreditCardCancel?: () => void;
  recaptchaSitekey?: string;
  showButtons?: boolean;
  showError?: boolean;
  showCaptcha?: boolean;
  isSetupIntentLoading?: boolean;
}

const CreditCardForm: FC<CreditCardFormProps> = ({
  captchaToken,
  creditCardError,
  setupIntentId,
  buttonText = 'Save',
  checkoutSeparator,
  onCreditCardSave,
  onCreditCardCancel,
  recaptchaSitekey,
  showButtons = true,
  showError = true,
  showCaptcha,
  isSetupIntentLoading = false,
}) => {
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch: AppDispatch = useDispatch();
  const {stripe: stripeSettings} = useClientSettings();

  const handleSubmit = async (ev?: SyntheticEvent): Promise<void> => {
    ev?.preventDefault();

    const cardElement = elements?.getElement(CardElement);

    if (!cardElement || !stripe || !setupIntentId) return;

    dispatch(
      actions.confirmSetupIntent(
        stripe,
        cardElement,
        setupIntentId,
        captchaToken,
        onCreditCardSave,
        stripeSettings.enableStripeActions,
      ),
    );
  };

  const handleCancel = (ev: SyntheticEvent): void => {
    ev.preventDefault();
    onCreditCardCancel?.();
  };

  const handleCaptchaExpired = (): void => {
    dispatch(actions.captchaToken(null));
  };

  const handleCardChange = (event: StripeCardElementChangeEvent): void => {
    setCardComplete(event.complete);
  };

  if (!stripe || isSetupIntentLoading) {
    return <div className="form-control credit-card-form__placeholder">Loading...</div>;
  }

  return (
    <div className="credit-card-form" data-testid="credit-card-form">
      <CardElement className="form-control" onChange={handleCardChange} />

      {showCaptcha ? (
        <Recaptcha
          sitekey={recaptchaSitekey}
          render="explicit"
          onloadCallback={() => dispatch(actions.captchaToken(null))}
          verifyCallback={(token) => dispatch(actions.captchaToken(token))}
          expiredCallback={handleCaptchaExpired}
        />
      ) : null}
      <div className="credit-card-form__secure">
        <i className="credit-card-form__icon-lock icon icon-lock" />
        <span> We use SSL encryption to protect your information</span>
      </div>
      {checkoutSeparator ? <hr className="checkout__separator" /> : null}
      {showButtons ? (
        <div className="payment-details-page__controls checkout__button-row">
          <button
            type="button"
            className={classNames('submit-button', 'button--checkout', 'button')}
            onClick={handleSubmit}
            disabled={(showCaptcha && captchaToken == null) || !cardComplete}
          >
            {buttonText}
          </button>
          {onCreditCardCancel ? (
            <button
              type="button"
              className={classNames('button', 'button--checkout', 'button-is-secondary', 'cancel')}
              onClick={handleCancel}
            >
              Cancel
            </button>
          ) : null}
        </div>
      ) : null}
      {showError && creditCardError ? (
        <Alert type="error" heading="Sorry, there was a problem with your payment.">
          {creditCardError.message}
        </Alert>
      ) : null}
    </div>
  );
};

export default CreditCardForm;
