import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import assetPath from 'web/helpers/asset_path';
import {actions as zipCodeActions} from 'web/helpers/zip_code_duck';
import SigninHelpers from 'web/components/market_layout/components/header/components/signin_helpers';

import {actions} from './duck';
import FlowZipEntry from './components/zip_entry_screen';
import FlowSocialSignInScreen from './components/social_sign_in_screen';
import FlowSignUp from './components/sign_up_screen';
import FlowSignIn from './components/sign_in_screen';

export const STEPS = {
  zip: 'SIGN_IN_FLOW_STEP_ZIP',
  socialContinue: 'SIGN_IN_FLOW_SOCIAL_CONTINUE',
  signUp: 'SIGN_IN_FLOW_SIGN_UP',
  signIn: 'SIGN_IN_FLOW_SIGN_IN',
};

class SignInFlow extends Component {
  handleSignIn() {
    return this.props.onSubmitSignInForm(this.props.onAuthenticate);
  }

  handleSignUp() {
    return this.props.onSubmitSignUpForm(this.props.onAuthenticate, this.props.signUpOpts);
  }

  onTryAnotherZip() {
    this.props.goToZip();
    this.props.onTryAnotherZip();
  }

  render() {
    const standardProps = {
      error: this.props.error,
      isWorking: this.props.isWorking,
      zipCode: this.props.zipCode,
    };

    return (
      <div className="sign-in-flow__content">
        <div className="auth-flow__header">
          {!this.props.hideBack ? (
            <i className="icon icon-chevron welcome-layout__back" onClick={this.props.goBack} />
          ) : null}

          <div className="pdp-sign-in-flow__logo">
            <img
              className="sign-in-flow__logo"
              src={`${assetPath('/img/web/icon-76x76-black.png')}?auto=format`}
            />
          </div>
        </div>
        <div className="sign-in-flow">
          {
            {
              [STEPS.zip]: (
                <FlowZipEntry
                  onZipSuccessAction={this.props.goToSocialContinue}
                  onCheckZipCode={(zipCode, cb) => {
                    this.props.onCheckZipCode(
                      this.props.fulfillmentDay,
                      zipCode,
                      this.props.zipCode,
                      cb,
                    );
                  }}
                  onTryAnotherZip={this.onTryAnotherZip.bind(this)}
                  zipCodeError={this.props.zipCodeError}
                  user={this.props.user}
                  {...standardProps}
                />
              ),

              [STEPS.socialContinue]: (
                <FlowSocialSignInScreen
                  onContinueWithEmail={this.props.goToSignUp}
                  onLogin={this.props.onAuthenticate}
                  onTryAnotherZip={this.onTryAnotherZip.bind(this)}
                  {...standardProps}
                />
              ),

              [STEPS.signUp]: (
                <FlowSignUp
                  onSubmitSignUpForm={this.handleSignUp.bind(this)}
                  onClickErrorSignIn={this.props.goToSignIn}
                  {...standardProps}
                />
              ),

              [STEPS.signIn]: (
                <FlowSignIn
                  onGoToSignUp={this.props.goToSignUp}
                  onSubmitSignInForm={this.handleSignIn.bind(this)}
                  onLogin={this.props.onAuthenticate}
                  {...standardProps}
                />
              ),
            }[this.props.step]
          }
        </div>

        {!this.props.hideSignIn ? (
          <div className="pdp-sign-in-flow__auth">
            <p className="sign-in-flow__sign-in">
              Already have an account?{' '}
              <a
                id="flow-sign-in"
                className="text-link"
                href="/signin"
                onClick={(e) => {
                  e.preventDefault();
                  SigninHelpers.signinAndReturn();
                }}
              >
                Sign In
              </a>
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

SignInFlow.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isWorking: PropTypes.bool,
  zipCode: PropTypes.string,
  zipCodeError: PropTypes.string,
  step: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  goToZip: PropTypes.func.isRequired,
  goToSocialContinue: PropTypes.func.isRequired,
  goToSignUp: PropTypes.func.isRequired,
  goToSignIn: PropTypes.func.isRequired,
  onAuthenticate: PropTypes.func,
  onTryAnotherZip: PropTypes.func,
  subtitle: PropTypes.string,
  hideBack: PropTypes.bool,
  hideSignIn: PropTypes.bool,
  onSubmitSignInForm: PropTypes.func,
  onSubmitSignUpForm: PropTypes.func,
  onCheckZipCode: PropTypes.func,
  signUpOpts: PropTypes.shape({
    zip: PropTypes.string,
  }),
  user: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    goToZip: () => dispatch(actions.navigate(STEPS.zip)),
    goToSocialContinue: () => dispatch(actions.navigate(STEPS.socialContinue)),
    goToSignUp: () => dispatch(actions.navigate(STEPS.signUp)),
    goToSignIn: () => dispatch(actions.navigate(STEPS.signIn)),
    onSubmitSignInForm: (cb) => dispatch(actions.submitSignInForm(cb)),
    onSubmitSignUpForm: (cb, signUpOpts) => dispatch(actions.submitSignUpForm(cb, signUpOpts)),
    goBack: () => dispatch(actions.navigateBack()),
    onTryAnotherZip: () => dispatch(zipCodeActions.startCheckingZipCode()),
    onCheckZipCode: async (fulfillmentDay, zipCode, prevZipCode, success) => {
      await dispatch(zipCodeActions.updateZipCode(fulfillmentDay, zipCode, prevZipCode, true));
      success();
    },
  };
}

function mapStateToProps(state) {
  return {
    fulfillmentDay: state.currentFulfillmentDay,
    zipCode: state.zipCode,
    zipCodeError: state.zipCodeError,
    signUpOpts: {zip: state.zipCode},
    hideBack: !state.signInFlow.step || state.signInFlow.step === STEPS.zip,
    hideSignIn: state.signInFlow.step === STEPS.signIn,
    isWorking:
      state.socialSignIn.isAuthenticating || (state.navigation && state.navigation.isNavigating),
    error: state.socialSignIn.error || state.signInForm.error || state.signUpForm.error,
    step:
      !state.zipCode && state.signInFlow.step !== STEPS.signIn
        ? STEPS.zip
        : state.zipCode && !state.signInFlow.step
        ? STEPS.socialContinue
        : state.signInFlow.step,
    // - if we dont have a zipcode, we may still want to sign in.
    // - otherwise, if there is no step set (weve just arrived at the page) - default based on the existence of a zip in state (via cookie)
    // - otherwise, use the step determined by state.
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInFlow);
