import {postJson} from 'web/helpers/api_helpers';

export function postCheckout({fulfillmentId} = {}) {
  return postJson('/api/basket/checkout', {
    body: {
      addingToOrder: Boolean(fulfillmentId),
      fulfillment: fulfillmentId,
    },
  });
}
