import classNames from 'classnames';
import React, {ChangeEvent, FC, PropsWithChildren, ReactNode, useState} from 'react';

/*
 * Returns the label of the selected option, or the first label
 * if no option has the argument value. Traverses optgroups.
 */
function findSelectedText({
  value,
  defaultValue,
  optionsVirtualDom,
}: {
  value: string | number;
  defaultValue?: string;
  optionsVirtualDom: ReactNode[];
}): string {
  const optionPairs = optionsVirtualDom
    .filter(Boolean)
    .flatMap((optOrGroup) =>
      // @ts-ignore TODO(guilherme-vp): this does not make sense, we are filtering children components
      // accessing their children prop directly. Refactor this to avoid this behavior
      optOrGroup.type === 'optgroup' ? optOrGroup.props.children : optOrGroup,
    )
    .filter(({type}) => type === 'option')
    .map((option) => ({
      value: option.props.value,
      label: option.props.children,
    }));

  return (
    optionPairs.find((option) => option.value === value || option.value === defaultValue)?.label ??
    ''
  );
}

export interface ChoiceSelectProps {
  value: string | number;
  defaultValue?: string;
  className?: string;
  selectStyle?: string;
  disabled?: boolean;
  required?: boolean;
  onClick?: () => void;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const ChoiceSelect: FC<PropsWithChildren<ChoiceSelectProps>> = ({
  value,
  defaultValue,
  onChange,
  children,
  className,
  selectStyle,
  disabled,
  onClick,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const selectedText = findSelectedText({
    value,
    defaultValue,
    // TODO(guilherme-vp): This type assertions is wrong and error prone, once refactored the function, remove it
    optionsVirtualDom: children as ReactNode[],
  });
  const isDefault = value === defaultValue;
  const selectStyleClass = selectStyle ?? 'filled-select';

  const handleFocus = (): void => setIsFocused(true);
  const handleBlur = (): void => setIsFocused(false);

  return (
    <div className={classNames('choice-select', className)}>
      <span
        className={classNames(selectStyleClass, {
          disabled,
          focus: isFocused,
          'default-is-selected': isDefault,
        })}
      >
        <span className="select-text" data-testid="select-text">
          {selectedText}
        </span>
        <span className="icon icon-chevron-thin icon-down" />
        <select
          data-testid="choice-select__select"
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={onClick}
          disabled={disabled}
          required={required}
        >
          {/* Children should be options or optgroups */}
          {children}
        </select>
      </span>
    </div>
  );
};

export default ChoiceSelect;
