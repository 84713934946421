import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';

import {actions as zipCodeActions} from 'web/helpers/zip_code_duck';
import {actions as modalActions} from 'web/helpers/modal_duck';

import {actions} from './duck';
import FlowZipEntry from './components/zip_entry_screen';
import FlowSocialSignInScreen from './components/social_sign_in_screen';
import FlowSignUp from './components/sign_up_screen';
import FlowSignIn from './components/sign_in_screen';
import Footer from './components/footer';

export const STEPS = {
  zip: 'SIGN_IN_FLOW_STEP_ZIP',
  socialContinue: 'SIGN_IN_FLOW_SOCIAL_CONTINUE',
  signUp: 'SIGN_IN_FLOW_SIGN_UP',
  signIn: 'SIGN_IN_FLOW_SIGN_IN',
};

class AuthFlow extends Component {
  handleAuthenticate =
    ({shouldRefresh = true} = {}) =>
    async (_dispatch, _getState) => {
      if (this.props.onAuthenticate) {
        await this.props.onAuthenticate();
      }
      if (shouldRefresh) {
        this.props.completeFlow();
      }
    };

  async handleCheckZipCode(zipCode, onSuccessCallback) {
    const res = await this.props.onCheckZipCode(
      this.props.fulfillmentDay,
      zipCode,
      this.props.zipCode,
      onSuccessCallback,
    );
    if (res.data.new.foodhubSlug != null) {
      onSuccessCallback();
    } else {
      this.props.openConsolationModal({
        newZip: zipCode,
        comingSoon: false, // @TODO(dlowe) replace placeholder when this data is available
        onDismiss: this.props.onCloseModal,
      });
    }
  }

  handleSignIn() {
    return this.props.onSubmitSignInForm(this.handleAuthenticate());
  }

  handleSignUp() {
    return this.props.onSubmitSignUpForm(this.handleAuthenticate(), this.props.signUpOpts);
  }

  onTryAnotherZip() {
    this.props.goToZip();
    this.props.onTryAnotherZip();
  }

  onZipSuccessAction() {
    if (this.props.onAuthenticate) {
      toast(`Hooray we deliver to ${this.props.zipCode}. Start shopping now!`);
      this.props?.onAuthenticate();
      this.props.onCloseModal();
    } else {
      this.props.goToSocialContinue();
    }
  }

  render() {
    const standardProps = {
      error: this.props.error,
      isWorking: this.props.isWorking,
      zipCode: this.props.zipCode,
    };

    const imageSize = this.props.step === STEPS.zip ? 'large' : '';

    return (
      <div className="auth-flow__content">
        <div className="auth-flow__header">
          {!this.props.hideBack && (
            <div>
              <i className="icon icon-caret-left" onClick={this.props.goBack} />
            </div>
          )}

          <div className="close-modal__icon">
            <i
              className="icon icon-thin-x"
              data-testid="auth-flow__close-modal-icon"
              onClick={this.props.onCloseModal}
            />
          </div>

          <div className="auth-flow__logo">
            <i className={`icon icon-logo-black ${imageSize}`} onClick={this.props.goBack} />
          </div>
        </div>

        <div className="sign-in-flow">
          {
            {
              [STEPS.zip]: (
                <FlowZipEntry
                  onZipSuccessAction={this.onZipSuccessAction.bind(this)}
                  onCheckZipCode={this.handleCheckZipCode.bind(this)}
                  onDismiss={this.props.onCloseModal}
                  onTryAnotherZip={this.onTryAnotherZip.bind(this)}
                  onCloseModal={this.props.onCloseModal}
                  user={this.props.user}
                  {...standardProps}
                />
              ),

              [STEPS.socialContinue]: (
                <FlowSocialSignInScreen
                  onContinueWithEmail={this.props.goToSignUp}
                  onLogin={this.handleAuthenticate}
                  onTryAnotherZip={this.onTryAnotherZip.bind(this)}
                  successMessage={this.props.successMessage}
                  onCloseModal={this.props.onCloseModal}
                  {...standardProps}
                />
              ),

              [STEPS.signUp]: (
                <FlowSignUp
                  onSubmitSignUpForm={this.handleSignUp.bind(this)}
                  onClickErrorSignIn={this.props.goToSignIn}
                  {...standardProps}
                />
              ),

              [STEPS.signIn]: (
                <FlowSignIn
                  onGoToSignUp={this.props.goToSignUp}
                  onSubmitSignInForm={this.handleSignIn.bind(this)}
                  onLogin={this.handleAuthenticate}
                  {...standardProps}
                />
              ),
            }[this.props.step]
          }
        </div>

        <Footer />
      </div>
    );
  }
}

AuthFlow.propTypes = {
  error: PropTypes.shape({message: PropTypes.string}),
  goBack: PropTypes.func.isRequired,
  goToSignIn: PropTypes.func.isRequired,
  goToSignUp: PropTypes.func.isRequired,
  goToSocialContinue: PropTypes.func.isRequired,
  goToZip: PropTypes.func.isRequired,
  hideBack: PropTypes.bool,
  hideSignIn: PropTypes.bool,
  isWorking: PropTypes.bool,
  onAuthenticate: PropTypes.func,
  onCheckZipCode: PropTypes.func,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitSignInForm: PropTypes.func,
  onSubmitSignUpForm: PropTypes.func,
  onTryAnotherZip: PropTypes.func,
  signUpOpts: PropTypes.shape({zip: PropTypes.string}),
  step: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  zipCode: PropTypes.string,
  zipCodeError: PropTypes.string,
  features: PropTypes.array,
  successMessage: PropTypes.string,
  user: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    goToZip: () => dispatch(actions.navigate(STEPS.zip)),
    goToSocialContinue: () => dispatch(actions.navigate(STEPS.socialContinue)),
    goToSignUp: () => dispatch(actions.navigate(STEPS.signUp)),
    goToSignIn: () => dispatch(actions.navigate(STEPS.signIn)),
    completeFlow: () => dispatch(actions.completeFlow()),
    onSubmitSignInForm: (cb) => dispatch(actions.submitSignInForm(cb)),
    onSubmitSignUpForm: (cb, signUpOpts) => dispatch(actions.submitSignUpForm(cb, signUpOpts)),
    goBack: () => dispatch(actions.navigateBack()),
    onTryAnotherZip: () => dispatch(zipCodeActions.startCheckingZipCode()),
    onCheckZipCode: (fulfillmentDay, zipCode, prevZipCode) =>
      dispatch(zipCodeActions.updateZipCode(fulfillmentDay, zipCode, prevZipCode, true)),
    openConsolationModal: (props) => {
      dispatch(modalActions.replaceModal({modal: 'ZipCodeConsolationModal', props}));
    },
  };
}

function mapStateToProps(state) {
  return {
    features: state.features,
    fulfillmentDay: state.currentFulfillmentDay,
    zipCode: state.zipCode,
    zipCodeError: state.zipCodeError,
    signUpOpts: {zip: state.zipCode},
    hideBack: ![STEPS.signIn, STEPS.signUp].includes(state.signInFlow.step),
    hideSignIn: state.signInFlow.step === STEPS.signIn,
    isWorking:
      state.socialSignIn.isAuthenticating || (state.navigation && state.navigation.isNavigating),
    error: state.socialSignIn.error || state.signInForm.error || state.signUpForm.error,
    referralId: state.referralId,
    step:
      !state.zipCode && state.signInFlow.step !== STEPS.signIn
        ? STEPS.zip
        : state.zipCode && !state.signInFlow.step
        ? STEPS.socialContinue
        : state.signInFlow.step,
    // - if we dont have a zipcode, we may still want to sign in.
    // - otherwise, if there is no step set (weve just arrived at the page) - default based on the existence of a zip in state (via cookie)
    // - otherwise, use the step determined by state.
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthFlow);
