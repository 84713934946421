import {find, uniq} from 'lodash';

export function getFirstAvailableDay({
  product,
  currentFulfillmentDay,
  fulfillmentDaySummaries,
  upcomingPreorderPeriods = [],
  fulfillmentDaysByDay,
}) {
  let searchableDays;

  const normalFulfillmentDays = fulfillmentDaySummaries.map((summary) => summary.day);
  const isShoppingForPreorder = !find(fulfillmentDaySummaries, {day: currentFulfillmentDay});

  if (!isShoppingForPreorder) {
    // normal day - check the normal week chronologically, then all possible preorder days chrono
    searchableDays = uniq(
      normalFulfillmentDays.concat(
        upcomingPreorderPeriods.reduce((memo, period) => memo.concat(period.preorderDays), []),
      ),
    );
    searchableDays.sort();
  } else {
    // preorder day - check the preorder week specific to that day, then the normal week
    const relevantPreorderPeriodId =
      fulfillmentDaysByDay[currentFulfillmentDay]?.preorderPeriodIds?.[0];
    if (!relevantPreorderPeriodId) {
      searchableDays = normalFulfillmentDays;
    } else {
      searchableDays = upcomingPreorderPeriods
        .find((period) => period.id === relevantPreorderPeriodId)
        .preorderDays.concat(normalFulfillmentDays);
    }
  }

  return searchableDays?.find(
    (day) =>
      product.availabilitiesByDay?.[day]?.status === 'available' &&
      fulfillmentDaysByDay?.[day]?.status === 'available',
  );
}
