import update from 'immutability-helper';

import helpers from './helpers';
import basketActions from './actions';

export function reduceBasketItems(basketItems = [], action = {}) {
  switch (action.type) {
    case basketActions.ASSIGN_QUANTITY: {
      const existingQuantity = helpers.getQuantity({
        basketItems,
        productId: action.productId,
      });
      const proposedQuantity = action.quantity;
      if (proposedQuantity === existingQuantity) {
        return basketItems;
      }
      if (proposedQuantity < existingQuantity) {
        return helpers.decreaseQuantity({
          basketItems,
          productId: action.productId,
          proposedQuantity,
        });
      }
      return helpers.increaseQuantity({
        basketItems,
        products: action.products,
        productId: action.productId,
        proposedQuantity,
      });
    }
    case basketActions.INCREMENT_QUANTITY: {
      const existingQuantity = helpers.getQuantity({
        basketItems,
        productId: action.productId,
      });
      return helpers.increaseQuantity({
        basketItems,
        products: action.products,
        productId: action.productId,
        proposedQuantity: existingQuantity + 1,
      });
    }
    case basketActions.DECREMENT_QUANTITY: {
      const existingQuantity = helpers.getQuantity({
        basketItems,
        productId: action.productId,
      });
      return helpers.decreaseQuantity({
        basketItems,
        productId: action.productId,
        proposedQuantity: existingQuantity - 1,
      });
    }
    case basketActions.SET_SHOULD_SUBSCRIBE:
      return helpers.editProduct({
        basketItems,
        productId: action.productId,
        shouldSubscribe: action.shouldSubscribe,
      });
    case basketActions.UPDATED_ITEM:
      return helpers.editProduct({
        basketItems,
        productId: action.productId,
        quantity: action.quantity,
        shouldSubscribe: action.shouldSubscribe,
      });
    default:
      return basketItems;
  }
}

function reduceTotals(totals = {}, action = {}) {
  switch (action.type) {
    case basketActions.UPDATED_TOTALS:
      return action.totals || totals;
    default:
      return totals;
  }
}

function reduceBasketStatus(status = {isUpdating: false}, action = {}) {
  switch (action.type) {
    case basketActions.STARTED_UPDATING_BASKET:
      return update(status, {$set: {isUpdating: true}});
    case basketActions.FINISHED_UPDATING_BASKET:
      return update(status, {$set: {isUpdating: false}});
  }
}

function reduceQuantitySelect(quantitySelect = {}, action) {
  switch (action.type) {
    case basketActions.SET_QUANTITY_SELECT:
      return action.payload;
    default:
      return quantitySelect;
  }
}

export default function reducer(basket = {}, action) {
  return update(basket, {
    items: {$set: reduceBasketItems(basket.items, action)},
    totals: {$set: reduceTotals(basket.totals, action)},
    status: {$set: reduceBasketStatus(basket.status, action)},
    quantitySelect: {$set: reduceQuantitySelect(basket.quantitySelect, action)},
  });
}
