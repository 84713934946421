import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Alert from 'web/components/alert';
import Input from 'web/components/input';
import SubmitButton from 'web/components/submit_button';

import EmailEntry from './components/email_entry';

export default class FlowZipEntryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      input: props.zipCode || '',
    };
  }

  async onSubmit(e) {
    e.preventDefault();

    const zipCodeEntry = this.state.input;
    const zipCode = zipCodeEntry ? zipCodeEntry.trim() : '';
    this.setState({error: null});
    if (!/^\d{5}$/.test(zipCode)) {
      this.setState({error: "Oops - that doesn't quite look right"});
      return;
    }
    await this.props.onCheckZipCode(zipCode, this.props.onZipSuccessAction);
    const deliveryProps = {
      deliveryEligible: this.props.zipCodeError === null,
      loggedIn: this.props.user !== null,
      masquerading: this.props.user?.masquerading ?? false,
      pageUrl: window.location.href,
      zipCode,
    };
    window.metrics.track('Delivery Area Checked', deliveryProps, false);
  }

  onTryAnotherZip() {
    this.setState({input: ''});
    this.props.onTryAnotherZip();
  }

  renderZipCollection() {
    const className = this.state.input.length !== 5 ? 'disabled-buttons__modal' : 'action__button';
    return (
      <div className="flow-zip-entry__wrapper">
        {this.state.error ? (
          <Alert className="flow-zip-entry__errors" type="error" heading={this.state.error} />
        ) : null}

        <form className="flow-zip-entry-form" onSubmit={this.onSubmit.bind(this)}>
          <div className="sign-in-flow__zip-code-collection ">
            <Input
              type="tel"
              className="flow-zip-entry_refresh_modal__input js-zip-input"
              placeholder="Zip Code"
              onChange={(event) => {
                this.setState({input: event.target.value});
              }}
              value={this.state.input}
            />
            <SubmitButton
              disabled={this.state.input.length !== 5}
              className={`js-submit-zip ${className}`}
            >
              Continue
            </SubmitButton>{' '}
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="flow-zip-entry">
        {this.props.zipCodeError ? (
          <div>
            <EmailEntry
              onClickTryAnotherZipCode={this.onTryAnotherZip.bind(this)}
              zip={this.state.input}
              user={this.props.user}
            />
            <a
              className="pdp-sign-in-flow-refresh__email-link js-try-another-zip"
              onClick={this.onTryAnotherZip.bind(this)}
            >
              Try Another Zip Code
            </a>
          </div>
        ) : (
          <div>
            <h1 className="pdp-sign-in-flow-refresh__title">
              Absurdly fresh groceries, delivered.
            </h1>

            <div className="pdp-sign-in-flow-zip-entry-refresh__subtitle">
              Enter your zip code to see if we deliver to your address.
            </div>

            {this.renderZipCollection()}
          </div>
        )}
      </div>
    );
  }
}

FlowZipEntryScreen.propTypes = {
  zipCodeError: PropTypes.string,
  onZipSuccessAction: PropTypes.func,
  onTryAnotherZip: PropTypes.func,
  onCheckZipCode: PropTypes.func,
  zipCode: PropTypes.string,
  user: PropTypes.object,
};
