import React, {FC} from 'react';
import {formatCreditCard} from 'goodeggs-formatters';

export interface ActiveCreditCardProps {
  activeCard: {
    type?: string;
    last4?: string;
    exp_month?: string;
    exp_year?: string;
  };
  prefix?: string;
  mini?: boolean;
}

const ActiveCreditCard: FC<ActiveCreditCardProps> = ({activeCard, prefix, mini}) => {
  return mini ? (
    <span className="credit-card">{formatCreditCard(activeCard)}</span>
  ) : (
    <span data-testid="active-credit-card__expanded">
      {prefix}
      {activeCard.type} ending in {activeCard.last4} and expiring {activeCard.exp_month}/
      {activeCard.exp_year}
    </span>
  );
};

export default ActiveCreditCard;
