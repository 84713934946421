import React, {FC} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import SignUp from 'web/account/sign_up_page/sign_up';
import Modal from 'web/components/modal';
import {actions} from 'web/account/sign_up_page/duck';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {ModalViewed} from '@analytics/client/generated';
import {AppDispatch} from 'web/helpers/redux_client';

interface State {
  signUpForm: {
    isSaving: boolean;
    error: string | null;
  };
  modalViewed?: ModalViewed;
}

const SignUpModal: FC = () => {
  const working = useSelector((state: State) => state.signUpForm.isSaving);
  const error = useSelector((state: State) => state.signUpForm.error);
  const modalViewed = useSelector((state: State) => state.modalViewed);
  const dispatch: AppDispatch = useDispatch();

  const handleCloseModal = (): void => {
    dispatch(modalActions.closeModal({modal: 'SignUpModal'}));
  };

  const handleSubmit = (): void => {
    dispatch(actions.submitSignUpForm());
  };

  return (
    <Modal onClose={handleCloseModal}>
      <SignUp
        segmentFeature={modalViewed?.feature}
        headingText="Sign up to shop"
        working={working}
        error={error}
        submitSignUpForm={handleSubmit}
        onClose={handleCloseModal}
        isModal
      />
    </Modal>
  );
};

export default SignUpModal;
