/* global gapi */

import {gql} from '@apollo/client';
import update from 'immutability-helper';

import client, {sanitizeGraphQLError} from 'web/helpers/graphql_client';
import segmentAnalytics from '@analytics/client';
import socialSignInImport from 'web/helpers/social_sign_in_import';
import {actions as modalActions, CLOSE_ALL_MODALS} from 'web/helpers/modal_duck';
import {actions as signUpFormActions} from 'web/components/sign_up_form/duck';
import {getSegmentFeature} from 'web/components/social_sign_in/duck';

export const CONNECTING = 'GOOGLE_LOGIN_BUTTON__CONNECTING';
export const CONNECTED = 'GOOGLE_LOGIN_BUTTON__CONNECTED';
export const AUTHENTICATING = 'GOOGLE_LOGIN_BUTTON__AUTHENTICATING';
export const AUTHENTICATING_CANCEL = 'GOOGLE_LOGIN_BUTTON__AUTHENTICATING_CANCEL';
export const AUTHENTICATING_SUCCESS = 'GOOGLE_LOGIN_BUTTON__AUTHENTICATING_SUCCESS';
export const AUTHENTICATING_ERROR = 'GOOGLE_LOGIN_BUTTON__AUTHENTICATING_ERROR';
export const CLEAR_ERRORS = 'GOOGLE_LOGIN_BUTTON__CLEAR_ERRORS';
export const GOOGLE_AUTH_DISABLED = 'GOOGLE_LOGIN_BUTTON__DISABLED';

export const actions = {
  initialize: () => async (dispatch) => {
    const id = 'google-jssdk';
    const source = '//apis.google.com/js/platform.js';
    const isLoaded = socialSignInImport(id, source, () => dispatch(actions.initialize()));

    if (!isLoaded) {
      return;
    }

    dispatch({type: CONNECTING});

    try {
      gapi.load('auth2', async function () {
        await gapi.auth2.init();
        dispatch({type: CONNECTED});
      });
    } catch {
      dispatch({type: GOOGLE_AUTH_DISABLED});
    }
  },

  login:
    (onLogin, loginOpts = {}) =>
    async (dispatch, getState) => {
      dispatch({type: AUTHENTICATING});

      try {
        const auth2 = gapi.auth2.getAuthInstance();
        const state = getState();
        const googleUser = await auth2.signIn();
        const input = {...loginOpts, token: googleUser.getAuthResponse().id_token};
        const {
          data: {
            result: {user, accountCreated},
          },
        } = await authenticateWithGoogle(input);

        dispatch({type: AUTHENTICATING_SUCCESS, user});
        if (accountCreated) {
          dispatch(signUpFormActions.showSignUpSuccessModal());
        } else {
          dispatch(modalActions.closeModal({modal: CLOSE_ALL_MODALS}));
        }
        dispatch(signUpFormActions.onSignInSuccessful(user));
        segmentAnalytics.updateUserId({userId: user.id});

        if (accountCreated) {
          segmentAnalytics.track('accountCreated', {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            signInMethod: 'google',
            feature: getSegmentFeature(state.modalViewed),
            pageUrl: window.location.pathname,
          });
        } else {
          window.metrics.track('Signed In', {provider: 'google', userId: user.id});
        }

        if (onLogin) {
          dispatch(onLogin());
        }
      } catch (error) {
        sanitizeGraphQLError(error);

        if (error.error === 'popup_closed_by_user') {
          dispatch({type: AUTHENTICATING_CANCEL});
        } else {
          dispatch({type: AUTHENTICATING_ERROR, error});
        }
      }
    },

  clearErrors: () => ({
    type: CLEAR_ERRORS,
  }),
};

const authenticateWithGoogle = (input) =>
  client.mutate({
    mutation: gql`
      mutation AuthenticateWithGoogle($input: AuthenticateWithTokenInput!) {
        result: authenticateWithGoogle(input: $input) {
          accountCreated
          user {
            id
            email
            firstName
            lastName
            zip
            google {
              userId
            }
          }
        }
      }
    `,
    variables: {
      input,
    },
  });

export const initialState = {
  isConnected: false,
  isConnecting: false,
  isAuthenticating: false,
  error: null,
  enabled: true,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CONNECTING:
      return update(state, {isConnected: {$set: false}, isConnecting: {$set: true}});
    case CONNECTED:
      return update(state, {isConnected: {$set: true}, isConnecting: {$set: false}});
    case AUTHENTICATING:
      return update(state, {isAuthenticating: {$set: true}});
    case AUTHENTICATING_CANCEL:
      return update(state, {isAuthenticating: {$set: false}});
    case AUTHENTICATING_SUCCESS:
      return update(state, {isAuthenticating: {$set: false}});
    case AUTHENTICATING_ERROR:
      return update(state, {isAuthenticating: {$set: false}, error: {$set: action.error}});
    case CLEAR_ERRORS:
      return update(state, {error: {$set: null}});
    case GOOGLE_AUTH_DISABLED:
      return update(state, {enabled: {$set: false}});
  }
  return state;
}
