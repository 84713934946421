/* eslint-disable unicorn/filename-case */
import {useState, useEffect} from 'react';

function useIsComponentLoading(): boolean {
  const [isComponentLoading, setIsComponentLoading] = useState(true);

  useEffect(() => {
    setIsComponentLoading(false);
  }, []);

  return isComponentLoading;
}

export default useIsComponentLoading;
