import React from 'react';
import {string, func} from 'prop-types';

export default function EmailProvided({zip, onCloseModal}) {
  return (
    <div className="sign-in-flow__zip-code-collection ">
      <h1 className="sign-in-flow_refresh_modal__title center-flow__title">Thank you!</h1>
      <div className="flow-zip-entry_refresh_modal__subtitle">
        We will notify you when Good Eggs delivers to {zip}.
      </div>
      <button
        type="button"
        onClick={() => onCloseModal()}
        className="auth-flow_refresh_modal__button flow-social-sign-in-screen__signup-button js-sign-up-with-email"
      >
        Close
      </button>
    </div>
  );
}
EmailProvided.propTypes = {
  zip: string.isRequired,
  onCloseModal: func.isRequired,
};
