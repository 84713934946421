import update from 'immutability-helper';
import {gql} from '@apollo/client';
import {AnyAction, Dispatch} from 'redux';

import client from 'web/helpers/graphql_client';
import segmentAnalytics from '@analytics/client';

export interface ZipCodeData {
  new: {zipCode: string; foodhubSlug?: string | null};
  previous: {zipCode?: string | null; foodhubSlug?: string | null} | null;
}

const CHANGE = 'ZIP_CODE_CHANGE';
const ERROR = 'ZIP_CODE_ERROR';
const START_CHECKING = 'ZIP_CODE_START_CHECKING';

export const resultCodes = {
  CHANGE_SUCCESS: 'ZIP_CODE_CHANGE_SUCCESS',
  CHANGE_ERROR: 'ZIP_CODE_CHANGE_ERROR',
  CONFIRMATION_REQUIRED: 'ZIP_CODE_CHANGE_CONFIRMATION',
};

export const actions = {
  changeZipCode: (zipCode: string) => ({
    type: CHANGE,
    value: zipCode,
  }),

  zipCodeError: (err: unknown) => ({
    type: ERROR,
    err,
  }),

  startCheckingZipCode: () => ({
    type: START_CHECKING,
  }),

  updateZipCode:
    (
      _fulfillmentDay: string,
      zipCode: string,
      prevZipCode?: string | null,
      confirmFoodhubChange = false,
    ) =>
    async (dispatch: Dispatch) => {
      dispatch(actions.startCheckingZipCode());

      const response = await client.mutate({
        mutation: gql`
          mutation UpdateZipCode(
            $zipCode: String!
            $previousZipCode: String
            $confirmFoodhubChange: Boolean
          ) {
            updateZipCode(
              input: {
                zipCode: $zipCode
                previousZipCode: $previousZipCode
                confirmFoodhubChange: $confirmFoodhubChange
              }
            ) {
              zipCode
              foodhubSlug
              previousZipCode
              previousFoodhubSlug
              success
              errorInfo {
                code
                message
              }
            }
          }
        `,
        variables: {
          zipCode,
          previousZipCode: prevZipCode,
          confirmFoodhubChange,
        },
      });
      let result = resultCodes.CHANGE_ERROR; // default

      const data: ZipCodeData = {
        new: {zipCode, foodhubSlug: response.data.updateZipCode.foodhubSlug},
        previous: null,
      };
      if (response.data.updateZipCode.previousZipCode != null) {
        data.previous = {
          zipCode: prevZipCode,
          foodhubSlug: response.data.updateZipCode.previousFoodhubSlug,
        };
      }
      if (response.data.updateZipCode.foodhubSlug != null) {
        result = resultCodes.CHANGE_SUCCESS;
      }

      if (response.data.updateZipCode.errorInfo?.code === 'REGION_CHANGE_CONFIRMATION_REQUIRED') {
        result = resultCodes.CONFIRMATION_REQUIRED;
      }
      if (result === resultCodes.CHANGE_SUCCESS) {
        dispatch(actions.changeZipCode(zipCode));
        segmentAnalytics.track('deliveryAreaUpdated', {
          regionNameBefore: response.data.updateZipCode.previousFoodhubSlug,
          regionNameAfter: response.data.updateZipCode.foodhubSlug,
          zipCodeBefore: response.data.updateZipCode.previousZipCode,
          zipCodeAfter: response.data.updateZipCode.zipCode,
        });
      } else {
        dispatch(actions.zipCodeError(result));
      }

      return {data, result};
    },
};

export interface State {
  zipCode: string;
  zipCodeError?: unknown | null;
}

export function reducer(state: State, action: AnyAction): State {
  switch (action.type) {
    case CHANGE:
      return update(state, {zipCode: {$set: action.value}});
    case ERROR:
      return update(state, {zipCodeError: {$set: action.err}});
    case START_CHECKING:
      return update(state, {zipCodeError: {$set: null}});
    default:
      return state;
  }
}
