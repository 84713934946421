import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import assetPath from 'web/helpers/asset_path';

import {actions} from './duck';

export class AppleLoginButton extends Component {
  componentDidMount() {
    if (!this.props.isConnected && !this.props.isConnecting) {
      this.props.initialize(this.props.nonceHash, this.props.onFailure);
    }
  }

  handleLogin() {
    if (this.props.isConnected && !this.props.isAuthenticating) {
      if (this.props.onLogin) {
        this.props
          .login(this.props.onLogin, {zip: this.props.zipCode, nonce: this.props.nonce})
          .catch((error) => this.props.onFailure && this.props.onFailure(error));
      } else if (this.props.onSubmit) {
        this.props.onSubmit({zip: this.props.zipCode, nonce: this.props.nonce});
      }
    }
  }

  render() {
    return (
      <button
        type="button"
        className={classNames('button apple-login-button', this.props.className, {
          'is-working': this.props.isConnecting || this.props.isAuthenticating,
          'is-not-ready': !this.props.isConnected,
        })}
        onClick={this.handleLogin.bind(this)}
      >
        {this.props.icon || (
          <img
            alt="Apple Logo"
            src={`${assetPath('/img/jujube/icons/sso-apple.svg')}?auto=format`}
          />
        )}
        <span>Continue with Apple</span>
      </button>
    );
  }
}

AppleLoginButton.defaultProps = {
  icon: null,
  className: '',
};

AppleLoginButton.propTypes = {
  onSubmit: PropTypes.func,
  onLogin: PropTypes.func,
  onConnect: PropTypes.func,
  onFailure: PropTypes.func,
  connect: PropTypes.func,
  login: PropTypes.func,
  isConnected: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
  icon: PropTypes.element,
  className: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    initialize: (nonce) => dispatch(actions.initialize(nonce)),
    connect: () => dispatch(actions.connect()),
    login: (onLogin, loginOpts = {}) => dispatch(actions.login(onLogin, loginOpts)),
  };
}

function mapStateToProps(state) {
  return {
    nonce: state.appleSignIn.nonce,
    nonceHash: state.appleSignIn.nonceHash,
    isConnected: state.socialSignIn.appleLogin.isConnected,
    isConnecting: state.socialSignIn.appleLogin.isConnecting,
    isAuthenticating: state.socialSignIn.appleLogin.isAuthenticating,
    zipCode: state.zipCode,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppleLoginButton);
