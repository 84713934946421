// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import {normalizePhone} from 'goodeggs-formatters';
import {email as validateEmail} from 'goodeggs-validators';

export const ERROR_MESSAGES = {
  firstName: {
    empty: 'What’s your first name?',
  },
  lastName: {
    empty: 'What’s your last name?',
  },
  email: {
    empty: 'Please enter a valid email address',
    invalid: 'That email address doesn’t look quite right',
  },
  phone: {
    empty: 'Phone is required',
    invalid: 'That phone number doesn’t look quite right',
  },
  password: {
    empty: 'Password is required',
    // TODO(jason-h-hu): These should be read from global settings
    invalid: 'Password must be 8 or more characters',
    reachedLimit: 'Your password cannot exceed 128 characters.',
  },
};

export default function validate(values = {}, props = {}) {
  const errors = {};
  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = ERROR_MESSAGES.firstName.empty;
  }
  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = ERROR_MESSAGES.lastName.empty;
  }
  if (!values.email || !values.email.trim()) {
    errors.email = ERROR_MESSAGES.email.empty;
  } else if (!validateEmail(values.email)) {
    errors.email = ERROR_MESSAGES.email.invalid;
  }
  if (!props.excludePhone) {
    if (!values.phone) {
      errors.phone = ERROR_MESSAGES.phone.empty;
    } else if (!/^\+1\d{10}$/.test(normalizePhone(values.phone))) {
      errors.phone = ERROR_MESSAGES.phone.invalid;
    }
  }
  if (!props.excludePassword) {
    if (!values.password) {
      errors.password = ERROR_MESSAGES.password.empty;
    } else if (values.password.length < settings.minPasswordLength) {
      errors.password = ERROR_MESSAGES.password.invalid;
    } else if (values.password.length > settings.maxPasswordLength) {
      errors.password = ERROR_MESSAGES.password.reachedLimit;
    }
  }
  return errors;
}
