// These are Redux Actions for mutating the basket.
// They use redux-thunk to asynchronously post updates
// to the server but optimistically dispatch an action object
// immediately.
// http://redux.js.org/docs/basics/Actions.html
//
// Context option is an object with fields attached
// to revenue attribution metrics.
import Cents from 'goodeggs-money';

import getSubscriptionAmountToMinimum from 'web/helpers/get_subscription_amount_to_minimum';
import segmentAnalytics from '@analytics/client';
import {trackProductAdded} from '@analytics/client/product';

import helpers from './helpers';
import {postRemoveItem, postUpdateItem, getTotals, postChangeDay} from './api_client';

function _trackProductRemoved({productId, quantity, state}) {
  const {currentFulfillmentDay: deliveryDay, products, basket, category} = state;
  const product = products[productId];

  segmentAnalytics.track('productRemoved', {
    productId,
    category: category?.id,
    price: product == null ? 0 : product.retailPrice,
    quantity,
    deliveryDay,
    basketId: basket._id,
  });
}

// Lodash.debounce has a tricky behavior when it comes to debounce Promises,
// its implementation always returns the result of the previous invocation of the specified callback argument.
// Causing _.debounce(asyncFunc, wait) to always return the previous Promise executed, at first bein undefined.
// For more details read: https://github.com/lodash/lodash/issues/4400#issuecomment-904931112
// TODO: (@guilherme-vp) Look for better solutions or move this to the util functions with tests,
// I can see more cases where this will be useful.
function debouncePromise(func, wait) {
  let timeout;
  function executionFunction(...args) {
    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        Promise.resolve(func.apply(this, [...args]))
          .then(resolve)
          .catch(reject);
      }, wait);
    });
  }
  return executionFunction;
}
const debouncePostUpdateItem = debouncePromise(postUpdateItem, 500);

// Returns a promise for the HTTP request
const _postProductUpdate = async function (
  {dispatch, getState, productId, quantity, shouldSubscribe, priorBasketItem},
  {context},
) {
  dispatch({type: basketActions.STARTED_UPDATING_BASKET});

  const removal = quantity != null && quantity <= 0;
  dispatch(
    basketActions.updatedItem({
      productId,
      basketItem: {
        ...priorBasketItem,
        quantity,
        shouldSubscribe: Boolean(shouldSubscribe) ?? priorBasketItem?.shouldSubscribe,
      },
    }),
  );

  const {addToOrderId} = getState();

  const itemUpdate = removal
    ? postRemoveItem({productId, addToOrderId}, {context})
    : debouncePostUpdateItem({productId, quantity, shouldSubscribe, addToOrderId}, {context});
  return itemUpdate
    .then(function ({totals, isAttendedDeliveryRequired}) {
      dispatch({type: basketActions.FINISHED_UPDATING_BASKET});
      // NOTE - we do NOT updateItem here - we presume the optimistic, pre-save, update has already been applied to the
      // store. Also, several updates to the same product might be applied to the store and queued; so we don't want
      // each serial response to cause the basket quantity to bounce
      // Update basket with new subtotals from response
      dispatch(basketActions.updatedTotals(totals));
      if (removal) {
        dispatch({
          type: basketActions.SET_ATTENDED_DELIVERY_REQUIRED,
          required: isAttendedDeliveryRequired,
        });
      }
    })
    .catch(function () {
      // todo - this does not work for multiple queued updates for a single product
      // todo - flash message about failure - or perhaps that's handled in the api client?
      dispatch({type: basketActions.FINISHED_UPDATING_BASKET});
      dispatch(basketActions.updatedItem({productId, basketItem: priorBasketItem}));
    });
};

const basketActions = {
  ASSIGN_QUANTITY: 'BASKET_ASSIGN_QUANTITY',
  INCREMENT_QUANTITY: 'BASKET_INCREMENT_QUANTITY',
  DECREMENT_QUANTITY: 'BASKET_DECREMENT_QUANTITY',
  SET_SHOULD_SUBSCRIBE: 'BASKET_SET_SHOULD_SUBSCRIBE',
  SET_ATTENDED_DELIVERY_REQUIRED: 'BASKET_SET_ATTENDED_DELIVERY_REQUIRED',
  UPDATED_ITEM: 'UPDATED_ITEM',
  UPDATED_TOTALS: 'BASKET_UPDATED_TOTALS',
  STARTED_UPDATING_BASKET: 'STARTED_UPDATING_BASKET',
  FINISHED_UPDATING_BASKET: 'FINISHED_UPDATING_BASKET',
  SET_QUANTITY_SELECT: 'SET_QUANTITY_SELECT',

  // context: {pageName, feature} - used for revenue attribution
  assignQuantity({productId, quantity}, {context} = {}) {
    return function (dispatch, getState) {
      const priorBasketItem = helpers.findItem({basketItems: getState().basket.items, productId});

      quantity = parseInt(quantity);
      const {products} = getState();

      dispatch({type: basketActions.ASSIGN_QUANTITY, productId, quantity, products});
      return _postProductUpdate(
        {dispatch, getState, productId, quantity, priorBasketItem},
        {context},
      ).then(() => {
        if (priorBasketItem != null && priorBasketItem.quantity > quantity) {
          _trackProductRemoved({productId, quantity, state: getState()});
        } else {
          trackProductAdded({productId, quantity, state: getState(), context});
        }
      });
    };
  },

  // context: {pageName, feature} - used for revenue attribution
  setQuantitySelect(productId, position, quantity) {
    return function (dispatch) {
      dispatch({
        type: basketActions.SET_QUANTITY_SELECT,
        payload: {productId, position, quantity},
      });
    };
  },

  // context: {pageName, feature} - used for revenue attribution
  incrementQuantity({productId, position, currentQuantity = 0}, {context} = {}) {
    return function (dispatch, getState) {
      const priorBasketItem = helpers.findItem({basketItems: getState().basket.items, productId});

      const {products} = getState();
      dispatch({type: basketActions.INCREMENT_QUANTITY, productId, products});

      const basketItems = getState().basket.items;
      const quantity =
        currentQuantity === 0 ? helpers.getQuantity({basketItems, productId}) : quantity;
      return _postProductUpdate(
        {dispatch, getState, productId, quantity, priorBasketItem},
        {context},
      ).then(() => {
        trackProductAdded({productId, quantity, position, state: getState(), context});
      });
    };
  },

  // context: {pageName, feature} - used for revenue attribution
  decrementQuantity({productId}, {context} = {}) {
    return function (dispatch, getState) {
      const priorBasketItem = helpers.findItem({basketItems: getState().basket.items, productId});

      dispatch({type: basketActions.DECREMENT_QUANTITY, productId});
      const basketItems = getState().basket.items;

      const quantity = helpers.getQuantity({basketItems, productId});
      return _postProductUpdate(
        {dispatch, getState, productId, quantity, priorBasketItem},
        {context},
      ).then(() => {
        _trackProductRemoved({productId, quantity, state: getState()});
      });
    };
  },

  // context: {pageName, feature} - used for revenue attribution
  setShouldSubscribe({productId, shouldSubscribe, existingSubscriptionId}, {context} = {}) {
    return function (dispatch, getState) {
      const priorBasketItem = helpers.findItem({basketItems: getState().basket.items, productId});
      shouldSubscribe = Boolean(shouldSubscribe);
      const quantity = helpers.getQuantity({basketItems: getState().basket.items, productId});

      dispatch({type: basketActions.SET_SHOULD_SUBSCRIBE, productId, shouldSubscribe});
      return _postProductUpdate(
        {dispatch, getState, productId, shouldSubscribe, priorBasketItem, quantity},
        {context},
      ).then(() => {
        const {products, basket, fulfillmentDaySummaries, user, isMasquerading, location} =
          getState();

        const product = products[productId];
        const subscriptionAmountToMinimum = getSubscriptionAmountToMinimum(basket, products);
        const feature = location ? location.replace('/', '') : '';

        segmentAnalytics.track(
          shouldSubscribe ? 'subscriptionProductAdded' : 'subscriptionProductRemoved',
          {
            basketId: basket._id,
            subscriptionBasketId: basket._id,
            daysAvailable: fulfillmentDaySummaries.map((summary) => summary.day).join(),
            imageUrl: product.photoUrl,
            loggedIn: user !== null,
            masquerading: isMasquerading,
            name: product.name,
            price: new Cents(product.retailPrice).toDollars(),
            producer: product.currentProducer.name,
            productId: product.id,
            feature,
            quantity,
            sku: product.id,
            value: new Cents(basket.totals.subtotal).toDollars(),
            underSubscriptionMinimum: subscriptionAmountToMinimum > 0,
            valueUnderSubscriptionMinimum: new Cents(subscriptionAmountToMinimum ?? 0).toDollars(),
            subscriptionId: existingSubscriptionId,
          },
        );
      });
    };
  },

  updateTotals() {
    return function (dispatch, getState) {
      const {currentFulfillmentDay, addToOrderId} = getState();
      return getTotals({fulfillmentDay: currentFulfillmentDay, addToOrderId}).then((totals) =>
        dispatch(basketActions.updatedTotals(totals)),
      );
    };
  },

  changeFulfillmentDay(newDay) {
    window.metrics.track('Changed Shopping Day', {day: newDay});
    return function () {
      return postChangeDay(newDay);
    };
  },

  // Dispatch when we get current item information from the server
  updatedItem({productId, basketItem}) {
    return function (dispatch) {
      const quantity = (basketItem && basketItem.quantity) || 0;
      const shouldSubscribe = (basketItem && basketItem.shouldSubscribe) || false;

      dispatch({type: basketActions.UPDATED_ITEM, productId, quantity, shouldSubscribe});
    };
  },

  updatedTotals(totals) {
    return {type: basketActions.UPDATED_TOTALS, totals};
  },
};

export default basketActions;
