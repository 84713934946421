import classNames from 'classnames';
import React, {FC, PropsWithChildren} from 'react';

export interface LayoutRowProps {
  className: string;
}

export interface LayoutCellProps {
  expanding?: boolean;
  className: string;
}

export const LayoutRow: FC<PropsWithChildren<LayoutRowProps>> = (props) => {
  return (
    <div data-testid="layout_row-className" className={classNames('layout-row', props.className)}>
      {props.children}
    </div>
  );
};

export const LayoutCell: FC<PropsWithChildren<LayoutCellProps>> = (props) => {
  return (
    <div
      data-testid="layout_cell-className"
      className={classNames(
        {'layout-cell': !props.expanding},
        {'layout-cell--expanding': props.expanding},
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
