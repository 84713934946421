import React, {FC, PropsWithChildren} from 'react';

import assetPath from 'web/helpers/asset_path';

export interface SurveyPopInProps {
  onDismiss: () => void;
}

const SurveyPopIn: FC<PropsWithChildren<SurveyPopInProps>> = (props) => {
  return (
    <div data-testid="survey-popin" className="survey-sticky-overlay">
      <div className="survey-sticky-overlay-container">
        <img
          className="survey-sticky-overlay__image-header"
          alt="Good Eggs Survey image header"
          src={`${assetPath(`/img/web/survey_popin/survey_popin_header.png`)}?auto=format`}
        />
        <div className="survey-sticky-overlay__title-container">
          <label className="survey-sticky-overlay__title-container-label">
            Customize your
            <br /> Good Eggs experience
          </label>
        </div>
        <div className="survey-sticky-overlay__discount_container">
          <label className="survey-sticky-overlay__discount-container-label">
            Get 15% off your next order
          </label>
        </div>
        <div className="survey-sticky-overlay__button-container">
          <div>
            <button
              onClick={props.onDismiss}
              type="button"
              className="dismiss-button transparent_pill_button"
              data-testid="dismiss-button"
            >
              Dismiss
            </button>
          </div>
          <div>
            <a href="/survey/">
              <button
                type="button"
                className="take-quiz-button pill_button"
                data-testid="take-quiz-button"
              >
                TAKE THE QUIZ
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyPopIn;
