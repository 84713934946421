import React, {FC} from 'react';
import useAlgolia from 'use-algolia';

import useClientSettings from 'web/hooks/useClientSettings';
import segmentAnalytics from '@analytics/client';
import useRecentSearches from 'web/hooks/useRecentSearches';

export interface TrendingItemsProps {
  onItemClick?: (searchTerm: string) => void;
}

export interface TrendingAlgoliaHit {
  objectID: string;
  query: string;
}

const TrendingItems: FC<TrendingItemsProps> = ({onItemClick}) => {
  const clientSettings = useClientSettings();
  const {recentSearches} = useRecentSearches();
  const [trendingItems] = useAlgolia<TrendingAlgoliaHit>(
    clientSettings.search.algolia.app,
    clientSettings.search.algolia.key,
    clientSettings.search.algolia.trendingIndex,
    {
      hitsPerPage: recentSearches?.length > 0 ? 5 : 10,
      attributesToRetrieve: ['objectID', 'query'],
      clickAnalytics: true,
    },
  );

  const handleClickItem = (query: string): void => {
    segmentAnalytics.track('siteSearched', {
      query,
      queryType: 'trending',
      index: clientSettings.search.algolia.index,
      queryID: trendingItems.response?.queryID,
    });
    onItemClick?.(query);
  };

  return trendingItems.hits.length > 0 ? (
    <div className="search-suggestions__trending-searches">
      <h3>Trending Searches</h3>
      <div className="trending-items">
        {trendingItems?.hits?.map(({objectID, query}) => (
          <div
            key={objectID}
            onClick={(): void => handleClickItem(query)}
            className="trending-item"
            data-testid="trending-item"
          >
            <i className="icon icon-small-search trending-icon" />
            <span className="trending-label">{query}</span>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default TrendingItems;
