import React, {ReactElement} from 'react';
import classNames from 'classnames';

import {actions as favoritesDuckActions} from 'web/helpers/favorites_duck';

export interface FavoriteProps {
  isFavorited: boolean;
  segmentFeature: string;
  productId: string | undefined;
  onFavorite?(productId: string): void;
  onUnFavorite?(productId: string): void;
  dispatch(obj: any): void;
}

const Favorite = ({
  isFavorited,
  segmentFeature,
  productId,
  onFavorite,
  onUnFavorite,
  dispatch,
}: FavoriteProps): ReactElement => {
  const handleFavorite = (favoriteProductId: string): void => {
    dispatch(
      favoritesDuckActions.addFavorite(favoriteProductId, {
        context: {feature: segmentFeature},
      }),
    );
    onFavorite?.(favoriteProductId);
  };

  const handleUnFavorite = (favoriteProductId: string): void => {
    dispatch(
      favoritesDuckActions.removeFavorite(favoriteProductId, {
        context: {feature: segmentFeature},
      }),
    );
    onUnFavorite?.(favoriteProductId);
  };
  return (
    <div
      className={classNames('product-tile__favorite', {
        'not-favorited': !isFavorited,
        favorited: isFavorited,
      })}
    >
      <div
        onClick={(): void =>
          isFavorited ? handleUnFavorite(productId || '') : handleFavorite(productId || '')
        }
        data-testid="product-tile-favorite-button"
      >
        <div className="product-tile__favorite-circle">
          <svg
            data-testid="product-tile__favorite-heart"
            className="product-tile__favorite-heart"
            viewBox="8.9 7.8 18 16.633654"
          >
            <path d="m 17.89989,23.433653 c 0.631042,-0.630881 4.233291,-4.23521 6.485296,-6.486896 2.017605,-2.017765 1.974565,-4.958732 0.140161,-6.783217 -1.834405,-1.8249642 -4.800652,-1.8169641 -6.625457,0.0176 -1.824645,-1.8345641 -4.790892,-1.8425642 -6.625297,-0.0176 -1.8344041,1.824485 -1.8772842,4.765452 0.140161,6.783217 2.251845,2.251686 5.854574,5.856015 6.485136,6.486896 l 0,0 z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Favorite;
