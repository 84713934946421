import classNames from 'classnames';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {centsRoundedToDollars} from 'web/helpers/money';
import Alert from 'web/components/alert';

export default class SanitizationChanges extends Component {
  buildChangeMessage(change) {
    switch (change.issue) {
      case 'lowerQuantity': {
        return `${change.productName} quantity was reduced from ${change.before} to ${change.after}.`;
      }
      case 'noLongerAvailable': {
        return `${change.productName} is no longer available and was removed.`;
      }
      case 'noLongerAvailableSoldOut': {
        return `${change.productName} is sold out and was removed.`;
      }
      case 'invalidProduct': {
        return `${change.productName} has incomplete information and was removed.`;
      }
      case 'promotionalGiftWithPromoCode': {
        return `Promotional gifts cannot be combined with a promo code. The gift was removed from your basket.`;
      }
      case 'changedProduct':
      case 'changedProductAndDay': {
        return `${change.productName} has been updated. ${change.message || ''}`;
      }
      case 'compensatedWithPromo': {
        const discountText =
          change.promoCode.type === 'percent'
            ? `${centsRoundedToDollars(change.promoCode.value)}%`
            : `$${centsRoundedToDollars(change.promoCode.value)}`;
        return (
          <div>
            Your gift of {change.productName} is no longer available, promo code{' '}
            <b>{change.promoCode.code}</b> has been applied to your basket. Enjoy {discountText} off
            on us!{' '}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  render() {
    const changeMessages = this.props.sanitizationChanges
      .map(this.buildChangeMessage)
      .filter((message) => message !== null);
    if (changeMessages.length === 0) {
      return null;
    }

    return (
      <div className="basket-changes">
        <Alert type="warning" heading="The items in your basket have changed.">
          <ul
            className={classNames(
              'basket-changes__list',
              'js-basket-changes-list',
              {single: changeMessages.length === 1},
              {multiple: changeMessages.length > 1},
            )}
          >
            {changeMessages.map((message, i) => (
              <li key={i} className="basket-changes__list-item">
                {message}
              </li>
            ))}
          </ul>
        </Alert>
      </div>
    );
  }
}

SanitizationChanges.propTypes = {
  sanitizationChanges: PropTypes.array,
};
