import {connect} from 'react-redux';
import React, {Component} from 'react';
import {reduxForm, Field} from 'redux-form';

const MasqueradeOverridesForm = ({handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <div className="button-area">
      <button type="submit" className="button">
        Change
      </button>
    </div>
    <div className="form-row checkbox">
      <Field
        id="ignore-minimum-order"
        name="ignoreMinimumOrder"
        component="input"
        type="checkbox"
      />
      <label htmlFor="ignore-minimum-order">Ignore order minimum</label>
    </div>
    <div className="form-row checkbox">
      <Field
        id="dont-send-confirmation"
        name="dontSendConfirmation"
        component="input"
        type="checkbox"
      />
      <label htmlFor="dont-send-confirmation">Don{"'"}t send confirmation</label>
    </div>
    <div className="form-row checkbox">
      <Field id="ignore-capacities" name="ignoreCapacities" component="input" type="checkbox" />
      <label htmlFor="ignore-capacities">Ignore Capacities</label>
    </div>
  </form>
);

const MasqueradeOverridesReduxForm = reduxForm({
  form: 'masqueradeOverrides',
})(MasqueradeOverridesForm);

const MasqueradeOverridesConnectedForm = connect((state) => ({
  initialValues: state.masqueradeOverrides,
}))(MasqueradeOverridesReduxForm);

export default class MasqueradeOverrides extends Component {
  handleSubmit(values) {
    this.props.onSubmit(values);
  }

  render() {
    return (
      <div className="basket-view__masquerade-overrides raised-tile">
        <div className="raised-tile__header raised-tile-admin__header">Masquerading Options</div>
        <div className="raised-tile__body">
          <MasqueradeOverridesConnectedForm onSubmit={this.handleSubmit.bind(this)} />
        </div>
      </div>
    );
  }
}
