/* global AppleID */

import {gql} from '@apollo/client';
import update from 'immutability-helper';

import client, {sanitizeGraphQLError} from 'web/helpers/graphql_client';
import segmentAnalytics from '@analytics/client';
import socialSignInImport from 'web/helpers/social_sign_in_import';
import {actions as modalActions, CLOSE_ALL_MODALS} from 'web/helpers/modal_duck';
import {actions as signUpFormActions} from 'web/components/sign_up_form/duck';
import {getSegmentFeature} from 'web/components/social_sign_in/duck';

export const CONNECTING = 'APPLE_LOGIN_BUTTON__CONNECTING';
export const CONNECTED = 'APPLE_LOGIN_BUTTON__CONNECTED';
export const AUTHENTICATING = 'APPLE_LOGIN_BUTTON__AUTHENTICATING';
export const AUTHENTICATING_CANCEL = 'APPLE_LOGIN_BUTTON__AUTHENTICATING_CANCEL';
export const AUTHENTICATING_SUCCESS = 'APPLE_LOGIN_BUTTON__AUTHENTICATING_SUCCESS';
export const AUTHENTICATING_ERROR = 'APPLE_LOGIN_BUTTON__AUTHENTICATING_ERROR';
export const CLEAR_ERRORS = 'APPLE_LOGIN_BUTTON__CLEAR_ERRORS';
export const APPLE_AUTH_DISABLED = 'APPLE_LOGIN_BUTTON__DISABLED';

export const actions = {
  initialize: (nonce, onFailure) => (dispatch) => {
    const id = 'apple-jssdk';
    const source = '//appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    const isLoaded = socialSignInImport(id, source, () =>
      dispatch(actions.initialize(nonce, onFailure)),
    );

    if (!isLoaded) {
      return;
    }

    dispatch({type: CONNECTING});
    try {
      AppleID.auth.init({
        clientId: window.settings.apple.clientId,
        scope: 'email name',
        state: 'origin:web',
        redirectURI: `${window.location.origin}/home`,
        nonce,
        usePopup: true,
      });

      dispatch({type: CONNECTED});
    } catch {
      dispatch({type: APPLE_AUTH_DISABLED});
      onFailure?.();
    }
  },

  login:
    (onLogin, loginOpts = {}) =>
    async (dispatch, getState) => {
      dispatch({type: AUTHENTICATING});
      try {
        const appleUser = await AppleID.auth.signIn();
        const state = getState();
        const input = {
          appleUser: {
            ...loginOpts,
            email: appleUser.user?.email,
            firstName: appleUser.user?.name.firstName,
            lastName: appleUser.user?.name.lastName,
            token: appleUser.authorization.id_token,
          },
        };
        const {
          data: {
            result: {user, accountCreated},
          },
        } = await authenticateWithApple(input);

        dispatch({type: AUTHENTICATING_SUCCESS, user});
        if (accountCreated) {
          dispatch(signUpFormActions.showSignUpSuccessModal());
        } else {
          dispatch(modalActions.closeModal({modal: CLOSE_ALL_MODALS}));
        }
        dispatch(signUpFormActions.onSignInSuccessful(user));
        segmentAnalytics.updateUserId({userId: user.id});
        if (accountCreated) {
          segmentAnalytics.track('accountCreated', {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            signInMethod: 'apple',
            feature: getSegmentFeature(state.modalViewed),
            pageUrl: window.location.pathname,
          });
        } else {
          window.metrics.track('Signed In', {provider: 'apple', userId: user.id});
        }

        if (onLogin) {
          dispatch(onLogin());
        }
      } catch (error) {
        sanitizeGraphQLError(error);

        if (error.error === 'popup_closed_by_user') {
          dispatch({type: AUTHENTICATING_CANCEL});
        } else {
          dispatch({type: AUTHENTICATING_ERROR, error});
        }
      }
    },

  clearErrors: () => ({
    type: CLEAR_ERRORS,
  }),
};

const authenticateWithApple = ({appleUser}) =>
  client.mutate({
    mutation: gql`
      mutation AuthenticateWithApple($appleUser: AuthenticateWithAppleInput!) {
        result: authenticateWithApple(input: $appleUser) {
          accountCreated
          user {
            id
            email
            firstName
            lastName
            zip
          }
        }
      }
    `,
    variables: {
      appleUser,
    },
  });

export const initialState = {
  isConnected: false,
  isConnecting: false,
  isAuthenticating: false,
  error: null,
  enabled: true,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CONNECTING:
      return update(state, {isConnected: {$set: false}, isConnecting: {$set: true}});
    case CONNECTED:
      return update(state, {isConnected: {$set: true}, isConnecting: {$set: false}});
    case AUTHENTICATING:
      return update(state, {isAuthenticating: {$set: true}});
    case AUTHENTICATING_CANCEL:
      return update(state, {isAuthenticating: {$set: false}});
    case AUTHENTICATING_SUCCESS:
      return update(state, {isAuthenticating: {$set: false}});
    case AUTHENTICATING_ERROR:
      return update(state, {isAuthenticating: {$set: false}, error: {$set: action.error}});
    case CLEAR_ERRORS:
      return update(state, {error: {$set: null}});
    case APPLE_AUTH_DISABLED:
      return update(state, {enabled: {$set: false}});
  }
  return state;
}
