import update from 'immutability-helper';
import {Reducer} from 'redux';

export interface NavigationState {
  isNavigating: boolean;
}

export const NAVIGATE = 'NAVIGATE';
export const NAVIGATE_RELOAD = 'NAVIGATE_RELOAD';

export const _stubbable = {
  navigate(url: string) {
    window.location.href = url;
  },

  reload() {
    window.location.reload();
  },
};

export const actions = {
  navigate: (path: string) => {
    _stubbable.navigate(path);
    return {
      type: NAVIGATE,
      path,
    };
  },

  reload: () => {
    _stubbable.reload();
    return {
      type: NAVIGATE_RELOAD,
    };
  },
};

const initialState = {
  isNavigating: false,
};

export const reducer: Reducer<NavigationState> = (state, action) => {
  state = state ?? initialState;

  switch (action.type) {
    case NAVIGATE:
    case NAVIGATE_RELOAD:
      return update(state, {isNavigating: {$set: true}});
  }
  return state;
};
