/*
 * Use these functions to communicate with garbanzo web apis.
 * Lives in web/ rather than infra/ 'cause it's coupled to web api
 * conventions.
 */
import {assignIn, omit} from 'lodash';
import fetch from 'isomorphic-fetch';
import {v4 as uuidv4} from 'uuid';

declare global {
  interface Window {
    // TODO: (@shermam) Rollbar is set in the following file
    // src/orzo/server/view_helpers/rollbar.js
    // there might be a better way of typing this...
    Rollbar: {
      error: (...args: unknown[]) => void;
    };
  }
}

/* Wrap fetch in a method that's stubbable in tests */
export const _stubbable = {fetch};

/*
 * Perform a json request.
 * Understands our error response conventions.
 * Accepts same options as MDN fetch(), automatically serializing {body}.
 * Accepts metricsProperties, which are merged with defaults. Argument overrides win.
 * Adds a request-id, especially helpful for tracking errors through our cdn to the backend.
 * Returns a promise that resolves to the response json,
 * or rejects with the json application error, or a representation of
 * the network error following our docs/errors.md conventions.
 */
async function requestJson<Response = unknown>(
  url: string,
  options: {metricsProperties?: Record<string, unknown>; method?: string} = {},
): Promise<Response> {
  const metricsProperties = {
    // Black-list properties we know are overridden on the server
    ...omit(window.metrics.pageProperties, 'shoppingSession', 'loggedIn', 'experiments'),
    ...options.metricsProperties,
  };

  const requestId = uuidv4();
  const credentials: RequestCredentials = 'same-origin';

  const mergedOptions = omit(
    {
      credentials,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-metrics-properties': JSON.stringify(metricsProperties),
        'x-request-id': requestId,
      },
      ...options,
    },
    'metricsProperties',
  );

  return _stubbable.fetch(url, mergedOptions).then(async (response) => {
    if (!response.ok) {
      return response.text().then(async (body) => {
        let error;
        try {
          error = JSON.parse(body).error || {};
        } catch (e) {
          error = e;
        }

        if (!error.type) {
          error.type = 'UNKNOWN_SERVER_ERROR';
        }
        error.response = response;

        if (!response.status || (response.status >= 405 && ![406, 409].includes(response.status))) {
          // TODO: This would be a good place for an app wide flash message Oops
          // log details of unexpected errors
          const message = `Unexpected ${response.status} response from web api`;
          const fingerprint = message; // group all of similar status together
          window.Rollbar.error(message, error, {
            fingerprint,
            body,
            status: response.status,
            url,
            'x-request-id': requestId,
          });
        }

        return Promise.reject(error);
      });
    }
    return response.json();
  });
}

/*
 * Posts a JSON payload, parses a json response.
 */
export async function postJson<T = unknown>(
  url: string,
  options: {body?: Record<string, unknown>} = {},
): Promise<T> {
  const body = JSON.stringify(options.body);
  return requestJson<T>(url, assignIn({method: 'post'}, omit(options, 'body'), {body}));
}

/*
 * Get a json response.
 */
export async function getJson<T = unknown>(url: string, options = {}): Promise<T> {
  return requestJson<T>(url, {method: 'get', ...options});
}

export default {_stubbable, postJson, getJson};
