import React, {Component} from 'react';
import {email as validateEmail} from 'goodeggs-validators';
import {func} from 'prop-types';

import Input from 'web/components/input';
import SubmitButton from 'web/components/submit_button';
import Alert from 'web/components/alert';
import EmailProvided from 'web/components/auth_flow/components/email_provided';

export default class EmailEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      collectedEmail: false,
      input: '',
    };
  }

  async onSubmit(e) {
    e.preventDefault();

    const {input: email} = this.state;

    this.setState({error: null});

    if (!validateEmail(email)) {
      this.setState({error: "Oops - that doesn't quite look right"});
      return;
    }
    const emailCapturedProps = {
      email,
      formName: 'Modal',
      loggedIn: this.props.user !== null,
      masquerading: this.props.user?.masquerading ?? false,
      zipCode: this.props.zip,
    };
    window.metrics.track('Email Captured', emailCapturedProps, false);

    if (this.props.onSuccess != null) {
      this.props.onSuccess();
    }
    await this.setState({collectedEmail: true});

    if (this.props.onCollectedEmail != null) {
      this.props.onCollectedEmail();
    }
  }

  handleInputValue = (ev) => {
    this.setState({input: ev.target.value});
  };

  render() {
    const className = !validateEmail(this.state.input)
      ? 'disabled-buttons__modal'
      : 'action__button';
    return (
      <div className="sign-in-flow__zip-code-collection ">
        {this.state.collectedEmail ? (
          <div>
            <EmailProvided zip={this.props.zip} onCloseModal={this.props.onCloseModal} />
          </div>
        ) : (
          <div>
            {this.state.error ? (
              <Alert className="flow-zip-entry__errors" type="error" heading={this.state.error} />
            ) : null}
            <div className="sign-in-flow__form-content">
              <form
                className="flow-zip-entry-form flow-zip-entry-form__email"
                onSubmit={this.onSubmit.bind(this)}
              >
                <div className="sign-in-flow__zip-code-collection ">
                  <Input
                    type="email"
                    className="flow-zip-entry_refresh_modal__input js-zip-input"
                    placeholder="Email"
                    onChange={this.handleInputValue}
                    value={this.state.input}
                  />
                  <SubmitButton
                    disabled={!validateEmail(this.state.input)}
                    className={`js-submit-zip ${className}`}
                  >
                    Notify me
                  </SubmitButton>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EmailEntry.propTypes = {
  onCollectedEmail: func,
};
