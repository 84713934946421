import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* redux-form-friendly toggle button */

export default class ToggleButton extends Component {
  render() {
    const meta = this.props.meta || {};
    const input = this.props.input || {};
    const onChange = input.onChange ? input.onChange.bind(this, !input.value) : () => null;
    return (
      <div className="toggle-button">
        <div
          className={classNames('toggle-button btn no', {active: !input.value})}
          onClick={onChange}
        >
          No
        </div>
        <div
          className={classNames('toggle-button btn yes', {active: input.value})}
          onClick={onChange}
        >
          Yes
        </div>
        {meta.touched && meta.invalid ? <div className="error">{meta.error}</div> : null}
      </div>
    );
  }
}

ToggleButton.propTypes = {
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }),
};
