import React, {FC} from 'react';

import useClientSettings from 'web/hooks/useClientSettings';
import assetPath from 'web/helpers/asset_path';

export type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  alt: string;
  src: string;
  optimizeFormat?: boolean;
  width?: number;
  height?: number;
};

const Image: FC<ImageProps> = ({src, width, height, optimizeFormat = true, ...props}) => {
  const clientSettings = useClientSettings();
  const manifestPath = assetPath(src);

  const isImage = /\.(png|svg|jpg|jpeg|gif)$/i.test(manifestPath);

  if (!isImage) {
    throw new Error('Cannot use image component with non-supported image type');
  }

  let processedSrc = manifestPath;

  if (clientSettings.imgix.enableAssetProcessing) {
    const url = new URL(manifestPath);

    if (width != null) {
      url.searchParams.set('width', width.toString());
    }

    if (height != null) {
      url.searchParams.set('height', height.toString());
    }

    if (optimizeFormat) {
      url.searchParams.set('auto', 'format');
    }

    processedSrc = url.toString();
  }

  return <img {...props} src={processedSrc} width={width} height={height} />;
};

export default Image;
