import React, {ReactElement, useState} from 'react';
import classNames from 'classnames';

import basketDuck from 'web/helpers/basket_duck';

export interface QuickAddProps {
  segmentFeature: string;
  quantityInBasket: number;
  productId: string;
  maxQuantity: number;
  position: number;
  context?: any;
  onQuickAdd?(productId: string): void;
  onQuickRemove?(productId: string): void;
  dispatch(obj: any): void;
}
const QuickAdd = ({
  segmentFeature,
  quantityInBasket,
  productId,
  maxQuantity,
  position,
  context,
  onQuickAdd,
  onQuickRemove,
  dispatch,
}: QuickAddProps): ReactElement => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const [previousQuantity, setPreviousQuantity] = useState(0);

  const handleQuickAdd = (): void => {
    scrollBasketNumber('up');
    dispatch(
      basketDuck.actions.incrementQuantity(
        {productId, position},
        {context: {feature: segmentFeature, ...context}},
      ),
    );
    onQuickAdd?.(productId);
  };

  const handleQuickRemove = (): void => {
    scrollBasketNumber('down');
    dispatch(
      basketDuck.actions.decrementQuantity(
        {productId},
        {context: {feature: segmentFeature, ...context}},
      ),
    );
    onQuickRemove?.(productId);
  };

  const scrollBasketNumber = (scrollDir?: string | null): void => {
    if (
      (quantityInBasket >= maxQuantity && scrollDir === 'up') ||
      (quantityInBasket === 0 && scrollDir === 'down')
    )
      return;

    setPreviousQuantity(quantityInBasket);
    setScrollDirection(scrollDir || '');
  };

  const handleScrollAnimationEnd = (): void => {
    setScrollDirection('');
  };

  const scrollAnimationClass = (): string => scrollDirection && `animate-scroll-${scrollDirection}`;

  return (
    <div className="product-tile__quick-add" data-testid="product-tile-quick-add-component">
      <div
        className={classNames('product-tile__quick-add-count', {
          'is-transparent': quantityInBasket === 0,
        })}
      >
        <div className={classNames('product-tile__quick-add-quantity', scrollAnimationClass())}>
          <div
            className="product-tile__quantity-spinner product-tile__quantity-spinner__quantity js-quantity-indicator"
            onAnimationEnd={handleScrollAnimationEnd}
            data-testid="product-tile-quick-add-quantity-showing"
          >
            {quantityInBasket}
          </div>
          <div className="product-tile__quantity-spinner product-tile__quantity-spinner__previous-quantity js-quantity-indicator">
            {previousQuantity}
          </div>
        </div>
        <div className="product-tile__quick-add-quantity-label">
          <div className="product-tile__quick-add-quantity-label-result">added</div>
          <div className="product-tile__quick-add-quantity-label-summary">in your basket</div>
        </div>
        <div className="product-tile__quick-add-gift">
          <i className="icon icon-share-gift" />
        </div>
        <div className="product-tile__quick-add-gift-label">
          <div className="product-tile__quick-add-gift-summary">Gift in your basket</div>
        </div>
      </div>
      <div className="product-tile__quick-add-buttons">
        <button
          type="button"
          className="btn product-tile__quick-add-button product-tile__quick-add-button-remove js-product-tile__quick-remove"
          data-testid="product-tile-quick-remove"
          onClick={handleQuickRemove}
        >
          <div className="product-tile__quick-add-minus" />
        </button>
        <button
          type="button"
          aria-label="Add to basket"
          className="btn product-tile__quick-add-button product-tile__quick-add-button-add js-product-tile__quick-add"
          data-testid="product-tile-quick-add"
          onClick={handleQuickAdd}
        >
          <div className="product-tile__quick-add-plus" />
        </button>
      </div>
    </div>
  );
};

export default QuickAdd;
