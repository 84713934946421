import React, {ReactElement} from 'react';

import * as formatMoney from 'infra/formatters/money';
import SplitPrice from 'web/components/split_price';
import {IMarketProductUI} from 'domain/catalog/products/models/market_product_ui';
import useClientSettings from 'web/hooks/useClientSettings';

interface PriceInfoProps {
  product: IMarketProductUI;
  enableStrikethroughPrice?: boolean;
}

const PriceInfo = ({
  product: {retailUnits, comparisonUnitPrice, comparisonUnitName, basePrice, promoPrice},
  enableStrikethroughPrice = true,
}: PriceInfoProps): ReactElement => {
  const clientSettings = useClientSettings();
  const [dollars, cents] = formatMoney.split(comparisonUnitPrice ?? 0);
  const [basePriceDollars, basePriceCents] = formatMoney.split(basePrice);

  const hasPromotion = promoPrice != null && promoPrice > 0;
  const retailPrice = hasPromotion ? promoPrice : basePrice;

  const applyStrikethroughPrice =
    clientSettings.strikethroughPricing.enableWeb && enableStrikethroughPrice && hasPromotion;

  return (
    <>
      <div className="product-tile__price-info product-tile-collapsing-row product-tile-strikethrough clearfix">
        <div className="product-tile__purchase-price product-tile-collapsing-row__fixed-right">
          {applyStrikethroughPrice && (
            <span data-testid="product-tile__strikethrough-price" className="dollars">
              ${basePriceDollars}.{formatMoney.padCents(basePriceCents)}
            </span>
          )}
        </div>
      </div>

      <div className="product-tile__price-info product-tile-collapsing-row clearfix">
        <div
          data-testid="product-tile__final-price"
          className={`product-tile__purchase-price product-tile-collapsing-row__fixed-right ${
            applyStrikethroughPrice ? ' promo-discount-info' : ''
          }`}
        >
          <SplitPrice priceInCents={retailPrice} />
        </div>
        {comparisonUnitPrice != null && comparisonUnitPrice > 0 && (
          <div
            className="product-tile__comparison-price product-tile-collapsing-row__secondary-info"
            data-testid="product-tile__comparison-price"
          >
            <span className="price-per">{`$${dollars}.${formatMoney.padCents(cents)} `}</span>/{' '}
            <span className="per-unit">{comparisonUnitName}</span>
          </div>
        )}
        <div
          data-testid="product-tile__purchase-unit"
          className="product-tile__purchase-unit product-tile-collapsing-row__primary-info"
        >
          {retailUnits}
        </div>
      </div>
    </>
  );
};

export default PriceInfo;
