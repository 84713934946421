// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import React from 'react';

const Disclaimer = () => (
  <div className="auth-flow__disclaimer">
    By signing up, you agree to Good Eggs{"' "}
    <a href="/terms" rel="noopener" target="_blank">
      Terms of Service
    </a>{' '}
    and{' '}
    <a href={settings.privacyLink} rel="noopener" target="_blank">
      Privacy Policy
    </a>
    .
  </div>
);

export default Disclaimer;
