import $ from 'jquery';
import {once} from 'lodash';

import Metrics from 'infra/metrics/client';
import segmentAnalytics from '@analytics/client';

/**
 * Initialize the page level services (ajax error, metrics, rollbar) in such a way that it only happens once
 * (eg for the nettle/redux hybrid)
 */

export const initWindowServices = once(({disableSegment} = {}) => {
  window.metrics = new Metrics({disableSegment});

  $(document).on('ajaxError', (e, xhr, options, error) => {
    if (!xhr || (xhr.status === 0 && xhr.readyState === 0) || error === 'abort') {
      // connection error
      $(document).trigger('alert', [
        'Well that’s not working quite right. We were unable to connect to the server.',
        'error',
      ]);
    } else if ([400, 402, 409].indexOf(xhr.status) >= 0) {
      // ignore - login or validation error
    } else if (xhr.status === 401) {
      $(document).trigger('alert', ['You are not authorized to view that page.', 'error']);
    } else if (xhr.status === 503) {
      // scheduled downtime
      window.location.reload();
    } else {
      $(document).trigger('alert', [
        'Well that’s not working quite right. We’ll get it fixed as soon as possible.',
        'error',
      ]);
    }
  });
});

export const initSession = once(({sessionId, userId, email, masquerading}) => {
  if (!sessionId) {
    window.console.error('storeData.session missing required for metrics & rollbar tracking');
  }
  window.metrics.setUserIds({
    userId,
    anonymousId: sessionId,
  });
  segmentAnalytics.setUserIds({userId, anonymousId: sessionId});

  window.metrics.setPageProperty('masquerading', Boolean(masquerading));
  segmentAnalytics.setContextField('masquerading', Boolean(masquerading));

  if (userId && window.Rollbar) {
    window.Rollbar.configure({
      payload: {
        person: {
          id: userId,
          username: email,
          email,
        },
      },
    });
  }
});
