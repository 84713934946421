import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AddressAutocompleteInput from './components/address_autocomplete_input';

export const AddressAutoCompleteInput = (fields) => {
  const handleChangeAddress = ({address, city, state, zip} = {}) => {
    if (!address || !zip || !city || !state) {
      window.metrics.track('Partial address', {city, state, zip, address});
    }
    fields.address.input.onChange(address);
    fields.city.input.onChange(city);
    fields.state.input.onChange(state);
    fields.zip.input.onChange(zip);
  };
  return (
    <AddressAutocompleteInput
      onSuggestSelect={handleChangeAddress}
      errorMessage={fields.address.meta.touched && fields.address.meta.error}
      initialValue={`${[
        fields.address.input.value,
        fields.city.input.value,
        fields.state.input.value,
        fields.zip.input.value,
      ]
        .filter(Boolean)
        .join(', ')}`}
      country="us"
      onFocus={handleChangeAddress}
      /* DLNOTE: Issue: https://github.com/ubilabs/react-geosuggest/issues/77*/
      /* types={["street_address"]}*/
    />
  );
};

export const GiftRecipientEmailInput = ({input, meta}) => (
  <div className="delivery-address-form__gift-recipient-email-input">
    <input
      className={classNames('form-control', {
        'delivery-address-form__has-error': meta.touched && meta.invalid,
      })}
      type="email"
      placeholder="Recipient's Email Address"
      {...input}
    />
    {meta.touched && meta.invalid ? (
      <div className="delivery-address-form__error">{meta.error}</div>
    ) : null}
    <p className="delivery-address-form__helper">
      If provided, we use this email address to send your gift message and meal kit instructions.
    </p>
  </div>
);

GiftRecipientEmailInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export const GiftRecipientPhoneInput = ({input, meta}) => (
  <div className="delivery-address-form__gift-recipient-phone-input">
    <input
      className={classNames('form-control', {
        'delivery-address-form__has-error': meta.touched && meta.invalid,
      })}
      type="tel"
      placeholder="Recipient's Phone Number"
      data-testid="recipientPhoneNumber"
      {...input}
    />
    {meta.touched && meta.invalid ? (
      <div className="delivery-address-form__error">{meta.error}</div>
    ) : null}
    <p className="delivery-address-form__helper">
      We use this phone number to send a delivery reminder text right before delivery.
    </p>
  </div>
);

GiftRecipientPhoneInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export const CanLeaveAtDoorInput = ({input, isAttendedDeliveryRequired}) => {
  const canLeaveAtDoorSelected = input.value && !isAttendedDeliveryRequired;
  return (
    <div className="delivery-address-form__leave-at-door-options">
      {isAttendedDeliveryRequired ? (
        <div className="delivery-address-form__leave-at-door-warning">
          <i
            className={classNames('icon', 'delivery-address-form__orange-icon', 'icon-circle-info')}
          />
          <span>ID required in person for alcohol delivery</span>
        </div>
      ) : null}
      <div
        className={classNames({
          'delivery-address-form__leave-at-door-option': !isAttendedDeliveryRequired,
          'delivery-address-form__disabled': isAttendedDeliveryRequired,
        })}
        onClick={() => {
          if (!isAttendedDeliveryRequired) {
            input.onChange(true);
          }
        }}
      >
        <i
          className={classNames('icon', {
            'delivery-address-form__green-icon': canLeaveAtDoorSelected,
            'delivery-address-form__unselected-icon': !canLeaveAtDoorSelected,
            'delivery-address-form__disabled-icon': isAttendedDeliveryRequired,
            'icon-circle-checkmark': canLeaveAtDoorSelected,
            'icon-sidebar-blank-circle': !canLeaveAtDoorSelected,
            'icon-disabled': isAttendedDeliveryRequired,
          })}
        />
        <span>It{"'"}s ok to leave the order unattended</span>
      </div>
      <div
        className="delivery-address-form__leave-at-door-option"
        onClick={() => input.onChange(false)}
      >
        <i
          className={classNames('icon', {
            'delivery-address-form__green-icon': !canLeaveAtDoorSelected,
            'delivery-address-form__unselected-icon': canLeaveAtDoorSelected,
            'icon-circle-checkmark': !canLeaveAtDoorSelected,
            'icon-sidebar-blank-circle': canLeaveAtDoorSelected,
          })}
        />
        <span>Don{"'"}t leave the order unattended</span>
      </div>
    </div>
  );
};

CanLeaveAtDoorInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }),
  isAttendedDeliveryRequired: PropTypes.bool,
};

export const InputWithErrors = ({input, meta, className, placeholder}) => {
  return (
    <div className="delivery-address-form__field">
      <input
        type="text"
        className={classNames(className, {
          'delivery-address-form__has-error': meta.touched && meta.invalid,
        })}
        placeholder={placeholder}
        data-testid="giftRecipientName"
        {...input}
      />
      {meta.touched && meta.error ? (
        <div className="delivery-address-form__error error">{meta.error}</div>
      ) : null}
    </div>
  );
};

InputWithErrors.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  className: PropTypes.string,
};
