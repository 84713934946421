import update from 'immutability-helper';
import {ConfirmCardSetupOptions, SetupIntentResult} from '@stripe/stripe-js';
import {Dispatch, Reducer} from 'redux';

import {createStripeSetupIntent, updateStripePaymentInfo} from './helpers';

export const STRIPE_SETUP_TYPE = {
  SETUP_INTENT_CREATED: 'SETUP_INTENT_CREATED',
  SETUP_INTENT_ERROR: 'SETUP_INTENT_ERROR',
  SETUP_INTENT_CONFIRMING: 'SETUP_INTENT_CONFIRMING',
  SETUP_INTENT_CONFIRMED: 'SETUP_INTENT_CONFIRMED',
  CAPTCHA_TOKEN: 'CAPTCHA_TOKEN',
};

export const actions = {
  captchaToken: (token?: string | null) => ({
    type: STRIPE_SETUP_TYPE.CAPTCHA_TOKEN,
    token,
    error: null,
  }),
  createdSetupIntent: (setupIntentId: string) => ({
    type: STRIPE_SETUP_TYPE.SETUP_INTENT_CREATED,
    setupIntentId,
  }),
  setupIntentError: (error: unknown) => ({type: STRIPE_SETUP_TYPE.SETUP_INTENT_ERROR, error}),
  confirmingSetupIntent: () => ({type: STRIPE_SETUP_TYPE.SETUP_INTENT_CONFIRMING}),
  confirmedSetupIntent: () => ({type: STRIPE_SETUP_TYPE.SETUP_INTENT_CONFIRMED}),
  createSetupIntent:
    (successFun?: (setupIntentId: string) => void) =>
    async (dispatch: Dispatch): Promise<void> => {
      const response = await createStripeSetupIntent();

      if (
        !response.data?.createSetupIntent.success ||
        response.data?.createSetupIntent.setupIntentId == null
      ) {
        const error = response.data?.createSetupIntent.errorInfo;
        dispatch(actions.setupIntentError(error));
        return;
      }

      const {setupIntentId} = response.data.createSetupIntent;

      dispatch(actions.createdSetupIntent(setupIntentId));
      successFun?.(setupIntentId);
    },
  confirmSetupIntent:
    <TStripeCardElement>(
      // TODO: (@shermam) `stripe` and `cardElement` here were fully typed with types `Stripe` and `StripeCardElement`
      // but if we go with that full type it becomes a bit cumbersome to create the full mocks.
      // Revert back to the full type once we take the time to create full mocks for both Stripe things
      stripe: {
        confirmCardSetup(
          clientSecret: string,
          data?: {payment_method: {card: TStripeCardElement}},
          options?: ConfirmCardSetupOptions,
        ): Promise<SetupIntentResult>;
      },
      cardElement: TStripeCardElement,
      clientSecret: string,
      captchaToken?: string | null,
      successFun?: () => void,
      enableStripeActions?: boolean,
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(actions.confirmingSetupIntent());
        const result = await stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: {card: cardElement},
          },
          {handleActions: enableStripeActions ?? false},
        );

        if (result.error) {
          dispatch(actions.setupIntentError(result.error));
          return;
        }

        const response = await updateStripePaymentInfo(result.setupIntent.id, captchaToken);

        if (response !== undefined) {
          dispatch(actions.confirmedSetupIntent());
          successFun?.();
        }
      } catch (error) {
        if (error != null && typeof error === 'object' && 'type' in error && 'message' in error) {
          dispatch(actions.setupIntentError(error));
        } else {
          dispatch(actions.setupIntentError({type: 'UNKNOWN_ERROR', message: 'Unable to confirm'}));
        }
      }
    },
};

export interface SetupIntentState {
  creditCardError?: {message: string} | null;
  captchaToken?: string | null;
  setupIntentId?: string | null;
}

const initialState: SetupIntentState = {
  creditCardError: null,
  captchaToken: null,
  setupIntentId: null,
};

export const reducer: Reducer<SetupIntentState> = (state, action) => {
  state = state ?? initialState;
  switch (action.type) {
    case STRIPE_SETUP_TYPE.SETUP_INTENT_ERROR:
      return update(state, {creditCardError: {$set: action.error}, setupIntentId: {$set: null}});
    case STRIPE_SETUP_TYPE.SETUP_INTENT_CONFIRMING:
      return update(state, {creditCardError: {$set: null}});
    case STRIPE_SETUP_TYPE.SETUP_INTENT_CREATED:
      return update(state, {
        creditCardError: {$set: null},
        setupIntentId: {$set: action.setupIntentId},
      });
    case STRIPE_SETUP_TYPE.SETUP_INTENT_CONFIRMED:
      return update(state, {
        creditCardError: {$set: null},
        captchaToken: {$set: null},
        setupIntentId: {$set: null},
      });
    case STRIPE_SETUP_TYPE.CAPTCHA_TOKEN:
      return update(state, {captchaToken: {$set: action.token}});
    default:
      return state;
  }
};
