import React, {FC} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

import SignUpModal from 'web/components/sign_up_modal';
import SignInModal from 'web/components/sign_in_modal';
import SignUpSuccessModal from 'web/components/sign_up_success_modal';
import AuthFlowModal from 'web/components/auth_flow_modal';
import ZipCodeModal from 'web/components/zip_code_modal/index';
import ZipCodeConsolationModal from 'web/components/zip_code_consolation_modal/index';
import ZipCodeSuccessModal from 'web/components/zip_code_success_modal/index';

type ModalTypes =
  | typeof AuthFlowModal
  | typeof ZipCodeModal
  | typeof ZipCodeConsolationModal
  | typeof ZipCodeSuccessModal
  | typeof SignUpModal
  | typeof SignInModal
  | typeof SignUpSuccessModal;

const componentMapping: {[key: string]: ModalTypes} = {
  AuthFlowModal,
  ZipCodeModal,
  ZipCodeSuccessModal,
  ZipCodeConsolationModal,
  SignUpModal,
  SignInModal,
  SignUpSuccessModal,
};

export type ModalStackArray = Array<[string, Record<string, unknown> | null]>;

interface StateProps {
  displayModal: boolean;
  modalComponent: ModalTypes | null;
  modalStack: ModalStackArray;
  modalProps: Record<string, unknown>;
}

interface Props extends StateProps {}

const ModalStack: FC<Props> = ({displayModal, modalComponent, modalProps}) => {
  const ModalComponent = displayModal ? modalComponent : null;

  const [domReady, setDomReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDomReady(true);
    if (document.querySelector('#modal-container') == null) {
      const container = document.createElement('div');
      container.id = 'modal-container';
      document.body.appendChild(container);
    }
  }, []);

  return domReady
    ? ReactDOM.createPortal(
        // @ts-ignore FIXME: (guilherme-vp) For some reason the properties of this component started
        // to throw after I updated React types
        <div>{ModalComponent != null ? <ModalComponent {...modalProps} /> : null}</div>,
        document.getElementById('modal-container') as Element,
      )
    : null;
};

const mapStateToProps = (state: StateProps): StateProps => {
  const {modalStack} = state;
  const displayModal = modalStack != null && modalStack.length > 0;
  const modalProps = displayModal ? modalStack.reverse()[0][1] ?? {} : {};
  const modalComponent = displayModal ? componentMapping[modalStack.reverse()[0][0]] : null;
  return {
    displayModal,
    modalComponent,
    modalStack,
    modalProps,
  };
};

export default connect(mapStateToProps)(ModalStack);
