import React, {FC, useEffect, useState, useCallback} from 'react';
import {debounce, isEmpty} from 'lodash';
import useAlgolia from 'use-algolia';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import segmentAnalytics from '@analytics/client';
import useRecentSearches from 'web/hooks/useRecentSearches';
import useClientSettings from 'web/hooks/useClientSettings';

interface Name {
  value: string;
  matchLevel: string;
  matchedWords: string[];
}

interface HighlightResult {
  name: Name;
}

export interface SearchAsYouTypeAlgoliaHit {
  name: string;
  objectID: string;
  _highlightResult: HighlightResult;
}

export interface SearchAsYouTypeProps {
  searchTerm: string;
  rootRef?: React.RefObject<HTMLElement>;
  onChangeSelectedItem?: (selectedItem?: string) => void;
  onItemClick?: (item: string) => void;
  isMobile?: boolean;
}

const SearchAsYouType: FC<SearchAsYouTypeProps> = ({
  searchTerm,
  rootRef,
  onChangeSelectedItem,
  onItemClick,
  isMobile,
}) => {
  const clientSettings = useClientSettings();
  const foodhubSlug = useSelector<{foodhubSlug: string}, string>((state) => state.foodhubSlug);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const {recentSearches, addRecentSearch} = useRecentSearches();
  const [searchState, requestDispatch] = useAlgolia<SearchAsYouTypeAlgoliaHit>(
    clientSettings.search.algolia.app,
    clientSettings.search.algolia.key,
    clientSettings.search.algolia.index,
    {
      hitsPerPage: !isMobile ? 10 : 3,
      filters: `availableFoodhubs:${foodhubSlug}`,
      query: searchTerm,
      attributesToRetrieve: ['name', 'objectID', '_highlightResult'],
      clickAnalytics: true,
    },
  );
  const matchingRecentSearches = recentSearches
    .filter((recentSearch) => recentSearch.toLowerCase().includes(searchTerm.toLowerCase()))
    .slice(0, 3);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const resultsLength = Math.min(
        (searchState.response?.hits.length ?? 1) + matchingRecentSearches.length,
        !isMobile ? 10 : 3,
      );
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItemIndex((currentSelectedItem) => {
          if (currentSelectedItem === 0) return -1;
          if (currentSelectedItem < 0) return resultsLength - 1;
          return currentSelectedItem - 1;
        });
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItemIndex((currentSelectedItem) => {
          if (currentSelectedItem === resultsLength - 1) return -1;
          return currentSelectedItem + 1;
        });
      }
    },
    [searchState.response, matchingRecentSearches],
  );

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      setSelectedItemIndex(-1);
      requestDispatch({
        filters: `availableFoodhubs:${foodhubSlug}`,
        query: searchQuery,
        hitsPerPage: !isMobile ? 10 : 3,
        clickAnalytics: true,
      });
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    const ref = rootRef?.current;
    ref?.addEventListener('keydown', handleKeyDown);
    return (): void => {
      ref?.removeEventListener('keydown', handleKeyDown);
    };
  }, [rootRef, searchState.response, handleKeyDown]);

  useEffect(() => {
    onChangeSelectedItem?.(
      selectedItemIndex === -1
        ? undefined
        : matchingRecentSearches[selectedItemIndex] ??
            searchState.hits[selectedItemIndex - matchingRecentSearches.length].name,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemIndex, searchTerm]);

  const handleRecentSearchClick = (recentSearch: string): void => {
    segmentAnalytics.track('siteSearched', {
      query: recentSearch,
      queryType: 'recent',
      index: clientSettings.search.algolia.index,
      queryID: searchState.response?.queryID,
    });
    addRecentSearch(recentSearch);
    onItemClick?.(recentSearch);
  };

  const handleSuggestionClick = (suggestion: string): void => {
    segmentAnalytics.track('siteSearched', {
      query: suggestion,
      queryType: 'suggestion',
      index: clientSettings.search.algolia.index,
      queryID: searchState.response?.queryID,
    });
    onItemClick?.(suggestion);
  };

  return !isEmpty(searchState?.response?.hits) || matchingRecentSearches.length > 0 ? (
    <div className="search-as-you-type">
      {matchingRecentSearches.map((recentSearch, recentSearchIndex) => (
        <div
          role="button"
          key={recentSearch}
          data-testid="search-as-you-type__item-recent"
          onClick={(): void => handleRecentSearchClick(recentSearch)}
          className={classNames('search-as-you-type__item', {
            active: recentSearchIndex === selectedItemIndex,
          })}
        >
          <i className="icon icon-recent-clock recent-search-icon" />
          <span>
            {recentSearch
              .split(new RegExp(`(${searchTerm})`, 'gi'))
              .map((part: string, partIndex) =>
                part.toLowerCase() === searchTerm.toLowerCase() ? (
                  <em key={`${searchTerm}-${part}-${partIndex}`}>{part}</em>
                ) : (
                  <span key={`${searchTerm}-${part}-${partIndex}`}>{part}</span>
                ),
              )}
          </span>
        </div>
      ))}
      {searchState.response?.hits
        ?.slice(0, searchState.response?.hits?.length - matchingRecentSearches.length)
        .map((hit, index) => (
          <div
            key={hit.objectID}
            role="button"
            data-testid="search-as-you-type__item-algolia"
            onClick={(): void => handleSuggestionClick(hit.name)}
            className={classNames('search-as-you-type__item', {
              active: index + matchingRecentSearches.length === selectedItemIndex,
            })}
          >
            <i className="icon icon-small-search" />
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{__html: hit._highlightResult.name.value}} />
          </div>
        ))}
    </div>
  ) : null;
};

export default SearchAsYouType;
