import moment, {MomentInput} from 'infra/moment';
import {humanPriceFromCents, humanDollarsFromCents, Cents} from 'infra/formatters/money';
import {DeliveryStatus} from 'orzo/server/models/fulfillment';

/*
Determine if some date is the day after some other date.

@param {(Date|string|moment)} target - the date we want to compare to now
@param {string} tzid - a valid timezone name
@returns {boolean}
*/

function isTomorrow(target: MomentInput, tzid: string): boolean {
  target = moment.tz(target, tzid);
  const dayTomorrow = moment().tz(tzid).add(1, 'days').format('YYYY-MM-DD');
  const dayTarget = target.format('YYYY-MM-DD');
  return dayTomorrow === dayTarget;
}

/*
Human readable day of week

@param {(Date|string|moment)} target - the date we want to format
@param {string} tzid - a valid timezone name
@param {object} [options={titleCase: true}] - capitalize the returned day
@param {boolean} [options={forceDayString}] - always return the day of week, never 'today' or 'tomorrow'
@return {string} Either 'Today', 'Tomorrow', or the day of the week

@example It is Sunday Jan 31 2016, pacific time
   humanDayOfWeek('2016-02-01', 'America/Los_Angeles')
   // returns: "Tomorrow"

@example It is Sunday Jan 31 2016, pacific time
   humanDayOfWeek('2016-01-31', 'America/Los_Angeles')
   // returns: "Today"

@example It is Sunday Jan 31 2016, pacific time
   humanDayOfWeek('2016-02-02', 'America/Los_Angeles')
   // returns: "Tuesday"

@example It is Sunday Jan 31 2016, pacific time
   humanDayOfWeek('2016-02-01', 'America/Los_Angeles', {titleCase: false})
   // returns: "tomorrow"

@example It is Sunday Jan 31 2016, pacific time
   humanDayOfWeek('2016-02-01', 'America/Los_Angeles', {titleCase: false, forceDayString})
   // returns: "Monday"
*/
export function humanDayOfWeek(
  target: MomentInput,
  tzid: string,
  {
    titleCase,
    fullDate,
    forceDayString,
  }: {titleCase?: boolean; fullDate?: boolean; forceDayString?: boolean} = {},
): string {
  target = moment.tz(target, tzid);
  const now = moment().tz(tzid);
  if (titleCase == null) {
    titleCase = true;
  }

  if (forceDayString) {
    return target.format('dddd');
  }
  if (target.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
    return titleCase ? 'Today' : 'today';
  } else if (isTomorrow(target, tzid)) {
    return titleCase ? 'Tomorrow' : 'tomorrow';
  }

  if (fullDate) {
    return target.format('dddd M/D');
  }
  return target.format('dddd');
}

/*
@param {(Date|string|moment)} startAt - beginning of the fulfillment window, including day and time
@param {(Date|string|moment)} endAt - end of the fulfillment window, including day and time
@param {string} tzid - a valid timezone name
@returns {string}
*/
export function deliveryTimeHeader(startAt: MomentInput, endAt: MomentInput, tzid: string): string {
  startAt = moment.tz(startAt, tzid);
  endAt = moment.tz(endAt, tzid);
  const now = moment().tz(tzid);

  if (now.valueOf() === startAt.valueOf() || now.isBefore(startAt)) {
    return `${humanDayOfWeek(startAt, tzid)} ${startAt.format('M/D')}, ${humanTimeSlotDescriptor(
      startAt,
      endAt,
      tzid,
    )}`;
  }

  return `${humanDayOfWeek(startAt, tzid)} ${humanTimeSlotDescriptor(startAt, endAt, tzid)}`;
}

/*
Delivery time slot label

@param {(Date|string|moment)} startAt - beginning of the fulfillment window, including day and time
@param {(Date|string|moment)} endAt - end of the fulfillment window, including day and time
@param {string} tzid - a valid timezone name
@param {Object} options
@param {boolean} [options.relative = true] - If true, use 'Today' and 'Tomorrow' instead of week day when applicable
@returns {string}

@example It is 7am pacific, Sunday, Jan 31, 2016
  deliveryTimeLabel('2016-01-31 17:00:00', '2016-01-31 19:00:00', 'America/Los_Angeles')
  // returns: "Today, 5-7pm"

@example It is 7am pacific, Sunday, Jan 31, 2016
  deliveryTimeLabel('2016-01-31 17:00:00', '2016-01-31 19:00:00', 'America/Los_Angeles', {relative: false})
  // returns: "Sunday, 5-7pm"

@example It is 7am pacific, Sunday, Jan 31, 2016
  deliveryTimeLabel('2016-02-01 10:00:00', '2016-02-01 12:00:00', 'America/Los_Angeles')
  // returns: "Tomorrow, 10am - noon"

@example It is 7am pacific, Sunday, Jan 31, 2016
  deliveryTimeLabel('2016-02-05 11:00:00', '2016-02-05 13:00:00', 'America/Los_Angeles')
  // returns: "Friday, 11am - 1pm"

@example It is 7am pacific, Sunday, Jan 31, 2016
  deliveryTimeLabel('2016-01-30 16:00:00', '2016-01-30 18:00:00', 'America/Los_Angeles')
  // returns: "Saturday, 1/30, 4-6pm"
*/
export function deliveryTimeLabel(
  startAt: MomentInput,
  endAt: MomentInput,
  tzid: string,
  options: {relative?: boolean} = {relative: true},
): string {
  startAt = moment.tz(startAt, tzid);
  endAt = moment.tz(endAt, tzid);
  const now = moment().tz(tzid);

  if (!options.relative) {
    return `${startAt.format('dddd M/D')}, ${humanTimeRange(startAt, endAt, tzid)}`;
  }

  if (now.isAfter(startAt, 'day')) {
    return `${humanDayOfWeek(startAt, tzid)} ${startAt.format('M/D')}, ${humanTimeRange(
      startAt,
      endAt,
      tzid,
    )}`;
  }
  return `${humanDayOfWeek(startAt, tzid, {fullDate: true})}, ${humanTimeSlotDescriptor(
    startAt,
    endAt,
    tzid,
  )}`;
}

/*
Shorter version of order cutoff used by basket time picker (omits cutoff day if today)

@param {(Date|string|moment)} orderCutoff - final time when an order can be placed for a given fulfillment option, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example It is Sunday Jan 31 2016, 7am, pacific time
   shortCutoffTimeAndDay('2016-01-31 08:00:00', 'America/Los_Angeles')
   // returns: "8am"

@example It is Sunday Jan 31 2016, 7am, pacific time
   shortCutoffTimeAndDay('2016-02-01 00:00:00', 'America/Los_Angeles')
   // returns: "midnight"

@example It is Sunday Jan 31 2016, 7am, pacific time
   shortCutoffTimeAndDay('2016-01-31 08:00:00', 'America/Los_Angeles')
   // returns: "1pm tomorrow"

@example It is Sunday Jan 31 2016, 7am, pacific time
   shortCutoffTimeAndDay('2016-02-02 08:00:00', 'America/Los_Angeles')
   // returns: "8am Tuesday"
*/
export function shortCutoffTimeAndDay(
  orderCutoff: MomentInput,
  tzid: string,
  formatOptions: {isForSubscriptionSms: boolean} = {isForSubscriptionSms: false},
): string {
  let day;
  orderCutoff = moment.tz(orderCutoff, tzid);
  const now = moment().tz(tzid);
  const time = orderCutoffTime(orderCutoff, tzid);

  // Count midnight as the previous day, despite _technically_ starting the new day
  if (time === 'midnight') {
    orderCutoff = orderCutoff.subtract({seconds: 1});
  }

  if (now.format('YYYY-MM-DD') === orderCutoff.format('YYYY-MM-DD')) {
    return time;
  }

  if (formatOptions.isForSubscriptionSms) {
    day = isTomorrow(orderCutoff, tzid) ? 'tomorrow' : orderCutoff.format('dddd, M/D');
    return `${day} at ${time}`;
  }

  day = isTomorrow(orderCutoff, tzid) ? 'tomorrow' : orderCutoff.format('dddd M/D');
  return `${time} ${day}`;
}

/*
Human readable order cutoff time.

@param {(Date|string|moment)} orderCutoff - final time when an order can be placed for a given fulfillment option, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example
  orderCutoffTime('2016-01-31 13:00:00', 'America/Los_Angeles')
  // returns: "1pm"

@example
  orderCutoffTime('2016-01-31 00:00:00', 'America/Los_Angeles')
  // returns: "midnight"
*/
export function orderCutoffTime(orderCutoff: MomentInput, tzid: string): string {
  orderCutoff = moment.tz(orderCutoff, tzid);
  if (moment(orderCutoff).startOf('day').valueOf() === orderCutoff.valueOf()) {
    return 'midnight';
  }
  return orderCutoff.minutes() === 0 ? orderCutoff.format('ha') : orderCutoff.format('h:mma');
}

/*
Human readable order status. Not ideal for same day (see first example below)

@param {(Date|string|moment)} startAt - beginning of the fulfillment window for a fulfillment option
@param {(Date|string|moment)} orderCutoff - final time when an order can be placed for a given fulfillment option, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example It's Sunday, 7am pacific, Jan 31, 2016. I placed a same day order with an 8am cutoff, and the fulfillment window starts at 1pm.
  orderStatus('2016-01-31 13:00:00', '2016-01-31 08:00:00', 'America/Los_Angeles')
  // returns: "Make changes until 8am tonight"

@example It's Sunday, 7am pacific, Jan 31, 2016. I placed a next day order with a midnight cutoff, and the fulfillment window starts at 6pm.
  orderStatus('2016-02-01 18:00:00', '2016-02-01 00:00:00', 'America/Los_Angeles')
  // returns: "Make changes until midnight tonight"

@example It's Sunday, 7am pacific, Jan 31, 2016. I placed a Thursday day order with a midnight cutoff, and the fulfillment window starts at 5pm.
  orderStatus('2016-02-04 17:00:00', '2016-02-04 00:00:00', 'America/Los_Angeles')
  // returns: "Make changes until midnight Wednesday"
*/
export function orderStatus({
  startAt,
  orderCutoff,
  pricingDetails,
  tzid,
  deliveryStatus,
}: {
  startAt: MomentInput;
  orderCutoff: MomentInput;
  pricingDetails: {
    amountToMinimum: Cents;
    orderMinimum: Cents;
  };
  tzid: string;
  deliveryStatus?: DeliveryStatus;
}): string {
  startAt = moment.tz(startAt, tzid);
  orderCutoff = moment.tz(orderCutoff, tzid);
  const now = moment().tz(tzid);
  const {amountToMinimum, orderMinimum} = pricingDetails;

  const amountToMinimumPrice = humanPriceFromCents(amountToMinimum);
  const orderMinimumPrice = humanDollarsFromCents(orderMinimum);

  if (deliveryStatus === 'delivered') {
    return 'Delivered';
  }

  if (now.isBefore(orderCutoff)) {
    if (amountToMinimum > 0) {
      const cutoffString = shortCutoffTimeAndDay(orderCutoff, tzid);
      return `Add ${amountToMinimumPrice} to meet the ${orderMinimumPrice} minimum by ${cutoffString}`;
    }
    const cutoffString = shortCutoffTimeAndDay(orderCutoff, tzid);
    return `Make changes until ${cutoffString}`;
  } else if (now.format('YYYY-MM-DD') === startAt.format('YYYY-MM-DD')) {
    return 'Your order will be delivered today';
  }
  return 'Delivered';
}

/*
Human readable time slot description

@param {(Date|string|moment)} startAt - beginning of the fulfillment window, including day and time
@param {(Date|string|moment)} endAt - end of the fulfillment window, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example It is 7am, Sunday Jan 31 2016, pacific time
   humanTimeSlotDescriptor('2016-02-02 14:00', '2016-02-02 15:00', 'America/Los Angeles')
   // returns: "2pm-3pm" because the time slot is in the future

@example It is 7am, Sunday Jan 31 2016, pacific time
   humanTimeSlotDescriptor('2016-01-02 14:00', '2016-01-02 15:00', 'America/Los Angeles')
   // returns: "01/02" because the slot has past
*/
export function humanTimeSlotDescriptor(
  startAt: MomentInput,
  endAt: MomentInput,
  tzid: string,
): string {
  const now = moment.tz(tzid);
  startAt = moment.tz(startAt, tzid);
  endAt = moment.tz(endAt, tzid);

  if (now.format('YYYY-MM-DD') === startAt.format('YYYY-MM-DD') || now.isBefore(startAt, 'day')) {
    return humanTimeRange(startAt, endAt, tzid);
  }
  // This works because our horizon is 7 days
  return startAt.format('M/D');
}

/*
Human readable time range, when used as a label.
Collapses spacing and the first am/pm suffix when am/pm is the same.

@param {(Date|string|moment)} startAt - beginning of the fulfillment window, including day and time
@param {(Date|string|moment)} endAt - end of the fulfillment window, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example
  humanTimeRange('2016-01-31 17:30:00', '2016-01-31 19:30:00', 'America/Los_Angeles')
  // returns: "4-6pm"

@example
  humanTimeRange('2016-01-31 17:30:00', '2016-01-31 19:30:00', 'America/Los_Angeles')
  // returns: "5:30-7:30pm"

@example
  humanTimeRange('2016-01-31 17:30:00', '2016-01-31 19:30:00', 'America/Los_Angeles')
  // returns: "noon - 2pm"

@example
  humanTimeRange('2016-01-31 17:30:00', '2016-01-31 19:30:00', 'America/Los_Angeles')
  // returns: "11am - 1pm"
*/
export function humanTimeRange(startAt: MomentInput, endAt: MomentInput, tzid: string): string {
  startAt = moment.tz(startAt, tzid);
  endAt = moment.tz(endAt, tzid);
  const startSuffix = startAt.format('a');
  const endSuffix = endAt.format('a');

  const startTimeOfDay = shortTime(startAt, tzid);
  if (startSuffix === endSuffix && startTimeOfDay !== '12') {
    return normalizeTimeBoundaries(`${startTimeOfDay}-${shortTime(endAt, tzid, {suffix: true})}`);
  }
  return humanTimeRangeChoice(startAt, endAt, tzid);
}

/*
Human readable time range, when presented in a list of choices.
Maintains consistent components and spacing for all choices.

@param {(Date|string|moment)} startAt - beginning of the fulfillment window, including day and time
@param {(Date|string|moment)} endAt - end of the fulfillment window, including day and time
@param {string} tzid - a valid timezone name
@returns {string}

@example
  humanTimeRangeChoice('2016-01-31 10:00:00', '2016-01-31 12:00:00', 'America/Los_Angeles')
  // returns: "10am - noon"
*/
export function humanTimeRangeChoice(
  startAt: MomentInput,
  endAt: MomentInput,
  tzid: string,
): string {
  startAt = moment.tz(startAt, tzid);
  endAt = moment.tz(endAt, tzid);
  return normalizeTimeBoundaries(
    `${shortTime(startAt, tzid, {suffix: true})} - ${shortTime(endAt, tzid, {suffix: true})}`,
  );
}

/*
shortest time possible eg. 1 or 2:30 (1am or 2:30pm with suffix: true)

@param {(Date|string|moment)} target - the date we want to compare to format
@param {string} tzid - a valid timezone name
@returns {string}
*/
function shortTime(target: MomentInput, tzid: string, {suffix}: {suffix?: boolean} = {}): string {
  target = moment.tz(target, tzid);
  const result = target.minute() > 0 ? target.format('h:mm') : target.format('h');
  if (suffix) {
    return `${result}${target.format('a')}`;
  }
  return result;
}

/*
Uses noon and midnight instead of 12pm and 12am, respectively

@param {string} formattedTime - output of another time formatter
@return {string}
*/
function normalizeTimeBoundaries(formattedTime: string): string {
  return formattedTime.replace('12pm', 'noon').replace('12am', 'midnight');
}
