import React from 'react';

import MobileFooter from './components/mobile_footer';
import DesktopFooter from './components/desktop_footer';

interface FooterProps {
  preLogin?: boolean;
}

const Footer: React.FC<FooterProps> = ({preLogin = false}) => {
  return (
    <div>
      <MobileFooter preLogin={preLogin} />
      <DesktopFooter preLogin={preLogin} />
    </div>
  );
};

export default Footer;
