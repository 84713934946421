import React, {ReactElement} from 'react';

import Modal from 'web/components/modal';
import {Info} from 'web/basket/basket_page';

interface InfoModalProps {
  info: Info;
  onClose: () => void;
}

export const InfoModal = ({info, onClose}: InfoModalProps): ReactElement => {
  return (
    <Modal onClose={onClose}>
      <div className="totals__total-info-modal-content">
        <h2 className="totals__total-info-modal-header">{info.title}</h2>
        <hr />
        <div className="totals__total-info-modal-body">
          <p className="totals__total-body-text">{info.body}</p>
          {info.link?.text != null && info.link?.url != null && (
            <p className="totals__total-body-text">
              <a href={info.link.url} target="_blank" rel="noopener">
                {info.link.text}
              </a>
            </p>
          )}
        </div>
        <hr />
        <div className="totals__total-info-modal-button-container">
          <button
            className="button totals__total-info-modal-button"
            onClick={onClose}
            type="button"
          >
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};
