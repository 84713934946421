import {postJson} from 'web/helpers/api_helpers';

export function postSubscriptionSkip(subscriptionId, day) {
  return postJson(`/api/account/subscriptions/${subscriptionId}/skip`, {body: {day}});
}

export function postSubscriptionUnskip(subscriptionId, day) {
  return postJson(`/api/account/subscriptions/${subscriptionId}/unskip`, {body: {day}});
}

export function postSubscriptionCancel(subscriptionId) {
  return postJson(`/api/account/subscriptions/${subscriptionId}/cancel`, {body: {}});
}
