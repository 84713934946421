import update from 'immutability-helper';

import {reducer as googleReducer} from 'web/components/google_login_button/duck';
import {reducer as appleReducer} from 'web/components/apple_login_button/duck';

export function reducer(state = {}, action) {
  const newState = update(state, {
    googleLogin: {$set: googleReducer(state.googleLogin, action)},
    appleLogin: {$set: appleReducer(state.appleLogin, action)},
  });

  return update(newState, {
    error: {
      $set: newState.googleLogin.error || newState.appleLogin.error,
    },
    isWorking: {
      $set: newState.googleLogin.isAuthenticating || newState.appleLogin.isAuthenticating,
    },
  });
}

export function getSegmentFeature(modalViewed) {
  const SIGN_IN_PAGE = 'signin';
  const SIGN_UP_PAGE = 'signup';

  if (modalViewed?.feature) {
    return modalViewed.feature;
  }

  const pageFeature = window.location.pathname.replace('/', '');

  switch (pageFeature) {
    case SIGN_IN_PAGE:
    case SIGN_UP_PAGE:
      return pageFeature;
    default:
      return undefined;
  }
}
