export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
export const AUTHENTICATED_AUTH_MODAL = 'AUTHENTICATED_AUTH_MODAL';
export const ENABLE_SCROLLING = 'ENABLE_SCROLLING';
export const DISABLE_SCROLLING = 'DISABLE_SCROLLING';

export const actions = {
  openAuthModal: (productId: string) => ({
    type: OPEN_AUTH_MODAL,
    productId,
  }),
  closeAuthModal: () => ({
    type: CLOSE_AUTH_MODAL,
  }),
  disableScrolling: () => ({
    type: DISABLE_SCROLLING,
  }),
  enableScrolling: () => ({
    type: ENABLE_SCROLLING,
  }),
  authenticatedAuthModal: () => ({
    type: AUTHENTICATED_AUTH_MODAL,
  }),
};
