import React, {FC} from 'react';

import {padCents, split} from 'infra/formatters/money';

export interface SplitPriceProps {
  priceInCents: number;
  large?: boolean;
  highlight?: boolean;
  useSpan?: boolean;
}

const SplitPrice: FC<SplitPriceProps> = (props) => {
  const [dollars, cents] = split(props.priceInCents);
  const baseClass = props.large ? 'split-price--large' : 'split-price';

  if (props.useSpan) {
    return (
      <span
        data-testid="split"
        id="price-split"
        className={`usd split ${props.highlight ? 'highlight' : ''}`}
        content={`${dollars}.${padCents(cents)}`}
        itemScope
        itemType="http://schema.org/Product"
      >
        <span id="price-dollars" className="dollars">
          {dollars}
        </span>
        <span id="price-cents" className="cents">
          {padCents(cents)}
        </span>
        <meta itemProp="priceCurrency" content="USD" />
        <meta itemProp="price" content={`${dollars}.${padCents(cents)}`} />
      </span>
    );
  }

  return (
    <div
      data-testid="split"
      id="price-split"
      className={`${baseClass} ${props.highlight ? 'highlight' : ''}`}
    >
      <span id="price-dollars" className={`${baseClass}__dollars`}>
        {dollars}
      </span>
      <span id="price-cents" className={`${baseClass}__cents`}>
        {padCents(cents)}
      </span>
    </div>
  );
};

export default SplitPrice;
