import {FetchResult} from '@apollo/client';
import gql from 'graphql-tag';

import {postJson} from 'web/helpers/api_helpers';
import client from 'web/helpers/graphql_client';

export const updateStripePaymentInfo = async (
  setupIntentId: string,
  captchaToken: string | null = '',
): Promise<unknown> =>
  postJson('/api/account/update-payment', {
    body: {
      setupIntentId,
      captchaToken,
    },
  });

interface SetupIntentPayload {
  errorInfo: {message: string} | null;
  setupIntentId: string | null;
  stripeCustomerId: string | null;
  success: boolean;
}

export const createStripeSetupIntent = async (): Promise<
  FetchResult<{
    createSetupIntent: SetupIntentPayload;
  }>
> => {
  return client.mutate<{createSetupIntent: SetupIntentPayload}>({
    mutation: gql`
      mutation CreateSetupIntent {
        createSetupIntent {
          errorInfo {
            message
          }
          setupIntentId
          stripeCustomerId
          success
        }
      }
    `,
  });
};
