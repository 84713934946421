import {ISerializedUser} from 'domain/users';
import {postJson} from 'web/helpers/api_helpers';

import enqueue from '../enqueue';

export interface UserInput {
  email: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  password?: string;
  zip?: string;
}

// TODO: (@shermam) Ideally we should replace these with
// their GraphQL counter parts
export async function postUser({
  firstName,
  lastName,
  email,
  password,
  phone,
  zip,
}: UserInput): Promise<ISerializedUser> {
  return enqueue(async () =>
    postJson('/api/users/create-user', {
      body: {
        firstName,
        lastName,
        email,
        password,
        phone,
        zip,
      },
    }),
  );
}

// TODO: (@shermam) Ideally we should replace these with
// their GraphQL counter parts
export async function login({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<ISerializedUser> {
  return enqueue(async () => postJson('/api/users/authenticate', {body: {email, password}}));
}
