import PropTypes from 'prop-types';
import React, {Component, PropsWithChildren, ReactElement, SyntheticEvent} from 'react';
import classNames from 'classnames';

export interface Props {
  disabled?: boolean;
  isSaving: boolean;
  workingLabel?: string;
  className?: string;
  // How could a SubmitButton component's `onClick` possibly not be required, you ask? Because some
  // consumers of this component are leaking implementation details by including this in a `<form>`
  // and using an `onSubmit`.
  onClick?: (e: SyntheticEvent) => void;
}

/**
 * This component provides a styled button with optional 'bouncing eggs' working animation state
 * consider using a skinny-button() css mixin on this.
 */
export default class SubmitButton extends Component<PropsWithChildren<Props>> {
  static propTypes = {
    disabled: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
    workingLabel: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    workingLabel: 'working',
    isSaving: false,
  };

  render(): ReactElement {
    const {children, disabled, isSaving, workingLabel, className, onClick} = this.props;

    let buttonText = children != null ? children : 'submit';
    if (isSaving) {
      buttonText = (
        <span className="saving-state">
          <span className="submit-button__label">{workingLabel}</span>
          <span className="eggs">
            <i className="icon icon-egg first" />
            <i className="icon icon-egg second" />
            <i className="icon icon-egg third" />
          </span>
        </span>
      );
    }
    return (
      <button
        className={classNames('submit-button', className, {saving: isSaving})}
        onClick={isSaving ? undefined : onClick}
        disabled={disabled}
      >
        {buttonText}
      </button>
    );
  }
}
