import React, {ReactElement} from 'react';
import classNames from 'classnames';

import moment from 'infra/moment';

interface AvailabilityIndicatorProps {
  firstAvailableDay: string;
  isPreorderDay: boolean;
  isShoppingForPreorder: boolean;
}

const AvailabilityIndicator = ({
  firstAvailableDay,
  isPreorderDay,
  isShoppingForPreorder,
}: AvailabilityIndicatorProps): ReactElement => {
  return (
    <div
      className="product-tile__availability-wrapper"
      data-testid="product-tile-availability-wrapper"
    >
      <div className="product-tile__availability-info-container">
        <div className="product-tile__availability-info">
          <i className="icon icon-truck" />
          <span>Next available day for delivery</span>
        </div>
      </div>
      <div className="product-tile__next-available-day">
        <span className="product-tile__availability-status">
          <div
            className={classNames('product-tile__availability-indicator', {
              'product-tile__availability-indicator__preorder': isPreorderDay,
            })}
          >
            <i className="icon icon-clock" data-testid="clock">
              {isPreorderDay || isShoppingForPreorder ? (
                <span className="day">
                  {moment(firstAvailableDay, 'YYYY-MM-DD').format('ddd M/D')}
                </span>
              ) : (
                <span className="day">{moment(firstAvailableDay, 'YYYY-MM-DD').format('ddd')}</span>
              )}
            </i>
          </div>
        </span>
      </div>
    </div>
  );
};

export default AvailabilityIndicator;
