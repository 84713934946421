/* eslint-disable unicorn/filename-case */
import React, {useContext} from 'react';

import type {ClientSettings} from 'config/settings';

export const ClientSettingsContext = React.createContext<ClientSettings>(
  // This is just a safety to our SSR, on web `window.settings` should always exist, at the SSR
  // side, not exactly and when it is not, everything breaks.
  typeof window !== 'undefined'
    ? window.settings
    : // This is very unlikely to occur since all pages are guarded by the provider or will be
      // rendered client side. Returning `null` here would be a pain to deal on the actual React
      // components due to the nullability of settings, sometimes there is no safe fallback, so we
      // just pretend that we will be returning a `ClientSettings` instance anyway
      ({} as ClientSettings),
);

export const ClientSettingsProvider = ClientSettingsContext.Provider;
export const ClientSettingsConsumer = ClientSettingsContext.Consumer;

export default function useClientSettings(): ClientSettings {
  const value = useContext(ClientSettingsContext);

  return value;
}
