import React, {Component} from 'react';
import Geosuggest from 'react-geosuggest';
import classNames from 'classnames';

import {parseGooglePlace} from 'infra/google_maps/parser';

export default class AddressAutocompleteInput extends Component {
  onSuggestSelect(input) {
    if (input == null || input.gmaps == null || this.props.onSuggestSelect == null) {
      return;
    }
    this.props.onSuggestSelect(parseGooglePlace(input.gmaps));
  }

  onFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this._geoSuggest.clear();
  }

  render() {
    return (
      <div>
        <Geosuggest
          inputClassName={classNames('form-control', {
            error: this.props.errorMessage,
          })}
          placeholder="Street Address"
          {...this.props}
          /*
           * It is very important that this is below the props above,
           * in order to take precendence over the input parameters
           */
          onSuggestSelect={this.onSuggestSelect.bind(this)}
          onFocus={this.onFocus.bind(this)}
          ref={(el) => {
            this._geoSuggest = el;
          }}
        />
        <div className="address-autocomplete-input__error">
          {this.props.errorMessage ? this.props.errorMessage : null}
        </div>
      </div>
    );
  }
}
