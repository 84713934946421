import {ClientSettings} from 'config/settings';

// TODO(serhalp) Ideally we would either:
// 1) Stop using globals like this, by somehow wrapping the settings module in an importable module
// that works on both the client side and server side.
// 2) Don't share any code between client and server.
// 3) Configure TypeScript to know which globals are available in which context (client/server).
declare const settings: ClientSettings;

const baseUrl =
  typeof globalThis !== 'undefined' &&
  globalThis != null &&
  globalThis.location != null &&
  globalThis.location.origin != null
    ? globalThis.location.origin
    : settings.sites.www.serverUrl;

export default function sanitizeReturnPath({
  path,
  defaultPath,
}: {
  path?: string;
  defaultPath: string;
}): string {
  if (path == null || path === '') return defaultPath;

  // URIs containing CRLF characters can lead to header injection
  // or response splitting attacks, and should be blocked.
  // https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html#validating-urls:~:text=URIs%20containing%20CRLF%20characters%20can%20lead,splitting%20attacks%2C%20and%20should%20be%20blocked.
  const pathWithoutLineBreaks = path.toString().replace(/\n|\r/g, '');

  // There are some marketing links out in the wild that have /sfbay in them.
  // This removes them from the returnTo link so that promos are applied and then redirected
  // to a modern goodeggs.com/ URL.
  const pathWithoutDeprecatedSfbay = pathWithoutLineBreaks.toString().replace(/\/sfbay\//, '/');

  let url: URL;
  try {
    url = new URL(pathWithoutDeprecatedSfbay, baseUrl);
  } catch (err) {
    if (err instanceof TypeError) {
      // The user can type anything on the returnTo path
      // so if they type something that is not a valid URL
      // we just ignore here, and return the defaultPath.
      return defaultPath;
    }

    throw err;
  }

  if (!['https:', 'http:'].includes(url.protocol.toLowerCase())) {
    return defaultPath;
  }

  for (const whiteListedDomain of settings.allowedHostnamesForReturnPath) {
    if (url.hostname === whiteListedDomain) {
      return pathWithoutDeprecatedSfbay;
    }
  }

  return defaultPath;
}
