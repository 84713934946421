import React, {useState} from 'react';

import Alert from 'web/components/alert';
import {getCheckoutSessionUrl} from 'web/membership/join_membership_page/helpers/api_client';

import NavList from '../nav_list';

export default function AccountLayout({slug, isMember = false, children}) {
  const [error, setError] = useState();

  const handleClick = async (event) => {
    try {
      const url = event.currentTarget.attributes.getNamedItem('href')?.value;
      // Handle only for account page
      if (!slug && url === '/account/membership' && !isMember) {
        event.preventDefault();
        window.location.href = await getCheckoutSessionUrl();
      }
    } catch (apiError) {
      if (apiError) {
        setError({
          heading: 'Membership Checkout error',
          message: apiError.message,
        });
      } else {
        setError({
          heading: 'Membership Checkout error',
          message:
            'Sorry, there was a problem connecting to the Membership Checkout. Try again later!',
        });
      }
    }
  };
  return (
    <div className="account-layout">
      {error && (
        <div
          className="account-layout__membership__error__nav"
          data-testid="join-membership__error"
        >
          <Alert type="error" heading={error?.heading}>
            {error.message}
          </Alert>
        </div>
      )}
      <div className="account-layout__columns">
        <div className="account-layout__column-nav">
          <NavList currentSlug={slug} handleClick={handleClick} />
        </div>
        <div className="account-layout__column-content">{children}</div>
      </div>
    </div>
  );
}
