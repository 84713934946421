import $ from 'jquery';
import React, {FC, useEffect} from 'react';
import classNames from 'classnames';

import analytics from '@analytics/client';
import {DropdownOpener} from 'web/helpers/dropdown_opener';

import {CategoryProps} from './category';

const StickyCategory: FC<CategoryProps> = ({category, flyout, selected, className}) => {
  useEffect(() => {
    // eslint-disable-next-line no-new
    new DropdownOpener(
      `.sticky-desktop-header__category-${category.id}`,
      `.sticky-desktop-header__category-${category.id} .category-expansion`,
      (pointing) =>
        $(`.sticky-desktop-header__category-${category.id} .category-expansion`).toggleClass(
          'open',
          pointing,
        ),
      `.sticky-desktop-header__category-${category.id} .category-expansion__item`,
    );
  }, []);

  const handleRedirect = ({
    url,
    categoryName,
    subcategory,
  }: {
    categoryName: string;
    url: string;
    subcategory?: string;
  }): void => {
    analytics.track('productCategoryClicked', {
      category: categoryName,
      destination: url,
      pageUrl: window.location.href.split(window.location.host)[1],
      subCategory: subcategory,
    });

    window.location.href = url;
  };

  return (
    <div
      className={classNames(
        'sticky-desktop-header__category',
        `sticky-desktop-header__category-${category.id}`,
        {
          current: selected,
        },
        className,
      )}
    >
      <a
        className="remote sticky-desktop-header__category-link"
        href={category.url}
        onClick={() => {
          handleRedirect({
            url: category.url,
            categoryName: category.shortName,
          });
        }}
        data-slug={category.id}
      >
        <div className="sticky-desktop-header__category-link-interior">
          <div className="sticky-desktop-header__category-link-interior">
            <span>{category.shortName}</span>
          </div>
        </div>
      </a>

      {flyout ? (
        <div className="category-expansion expansion">
          <div className="category-expansion__content">
            {category.subcategories.map((subcategory) => (
              <a
                key={subcategory.id}
                className="category-expansion__item"
                href={subcategory.url}
                onClick={(e) => {
                  e.preventDefault();
                  handleRedirect({
                    url: subcategory.url,
                    categoryName: category.shortName,
                    subcategory: subcategory.name,
                  });
                }}
              >
                {subcategory.name}
              </a>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StickyCategory;
