import update from 'immutability-helper';
import {reducer as formReducer} from 'redux-form';

import {
  actions as navigationActions,
  reducer as navigationReducer,
} from 'web/helpers/navigation_duck';
import {
  actions as signUpFormActions,
  reducer as signUpFormReducer,
} from 'web/components/sign_up_form/duck';
import {
  actions as signInFormActions,
  reducer as signInFormReducer,
} from 'web/components/sign_in_form/duck';
import {reducer as socialSignInReducer} from 'web/components/social_sign_in/duck';
import {actions as googleActions} from 'web/components/google_login_button/duck';

import {STEPS} from '.';

const GO_TO = 'SIGN_IN_FLOW_GO_TO';
const GO_BACK = 'SIGN_IN_FLOW_GO_BACK';

export const actions = {
  navigate: (step) => (dispatch) => {
    dispatch(signUpFormActions.clearErrors());
    dispatch(signInFormActions.clearErrors());
    dispatch(googleActions.clearErrors());
    dispatch(actions.goTo(step));
  },

  navigateBack: () => (dispatch) => {
    dispatch(signUpFormActions.clearErrors());
    dispatch(signInFormActions.clearErrors());
    dispatch(googleActions.clearErrors());
    dispatch(actions.goBack());
  },

  goTo: (step) => ({
    type: GO_TO,
    step,
  }),

  goBack: () => ({
    type: GO_BACK,
  }),

  completeFlow: () => (dispatch, getState) => {
    const {referralId} = getState();
    if (referralId) {
      // passing this redirect flag so that we don't count this as a user inititate metric
      dispatch(navigationActions.navigate(`/r/${referralId}?didRedirect=true`));
    } else {
      window.location.reload();

      return {type: ''};
    }
  },

  submitSignInForm: (cb) => (dispatch) => {
    dispatch(signInFormActions.submitForm({rejectUponError: true}))
      .then(() => cb())
      .catch(() => null);
  },

  submitSignUpForm: (cb, signUpOpts) => (dispatch) => {
    dispatch(signUpFormActions.submitForm({rejectUponError: true}, signUpOpts))
      .then(() => cb())
      .catch(() => null);
  },
};

const initialState = {
  history: [STEPS && STEPS.zip],
};

function flowReducer(state = initialState, action) {
  switch (action.type) {
    case GO_TO:
      return update(state, {
        step: {$set: action.step},
        history: {$push: state.step ? [state.step] : []},
      });
    case GO_BACK:
      return update(state, {
        step: {
          $set: state.history.length > 0 ? state.history[state.history.length - 1] : STEPS.zip,
        },
        history: {$set: state.history.length > 0 ? state.history.slice(0, -1) : state.history},
      });
    default:
      return state;
  }
}

export function reducer(state, action) {
  return update(state, {
    form: {$set: formReducer(state.form, action)},
    signInFlow: {$set: flowReducer(state.signInFlow, action)},
    socialSignIn: {$set: socialSignInReducer(state.socialSignIn, action)},
    signUpForm: {$set: signUpFormReducer(state.signUpForm, action)},
    signInForm: {$set: signInFormReducer(state.signInForm, action)},
    navigation: {$set: navigationReducer(state.navigation, action)},
  });
}
