import $ from 'jquery';

const getCurrentPath = (): string => {
  return `${window.location.pathname}${window.location.search || ''}${window.location.hash || ''}`;
};

const getReturnTo = (): string => {
  const url = getCurrentPath();

  const queryParams = new URLSearchParams(window.location.search);

  const returnTo = queryParams.get('returnTo');

  return typeof returnTo === 'string' ? returnTo : url;
};

class SigninHelpers {
  public static signinAndReturn(): void {
    const returnTo = getReturnTo();

    window.location.href = `/signin?returnTo=${encodeURIComponent(returnTo)}`;
  }

  public static signoutMasquerade(): void {
    $.ajax({
      type: 'DELETE',
      url: '/masquerade',
      data: JSON.stringify({id: 'NA'}),
      contentType: 'application/json',
      dataType: 'json',
      success: () => {
        window.location.reload();
      },
    });
  }
}

export default SigninHelpers;
