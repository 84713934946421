import React, {useState, useEffect, FC} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import qs from 'query-string';

import moment from 'infra/moment';
import useLocalStorage from 'web/hooks/useLocalStorage';
import useRecentSearches from 'web/hooks/useRecentSearches';
import MobileSearchBox from 'web/components/mobile_search_box';
import {SerializedBasket} from 'domain/baskets/serializer';
import segmentAnalytics from '@analytics/client';
import {
  SerializedUiMarketCategory,
  SerializedUiMarketSubcategory,
} from 'web/helpers/serializers/category';
import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

import SearchSuggestions from '../search_suggestions';
import MobileLocalNavigation from '../mobile_local_navigation';
import MobileBanner from '../mobile_banner';

export interface BreadcrumbProp {
  show: boolean;
  categories: SerializedUiMarketCategory[];
  selectedCategoryName?: string;
  selectedSubcategoryName?: string;
  subcategories?: SerializedUiMarketSubcategory[];
}

interface MobileHeaderProps {
  onMenuClicked: () => void;
  onSearchClicked: () => void;
  stuck: boolean;
  showBreadcrumb: boolean;
  breadcrumbProp: BreadcrumbProp;
}

interface State {
  basket: SerializedBasket;
  searchQuery: string;
  features: string[];
}

const MobileHeader: FC<MobileHeaderProps> = ({
  onMenuClicked,
  onSearchClicked,
  stuck,
  showBreadcrumb,
  breadcrumbProp,
}) => {
  const basketItemCount = useSelector((state: State) => state.basket.items.length);
  const searchQuery = useSelector((state: State) => state.searchQuery);
  const [searchIsExpanded, setSearchIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchQuery ?? '');
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [bannerClosedDate, setBannerClosedDate, removeBannerClosedDate] = useLocalStorage(
    'banner-closed-date',
    '',
  );
  const {addRecentSearch} = useRecentSearches();
  const clientSettings = useClientSettings();

  useEffect(() => {
    const isDateValid = bannerClosedDate != null && moment(bannerClosedDate).isValid();

    if (isDateValid) {
      const now = new Date();

      const differenceInDays = moment(now).diff(bannerClosedDate, 'days');

      if (differenceInDays >= 30) {
        removeBannerClosedDate();
        setIsBannerOpen(true);
      } else {
        setIsBannerOpen(false);
      }
    }
  }, []);

  const redirectToSearchPage = (term: string): void => {
    const searchUrl = qs.stringifyUrl({url: '/search', query: {q: term}});

    window.location.href = searchUrl;
  };

  const handleMenuToggled = (): void => {
    setSearchIsExpanded(false);
    document.body.style.overflow = 'unset';
    onMenuClicked();
  };

  const handleSearchToggled = (): void => {
    onSearchClicked();
    document.body.style.overflow = searchIsExpanded ? 'unset' : 'hidden';
    setSearchIsExpanded((prevSearchIsExpanded) => !prevSearchIsExpanded);
    setSearchTerm('');
  };

  const handleCloseBanner = (ctaLink: string): void => {
    const now = new Date();
    const {pathname} = window.location;

    segmentAnalytics.track('ctaDismissed', {
      type: 'banner',
      text: 'Get',
      destination: ctaLink,
      pageUrl: pathname,
      feature: pathname.split('/').slice(-1)[0],
    });

    setBannerClosedDate(now.toISOString());
    setIsBannerOpen(false);
  };

  const handleOpenStore = (ctaLink: string): void => {
    const {pathname} = window.location;

    segmentAnalytics.track('ctaClicked', {
      type: 'banner',
      text: 'Get',
      destination: ctaLink,
      pageUrl: pathname,
      feature: pathname.split('/').slice(-1)[0],
    });
    window.location.assign(ctaLink);
  };

  const enableNewLogos = clientSettings.enableNewLogos.enableMobile;

  return (
    <div
      className={classNames('mobile-nav', {
        stuck,
        'search-open': searchIsExpanded,
      })}
    >
      {isBannerOpen && (
        <MobileBanner handleClose={handleCloseBanner} handleOpenStore={handleOpenStore} />
      )}
      <div className="mobile-nav__header">
        <div
          className={classNames('top-nav', 'top-nav-small', {
            stuck,
          })}
        >
          <a className="logo" href="/home">
            {enableNewLogos ? (
              <img src={`${assetPath('/img/svg/icons/ge-mobile-log-white.svg')}?auto=format`} />
            ) : (
              <i className="icon icon-logo-black" />
            )}
          </a>
          <div className="mobile-nav__buttons">
            <a id="search" className="icon" onClick={handleSearchToggled}>
              <i className="icon icon-search" data-testid="icon-search" />
            </a>
            <a className="basket icon" href="/basket">
              <i className="icon icon-basket-2">
                <div className="items-count js-mobile-header-basket-item-count">
                  {basketItemCount}
                </div>
              </i>
            </a>
            <i className="menu icon icon-menu" onClick={handleMenuToggled} />
          </div>
        </div>
        {showBreadcrumb && !searchIsExpanded && <MobileLocalNavigation {...breadcrumbProp} />}
        {searchIsExpanded && (
          <div
            className={classNames('mobile-search-dropdown', 'dropdown')}
            data-testid="search-container"
          >
            <div className="mobile-search-box search-box">
              <MobileSearchBox
                searchTerm={searchTerm ?? ''}
                onChange={(value: string): void => setSearchTerm(value)}
              />
              <button
                type="button"
                className="mobile-search__cancel-button"
                onClick={(): void => {
                  setSearchIsExpanded(false);
                  document.body.style.overflow = 'unset';
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
      {searchIsExpanded && (
        <SearchSuggestions
          searchTerm={searchTerm}
          onSearchItemClick={(item): void => {
            addRecentSearch(item);
            redirectToSearchPage(item);
          }}
          onItemClick={(item): void => {
            addRecentSearch(item);
            redirectToSearchPage(item);
          }}
          isMobile
        />
      )}
    </div>
  );
};

export default MobileHeader;
