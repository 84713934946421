interface IPathMap {
  [key: string]: string;
}

interface IAssetPathOptions {
  ifMissing?: string;
}

interface IAssetPath {
  (path?: string, options?: IAssetPathOptions): string;
  forceLoad?: (paths?: any) => void;
  disable: () => void;
  load: (paths?: any) => void;
  assetPathWithProtocol: (path: string) => string;
}

let pathMap: IPathMap = {};

const assetPath: IAssetPath = (path, {ifMissing} = {}) => {
  if (!pathMap) {
    throw new Error('Tried to lookup asset path before initializing paths');
  }
  if (path && pathMap[path]) {
    return pathMap[path];
  }
  if (ifMissing && pathMap[ifMissing]) {
    return pathMap[ifMissing];
  }
  if (path) {
    return path;
  }
  return '';
};

// For use in specs and tasks that don't need versioned assets
assetPath.disable = function (): void {
  pathMap = {};
  if (this.forceLoad == null) {
    this.forceLoad = this.load;
  } // keep a reference to the original load for specs that really need it

  this.load = async function (): Promise<void> {};
};

assetPath.load = function (manifest: Record<string, string>): void {
  pathMap = manifest;
};

assetPath.assetPathWithProtocol = function (path): string {
  let versionedPath: string = assetPath(path);

  // check for protocol
  if (/^\/\//.test(versionedPath)) {
    versionedPath = `https:${versionedPath}`;
  }

  return versionedPath;
};

export default assetPath;
