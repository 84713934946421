import React, {FC, PropsWithChildren} from 'react';

import TermsNavList from './terms_nav_list';

export interface TermsLayoutProps {
  currentSlug: string;
}

const TermsLayout: FC<PropsWithChildren<TermsLayoutProps>> = ({currentSlug, children}) => (
  <div className="terms-page__content">
    <TermsNavList currentSlug={currentSlug} />
    <div className="terms-page__column-content">{children}</div>
  </div>
);

export default TermsLayout;
