import cookieHelper from 'web/helpers/cookies';

interface Category {
  name: string;
}
interface User {
  id: string;
  masquerading?: boolean;
}

interface Props {
  user?: User;
  screenName?: string;
  category?: Category;
  page?: string;
  isMasquerading?: boolean;
}

interface Location {
  pathname: string;
  search: string;
}

interface Document {
  referrer: string;
  title: string;
  URL: string;
}

// This functions is for create the page event on segment.
export default function PageEvents(
  pageName: any,
  props: Props,
  location: Location,
  document: Document,
): void {
  // Get the necesary values to populate the segment properties.
  const {screenName, category, page, user, isMasquerading} = props;
  const {pathname, search} = location;
  const {referrer, title, URL} = document;

  // Call the page method to set it on segment.
  window.metrics.page(pageName, '', {
    loggedIn: user !== null && user !== undefined,
    masquerading: user?.masquerading ?? isMasquerading ?? false,
    name: screenName ?? page ?? category?.name,
    path: pathname,
    referrer,
    search,
    title,
    url: URL,
    category: category?.name ?? '',
    experiments: cookieHelper.getExperimentsFlattened(),
  });
}
