import React, {FC, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {connect, useDispatch} from 'react-redux';
import {partition, isNil} from 'lodash';

import moment from 'infra/moment';
import basketDuck from 'web/helpers/basket_duck';
import {humanPriceFromCents} from 'infra/formatters/money';
import {humanDayOfWeek, shortCutoffTimeAndDay} from 'infra/formatters/time';
import {FulfillmentDay, Totals, Item, FulfillmentOffer, Basket} from 'web/basket/basket_page';
import useClientSettings from 'web/hooks/useClientSettings';
import {UiMarketProduct} from 'web/helpers/serializers/product';
import {AppDispatch} from 'web/helpers/redux_client';

// Leave blank while we're reloading from the server
const formatPriceInCents = (priceInCents: number): string =>
  isNil(priceInCents) ? '' : humanPriceFromCents(priceInCents);

interface BasketDropdownItemProps {
  productId: string;
  url: string;
  thumbnailImageUrl: string;
  productName: string;
  quantity: number;
  retailUnits: string;
  availableQuantity?: number;
  subtotal?: number;
  onRemove: React.MouseEventHandler<HTMLAnchorElement>;
}

const BasketDropdownItem: FC<BasketDropdownItemProps> = ({
  productId,
  url,
  thumbnailImageUrl,
  productName,
  quantity,
  retailUnits,
  availableQuantity = 0,
  subtotal = 0,
  onRemove,
}) => {
  const isAvailable = useMemo(() => availableQuantity > 0, [availableQuantity]);
  return (
    <div
      className={classNames('basket-expansion-item', 'js-basket-item', {
        'basket-expansion-item--unavailable': !isAvailable,
      })}
      data-product-id={productId}
    >
      <a className="basket-expansion-item__photo" href={url}>
        <img src={thumbnailImageUrl} />
      </a>
      <div className="basket-expansion-item__summary">
        <a className="basket-expansion-item-summary__title" href={url}>
          {productName}
        </a>
        <div className="basket-expansion-item-summary__units">{`${quantity} x ${retailUnits}`}</div>
        {isAvailable && availableQuantity < 10 && (
          <div
            className="basket-expansion-item__available-quantity"
            data-testid="basket-expansion-item__available-quantity"
          >
            Only {availableQuantity} left!
          </div>
        )}
      </div>
      {isAvailable && (
        <div className="basket-expansion-item__price">
          {humanPriceFromCents(subtotal, {showZeroAsFree: true})}
        </div>
      )}
      <div className="basket-expansion-item-remove">
        <a className="summary-item__remove" onClick={onRemove}>
          <i className="icon icon-thin-x" />
        </a>
      </div>
    </div>
  );
};

function fulfillmentDayToCutoffText({
  fulfillmentDaySummaries,
  currentFulfillmentDay,
  tzid,
}: {
  fulfillmentDaySummaries: FulfillmentDay[];
  currentFulfillmentDay: string;
  tzid: string;
}): string {
  const fulfillmentDaySummary = fulfillmentDaySummaries.find(
    (summary) => summary.day === currentFulfillmentDay,
  );

  return (
    shortCutoffTimeAndDay(fulfillmentDaySummary?.cutoffDate, tzid)
      // Safe to replace because we couldn't deliver today if we receive the order at midnight today (cf Physics)
      .replace('midnight', 'midnight tonight')
  );
}

interface BasketDropdownProps {
  addToOrderId?: string;
  canCheckout: boolean;
  currentFulfillmentDay: string;
  deliveryRestrictedMinimumWindowStart: number;
  fulfillmentDaySummaries: FulfillmentDay[];
  isEmptyBasket: boolean;
  items: Item[];
  products: {[key: string]: UiMarketProduct};
  selectedFulfillmentOffer?: FulfillmentOffer;
  totals?: Totals;
  basket: Basket;
}

const BasketDropdown: FC<BasketDropdownProps> = ({
  addToOrderId,
  canCheckout,
  currentFulfillmentDay,
  deliveryRestrictedMinimumWindowStart,
  fulfillmentDaySummaries,
  isEmptyBasket,
  items,
  products,
  selectedFulfillmentOffer,
  totals,
}) => {
  const clientSettings = useClientSettings();

  let shouldDeliveryBeRestricted = false;
  if (selectedFulfillmentOffer?.offer) {
    const localTime = moment.tz(selectedFulfillmentOffer.offer.startAt, clientSettings.tzid);
    shouldDeliveryBeRestricted = localTime.hours() < deliveryRestrictedMinimumWindowStart;
  }
  const dispatch: AppDispatch = useDispatch();

  const [availableItems, unavailableItems] = useMemo(
    () =>
      partition(items, (item) => {
        const product = products[item.product.id];
        const availabilitySummary = product?.availabilitiesByDay[currentFulfillmentDay];
        if (product?.isDeliveryRestricted && shouldDeliveryBeRestricted) {
          return false;
        }
        return availabilitySummary?.quantity > 0;
      }),
    [items, currentFulfillmentDay, shouldDeliveryBeRestricted, products],
  );

  const handleRemove = useCallback(
    (productId: string): void => {
      dispatch(basketDuck.actions.assignQuantity({productId, quantity: 0}));
    },
    [dispatch],
  );

  const renderHeader = useMemo(() => {
    const today = moment.tz(clientSettings.tzid).format('YYYY-MM-DD');
    const tomorrow = moment.tz(clientSettings.tzid).add(1, 'day').format('YYYY-MM-DD');

    if ([today, tomorrow].includes(currentFulfillmentDay)) {
      const whichDay = currentFulfillmentDay === today ? 'today' : 'tomorrow';
      const cutoffText = fulfillmentDayToCutoffText({
        fulfillmentDaySummaries,
        currentFulfillmentDay,
        tzid: clientSettings.tzid,
      });

      return (
        <div className="basket-expansion__header-message">
          <span>Order by</span>
          <span className="basket-expansion__header-message__time">{` ${cutoffText} `}</span>
          <span>for delivery {whichDay}</span>
        </div>
      );
    }

    return (
      <div className="basket-expansion__header-message">
        <span>Shopping for delivery</span>
        <span className="basket-expansion__header-message__time">
          {` ${humanDayOfWeek(currentFulfillmentDay, clientSettings.tzid)}`}
        </span>
      </div>
    );
  }, [currentFulfillmentDay, fulfillmentDaySummaries]);

  const renderProgressIndicator = useMemo(() => {
    const {amountToMinimum = 0, orderMinimum = 0} = totals ?? {};
    if (amountToMinimum === orderMinimum && orderMinimum > 0) {
      return (
        <div className="basket-expansion-continue__progress">
          <div
            className="basket-expansion-continue__progress-header"
            data-testid="basket-expansion-continue__progress-header"
          >
            {humanPriceFromCents(orderMinimum, {short: true})} minimum — Continue shopping
          </div>
        </div>
      );
    }

    if (amountToMinimum > 0) {
      const progressPercentage =
        orderMinimum === 0
          ? 100
          : Math.round(((orderMinimum - amountToMinimum) / orderMinimum) * 100);
      const amountToMinimumPrice = humanPriceFromCents(amountToMinimum, {short: true});
      const orderMinimumPrice = humanPriceFromCents(orderMinimum, {short: true});
      return (
        <div className="basket-expansion-continue__progress">
          <div className="basket-expansion-continue__progress-header">
            Add {amountToMinimumPrice} to meet the {orderMinimumPrice} minimum
          </div>
          <div className="basket-expansion-continue__progress-bar">
            <div
              className="basket-expansion-continue__progress-bar-progress"
              style={{width: `${progressPercentage}%`}}
            />
          </div>
        </div>
      );
    }

    return null;
  }, [totals]);

  const renderCheckoutButton = useMemo(() => {
    if (canCheckout)
      return (
        <a className="basket-expansion-continue" href="/basket">
          Checkout
        </a>
      );

    if (!isEmptyBasket)
      return (
        <a
          className="basket-expansion-continue under-minimum"
          data-testid="basket-expansion-continue-under-minimum"
          href="/basket"
        >
          Review Basket
        </a>
      );

    return <div className="basket-expansion-continue disabled">Checkout</div>;
  }, [canCheckout, isEmptyBasket]);

  const renderUnavailableItems = useCallback(
    () => (
      <div
        className="basket-expansion__unavailable-items"
        data-testid="basket-expansion__unavailable-items"
      >
        <div className="basket-expansion__unavailable-items-header">
          <strong>
            Not Available {humanDayOfWeek(currentFulfillmentDay, clientSettings.tzid)}
          </strong>{' '}
          Try another delivery date!
        </div>
        {unavailableItems.map((item) => {
          const product = products[item.product.id];
          const basketDropDownItem =
            product != null ? (
              <BasketDropdownItem
                productId={product.id}
                productName={product.name}
                thumbnailImageUrl={product.photoUrl}
                url={product.url}
                quantity={item.quantity}
                retailUnits={product.retailUnits}
                onRemove={(): void => handleRemove(item.product.id)}
                key={`${product.id}`}
              />
            ) : null;
          return basketDropDownItem;
        })}
      </div>
    ),
    [currentFulfillmentDay, handleRemove, products, unavailableItems, clientSettings.tzid],
  );

  const renderTotalRow = useCallback(
    (type: string, cents: number, description: string) => (
      <div
        className={`basket-expansion-inline-totals__row basket-expansion-inline-totals__row-${type}`}
      >
        <div
          className={`basket-expansion-inline-totals__amount basket-expansion-inline-totals__${type}`}
        >
          {formatPriceInCents(cents)}
        </div>
        <div className="basket-expansion-inline-totals__description">
          <span>{description}</span>
        </div>
      </div>
    ),
    [],
  );

  return (
    <div className="basket-expansion expansion">
      <div className="basket-expansion__container page-boundary">
        <div className="basket-expansion__content expansion-content js-basket-expansion-content">
          <div className="js-basket-view">
            <div className="basket-expansion__header">
              <i className="icon icon-truck" />
              {renderHeader}
            </div>
            <div className="basket-expansion__basket-view">
              <div className="basket-expansion-basket-view__items">
                {availableItems.map((item) => {
                  const product = products[item.product.id];
                  const availabilitySummary = product.availabilitiesByDay[currentFulfillmentDay];
                  const availableQuantity = availabilitySummary?.quantity ?? 0;
                  const {subtotal} = totals?.lineItemTotals?.[item.id] ?? {};
                  return (
                    <BasketDropdownItem
                      productId={product.id}
                      availableQuantity={availableQuantity}
                      productName={product.name}
                      thumbnailImageUrl={product.photoUrl}
                      url={product.url}
                      quantity={item.quantity}
                      retailUnits={product.retailUnits}
                      subtotal={subtotal}
                      onRemove={handleRemove.bind(this, item.product.id)}
                      key={`${product.id}|`}
                    />
                  );
                })}
                {unavailableItems.length > 0 && renderUnavailableItems.bind(this)()}
                {totals && (
                  <div className="basket-expansion-inline-totals">
                    {renderTotalRow('subtotal', totals.subtotal, 'Subtotal')}
                    {totals?.subscriptionDiscount > 0 &&
                      renderTotalRow(
                        'subscription-discount',
                        0 - totals.subscriptionDiscount,
                        'Subscription Savings',
                      )}
                    {totals?.promoDiscount > 0 &&
                      renderTotalRow('promo-discount', 0 - totals.promoDiscount, 'Discount')}
                    {totals.referralDiscount > 0 &&
                      renderTotalRow('referral-discount', 0 - totals.referralDiscount, 'Discount')}
                  </div>
                )}
              </div>
              {totals && !addToOrderId ? renderProgressIndicator : null}
              {renderCheckoutButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// for performance, don't re-render on changes to products (eg. lazy product load) unless the basket has changed
let lastBasket: Basket | undefined;
let lastProducts: {[key: string]: UiMarketProduct};

interface State {
  basket: Basket;
  currentFulfillmentDay: string;
  addToOrderId: string;
  fulfillmentDaySummaries: FulfillmentDay[];
  products: {[key: string]: UiMarketProduct};
  selectedFulfillmentOffer: FulfillmentOffer;
  deliveryRestrictedMinimumWindowStart: number;
}

const productsSelector = (state: State): {[key: string]: UiMarketProduct} => {
  if (state.basket === lastBasket) {
    return lastProducts;
  }
  lastBasket = state.basket;
  lastProducts = state.products;
  return lastProducts;
};

function mapStateToProps(state: State): BasketDropdownProps {
  const {
    basket,
    currentFulfillmentDay,
    addToOrderId,
    fulfillmentDaySummaries,
    selectedFulfillmentOffer,
    deliveryRestrictedMinimumWindowStart,
  } = state;

  const {totals} = basket;
  const {items} = basket;

  const canCheckout = totals?.amountToMinimum === undefined || totals?.amountToMinimum === 0;
  const isEmptyBasket = items.length === 0;

  return {
    items,
    totals,
    basket,
    products: productsSelector(state),
    currentFulfillmentDay,
    addToOrderId,
    fulfillmentDaySummaries,
    canCheckout,
    isEmptyBasket,
    selectedFulfillmentOffer,
    deliveryRestrictedMinimumWindowStart,
  };
}

export default connect(mapStateToProps)(BasketDropdown);
