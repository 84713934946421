import React from 'react';

import moment from 'infra/moment';
import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

import SocialLinks from './share_links';

export interface MobileFooterProps {
  preLogin: boolean;
}

const MobileFooter: React.FC<MobileFooterProps> = ({preLogin}) => {
  const settings = useClientSettings();
  const newLogosEnabled = settings.enableNewLogos.enableWeb;
  const logoColor = newLogosEnabled ? 'green' : 'white';
  const backgroundColor = newLogosEnabled ? 'forest' : 'black';

  return (
    <footer
      className={`mobile-footer mobile-footer__${backgroundColor}`}
      data-testid="mobile-footer"
    >
      <div className="mobile-footer__logotag">
        <div className="mobile-footer__logo">
          <a href="https://www.goodeggs.com/home">
            <img
              alt={`goodeggs logo in ${logoColor}`}
              src={`${assetPath(`/img/svg/icons/logo_${logoColor}.svg`)}?auto=format`}
            />
          </a>
        </div>
        {!newLogosEnabled && (
          <div className="mobile-footer__tagline">
            <img
              alt="a radically fresh market"
              src={`${assetPath('/img/svg/icons/tagline-white.svg')}?auto=format`}
            />
          </div>
        )}
      </div>
      <div className="mobile-footer__links">
        <div className="mobile-footer__block">
          <div className="mobile-footer__title">Shop</div>
          <ul>
            <li>
              <a href="/produce#produce-peak-season">Shop Featured Items</a>
            </li>
            <li>
              <a href="/meal-solutions">Meal Solutions</a>
            </li>
            <li>
              <a href="/giftcards">Gift Cards</a>
            </li>
            {!preLogin && (
              <li>
                <a href="/refer-a-friend">Refer A Friend</a>
              </li>
            )}
            <li>
              <a href="/join-membership">Join Membership</a>
            </li>
          </ul>
        </div>
        <div className="mobile-footer__block">
          <div className="mobile-footer__title">About Good Eggs</div>
          <ul>
            <li>
              <a href="https://about.goodeggs.com/">About Us</a>
            </li>
            <li>
              <a href="https://blog.goodeggs.com/">Blog</a>
            </li>
            <li>
              <a href="/about/standards">Food Standards</a>
            </li>
            {!preLogin && (
              <li>
                <a href="/how-it-works">How It Works</a>
              </li>
            )}
            <li>
              <a href="https://careers.goodeggs.com/">Careers</a>
            </li>
            <li>
              <a href="https://about.goodeggs.com/sell-your-goods">Sell Your Goods</a>
            </li>
          </ul>
        </div>
        <div className="mobile-footer__block">
          <div className="mobile-footer__title">Holiday Guides</div>
          <ul>
            <li>
              <a href="https://thanksgiving.goodeggs.com/">Thanksgiving</a>
            </li>
            <li>
              <a href="https://holiday.goodeggs.com/">Winter Holidays</a>
            </li>
          </ul>
        </div>
        <div className="mobile-footer__block">
          <div className="mobile-footer__title">Need Help?</div>
          <ul>
            <li>
              <a href="https://good-eggs.trsnd.co/policies">Privacy Policy</a>
            </li>
            <li>
              <a href="https://help.goodeggs.com/hc/en-us/requests/new">Contact Us</a>
            </li>
            <li>
              <a href="https://help.goodeggs.com">Help Center</a>
            </li>
          </ul>
        </div>
        <div className="mobile-footer__block">
          <div className="mobile-footer__title">Get the App</div>
          <ul className="download-stores">
            <li className="app-store-link">
              <a href="https://www.goodeggs.com/app">
                <img
                  alt="apple icon"
                  className="app-store-ios"
                  src={`${assetPath('/img/svg/icons/apple.svg')}?auto=format`}
                />
              </a>
            </li>
            <li className="google-play-link">
              <a href="https://www.goodeggs.com/app">
                <img
                  alt="android icon"
                  className="google-play-android"
                  src={`${assetPath('/img/svg/icons/android.svg')}?auto=format`}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SocialLinks />
      <div className="mobile-footer__terms">
        <span>{`© ${moment().tz(settings.tzid).format('YYYY')} Good Eggs, Inc.`}</span>
        <a href="/terms">Terms &amp; Privacy</a>
      </div>
    </footer>
  );
};

export default MobileFooter;
