import React, {FC, InputHTMLAttributes} from 'react';
import classNames from 'classnames';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input: FC<InputProps> = (props) => {
  return (
    <input
      data-testid="input"
      {...props}
      type={props.type ?? 'text'}
      className={classNames('underline__input', props.className)}
    />
  );
};

export default Input;
