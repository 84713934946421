import React, {useEffect, useState} from 'react';
import {bool, string, func, shape} from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import classNames from 'classnames';

import SubmitButton from 'web/components/submit_button';

import {default as validate} from './validate';

export {default as Errors} from './errors';

export const SignUpFormCta = 'Join Now';

const RenderField = ({
  input,
  type = 'text',
  meta,
  className,
  placeholder,
  field,
  editNameValid,
  editPhoneValid,
  showError,
}) => {
  useEffect(() => {
    if (field === 'last name') {
      editNameValid(meta.valid);
    } else if (field === 'phone') {
      editPhoneValid(meta.valid);
    }
  }, [field, meta, editNameValid, editPhoneValid]);

  const inputClasses = classNames(className, {
    invalid: (meta.touched && meta.error && !meta.active) || showError,
    valid: meta.touched && !meta.error,
    '': !meta.touched && !meta.error,
  });

  return (
    <div className="sign-up-form__field">
      <input className={inputClasses} {...input} placeholder={placeholder} type={type} />
      {inputClasses.indexOf('invalid') !== -1 ? (
        <div className="sign-up-form__error error">{meta.error}</div>
      ) : null}
    </div>
  );
};

RenderField.propTypes = {
  input: shape({
    value: string,
    name: string,
  }),
  meta: shape({
    touched: bool,
    error: string,
  }),
  placeholder: string,
  type: string,
  className: string,
  field: string,
  editNameValid: func,
  editPhoneValid: func,
  showError: bool,
};

const SignUpForm = ({
  valid,
  submitting,
  pristine,
  updateUser,
  handleSubmit,
  excludePhone,
  excludePassword,
  isSaving,
  setCTA,
  disableCTA,
  displayTerms,
  initialValues,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);

  const shouldShowSubmitButton = !updateUser;

  function onHandleSubmit(event) {
    handleSubmit();
    event.preventDefault?.();
  }

  function togglePasswordShown() {
    setPasswordShown(!passwordShown);
  }
  function editNameValid(isValid) {
    setLastNameIsValid(isValid);
  }
  function editPhoneValid(isValid) {
    setPhoneIsValid(isValid);
  }

  useEffect(() => {
    if (!setCTA) return;

    if (phoneIsValid && lastNameIsValid && disableCTA) {
      setCTA(false);
    }
    if ((!phoneIsValid || !lastNameIsValid) && !disableCTA) {
      setCTA(true);
    }
  }, [phoneIsValid, lastNameIsValid, setCTA, disableCTA]);

  return (
    <form onSubmit={onHandleSubmit} className="sign-up-form">
      <div className="sign-up-form__first-name-column">
        <Field
          name="firstName"
          className="form-control"
          placeholder="First Name"
          component={RenderField}
          field="first name"
        />
      </div>
      <div className="sign-up-form__last-name-column">
        <Field
          name="lastName"
          className="form-control"
          placeholder="Last Name"
          component={RenderField}
          field="last name"
          editNameValid={(isValid) => {
            editNameValid(isValid);
          }}
          showError={initialValues?.email && !initialValues?.lastName && !lastNameIsValid}
        />
      </div>
      <div className="sign-up-form__email-row">
        <Field
          name="email"
          type="email"
          className="form-control"
          placeholder="Email"
          component={RenderField}
          field="email"
        />
      </div>
      {excludePhone ? null : (
        <>
          <div className="sign-up-form__phone-row">
            <Field
              name="phone"
              type="tel"
              className="form-control"
              placeholder="Phone (for delivery reminders)"
              component={RenderField}
              field="phone"
              editPhoneValid={(isValid) => {
                editPhoneValid(isValid);
              }}
              showError={initialValues?.email && !initialValues?.phone && !phoneIsValid}
            />
          </div>
          {displayTerms ? (
            <div className="sign-up-page__phone-disclaimer">
              By entering your phone number, you agree to receive text messages from Good Eggs
              regarding your order. Standard message rates apply. Only U.S. based numbers are
              allowed.
            </div>
          ) : null}
        </>
      )}
      {excludePassword ? null : (
        <div className="sign-up-form__password-row">
          <Field
            name="password"
            className="form-control"
            placeholder="Password"
            type={passwordShown ? 'text' : 'password'}
            component={RenderField}
            field="password"
          />
          <i
            className={`icon ${passwordShown ? 'icon-eye-open-slash' : 'icon-eye-open'}`}
            onClick={() => {
              togglePasswordShown();
            }}
          />
        </div>
      )}
      {shouldShowSubmitButton ? (
        <SubmitButton
          className="sign-up-page__submit"
          isSaving={isSaving}
          disabled={!valid || submitting || pristine}
          workingLabel={SignUpFormCta}
        >
          {SignUpFormCta}
        </SubmitButton>
      ) : null}
    </form>
  );
};

SignUpForm.propTypes = {
  valid: bool,
  submitting: bool,
  pristine: bool,
  updateUser: bool,
  handleSubmit: func,
  excludePhone: bool,
  excludePassword: bool,
  isSaving: bool,
  setCTA: func,
  disableCTA: bool,
  displayTerms: bool,
};

export default reduxForm({
  form: 'signUp',
  validate,
  touchOnBlur: true,
})(SignUpForm);
