import React, {ReactElement} from 'react';

interface PlaceholderProduct {
  vendor?: {
    name: string;
  };
  vendorUrl: string;
  url: string;
  name: string;
}

interface PlaceHolderProps {
  productInfo?: PlaceholderProduct;
}

const PlaceHolder = ({productInfo}: PlaceHolderProps): ReactElement => {
  return (
    <div className="grid-item-placeholder">
      <div className="product-tile__placeholder-title">
        <a href={productInfo?.vendorUrl}>{productInfo?.vendor?.name}</a>
      </div>
      <div className="product-tile__placeholder-title">
        <a href={productInfo?.url}>{productInfo?.name}</a>
      </div>
    </div>
  );
};

export default PlaceHolder;
