import React from 'react';
import {string} from 'prop-types';
import {formatPhone} from 'goodeggs-formatters';

const MinimalFooter = (props) => (
  <footer className="minimal-footer">
    <div className="minimal-footer__container">
      <span className="minimal-footer__note">Need help? We{"'"}re here!</span>
      <div className="minimal-footer__line-break" />
      <a
        className="minimal-footer__email"
        target="_blank"
        rel="noopener"
        href={`mailto:${props.email}`}
      >
        {props.email}
      </a>
      <div className="minimal-footer__line-break" />
      <a className="minimal-footer__phone" href={`tel:${props.phoneNumber}`}>
        {formatPhone(props.phoneNumber)}
      </a>
    </div>
  </footer>
);

MinimalFooter.propTypes = {
  email: string,
  phoneNumber: string,
};

export default MinimalFooter;
