import React, {Component} from 'react';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import classNames from 'classnames';

import segmentAnalytics from '@analytics/client';
import {BasketLeanDocument} from 'domain/baskets/models/basket';
import Alert from 'web/components/alert';
import Input from 'web/components/input';
import SubmitButton from 'web/components/submit_button';
import {actions as zipCodeActions} from 'web/helpers/zip_code_duck';
import {ClientUser} from 'domain/users/client_types';

export interface FoodhubZipCode {
  zipCode: string;
  foodhubSlug?: string | null;
}

export interface PreviousFoodhubZipCode {
  zipCode?: string | null;
  foodhubSlug?: string | null;
}
export interface UpdateZipCodeResponse {
  data: {
    new: FoodhubZipCode;
    previous: PreviousFoodhubZipCode | null;
  };
  result: string;
}
type ErrorHandler = (response: UpdateZipCodeResponse) => unknown;
type SuccessHandler = (response: UpdateZipCodeResponse) => unknown;

interface ZipCodeCheckParams {
  zipCode: string;
  prevZipCode: string | null;
  fulfillmentDay: string;
  user: ClientUser;
  confirmFoodhubChange: boolean;
  checkHandler: (
    response: UpdateZipCodeResponse,
    successHandler: SuccessHandler,
    errorHandler: ErrorHandler,
  ) => unknown;
  successHandler: SuccessHandler;
  errorHandler: ErrorHandler;
}
interface DispatchProps {
  onZipCodeCheck: (params: ZipCodeCheckParams) => void;
}

interface StateProps {
  currentFulfillmentDay: string;
  user: ClientUser;
  zipCode?: string;
  basket: BasketLeanDocument;
}

export interface ZipCodeFormProps {
  confirmFoodhubChange?: boolean;
  successHandler: SuccessHandler;
  errorHandler: ErrorHandler;
}

interface Props extends DispatchProps, StateProps, ZipCodeFormProps {}

interface ZipCodeState {
  error: string | null;
  input: string;
}

class ZipCodeForm extends Component<Props, ZipCodeState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      input: '',
    };
  }

  public async handleSubmit(): Promise<void> {
    const zipCodeEntry = this.state.input;
    const zipCode = zipCodeEntry ? zipCodeEntry.trim() : '';
    this.setState({error: null});
    if (!/^\d{5}$/.test(zipCode)) {
      this.setState({error: "Oops - that doesn't quite look right"});
      return;
    }

    this.props.onZipCodeCheck({
      zipCode,
      prevZipCode: this.props.zipCode ?? null,
      fulfillmentDay: this.props.currentFulfillmentDay,
      confirmFoodhubChange:
        this.props.confirmFoodhubChange ?? this.props.basket.items?.length === 0,
      user: this.props.user,
      checkHandler: this.handleCheck.bind(this),
      successHandler: (response: UpdateZipCodeResponse) => {
        this.setState({input: ''});
        this.props.successHandler(response);
      },
      errorHandler: (response: UpdateZipCodeResponse) => {
        this.setState({input: ''});
        this.props.errorHandler(response);
      },
    });
  }

  public handleCheck(
    response: UpdateZipCodeResponse,
    successHandler: SuccessHandler,
    errorHandler: ErrorHandler,
  ): void {
    const {foodhubSlug} = response.data.new;
    const deliveryProps = {
      deliveryEligible: foodhubSlug != null,
      pageUrl: window.location.href,
      zipCode: response.data.new.zipCode,
    };
    segmentAnalytics.track('deliveryAreaChecked', deliveryProps);

    if (foodhubSlug != null) {
      // Success: queried zip has a foodhub
      successHandler(response);
    } else {
      errorHandler(response);
    }
  }

  public isZipCodeValid(): boolean {
    return this.state.input.length === 5;
  }

  public render(): React.ReactElement {
    return (
      <>
        {this.state.error ? (
          <Alert className="flow-zip-entry__errors" type="error" heading={this.state.error} />
        ) : null}
        <form
          className="flow-zip-entry-form"
          onSubmit={(e): void => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="sign-in-flow__zip-code-collection">
            <Input
              type="text"
              className="flow-zip-entry_refresh_modal__input js-zip-input"
              placeholder="Zip Code"
              value={this.state.input}
              onChange={(event): void => this.setState({input: event.target.value})}
            />
            <SubmitButton
              disabled={this.state.input.length !== 5}
              className={classNames('js-submit-zip', {
                'disabled-buttons__modal': !this.isZipCodeValid(),
                action__button: this.isZipCodeValid(),
              })}
            >
              Continue
            </SubmitButton>
          </div>
        </form>
      </>
    );
  }
}

function mapStateToProps(state: StateProps): StateProps {
  return {
    currentFulfillmentDay: state.currentFulfillmentDay,
    user: state.user,
    zipCode: state.zipCode,
    basket: state.basket,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<DispatchProps, Props, AnyAction>,
): DispatchProps {
  return {
    onZipCodeCheck: async ({
      zipCode,
      prevZipCode,
      fulfillmentDay,
      confirmFoodhubChange,
      checkHandler,
      successHandler,
      errorHandler,
    }: ZipCodeCheckParams): Promise<void> => {
      const response = await dispatch(
        zipCodeActions.updateZipCode(fulfillmentDay, zipCode, prevZipCode, confirmFoodhubChange),
      );
      checkHandler(response, successHandler, errorHandler);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodeForm);
