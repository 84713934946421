import React from 'react';

import moment from 'infra/moment';
import assetPath from 'web/helpers/asset_path';
import useClientSettings from 'web/hooks/useClientSettings';

import ShareLinks from './share_links';

export interface DesktopFooterProps {
  preLogin: boolean;
}

const DesktopFooter: React.FC<DesktopFooterProps> = ({preLogin}) => {
  const settings = useClientSettings();
  const newLogosEnabled = settings.enableNewLogos.enableWeb;
  const logoColor = newLogosEnabled ? 'green' : 'white';
  const backgroundColor = newLogosEnabled ? 'forest' : 'black';

  return (
    <footer
      id="footer"
      className={`desktop-footer desktop-footer__${backgroundColor}`}
      data-testid="desktop-footer"
    >
      <div className="desktop-footer__content">
        <div className="desktop-footer__row">
          <div className="desktop-footer__logotag">
            <div className="desktop-footer__logo">
              <a href="https://www.goodeggs.com/home">
                <img
                  alt={`goodeggs logo in ${logoColor}`}
                  src={`${assetPath(`/img/svg/icons/logo_${logoColor}.svg`)}?auto=format`}
                />
              </a>
            </div>
            {!newLogosEnabled && (
              <div className="desktop-footer__tagline">
                <img
                  alt="a radically fresh market"
                  src={`${assetPath('/img/svg/icons/tagline-white.svg')}?auto=format`}
                />
              </div>
            )}
          </div>
          <div className="desktop-footer__links col-normal">
            <div className="desktop-footer__title">Shop</div>
            <ul>
              <li>
                <a href="/produce#produce-peak-season">Shop Featured Items</a>
              </li>
              <li>
                <a href="/meal-solutions">Meal Solutions</a>
              </li>
              <li>
                <a href="/giftcards">Gift Cards</a>
              </li>
              {!preLogin && (
                <li>
                  <a href="/refer-a-friend">Refer A Friend</a>
                </li>
              )}
              <li>
                <a href="/join-membership">Join Membership</a>
              </li>
            </ul>
          </div>
          <div className="desktop-footer__links col-normal">
            <div className="desktop-footer__title">About Good Eggs</div>
            <ul>
              <li>
                <a href="https://about.goodeggs.com/">About Us</a>
              </li>
              <li>
                <a href="https://blog.goodeggs.com/">Blog</a>
              </li>
              <li>
                <a href="/about/standards">Food Standards</a>
              </li>
              {!preLogin && (
                <li>
                  <a href="/how-it-works">How It Works</a>
                </li>
              )}
              <li>
                <a href="https://careers.goodeggs.com/">Careers</a>
              </li>
              <li>
                <a href="https://about.goodeggs.com/sell-your-goods">Sell Your Goods</a>
              </li>
            </ul>
          </div>
          <div className="desktop-footer__links col-normal">
            <div className="desktop-footer__title">Holiday Guides</div>
            <ul>
              <li>
                <a href="https://thanksgiving.goodeggs.com/">Thanksgiving</a>
              </li>
              <li>
                <a href="https://holiday.goodeggs.com/">Winter Holidays</a>
              </li>
            </ul>
          </div>
          <div className="desktop-footer__links col-normal">
            <div className="desktop-footer__title">Need Help?</div>
            <ul>
              <li>
                <a href="https://good-eggs.trsnd.co/policies">Privacy Policy</a>
              </li>
              <li>
                <a href="https://help.goodeggs.com/hc/en-us/requests/new">Contact Us</a>
              </li>
              <li>
                <a href="https://help.goodeggs.com">Help Center</a>
              </li>
            </ul>
            <div className="desktop-footer__app_title">Get the App</div>

            <div className="download-stores">
              <a href="https://www.goodeggs.com/app" className="store-link">
                <img
                  alt="apple icon"
                  className="app-store-ios"
                  src={`${assetPath('/img/svg/icons/apple.svg')}?auto=format`}
                />
              </a>
              <a href="https://www.goodeggs.com/app" className="store-link">
                <img
                  alt="android icon"
                  className="google-play-android"
                  src={`${assetPath('/img/svg/icons/android.svg')}?auto=format`}
                />
              </a>
            </div>
          </div>
          <ShareLinks />
        </div>
      </div>
      <div className="desktop-footer__terms">
        <span>{`© ${moment().tz(settings.tzid).format('YYYY')} Good Eggs, Inc.`}</span>
        <a href="/terms">Terms &amp; Privacy</a>
      </div>
    </footer>
  );
};

export default DesktopFooter;
