import {formatPhone} from 'goodeggs-formatters';
import React, {Component} from 'react';

import assetPath from 'web/helpers/asset_path';

export default class PrintableGiftCard extends Component {
  render() {
    return (
      <div className="printable-gift-card">
        <div className="printable-gift-card__to-and-from-row">
          <h3>
            <span className="printable-gift-card__to-name">
              To: {this.props.giftCard.recipientName}
            </span>
            <span className="printable-gift-card__from-name">
              From: {this.props.giftCard.fromName}
            </span>
          </h3>
          {this.props.giftCard.message ? (
            <p className="printable-gift-card__message">{this.props.giftCard.message}</p>
          ) : null}
        </div>
        <div className="printable-gift-card__card-row">
          <div className="printable-gift-card__card-left-col">
            <img
              src={`${assetPath('/img/jujube/gift_cards/giftcard-photo.png')}?auto=format`}
              alt="Gift Card"
            />
          </div>
          <div className="printable-gift-card__card-right-col">
            <h3 className="printable-gift-card__amount">${this.props.giftCard.amount}</h3>
            <p className="printable-gift-card__redeem-instructions">
              Fill your basket at goodeggs.com then use your code at checkout:
            </p>
            <div className="printable-gift-card__code">
              {this.props.giftCard.code.toUpperCase()}
            </div>
            <div className="printable-gift-card__redeem-later">
              Your gift card credit never expires.
            </div>
          </div>
        </div>
        <div className="printable-gift-card__call-us">
          Feel free to give us a ring at {formatPhone(this.props.eaterHelp.phone)} with any
          questions!
        </div>
      </div>
    );
  }
}
