import {postJson} from 'web/helpers/api_helpers';

export function add(productId, opts = {}) {
  const {context} = opts;
  return postJson('/api/favorites/add-favorite', {
    body: {
      productId,
    },
    metricsProperties: context || {},
  });
}

export function remove(productId, opts = {}) {
  const {context} = opts;
  return postJson('/api/favorites/remove-favorite', {
    body: {
      productId,
    },
    metricsProperties: context || {},
  });
}
