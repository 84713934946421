import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Alert from 'web/components/alert';

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default class CheckoutErrors extends Component {
  componentDidMount() {
    if (this.componentDiv && !isElementInViewport(this.componentDiv)) {
      window.scrollTo(0, this.componentDiv.offsetTop - 80);
    }
  }

  render() {
    let heading;
    switch (this.props.type) {
      case 'SOLD_OUT':
        heading = this.props.message || 'Some products in your basket have sold out.';
        break;
      case 'PAYMENT_ERROR':
        heading = 'There was a problem with your payment.';
        break;
      case 'PROMO_CODE_UNAVAILABLE':
        heading = this.props.message || 'Sorry, your promo code has expired.';
        break;
      default:
        heading = 'Uh oh, something went wrong.';
    }
    let body;
    switch (this.props.type) {
      case 'SOLD_OUT':
      case 'BELOW_MINIMUM':
      case 'PROMO_CODE_UNAVAILABLE':
        body = <a href="/basket">Please review your basket.</a>;
        break;
      default:
        body = this.props.message;
    }
    return (
      <div
        ref={(componentDiv) => {
          this.componentDiv = componentDiv;
        }}
        className="checkout__warning"
      >
        <Alert type="warning" heading={heading}>
          {body}
        </Alert>
      </div>
    );
  }
}

CheckoutErrors.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};
