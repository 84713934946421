import {formatPhone} from 'goodeggs-formatters';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import rolodex from 'goodeggs-rolodex';

import {humanPriceFromCents} from 'infra/formatters/money';

export default class PromoCodeRedeemedBanner extends Component {
  constructor() {
    super();
    this.state = {
      dismissed: false,
    };
  }

  describePromoCode({code, label, type, value}) {
    switch (type) {
      case 'percent':
        return `${value}% off`;
      case 'dollar':
        return humanPriceFromCents(value);
      default:
        return `"${label || code}"`;
    }
  }

  title() {
    if (this.props.redeemPromoCode.error) {
      return 'Discount Not Applicable';
    } else if (this.props.copyOverrides.confirmationTitle) {
      return this.props.copyOverrides.confirmationTitle;
    }

    if (this.props.redeemPromoCode.referral) {
      return 'Referral Discount Applied!';
    }

    if (this.props.redeemPromoCode.promo) {
      return 'Promo Code Applied!';
    }
  }

  successMessage() {
    const {confirmationMessageNoDiscount, confirmationMessage} = this.props.copyOverrides;

    if (this.props.redeemPromoCode.promo) {
      if (this.props.redeemPromoCode.promoCode.value === 0) {
        return confirmationMessageNoDiscount || '';
      }
      return (
        confirmationMessage ||
        `We've added ${this.describePromoCode(
          this.props.redeemPromoCode.promoCode,
        )} to your basket.`
      );
    }

    if (this.props.redeemPromoCode.referral) {
      return (
        confirmationMessage ||
        'Your referral discount has been successfully applied to your basket.'
      );
    }
  }

  errorMessage() {
    return this.props.copyOverrides && this.props.copyOverrides.redeemedCodeError
      ? this.props.copyOverrides.redeemedCodeError
      : '';
  }

  helpMessage() {
    return (
      <div>
        Need help? We{"'"}re here.{' '}
        <a
          className="redeem-promo-code-modal__need-help-link"
          target="_blank"
          rel="noopener"
          href={`mailto:${rolodex.eaterHelpEmailAddress()}`}
        >
          {'Email us '}
        </a>
        {'or call us at '}
        <a
          className="redeem-promo-code-modal__need-help-link"
          href={`tel:${formatPhone(rolodex.eaterHelpPhoneNumber())}`}
        >
          {`${formatPhone(rolodex.eaterHelpPhoneNumber())}`}
        </a>
      </div>
    );
  }

  onDismiss() {
    this.setState({dismissed: true});
  }

  render() {
    return this.state.dismissed ? null : (
      <div className="promo-code-redeemed-banner__main">
        <h2 className="promo-code-redeemed-banner__title">{this.title()}</h2>
        <div className="promo-code-redeemed-banner__description">
          {this.props.redeemPromoCode.error ? this.errorMessage() : this.successMessage()}
        </div>
        {this.props.redeemPromoCode.error ? this.helpMessage() : null}
        <div
          className="promo-code-redeemed-banner__close_button"
          onClick={() => {
            this.onDismiss();
          }}
        >
          <i className="icon icon-thin-x" />
        </div>
      </div>
    );
  }
}

PromoCodeRedeemedBanner.propTypes = {
  redeemPromoCode: PropTypes.object.isRequired,
  copyOverrides: PropTypes.shape({
    confirmationCTA: PropTypes.string,
    confirmationTitle: PropTypes.string,
    confirmationMessage: PropTypes.string,
    confirmationMessageNoDiscount: PropTypes.string,
    redeemedCodeError: PropTypes.string,
  }),
};
