import React, {FC, PropsWithChildren} from 'react';
import classNames from 'classnames';

type AlertType = 'confirmation' | 'warning' | 'error' | 'info';
export interface AlertProps {
  type?: AlertType;
  closeable?: boolean;
  heading: string;
  className?: string;
}

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  type,
  closeable,
  heading,
  className,
  children,
}) => {
  const alertType = type ?? '';
  return (
    <div className={classNames('ui-alert', `is-${alertType}`, className)}>
      <div className={classNames('ui-alert__line', `is-${alertType}`)}>
        <span className={classNames('ui-alert__icon', `is-${alertType}`)}>
          <i data-testid="icon" className={classNames('icon', `icon-alert-${alertType}`)} />
        </span>
        <span className="ui-alert__heading">{heading}</span>

        {closeable ? (
          <a data-testid="close-icon" className="ui-alert__close js-ui-alert__close" href="#">
            {'\u2715'}
          </a>
        ) : (
          <span className="ui-alert__close" />
        )}
      </div>
      <div className="ui-alert__line">
        <span className="ui-alert__spacer" />
        <span className="ui-alert__body">{children}</span>
      </div>
    </div>
  );
};

export default Alert;
