import React, {FC} from 'react';

import ModalStack from 'web/components/modal_stack';
import useClientSettings from 'web/hooks/useClientSettings';
import {UIUser} from 'web/helpers/serializers/user';
import {
  SerializedUiMarketCategory,
  SerializedUiMarketSubcategory,
} from 'web/helpers/serializers/category';

import MobileHeader from './components/mobile_header';
import DesktopHeader from './components/desktop_header';
import UpcomingOrderBanner from './components/upcoming_order_banner';
import PromoCodeRedeemedBanner from './components/promo_code_redeemed_banner';
import {ShoppingNotification} from '../..';
import {PromoCodeRedeemed, UpcomingOrder} from '../../store_builder';

interface HeaderProps {
  categories: SerializedUiMarketCategory[];
  selectedCategoryId?: string;
  selectedSubcategoryId?: string;
  onMenuClicked: () => void;
  onSearchClicked: () => void;
  disableBasketDropdown?: boolean;
  handleChangeDay: (day: string, shoppingNotification?: ShoppingNotification) => void;
  promoCodeRedeemed?: PromoCodeRedeemed | null;
  user?: UIUser | null;
  orders: UpcomingOrder[];
}

interface MobileLocalNavigation {
  show: boolean;
  categories: SerializedUiMarketCategory[];
  selectedCategoryName?: string;
  selectedSubcategoryName?: string;
  subcategories?: SerializedUiMarketSubcategory[];
}

const Header: FC<HeaderProps> = ({
  categories,
  selectedCategoryId,
  selectedSubcategoryId,
  onMenuClicked,
  onSearchClicked,
  disableBasketDropdown,
  handleChangeDay,
  promoCodeRedeemed,
  user,
  orders,
}) => {
  const clientSettings = useClientSettings();

  const mobileLocalNavigation: MobileLocalNavigation = {
    show: false,
    categories,
  };

  let selectedCategory;
  if (selectedCategoryId) {
    selectedCategory = categories.find((category) => category.id === selectedCategoryId);
  }
  if (selectedCategory) {
    mobileLocalNavigation.show = true;
    let selectedSubcategory: SerializedUiMarketSubcategory | undefined;

    if (selectedSubcategoryId) {
      selectedSubcategory = selectedCategory.subcategories.find(
        (subcategory) => subcategory.id === selectedSubcategoryId,
      );
    }

    selectedSubcategory = selectedSubcategory || selectedCategory.subcategories[0];
    mobileLocalNavigation.selectedCategoryName = selectedCategory.name;
    mobileLocalNavigation.subcategories = selectedCategory.subcategories;
    mobileLocalNavigation.selectedSubcategoryName = selectedSubcategory
      ? selectedSubcategory.name
      : '';
  }

  return (
    <>
      <MobileHeader
        stuck
        onMenuClicked={onMenuClicked}
        onSearchClicked={onSearchClicked}
        showBreadcrumb={mobileLocalNavigation.show}
        breadcrumbProp={mobileLocalNavigation}
      />
      <DesktopHeader
        handleChangeDay={handleChangeDay}
        disableBasketDropdown={disableBasketDropdown}
      />
      {promoCodeRedeemed && (
        <PromoCodeRedeemedBanner
          redeemPromoCode={promoCodeRedeemed.redeemPromoCode}
          copyOverrides={promoCodeRedeemed.promoCodeCopyOverrides}
          user={user}
        />
      )}
      <div>
        {orders.map((order) => (
          <UpcomingOrderBanner order={order} tzid={clientSettings.tzid} key={order.id} />
        ))}
      </div>
      <ModalStack />
    </>
  );
};

export default Header;
