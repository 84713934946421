/*
 * We track some Segment Semantic Ecommerce events (like 'Completed Order')
 * on both the client and server side.  See infra/metrics/README.md for details.
 * This module is responsible for routing either the client or server versions of the events
 * (bit not both) to our Segment integrated services.
 *
 * Note: our Redshift warehouse will get both versions.  We filter should filter
 * out client events for analysis.
 *
 * For available service names and syntax, see:
 * https://segment.com/docs/libraries/analytics.js/#selecting-integrations
 */

const SERVICE_MAP = {
  'Checkout Completed': {
    All: 'all',
    'Google Tag Manager': 'client',
  },
};

const tiersToFlags = function (integrationTiers, tierWhitelist = []) {
  const integrationFlags = {};
  const object = integrationTiers != null ? integrationTiers : {};
  for (const [service, tier] of Object.entries(object)) {
    integrationFlags[service] = tierWhitelist.includes(tier);
  }
  return integrationFlags;
};

export default {
  SERVICE_MAP,

  // Events that aren't explicitly routed go to all integrations from all tiers.
  // Returns an object in the format expected by https://segment.com/docs/libraries/analytics.js/#selecting-integrations
  buildClientIntegrations(eventName) {
    return tiersToFlags(SERVICE_MAP[eventName], ['client', 'all']);
  },

  // Events that aren't explicitly routed go to all integrations from all tiers.
  // Returns an object in the format expected by https://segment.com/docs/libraries/node/#selecting-integrations
  buildServerIntegrations(eventName) {
    return tiersToFlags(SERVICE_MAP[eventName], ['server', 'all']);
  },
};
