import $ from 'jquery';
import {throttle} from 'lodash';
import React, {PureComponent} from 'react';
import classNames from 'classnames';

import analytics from '@analytics/client';

const MINI_HEADER_HEIGHT = 55; // px

class SidebarMenuGroup extends PureComponent {
  handleClick() {
    this.props.onMenuGroupClick(this.props.menuGroup.id);
  }

  componentDidUpdate() {
    if (this.props.pushAnchor && this.props.active && window.history) {
      let target = this.props.menuGroup.id;
      if (this.props.activeMenuItemId) {
        target += `-${this.props.activeMenuItemId}`;
      }
      window.history.replaceState(null, null, `#${target}`);
    }
  }

  handleEvent({filter, secondaryFilter}) {
    const url = window.location.href.split(window.location.host)[1].split('#')[0];
    analytics?.track('productCategoryFiltered', {
      destination: secondaryFilter ? `${url}#${filter}-${secondaryFilter}` : `${url}#${filter}`,
      filter,
      secondaryFilter,
      pageUrl: window.location.href.split(window.location.host)[1],
    });

    // For main category, we call handleClick property to expand section
    if (secondaryFilter === undefined) this.handleClick();
  }

  render() {
    const {menuGroup} = this.props;
    return (
      <div>
        <a
          className={classNames('market-sidebar__menu-group-title', {selected: this.props.active})}
          data-testid="market-sidebar__menu-group-title"
          href={`#${menuGroup.id}`}
          onClick={() => {
            this.handleEvent({
              filter: menuGroup.id,
            });
          }}
        >
          {menuGroup.displayName}
        </a>
        <div className={classNames('market-sidebar__menu-group', {expanded: this.props.active})}>
          {menuGroup.menuItems.length > 1
            ? menuGroup.menuItems.map((menuItem) => (
                <React.Fragment key={`${menuGroup.id}-${menuItem.id}`}>
                  <div className="market-sidebar__menu-item-spacer" />
                  <a
                    className={classNames('market-sidebar__menu-item', {
                      selected: this.props.activeMenuItemId === menuItem.id,
                    })}
                    href={`#${menuGroup.id}-${menuItem.id}`}
                    onClick={() => {
                      this.handleEvent({
                        filter: menuGroup.id,
                        secondaryFilter: menuItem.id,
                      });
                    }}
                  >
                    {`${menuItem.displayName}`}
                  </a>
                  <div className="market-sidebar__menu-item-spacer" />
                </React.Fragment>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default class MarketSidebar extends PureComponent {
  constructor() {
    super();
    this.marketSidebar = React.createRef();
    this.state = {
      stuck: false,
    };
    this.headerOffset = 55;
  }

  componentDidMount() {
    $(window).scroll(throttle(this.onScroll, 100));
  }

  handleMenuGroupClick = (menuGroupId) => {
    this.setState({activeMenuGroupId: menuGroupId});
  };

  onScroll = () => {
    const boundingRect = this.marketSidebar.current.parentElement.getBoundingClientRect();
    const stuck = boundingRect.top < MINI_HEADER_HEIGHT;

    const footerEl = document.querySelector('#footer');
    const sidebarEl = document.querySelector('.market-sidebar__content');
    // Anchor sidebar to window height - visible footer height - subcategories height
    // so that it effectively is 'pushed' up by the footer
    const offsetTop = Math.min(
      this.headerOffset,
      footerEl ? footerEl.getBoundingClientRect().top - sidebarEl.offsetHeight : 0,
    );

    const {activeMenuGroupId, activeMenuItemId} = this.findActiveMenuItem();
    // Short circuit if we don't have any changes, 'cause this method is run often.
    if (
      this.state.stuck !== stuck ||
      this.state.offsetTop !== offsetTop ||
      this.state.activeMenuGroupId !== activeMenuGroupId ||
      this.state.activeMenuItemId !== activeMenuItemId
    ) {
      this.setState({
        stuck,
        offsetTop,
        activeMenuGroupId,
        activeMenuItemId,
      });
    }
  };

  isAnchorContentsVisible = (anchorId) => {
    const anchorEl = $(`#${anchorId}`)[0];
    if (anchorEl) {
      // quick hack to get the styleguide using a pretty sidebar. Styleguide anchors the container itself
      // whereas market pages anchor inside the container
      const listingsEl = this.props.anchorAtContainer ? anchorEl : anchorEl.parentElement;
      return listingsEl.getBoundingClientRect().bottom > 100;
    }

    return false;
  };

  findActiveMenuItem = () => {
    for (let i = 0; i < this.props.menuGroups.length; i++) {
      const menuGroup = this.props.menuGroups[i];
      if (this.isAnchorContentsVisible(menuGroup.id)) {
        const activeMenuGroupId = menuGroup.id;
        let activeMenuItemId = null;
        if (menuGroup.menuItems.length > 1) {
          for (let j = 0; j < menuGroup.menuItems.length; j++) {
            const menuItem = menuGroup.menuItems[j];
            if (this.isAnchorContentsVisible(`${menuGroup.id}-${menuItem.id}`)) {
              activeMenuItemId = menuItem.id;
              break;
            }
          }
        }
        return {activeMenuItemId, activeMenuGroupId};
      }
    }
    return {};
  };

  render() {
    return (
      <div
        id="market-sidebar"
        data-testid="market-sidebar"
        className={classNames('market-sidebar', this.props.className, {stuck: this.state.stuck})}
        ref={this.marketSidebar}
        style={{
          top: this.state.offsetTop,
        }}
      >
        <div className="market-sidebar__content">
          {this.props.menuGroups.map((menuGroup) => (
            <SidebarMenuGroup
              key={menuGroup.id}
              menuGroup={menuGroup}
              onMenuGroupClick={this.handleMenuGroupClick}
              active={this.state.activeMenuGroupId === menuGroup.id}
              activeMenuItemId={this.state.activeMenuItemId}
              pushAnchor={this.props.pushAnchor === 'true'}
            />
          ))}
        </div>
      </div>
    );
  }
}
