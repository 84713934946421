import update from 'immutability-helper';
import {getFormValues, getFormSyncErrors, isValid, submit} from 'redux-form';

import {login} from 'web/helpers/user_duck/api_client';
import {actions as signUpFormActions} from 'web/components/sign_up_form/duck';
import {serializeUser} from 'web/helpers/serializers/user';
import {actions as modalActions} from 'web/helpers/modal_duck';

export const SIGNING_IN = 'SIGN_IN_FORM_SIGNING_IN_BEGIN';
export const FINISH_SIGNING_IN = 'SIGN_IN_FORM_SIGNING_IN_FINISH';
export const ERROR_SIGNING_IN = 'SIGN_IN_FORM_SIGNING_IN_ERROR';
export const CLEAR_ERRORS = 'SIGN_IN_FORM_CLEAR_ERRORS';

export const actions = {
  submitForm:
    ({rejectUponError = false} = {}) =>
    (dispatch, getState) => {
      const state = getState();
      dispatch(submit('signIn'));
      const formIsValid = isValid('signIn')(state);
      if (formIsValid) {
        dispatch({type: SIGNING_IN});
        const {email, password} = getFormValues('signIn')(state);
        return login({email, password})
          .then((data) => {
            dispatch({
              type: FINISH_SIGNING_IN,
            });
            dispatch(signUpFormActions.onSignInSuccessful(serializeUser(data)));
            return dispatch(modalActions.closeModal({modal: 'SignInModal'}));
          })
          .catch((error) => {
            dispatch({
              type: ERROR_SIGNING_IN,
              error,
            });
            if (rejectUponError) {
              return Promise.reject(error);
            }
          });
      }
      if (rejectUponError) {
        const fieldErrors = getFormSyncErrors('signIn')(state);
        const error = {type: 'INVALID_FORM', message: 'Form is invalid', details: fieldErrors};
        return Promise.reject(error);
      }
      return Promise.resolve();
    },

  clearErrors: () => ({
    type: CLEAR_ERRORS,
  }),
};

const initialState = {
  isAuthenticating: false,
  error: null,
};

// Wire this into your page reducer alongside the redux-form reducer.
export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SIGNING_IN:
      return update(state, {isAuthenticating: {$set: true}});

    case FINISH_SIGNING_IN:
      return update(state, {isAuthenticating: {$set: false}, error: {$set: null}});

    case ERROR_SIGNING_IN:
      return update(state, {isAuthenticating: {$set: false}, error: {$set: action.error}});

    case CLEAR_ERRORS:
      return update(state, {error: {$set: null}});
  }
  return state;
}
