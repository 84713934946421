import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import ZipCodeForm from 'web/components/zip_code';
import {actions as modalActions} from 'web/helpers/modal_duck';

class FlowZipEntryScreen extends Component {
  onSuccess(response) {
    this.props.onCheckZipCode(response.data.new.zipCode, this.props.onZipSuccessAction);
  }

  onError = (response) => {
    if (this.props.onDismiss != null) {
      this.props.onDismiss();
    }
    this.props.openConsolationModal({
      newZip: response.data.new.zipCode,
      comingSoon: false, // placeholder until endpoint includes this data
    });
  };

  renderZipCollection() {
    return (
      <div className="flow-zip-entry__wrapper">
        <div className="flow-zip-entry-form">
          <div className="sign-in-flow__zip-code-collection ">
            <ZipCodeForm
              confirmFoodhubChange
              successHandler={this.onSuccess.bind(this)}
              errorHandler={this.onError.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const marginLeft = window.screen.width < 1024 ? '' : 'desktopMargin__modal';
    return (
      <div className={`flow-zip-entry ${marginLeft}`}>
        <div>
          <h1 className="sign-in-flow_refresh_modal__title">
            Absurdly fresh groceries, delivered.
          </h1>

          <div className="flow-zip-entry_refresh_modal__subtitle">
            Enter your zip code to see if we deliver to your address.
          </div>

          {this.renderZipCollection()}
        </div>
      </div>
    );
  }
}

FlowZipEntryScreen.propTypes = {
  closeModal: PropTypes.func,
  onDismiss: PropTypes.func,
  onZipSuccessAction: PropTypes.func,
  openConsolationModal: PropTypes.func,
  onCheckZipCode: PropTypes.func,
  zipCode: PropTypes.string,
  user: PropTypes.object,
};

export const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (cb) => {
      dispatch(modalActions.closeModal());
      if (cb != null) {
        cb();
      }
    },
    openAuthModal: (props) => {
      dispatch(modalActions.replaceModal({modal: 'AuthFlowModal', props}));
    },
    openConsolationModal: (props) => {
      dispatch(modalActions.replaceModal({modal: 'ZipCodeConsolationModal', props}));
    },
  };
};

const connector = connect(null, mapDispatchToProps);

export default connector(FlowZipEntryScreen);
