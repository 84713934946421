import React, {FC, PropsWithChildren} from 'react';

export interface ModalHeaderProps {
  isModal: boolean;
  headingText: string;
  onClose: () => void;
}

const ModalHeader: FC<PropsWithChildren<ModalHeaderProps>> = ({headingText, isModal, onClose}) => (
  <>
    {isModal && (
      <>
        <div className="close-modal__icon">
          <i className="icon icon-thin-x" data-testid="close-modal__icon" onClick={onClose} />
        </div>
        <div className="sign-up-modal__logo_container">
          <div className="goodeggs-workmark-logo" data-testid="close-modal__logo" />
        </div>
      </>
    )}

    <h1 className={isModal ? 'sign-up-modal__header' : ''}>{headingText}</h1>
  </>
);

export default ModalHeader;
