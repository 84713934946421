import React, {FC, MouseEvent, PropsWithChildren} from 'react';
import classNames from 'classnames';

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  onClick: (e: MouseEvent) => void;
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({type, className, onClick, children}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type ?? 'button'}
    onClick={onClick}
    className={classNames('action__button', className)}
    data-testid="ge-button"
  >
    {children}
  </button>
);

export default Button;
