import React, {ReactElement} from 'react';
import Skeleton from 'react-loading-skeleton';
import useAlgolia from 'use-algolia';
import {useSelector} from 'react-redux';

import useClientSettings from 'web/hooks/useClientSettings';
import segmentAnalytics from '@analytics/client';

interface Producer {
  name: string;
}

interface AlgoliaHit {
  name: string;
  objectID: string;
  photo: string;
  producer: Producer;
  url: string;
}

const PopularItemSkeleton = (): ReactElement => (
  <div className="popular-item__skeleton" data-testid="popular-item__skeleton">
    <Skeleton height={121} className="popular-item__skeleton__image" />
    <div className="popular-item__skeleton__info">
      <Skeleton width={Math.random() * (150 - 80) + 80} height={13} />
      <Skeleton height={18} />
    </div>
  </div>
);

const PopularItems = (): ReactElement => {
  const clientSettings = useClientSettings();
  const foodhubSlug = useSelector<{foodhubSlug: string}, string>((state) => state.foodhubSlug);
  const [searchState] = useAlgolia<AlgoliaHit>(
    clientSettings.search.algolia.app,
    clientSettings.search.algolia.key,
    clientSettings.search.algolia.index,
    {
      hitsPerPage: 6,
      filters: `availableFoodhubs:${foodhubSlug}`,
      attributesToRetrieve: ['name', 'objectID', 'photo', 'producer.name', 'url'],
      clickAnalytics: true,
    },
  );

  const handleClickLink = (productName: string): void => {
    segmentAnalytics.track('siteSearched', {
      query: productName,
      queryType: 'popular',
      index: clientSettings.search.algolia.index,
      queryID: searchState.response?.queryID,
    });
  };

  return (
    <div className="search-suggestions__popular-items">
      <h3>Popular Items Today</h3>
      <div className="popular-items">
        {searchState.loading
          ? Array.from({length: 6}).map((_, index) => <PopularItemSkeleton key={index} />)
          : searchState.hits.map((hit) => (
              <div key={hit.objectID} className="popular-item" data-testid="popular-item">
                <a
                  href={hit.url}
                  onClick={(): void => handleClickLink(hit.name)}
                  data-testid="popular-item__link-image"
                >
                  <img
                    src={`${hit.photo}?width=196`}
                    className="popular-item__image"
                    data-testid="popular-item__image"
                  />
                </a>
                <div className="popular-item__product-info">
                  <div
                    className="popular-item__producer-name"
                    data-testid="popular-item__producer-name"
                  >
                    {hit.producer.name}
                  </div>
                  <a
                    onClick={(): void => handleClickLink(hit.name)}
                    href={hit.url}
                    data-testid="popular-item__link-name"
                  >
                    <div className="popular-item__name" data-testid="popular-item__name">
                      {hit.name}
                    </div>
                  </a>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default PopularItems;
