import {AnyAction} from 'redux';

import segmentAnalytics from '@analytics/client';
import {ModalStackArray} from 'web/components/modal_stack';
import {DayChooserModal, UpcomingPreorderPeriod} from 'web/components/market_layout/store_builder';
import {ModalViewed} from '@analytics/client/generated';

import cookieHelper from './cookies';

export const CLOSE_LANDING_MODAL = 'LANDING_MODAL_CLOSE';
export const OPEN_DAY_CHOOSER_MODAL = 'HEADER_OPEN_DAY_CHOOSER_MODAL';
export const CLOSE_DAY_CHOOSER_MODAL = 'HEADER_CLOSE_DAY_CHOOSER_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REPLACE_MODAL = 'REPLACE_MODAL';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

export const actions = {
  openDayChooserModal: ({
    preorderPeriod,
    productAvailabilityDays = [],
    closeOnBack = false,
    redirectOnSelect = true,
  }: {
    preorderPeriod?: UpcomingPreorderPeriod;
    productAvailabilityDays?: unknown;
    closeOnBack?: boolean;
    redirectOnSelect?: boolean;
  }) => ({
    type: OPEN_DAY_CHOOSER_MODAL,
    preorderPeriod,
    productAvailabilityDays,
    redirectOnSelect,
    closeOnBack,
  }),
  closeDayChooserModal: () => ({
    type: CLOSE_DAY_CHOOSER_MODAL,
  }),
  openModal: ({modal, props}: {modal: string; props?: Record<string, unknown> | null}) => ({
    type: OPEN_MODAL,
    modal,
    props,
  }),
  closeModal: ({modal}: {modal?: string} = {}) => ({
    type: CLOSE_MODAL,
    modal,
  }),
  replaceModal: ({
    modal,
    props = {},
    modalViewed,
  }: {
    modal: string;
    props?: unknown | null;
    modalViewed?: ModalViewed;
  }) => {
    if (modalViewed) {
      const {ctaText, pageUrl, feature, loggedIn} = modalViewed;
      segmentAnalytics.track('modalViewed', {
        modalName: modal,
        ctaText,
        pageUrl,
        feature,
        loggedIn,
        experiments: cookieHelper.getExperimentsFlattened(),
      });
    }
    return {
      type: REPLACE_MODAL,
      modal,
      props,
      modalViewed,
    };
  },
};

export interface State {
  // TODO: (@shermam) Find where this comes from
  // and type it properly
  landingModal?: unknown | null;
  dayChooserModal: DayChooserModal;
  modalStack: ModalStackArray;
}

export function reducer<TState extends State>(state: TState, action: AnyAction): TState {
  switch (action.type) {
    case CLOSE_LANDING_MODAL:
      return {...state, ...{landingModal: null}};
    case OPEN_DAY_CHOOSER_MODAL:
      return {
        ...state,
        ...{
          dayChooserModal: {
            open: true,
            closeOnBack: action.closeOnBack,
            redirectOnSelect: action.redirectOnSelect,
            preorderPeriod: action.preorderPeriod,
            productAvailabilityDays: action.productAvailabilityDays,
          },
        },
      };
    case CLOSE_DAY_CHOOSER_MODAL:
      return {...state, ...{dayChooserModal: {open: false}}};
    case OPEN_MODAL: {
      const {modalStack} = state;
      if (modalStack.filter((m) => m[0] === action.modal).length === 0) {
        modalStack.push([action.modal, action.props]);
      }
      return {...state, modalStack};
    }
    case REPLACE_MODAL: {
      const {modalStack} = state;
      const {modalViewed, props, modal} = action;
      modalStack.splice(state.modalStack.length - 1, 1, [modal, props]);
      return {...state, modalStack, modalViewed};
    }
    case CLOSE_MODAL: {
      if (action.modal === CLOSE_ALL_MODALS) {
        return {...state, modalStack: []};
      }
      const stackMap = new Map<string, Record<string, unknown> | null>(state.modalStack);
      const deleteKey = action.modal ?? Array.from(stackMap)[stackMap.size - 1][0];
      stackMap.delete(deleteKey);
      return {...state, modalStack: Array.from(stackMap)};
    }
    default:
      return state;
  }
}
